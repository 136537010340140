import { useNavigation } from '@react-navigation/native';
import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { ScrollView, View, StyleSheet } from 'react-native';
import useSite from 'sites/contexts/sites';
import theme from 'styles';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import useOrder from 'orders/contexts/orders';
import getNextpage from 'common/utils/navigation/CleverNavigation';
import { PropTypes } from 'prop-types';
import useLocalOrders from 'orders/contexts/localOrders';

const OrderUserDetails = ({ route }) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { item: site } = useSite();
  const { item: order, setItem: setOrder, setFilter } = useOrder();
  const { localHistoryUser, setLocalHistoryUser } = useLocalOrders();
  const [formfields, setFormfields] = useState({
    name: localHistoryUser?.name || '',
    email: localHistoryUser?.email || '',
    phone: localHistoryUser?.phone || ':',
  });
  const [buttonValidState, setButtonValidState] = useState(false);

  /**
   * Update the fields validity state with on a field modification
   */
  const updateFieldState = (fieldName, fieldValue) => {
    setFormfields({
      ...formfields,
      [fieldName]: fieldValue,
    });
    setLocalHistoryUser((oldValues) => ({
      ...oldValues,
      [fieldName]: fieldValue,
    }));
  };

  /**
   * Save the fields and navigate to next page
   */
  const consolidateAndContinue = useCallback(async (currentOrder) => {
    let userId = localHistoryUser?.userId;
    const phone = formfields.phone.split(':')[1];

    if (!userId) {
      userId = Math.floor(Math.random() * 100) + Date.now(); // randomId
      setLocalHistoryUser({ userId });
    }
    setFilter('userId', userId);

    if (phone[0] === '0') {
      formfields.phone = `${formfields.phone.split(':')[0]}:${phone.slice(1)}`;
    }
    const { nextPage, newOrder } = await getNextpage({
      currentScreenName: route.name,
      currentOrder: { ...currentOrder, userInfo: { ...formfields }, userId },
      currentSite: site,
    });

    setOrder(newOrder);
    navigation.navigate(nextPage, { currentOrder: newOrder });
  }, [route.name, formfields, site, setOrder, navigation, setLocalHistoryUser, localHistoryUser, setFilter]);

  /**
   * Verify the form validity on the fields validity state updating
   */
  useEffect(() => {
    let isFormValid = true;

    // field.required doesn't exist, we just have key and value
    // const formRequiredFields = filterObject(formfields, (field) => (field.required));

    Object.keys(formfields).forEach((fieldName) => {
      const capitalizedFieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

      if (site[`isMandatory${capitalizedFieldName}`]
      && (!formfields[fieldName] || formfields[fieldName].trim() === '')) {
        isFormValid = false;
      }
    });

    setButtonValidState(isFormValid);
  }, [formfields, site]);

  return (
    <Page backgroundImage={site?.template.imageBackdrop}>

      <ScrollView style={[styles.innerView, { flex: 1, paddingBottom: 50 }]}>

        <View style={{ padding: theme.sizings.small }}>

          <View>
            {/* ici le texte si c'est à emporter, pour l'exemple, il faudra une condition autour liée
              au mode de livraison */
            }
            <Text
              isBold
              centered
              style={styles.text}
              customColor={site?.template?.backdropTextColor || theme.colors.dark}
            >
              {t('route.orderUserDetail')}
            </Text>

            <View style={{
              marginTop: theme.sizings.large,
            }}
            >

              <FormInput
                type="text"
                value={formfields?.name || ''}
                finalForm={false}
                label={`${t('auth.name')} ${site.isMandatoryName ? '*' : t('common.isFacultative')}`}
                // required does nothing
                onChange={(value) => {
                  updateFieldState('name', value);
                }}
              />
              <FormInput
                type="text"
                value={formfields?.email || ''}
                finalForm={false}
                label={`${t('auth.email')} ${site.isMandatoryEmail ? '*' : t('common.isFacultative')}`}
                // required does nothing
                onChange={(value) => {
                  updateFieldState('email', value);
                }}
              />
              <FormInput
                type="phone"
                value={formfields?.phone || ':'}
                finalForm={false}
                label={`${t('auth.phone')} ${site.isMandatoryPhone ? '*' : t('common.isFacultative')}`}
                // required does nothing
                onChange={(value) => {
                  updateFieldState('phone', value);
                }}
              />
              {/* if isSmsActive is true on site, display a text with t('common.dataPolicy')  */}
              <Text
                style={{
                  marginTop: theme.sizings.small,
                  marginBottom: theme.sizings.small,
                }}
                customColor={site?.template?.backdropTextColor || theme.colors.dark}

              >
                {t('common.dataPolicy')}
              </Text>
              {site.isSmsActive && (
                <Text
                  style={{
                    marginTop: theme.sizings.small,
                    marginBottom: theme.sizings.large,
                  }}
                  customColor={site?.template?.backdropTextColor || theme.colors.dark}
                >
                  {t('common.phoneUse')}
                </Text>
              )}

            </View>

          </View>

        </View>

        <FooterButtonWrapper relative>
          <Button
            variant="light"
            icon="arrow-right"
            text={t('orders.goNextStep')}
            disabled={!buttonValidState}
            onPress={() => consolidateAndContinue(order)}
          />
        </FooterButtonWrapper>
      </ScrollView>
    </Page>
  );
};

const styles = StyleSheet.create({
  type: {
    marginTop: theme.sizings.medium,
  },
  text: {
    fontFamily: theme.fonts.bold,
    margin: theme.sizings.larger,
    fontSize: theme.fontSizes.medium,
  },
  innerView: {
    paddingHorizontal: theme.sizings.small,
    paddingTop: theme.sizings.small,
  },
  inputContainerStyle: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.primary,
  },
  label: {
    paddingBottom: theme.sizings.small,
    fontSize: theme.fontSizes.medium,
    fontWeight: 'bold',
  },
  containerStyle: {
    marginVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
    alignItems: 'flex-start',
  },
  horizontal: {
    flexDirection: 'row',
  },
  switchLabel: {
    marginRight: theme.sizings.small,
  },
});

OrderUserDetails.propTypes = {
  route: PropTypes.object.isRequired,
};

export default OrderUserDetails;
