import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import useTranslation from 'common/contexts/translations';

import Text from 'common/components/Text/Text';
import Button from 'common/components/Button/Button';
import Page from 'common/components/Page/Page';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import ItemCard from 'common/components/ItemCard/ItemCard';
import ReductionPrice from 'common/components/ReductionPrice/ReductionPrice';
import useOffer from 'specialOffers/hooks/use-offer';

import theme from 'styles';
import commonStyles from 'styles/commonStyles';

const styles = StyleSheet.create({
  servicesLabel: {
    marginBottom: theme.sizings.small,
  },
  description: {
    padding: theme.sizings.small,
  },
});

const ServiceItem = ({ service, navigation }) => {
  const { t } = useTranslation();
  const {
    id, name, images, description, option, variant_prices: variantPrices, price,
  } = service;
  const {
    findServiceOffer,
  } = useOffer();

  const reduction = findServiceOffer(service);

  const startingPrice = option && variantPrices?.length > 0 ? Math.min(...variantPrices.filter(({ price }) => (
    price !== null)).map((vp) => vp.price)) : price;

  const handlePress = () => {
    navigation.navigate('ServiceDetail', { service, reduction });
  };

  return (
    <View key={id}>
      <View style={styles.servicesLabel}>
        <TranslatedText isBold size="large" value={name} />
      </View>
      <ItemCard
        onPress={handlePress}
        image={images[0]}
      >
        <View style={styles.description}>
          <TranslatedText tagsStyles={{ p: { color: theme.colors.light } }} value={description} truncateLength={50} />
        </View>
        <View style={commonStyles.cardButtonWrapper}>
          <View>
            <Button
              text={t('common.seeDetails')}
              icon="eye"
              variant="light"
              onPress={handlePress}
            />
          </View>
        </View>
        <View style={commonStyles.priceWrapper}>
          <Text
            style={commonStyles.priceLabel}
            centered
            isUppercase
            color="darkBlue"
            size="tiny"
          >
            {t('beach.priceFrom')}
          </Text>
          <ReductionPrice price={startingPrice} reduction={reduction} direction="row" />
        </View>
      </ItemCard>
    </View>
  );
};

ServiceItem.propTypes = {
  navigation: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
};

const renderServices = (services, navigation) => services.map((service, index) => (
  <ServiceItem key={index} service={service} navigation={navigation} />
));

const CategoryDetail = ({ route, navigation }) => {
  const { category } = route.params;

  return (
    <Page scroll padding>
      {renderServices(category.services, navigation) }
      { category.children.map(({ id, name, services }) => (
        <View key={id}>
          <View style={styles.servicesLabel}>
            <TranslatedText isBold color="primary" size="larger" value={name} />
          </View>
          {renderServices(services, navigation) }
        </View>
      ))}
    </Page>
  );
};

CategoryDetail.propTypes = {
  route: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

export default CategoryDetail;
