import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';

import Price from 'common/components/Price/Price';
import SeatName from 'common/components/SeatName/SeatName';
// import useBooking from 'bookings/contexts/bookings';
import theme from 'styles';

const Item = ({
  component, furniture, price_area: priceArea, name,
}) => {
  const [price, setPrice] = useState(null);
  // const { item: booking } = useBooking();

  useEffect(() => {
    if (priceArea && furniture && furniture.prices) {
      const priceAreaId = priceArea.id;
      const p = furniture.prices.find((p) => Number.isInteger(p.price_area)
        ? p.price_area === priceAreaId : p.price_area.id === priceAreaId);

      setPrice(p);
    }
  }, [furniture, priceArea]);

  return (
    <View style={styles.container}>
      {component}
      {price && (
        <Price
          price={price.price} // {booking.hotel ? price.hotelPrice : price.price}
          color="light"
          withGradient
          viewStyle={styles.priceContainer}
        />
      )}
      {name && (
        <SeatName
          name={name}
          colors={[theme.colors.darkBlue, theme.colors.darkerBlue]}
          color="light"
          withGradient
          viewStyle={styles.nameContainer}
        />
      )}
    </View>
  );
};

Item.propTypes = {
  component: PropTypes.element,
  furniture: PropTypes.object,
  price_area: PropTypes.object,
  name: PropTypes.string,
};

Item.defaultProps = {
  name: '',
  price_area: {},
  furniture: {},
  component: () => {},
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },

  priceContainer: {
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    width: 35,
    height: 30,
    zIndex: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 4,
  },

  nameContainer: {
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    width: 35,
    height: 30,
    zIndex: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 4,
  },

  priceText: {
    textAlign: 'center',
    fontFamily: 'MontserratBold',
  },
});

export default Item;
