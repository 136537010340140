import { Dimensions, StyleSheet, Platform } from 'react-native';
import theme from './index';

export default StyleSheet.create({
  contentWrapper: {
    paddingHorizontal: theme.sizings.small,
    paddingTop: theme.sizings.medium,
  },
  headerIcon: {
    minWidth: theme.sizings.large,
    minHeight: theme.sizings.large,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.sizings.small,
    borderWidth: 1,
    borderColor: 'rgba(255, 255 ,255, .3)',
    borderRadius: theme.radius.rounded,
    backgroundColor: 'transparent',
  },
  fullViewDetailsContainer2: {
    padding: theme.sizings.smallMedium,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',

  },
  fullViewDetailsContainer: {
    paddingTop: Dimensions.get('window').width > 767 ? 35 : theme.radius.large,
    width: Dimensions.get('window').width - theme.sizings.medium,
    position: 'absolute',
    bottom: theme.sizings.small,
    overflow: 'visible',
    borderRadius: theme.radius.medium,
  },

  preAuthorizedMessage: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  fullViewDetailsContent: {
    paddingTop: 30,
    minHeight: 110,
  },

  fullViewDetailsContentDiscount: {
    paddingTop: 30,
    width: '80%',
    minHeight: 150,
  },

  fullViewContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  fullViewButtonContainer: {
    position: 'absolute',
    top: 0,
    zIndex: 10,
    transform: [
      { translateX: -Dimensions.get('window').width / 2 },
    ],
    left: '50%',
    width: Dimensions.get('window').width,
    justifyContent: 'center',
    alignItems: 'center',
  },

  fullViewDetailsPriceWrapper: {
    zIndex: 10,
    backgroundColor: theme.colors.dark,
    borderTopLeftRadius: theme.radius.medium,
    borderBottomLeftRadius: theme.radius.medium,
    position: 'absolute',
    top: 36,
    right: -8,
    minWidth: 80,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.sizings.small,
  },
  fullViewDetailsIcon: {
    minWidth: 35,
    minHeight: 35,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.sizings.small,
    marginRight: theme.sizings.small,
    borderRadius: theme.radius.rounded,
    backgroundColor: theme.colors.dark,
  },
  textWithIcon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  singleText: {
    paddingLeft: theme.sizings.small,
    paddingRight: theme.sizings.small,
  },
  priceWrapper: {
    backgroundColor: theme.colors.white,
    borderTopLeftRadius: theme.radius.medium,
    borderBottomLeftRadius: theme.radius.medium,
    position: 'absolute',
    top: -235,
    right: -8,
    height: 80,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.sizings.smallMedium,
  },
  priceProductWrapper: {
    backgroundColor: theme.colors.white,
    borderTopLeftRadius: theme.radius.medium,
    borderBottomLeftRadius: theme.radius.medium,
    position: 'absolute',
    top: -140,
    right: -8,
    height: 80,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.sizings.smallMedium,
  },
  lineThrough: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  cardButtonWrapper: {
    position: 'absolute',
    top: -55,
    left: 0,
    height: 100,
    width: Dimensions.get('window').width - theme.sizings.large,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  cardButtonProductWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: theme.sizings.small,
  },
  shadowBottom: {
    shadowColor: theme.sizings.greyLightest,
    shadowOffset: { height: 1, width: 0 },
    shadowRadius: 2,
    shadowOpacity: 0.3,
  },

  scrollViewWithButton: {
    flex: 1,
    paddingBottom: 50,
  },

  buttonWrapper: {
    flex: 1,
    height: Platform.OS === 'web' ? 120 : 100,
    position: 'absolute',
    bottom: 0,
    marginTop: 0,
    left: 0,
    right: 0,
    paddingTop: theme.sizings.smallMedium,
    backgroundColor: theme.colors.dark,
    borderTopWidth: theme.sizings.mediumLarge,
    borderTopColor: theme.colors.white,
    borderStyle: 'solid',
  },

  buttonWrapperIn: {
    flex: 1,
    height: 60,
    position: 'absolute',
    bottom: 0,
    marginTop: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.colors.dark,
  },

  buttonContainer: {
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    top: -theme.sizings.large,
  },

  buttonContainerIn: {
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    top: 10,
  },

  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalMask: {
    flex: 1,
    backgroundColor: 'rgba(52, 52, 52, 0.8)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.sizings.small,
  },

  modalBox: {
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.light,
    opacity: 1,
    paddingVertical: theme.sizings.large,
    paddingHorizontal: theme.sizings.medium,
    alignSelf: 'stretch',
  },

  modalFooter: {
    marginTop: theme.sizings.large,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalText: {
    textAlign: 'center',
    fontSize: theme.sizings.medium,
  },

  modalButton: {
    marginHorizontal: theme.sizings.tiny,
  },

  btnOpenDetail: {
    alignSelf: 'center',
    borderRadius: theme.radius.rounded,
    marginTop: 15,
    marginBottom: 10,
  },

  gradientBtnBackground: {
    borderRadius: theme.radius.rounded,
    paddingHorizontal: 20,
    height: 40,
    lineHeight: 40,
    justifyContent: 'center',
    marginBottom: theme.sizings.small,
  },

  textReorderBtn: {
    alignSelf: 'center',
    color: 'white',
    fontSize: theme.sizings.medium,
    fontFamily: theme.fonts.bold,
  },

});
