import { fetchJSON } from '../fetchJSON';

const getNextpage = async ({
  currentScreenName, currentOrder, currentSite,
}) => {
  let newOrder = currentOrder;

  const pagesInOrder = [
    {
      name: 'Basket',
      skipCondition: () => false, /* Initial page */
      doOnSkip: () => { },
    },
    {
      name: 'OrderLocalization',
      skipCondition: async () => {
        // no localizations defined
        if (!currentSite.localization_models) {
          return true;
        }

        /* const firstActiveLocalizationModels = currentSite.localization_models.filter(
          (locModel) => locModel.active,
        )[0]; */

        /* const activeModel = await fetchJSON({
          url: `localization-models/${firstActiveLocalizationModels.id}`,
          method: 'GET',
        }); */

        /* if (
        // No Selector fields defined
          activeModel.localizationModelField
            && activeModel.localizationModelField.filter(
              (field) => field.type === 'select',
            )?.length === 0) {
          return true;
        } */

        /*
        if (
        // No assignement
          !activeModel.inclusionAreas
            && JSON.parse(activeModel.inclusionAreas)?.length === 0) {
          return true;
        } */

        /* const activePreparatorAreas = JSON.parse(activeModel.inclusionAreas)?.preparatorAreas?.filter(
          (pa) => pa.active,
        ); */
        /* const fields = activePreparatorAreas.length ? activePreparatorAreas[0].localizationModelField : []; */

        /* 1rst case : No alternative in choices */
        /* if (fields.filter((field) => {
            const fieldName = Object.keys(field)[0];

            return field[fieldName].length > 1;
          }).length === 0) {
            console.log('problemo ?');
            return true;
          } */

        /* 2nd case : Number of preparator area = 1 */
        /* if (activePreparatorAreas?.length === 1) {
          return true;
        } */
        // }

        return false;
      },
      doOnSkip: async () => {
        const autoLocalization = {};
        // no localizations defined

        if (!currentSite.localization_models) {
          autoLocalization['-'] = {
            value: '-',
            required: true,
            type: 'select',
          };
        } else {
          const firstActiveLocalizationModels = currentSite.localization_models?.filter(
            (locModel) => locModel.active,
          )[0];

          const activeModel = await fetchJSON({
            url: `localization-models/${firstActiveLocalizationModels.id}`,
            method: 'GET',
          });

          if (
            // No Selector fields defined
            activeModel.localizationModelField.filter(
              (field) => field.type === 'select' && field.required,
            ).length === 0
            // No assignement
            || !activeModel.inclusionAreas
            || JSON.parse(activeModel.inclusionAreas)?.length === 0
          ) {
            autoLocalization['-'] = {
              value: '-',
              required: true,
              type: 'select',
            };
          } else {
            const activePreparatorAreas = JSON.parse(activeModel.inclusionAreas)
              .preparatorAreas.filter(
                (pa) => pa.active,
              );

            /* 1rst case : Select the only available choices */
            /* 2nd case : Select the first localization choice that works for the only preparator area */
            activePreparatorAreas.forEach((field) => {
              const fieldName = Object.keys(field)[0];

              autoLocalization[fieldName] = {
                value: activePreparatorAreas.length > 1 ? '-' : field[fieldName][0],
                required: true,
                type: 'select',
              };
            });
          }
        }
        newOrder = {
          ...newOrder,
          localization: autoLocalization,
        };
      },
    },
    {
      name: 'OrderSelectDeliveryTime',
      skipCondition: () => (
        !currentSite?.showStepChoicePage
      ),
      doOnSkip: () => {
        newOrder = {
          ...newOrder, selectedHour: ' ',
        };
        /* [TODO] Set the unique planification option */
        /* const autoPlanification = (currentSite.hourSlotModels[0]?.orderHours[0]);

        newOrder = { ...newOrder, selectedHour: autoPlanification }; */
      },
    },
    {
      name: 'OrderUserDetails',
      skipCondition: () => !currentSite?.askCoordinates,
      /* [TODO] Number of planification options = 1 */
      // currentSite.hourSlotModels[0]?.orderHours.length === 1

      doOnSkip: () => {
        newOrder = {
          ...newOrder, userInfo: { name: '-', email: '-', phone: '-' },
        };
        /* [TODO] Set the unique planification option */
        /* const autoPlanification = (currentSite.hourSlotModels[0]?.orderHours[0]);

        newOrder = { ...newOrder, selectedHour: autoPlanification }; */
      },
    },
    {
      name: 'EditOrderSummary',
      skipCondition: () => !currentOrder?.id,

      doOnSkip: () => { },
    },
    {
      name: 'CurrentOrderSummary',
      skipCondition: () => false, /* Never skip */
      doOnSkip: () => { },
    },
    {
      name: 'PaymentRecap',
      skipCondition: () => false, /* Never skip */
      doOnSkip: () => { },
    },
  ];
  // get current navigation screen id
  const currentPageId = pagesInOrder.findIndex((page) => (page.name === currentScreenName));

  let nextPageId = currentPageId + 1;

  // go to the next screen that doesn't validate the skip conditions
  while (
    // eslint-disable-next-line no-await-in-loop
    (await pagesInOrder[nextPageId].skipCondition())
    && nextPageId < pagesInOrder.length - 1
  ) {
    // eslint-disable-next-line no-await-in-loop
    await pagesInOrder[nextPageId].doOnSkip();
    nextPageId += 1;
  }

  await Promise.all(pagesInOrder);

  // return the next not skipped page and updated order
  return { nextPage: pagesInOrder[nextPageId].name, newOrder };
};

export default getNextpage;
