import { useNavigation } from '@react-navigation/native';
import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, View, StyleSheet } from 'react-native';
import useSite from 'sites/contexts/sites';
import theme from 'styles';

import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import Select from 'common/components/Select/Select';
import useOrder from 'orders/contexts/orders';
import getNextpage from 'common/utils/navigation/CleverNavigation';
import filterObject from 'common/utils/objFiltering';
import { PropTypes } from 'prop-types';
import useTrans from 'common/hooks/use-trans';
import getWording from 'common/utils/getWording';
import useLocalizationField from './context/localizationField';

const OrderLocalization = ({ route }) => {
  const navigation = useNavigation();
  const { t, i18n } = useTranslation();
  const { translateText } = useTrans();
  const { item: site } = useSite();
  const { item: order, setItem: setOrder } = useOrder();
  const [formfields, setFormfields] = useState({});
  const [buttonValidState, setButtonValidState] = useState(false);

  const lang = i18n.language;

  /* const localizationModel = site.localization_models.find((loc) => loc.active).localizationModelField; */

  // Populate bug => "manually" populate the localizationModel fields)
  // (remove if bug is corrected)
  const {
    items: localizationModels,
    fetchItems: getLocalizationModels,
    setFilters,
    filters,
    isFetching: isLocalizationModelItemsFetching,
  } = useLocalizationField();

  /**
   * Gets the site data and prepare the localization models request filter
   */
  useEffect(() => {
    setFilters({ site: site.id, active: true });
  }, [site, setFilters]);

  /**
   * Returns an array of applicable localization models
   * (Only the first one will be used)
   */
  useEffect(() => {
    if (filters.site && filters.active) {
      getLocalizationModels();
    }
  }, [filters, getLocalizationModels]);

  /**
   * Initialize the fields validity state
   */
  useEffect(() => {
    let tmpFormFields = {};

    localizationModels[0]?.localizationModelField.forEach((field) => {
      const currentValue = order?.localization
        ? order?.localization[field.label]?.value
        : '';

      tmpFormFields = {
        ...tmpFormFields,
        [field.label]: {
          required: field.required,
          name: field.label,
          value: currentValue,
          type: field.type,
          tlabel: order?.localization
            ? order?.localization[field.label]?.tlabel
            : null,
          toption:
            field.type === 'select'
              ? field.SubItems?.find((option) => option.name === currentValue)
                ?.toption
              : null,
        },
      };
    });

    setFormfields(tmpFormFields);
  }, [localizationModels, order]);

  /**
   * Save the fields and navigate to next page
   */
  const consolidateAndContinue = useCallback(
    async (currentOrder) => {
      const { nextPage, newOrder } = await getNextpage({
        currentScreenName: route.name,
        currentOrder: { ...currentOrder, localization: formfields },
        currentSite: site,
      });

      setOrder(newOrder);
      navigation.navigate(nextPage, { currentOrder: newOrder });
    },
    [route.name, formfields, site, setOrder, navigation],
  );

  /**
   * Update the fields validity state with on a field modification
   */
  const updateFieldState = (fieldName, fieldValue, field) => {
    setFormfields({
      ...formfields,
      [fieldName]: {
        ...formfields[fieldName],
        value: fieldValue,
        tlabel: field.tlabel || null,
        toption:
          field.type === 'select'
            ? field.SubItems?.find((option) => option.name === fieldValue)
              ?.toption
            : null,
      },
    });
  };

  /**
   * Verify the form validity on the fields validity state updating
   */
  useEffect(() => {
    let isFormValid = true;

    const formRequiredFields = filterObject(
      formfields,
      (field) => field.required,
    );

    if (Object.keys(formRequiredFields).length > 0) {
      Object.keys(formRequiredFields).forEach((fieldName) => {
        /* [TODO] Validate fields here (add additional tests */
        if (formRequiredFields[fieldName].value.trim() === '') {
          isFormValid = false;
        }
      });
      // isFormValid = Object.values(formfields).reduce((a, b) => (a && b));
    }

    setButtonValidState(isFormValid);
  }, [formfields]);

  return (
    <Page backgroundImage={site?.template.imageBackdrop}>
      <LoadingSpinner visible={isLocalizationModelItemsFetching} />
      <ScrollView style={[styles.innerView, { flex: 1, paddingBottom: 50 }]}>
        <View style={{ padding: theme.sizings.small }}>
          <View>
            {/* ici le texte si c'est à emporter, pour l'exemple, il faudra une condition autour liée
              au mode de livraison
            <Text
              isBold
              centered
              style={styles.text}
              customColor={site?.template?.backdropTextColor}
            >
              {order?.deliveryMethod === 'takeaway'
                ? t('orders.localizationIntro.toTakeAway')
                : t(
                    site?.pleaseMyCar
                      ? site?.pleaseMyCarDriver ? 'orders.localizationIntro.onTheSpotDriver'
                        : 'orders.localizationIntro.onTheSpotValet'
                      : 'orders.localizationIntro.onTheSpot',
                )}
            </Text>
            */}

            <View
              style={{
                marginTop: theme.sizings.large,
                display: 'flex',
                flexFlow: 'wrap',
              }}
            >
              {!isLocalizationModelItemsFetching
                && localizationModels
                && localizationModels[0]?.localizationModelField.map(
                  (field, index) => {
                    const size = field.size === 'half'
                      ? '50%'
                      : field.size === 'oneThird'
                        ? '33.33%'
                        : field.size === 'twoThirds'
                          ? '66.66%'
                          : '100%';

                    return (
                      <View
                        key={index}
                        style={{
                          minWidth: size,
                          maxWidth: size,
                        }}
                      >
                        {field.type === 'text' && (
                          <FormInput
                            type="text"
                            finalForm={false}
                            label={getWording(
                              field.tlabel,
                              field.label,
                              lang,
                              translateText,
                            )}
                            required={field.required}
                            onChange={(value) => {
                              updateFieldState(field.label, value, field);
                            }}
                            labelStyle={styles.label}
                          />
                        )}
                        {field.type === 'select' && (
                          <View style={styles.containerStyle}>
                            <Text
                              isBold
                              style={styles.label}
                              customColor={
                                site?.template?.backdropTextColor
                                || theme.colors.dark
                              }
                            >
                              {getWording(
                                field.tlabel,
                                field.label,
                                lang,
                                translateText,
                              )}
                            </Text>
                            <Select
                              isField
                              options={field.SubItems?.map((item) => ({
                                label: getWording(
                                  item.toption,
                                  item.name,
                                  lang,
                                  translateText,
                                ),
                                value: item.name,
                              }))}
                              value={formfields[field.label]?.value}
                              icon="caret-down"
                              size="medium"
                              required={field.required}
                              onChange={(value) => {
                                updateFieldState(field.label, value, field);
                              }}
                            />
                          </View>
                        )}
                      </View>
                    );
                  },
                )}
            </View>
          </View>
        </View>

        <FooterButtonWrapper relative>
          <Button
            variant="light"
            icon="arrow-right"
            text={t('orders.goNextStep')}
            disabled={!buttonValidState}
            onPress={() => consolidateAndContinue(order)}
          />
        </FooterButtonWrapper>
      </ScrollView>
    </Page>
  );
};

const styles = StyleSheet.create({
  type: {
    marginTop: theme.sizings.medium,
  },
  text: {
    fontFamily: theme.fonts.bold,
    margin: theme.sizings.larger,
    fontSize: theme.fontSizes.medium,
  },
  innerView: {
    paddingHorizontal: theme.sizings.small,
    paddingTop: theme.sizings.small,
  },
  inputContainerStyle: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.primary,
  },
  label: {
    paddingBottom: theme.sizings.small,
    fontSize: theme.fontSizes.large,
    fontWeight: 'bold',
  },
  containerStyle: {
    marginVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
    alignItems: 'flex-start',
  },
  horizontal: {
    flexDirection: 'row',
  },
  switchLabel: {
    marginRight: theme.sizings.small,
  },
});

OrderLocalization.propTypes = {
  route: PropTypes.object.isRequired,
};

export default OrderLocalization;
