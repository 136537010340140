import React from 'react';
import { TouchableOpacity } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import { useAuth } from 'auth/contexts/auth';
// import useBooking from 'bookings/contexts/bookings';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import useSite from 'sites/contexts/sites';
import PropTypes from 'prop-types';

const UserButton = ({ onDarkBackground }) => {
  const { user } = useAuth(); // , booking
  // const { update: updateBooking } = useBooking();
  const navigation = useNavigation();
  const { item: site } = useSite();
  const template = site?.template;

  const signUp = () => {
    navigation.navigate('SignUpPage', {
      // callback: (newUser) => {
      //   if (booking && !booking.user) {
      //     updateBooking(booking.id, { user: newUser.id });
      //   }
      // },
    });
  };

  const goToProfile = () => {
    navigation.navigate('ProfilePage');
  };

  const handlePress = () => {
    user ? goToProfile() : signUp();
  };

  return (
    <TouchableOpacity style={commonStyles.headerIcon} onPress={handlePress}>
      <FontAwesome
        color={!onDarkBackground && template?.primaryTextColor ? template?.primaryTextColor : theme.colors.light}
        size={theme.fontSizes.larger}
        name="user"
        style={{ opacity: user?.id > 0 ? 0.7 : 1 }}
      />
    </TouchableOpacity>
  );
};

export default UserButton;

UserButton.propTypes = {
  onDarkBackground: PropTypes.bool,
};

UserButton.defaultProps = {
  onDarkBackground: false,
};
