import { Dimensions, StyleSheet } from 'react-native';
import theme from 'styles';

export default StyleSheet.create({
  categoryView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.sizings.medium,
    backgroundColor: theme.colors.greyLightest,
    marginBottom: theme.sizings.medium,
    borderRadius: theme.radius.small,
  },

  title: {
    marginTop: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
    paddingHorizontal: theme.sizings.medium,
    width: '100%',
  },

  productsCountView: {
    height: 30,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.radius.rounded,
  },

  productCount: {
    marginRight: theme.sizings.tiny,
  },

  reductionBadge: {
    borderRadius: theme.radius.large,
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.medium,
    margin: theme.sizings.medium,
    alignSelf: 'flex-end',
  },

  bottomImage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: Dimensions.get('window').width,
    height: 300,
  },
});
