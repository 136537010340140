import normalize from 'common/utils/normalizeFonts';

const theme = {
  fonts: {
    default: 'Avenir',
    bold: 'AvenirBold',
    russianDefault: 'Roboto',
    russianBold: 'RobotoBold',
  },
  fontSizes: {
    tiny: normalize(10),
    small: normalize(12),
    default: normalize(14),
    medium: normalize(16),
    large: normalize(18),
    larger: normalize(20),
    largest: normalize(24),
    huge: normalize(36),
  },
  sizings: {
    tiny: 4,
    small: 8,
    smallMedium: 12,
    medium: 16,
    mediumLarge: 24,
    large: 32,
    larger: 48,
  },
  radius: {
    small: 4,
    medium: 10,
    large: 20,
    rounded: 1000,
  },
  colors: {
    primary: '#74A6F1',
    primaryBright: '#60E8FF',
    secondary: '#FFD853',
    secondaryLight: '#FBEEB5',
    secondaryDark: '#FF9715',
    beach: '#FBEEB5',
    danger: '#e74C3C',
    dangerBright: '#FF1D47',
    dangerLight: '#FF9279',
    success: '#78e08f',
    white: '#fff',
    black: '#000',
    light: '#f4f4f4',
    dark: '#1d1c26',
    darkBlue: '#25478B',
    darkerBlue: '#3A3947',
    darkGrey: '#2f2f2f',
    info: '#60a3bc',
    link: '#4a69bd',
    warning: '#E74C3C',
    greyLightest: '#f9f9f9',
    greyLighter: '#dddddd',
    disabled: '#adadad',
    greyDarker: '#bdc3c7',
    greyAlpha: 'rgba(0,0,0,0.5)',
    greyDarkest: '#76757C',
    transparent: '#FFFFFF00',
    lightBrown: '#FAEEB5',
    lightYellow: '#FFC907',
    darkerYellow: '#B5C327',
    darkerGreen: '#20BF55',
    lighterGreen: '#01BAEF',
    orange: '#FF6600',
    pmcBlueDark: '#253778',
    pmcBlueLight: '#3C6CD6',
  },
  orderStateColor: {
    at_arrival: '#25478B',
    created: '#1D1C26',
    in_preparation: '#FFD853',
    ready: '#78e08f',
    done: '#74A6F1',
    canceled: '#B8B5C0',
    cornflowerblue: '#7ca4e0',
    goldenrod: '#e49e34',
    cornflowerbluebackground: '#cce0ff',
    goldenrodbackground: '#fbeeb5',
  },
};

export default theme;
