import React, {
  createContext, useContext, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import usePersistedState from 'common/utils/usePersistedState';
import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';
import Background from 'assets/defaultBackground.jpg';

const SiteContext = createContext();

export const SitesProvider = ({ children }) => {
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [currentSite, setCurrentSite] = usePersistedState(null, 'site');
  const [lock, setLockOn] = usePersistedState(null, 'lock');

  const fetchSiteData = useCallback(async (siteId) => {
    setIsDataFetching(true);
    try {
      const res = await fetchJSON({ url: `sites/${siteId}/data`, method: 'GET' });
      const categoriesByParentId = _.groupBy(res.categories, 'parent');

      if (res.categories) {
        res.categories.forEach((category, index) => {
          if (categoriesByParentId[category.id]) {
            res.categories[index].children = categoriesByParentId[category.id];
          } else {
            res.categories[index].children = [];
          }
        });
      }

      const categoryServicesByParentId = _.groupBy(res.category_services, 'parent');

      if (res.category_services) {
        res.category_services.forEach((categoryService, index) => {
          if (categoryServicesByParentId[categoryService.id]) {
            res.category_services[index].children = categoryServicesByParentId[categoryService.id];
          } else {
            res.category_services[index].children = [];
          }
        });
      }
      let complementaryProducts = [];

      res.categories.forEach((category) => {
        complementaryProducts = complementaryProducts.concat(category.products.filter((product) => (
          product.complementary)));
      });
      res.complementaryProducts = complementaryProducts;

      const templates = await fetchJSON({ url: `templates/?active=true&site.id=${siteId}`, method: 'GET' });

      res.template = templates.length ? templates[0] : {
        showRootCategoriesPage: true,
        isPriceVisible: true,
        isProductDetailVisible: true,
        categoriesDisplayType: 'grid',
        categoriesByColumn: '1',
        productDisplayType: 'icon',
        productsByColumn: '1',
        image: res.images[0],
        imageBackdrop: Background,
        primaryColor: '#333333',
        primaryShadeColor: '#111111',
        secondaryColor: '#555555',
        secondaryShadeColor: '#333333',
        titleFont: 'MontserratBold',
        subtitleFont: 'MontserratBold',
        primaryFont: 'Lato',
        backdropTextColor: 'black',
        cardTextColor: 'black',
        primaryTextColor: 'white',
        secondaryTextColor: 'white',
      };

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    } finally {
      setIsDataFetching(false);
    }
  }, []);

  const value = useMemo(() => ({
    fetchSiteData,
    isDataFetching,
    currentSite,
    setCurrentSite,
    lock,
    setLockOn,
  }), [
    fetchSiteData,
    isDataFetching,
    currentSite,
    setCurrentSite,
    lock,
    setLockOn,
  ]);

  return (
    <ContextProvider
      url="sites"
      context={SiteContext}
      value={value}
    >
      {children}
    </ContextProvider>
  );
};

SitesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useSite = () => useContext(SiteContext);

export default useSite;
