import React, { useCallback } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import PropTypes from 'prop-types';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import RelativeDate from 'common/components/RelativeDate/RelativeDate';
import Select from 'common/components/Select/Select';
import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import PaymentState from 'orders/components/PaymentState/PaymentState';
import useOrders from 'orders/contexts/orders';

import commonStyles from 'styles/commonStyles';
import theme from 'styles';
import Button from 'common/components/Button/Button';
import ConfirmButton from 'common/components/ConfirmButton/ConfirmButton';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import FlexRow from 'common/components/FlexRow/FlexRow';
import { useAuth } from 'auth/contexts/auth';
import zeroPad from 'common/utils/zeroPad';
import { lowerFirstLetter } from 'common/utils/capitalize';
import formatPrice from 'common/utils/formatPrice';
import useTrans from 'common/hooks/use-trans';
import getWording from 'common/utils/getWording';
import useSite from 'sites/contexts/sites';
import Price from 'common/components/Price/Price';

const OrderListItem = ({
  order_items: orderItems,
  id,
  displayedId,
  code,
  selectedHour,
  tSelectedHour,
  localization,
  created_at: createdAt,
  price,
  tip,
  state,
  user,
  userInfo,
  payment,
  barman,
  barmanList,
  waiter,
  viewRole,
}) => {
  const { t, i18n } = useTranslation();
  const { translateText } = useTrans();
  const navigation = useNavigation();
  const { updateOrderState, refreshOrders } = useOrders();
  const { user: currentUser } = useAuth();
  const { setAlert } = useAlert();
  const { item: currentSite } = useSite();

  /* keep it for later : const deleteOrder = useCallback(
    async (id) => {
      await remove(id);
      refreshOrders();
    },
    [remove, refreshOrders],
  ); */

  const updateOrder = useCallback(
    async (payload) => {
      try {
        await updateOrderState(id, payload);
        refreshOrders();
        setAlert({
          color: 'success',
          title: t('common.success'),
          message: t('orders.successUpdate'),
        });
      } catch {
        setAlert({
          color: 'error',
          title: t('common.error'),
          message: t('orders.errorUpdate'),
        });
      }
    },
    [id, setAlert, t, updateOrderState, refreshOrders],
  );

  const updateState = useCallback(
    async (newOrderState) => {
      updateOrder({ state: newOrderState });
    },
    [updateOrder],
  );

  const assignBarman = useCallback(
    async (barmanId) => {
      updateOrder({
        barman: barmanId && barmanId !== '' ? barmanId : null,
        state: barmanId ? 'in_preparation' : 'created',
      });
    },
    [updateOrder],
  );

  const assignWaiter = useCallback(
    async (waiterId, state) => {
      updateOrder({
        waiter: waiterId && waiterId !== '' ? waiterId : null,
        state,
      });
    },
    [updateOrder],
  );

  const lang = i18n.language;

  return (
    <View style={{ marginVertical: theme.sizings.tiny }}>
      <TouchableOpacity
        style={[
          styles.order,
          {
            padding: 0,
            backgroundColor: '#EEEEEE',
            shadowColor: '#000000',
            shadowOffset: { width: 2, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
            borderWidth: 1,
            borderColor: 'lightgrey',
            borderLeftWidth: 25,
            borderLeftColor:
              barman && currentUser.role.name !== 'waiter'
                ? barman.color
                : waiter && currentUser.role.name !== 'barman'
                  ? waiter.color
                  : 'lightgrey',
          },
        ]}
      >
        <View
          style={{
            alignSelf: 'center',
            flex: 1,
            padding: 0,
          }}
        >
          <FlexRow
            style={{
              paddingVertical: theme.sizings.tiny,
              paddingHorizontal: theme.sizings.small,
              backgroundColor: 'lightgrey',
              borderTopRightRadius: theme.radius.medium,
              flexFlow: 'wrap',
            }}
          >
            <FlexRow.Left>
              <Text isBold size="large" color="black">
                {t('common.number', { number: zeroPad(displayedId, 3) })}
              </Text>

              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: theme.sizings.small,
                }}
              >
                <FontAwesome
                  size={theme.fontSizes.small}
                  name="clock-o"
                  color={theme.colors.greyDarkest}
                />
                <Text
                  size="tiny"
                  style={{ fontStyle: 'italic', marginLeft: 7 }}
                >
                  <RelativeDate value={createdAt} />
                </Text>
              </View>
              <View
                style={{
                  marginHorizontal: theme.sizings.small,
                  height: 40,
                  maxWidth: '100%',
                  borderColor: theme.colors.greyLighter,
                  borderWidth: 1,
                }}
              />

              <Text>
                {`${lowerFirstLetter(
                  getWording(
                    tSelectedHour,
                    selectedHour,
                    lang,
                    translateText,
                  ),
                )}`}

              </Text>
            </FlexRow.Left>

            <FlexRow.Right>
              {((['at_arrival', 'created'].includes(state)
                && currentUser.role.name === viewRole)
                || (currentUser.role.name === 'barman'
                  && state === 'in_preparation'
                  && viewRole === 'barman')
                || ['admin', 'superadmin', 'manager'].includes(
                  currentUser.role.name,
                )) && (
                <View style={{ flexDirection: 'column', alignItems: 'center' }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      paddingLeft: theme.sizings.small,
                    }}
                  >
                    {/* keep it for later
                    <Confirm confirmText={t('orders.deleteConfirm')}>
                      <TouchableOpacity
                        style={[
                          commonStyles.headerIcon,
                          { marginHorizontal: theme.sizings.small },
                        ]}
                        onPress={() => deleteOrder(id)}
                      >
                        <FontAwesome
                          color={theme.colors.danger}
                          size={theme.fontSizes.large}
                          name="trash"
                        />
                      </TouchableOpacity>
                    </Confirm>
                      */}

                    <TouchableOpacity
                      style={[
                        commonStyles.headerIcon,
                        { marginHorizontal: theme.sizings.small },
                      ]}
                      onPress={() => navigation.navigate('OrderDetail', { orderId: id })}
                    >
                      <FontAwesome
                        color={theme.colors.greyDarkest}
                        size={theme.fontSizes.large}
                        name="pencil"
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              )}
            </FlexRow.Right>
          </FlexRow>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              width: '100%',
              paddingBottom: theme.sizings.tiny,
            }}
          >
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <View style={{ flexDirection: 'row' }}>
                <View
                  style={{
                    flexDirection: 'column',
                    flexGrow: 1,
                    borderBottomWidth: 1,
                    borderColor: 'rgba(0,0,0,0.2)',
                    maxWidth: '100%',
                    paddingVertical: theme.sizings.tiny,
                    paddingHorizontal: theme.sizings.smallMedium,
                  }}
                >
                  {orderItems.map((orderItem, index) => (
                    <View
                      key={`product${index}`}
                      style={{
                        borderBottomWidth:
                          index === orderItems.length - 1 ? 0 : 1,
                        borderBottomColor: 'lightgrey',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {!currentSite?.pleaseMyCar && (
                      <View
                        style={{
                          width: 40,
                        }}
                      >
                        <Text size="medium" color="orange" isBold>{`${orderItem.quantity}x`}</Text>
                      </View>
                      )}
                      <View style={{
                        wordBreak: 'break-word',
                        width: '100%',
                      }}
                      >
                        <TranslatedText size="medium" value={orderItem.name} />
                        {orderItem.selected_options.map((option) => (
                          option.variants.map((variant, variantIndex) => (
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                              }}
                              key={`variant-${variant.name}-${variantIndex}-${index}`}
                            >
                              <FontAwesome name="plus" color={theme.colors.dark} />
                              <View style={{ marginLeft: theme.sizings.medium }}>
                                <TranslatedText value={variant.name} />
                              </View>
                            </View>
                          ))
                        ))}
                      </View>
                    </View>
                  ))}
                </View>
              </View>

              <FlexRow
                style={{
                  flexWrap: 'wrap',
                  paddingRight: theme.sizings.small,
                  paddingTop: theme.sizings.small,
                }}
              >
                <FlexRow.Left
                  style={[
                    styles.wrapper,
                    {
                      flexDirection: 'column',
                      flexGrow: 2,
                      alignItems: 'flex-start',
                      paddingHorizontal: theme.sizings.smallMedium,
                      paddingTop: 0,
                    },
                  ]}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: theme.sizings.tiny,
                    }}
                  >
                    <FontAwesome
                      size={theme.fontSizes.medium}
                      name="map-marker"
                      color={theme.colors.greyDarkest}
                      style={{ paddingRight: 5, textAlign: 'center' }}
                    />
                    <Text>{user?.username || userInfo?.name}</Text>
                    {userInfo?.phone && (
                      <Text>
                        , +
                        {userInfo?.phone.replace(':', ' ')}
                      </Text>
                    )}
                  </View>

                  {!!localization
                    && Object.keys(localization).map((key, index) => {
                      const currentField = localization[key];

                      return (
                        <View
                          key={`loc${index}`}
                          style={[
                            { flexDirection: 'row', flexWrap: 'wrap' },
                            index === 0 ? styles.emphasis : null,
                          ]}
                        >
                          <Text size="small">
                            {`${getWording(
                              currentField.tlabel,
                              currentField.name || key,
                              lang,
                              translateText,
                            )} : `}
                          </Text>
                          {currentField.type === 'select' ? (
                            <Text>
                              {getWording(
                                currentField.toption,
                                currentField.value,
                                lang,
                                translateText,
                              )}
                            </Text>
                          ) : (
                            <Text>{currentField.value}</Text>
                          )}
                        </View>
                      );
                    })}

                </FlexRow.Left>

                <FlexRow.Right style={{ flexGrow: 1 }}>
                  <View
                    style={{
                      flexDirection: 'column',
                      paddingLeft: theme.sizings.small,
                    }}
                  >
                    <View
                      style={{ flexDirection: 'row', justifyContent: 'center' }}
                    >
                      <Text isBold isTitleText size="larger" color="black">
                        {`${formatPrice(price, currentUser.site?.currency)}`}
                      </Text>
                    </View>

                    {price > 0 && <PaymentState payment={payment} />}
                    <View style={{ flexDirection: 'row' }}>
                      <FontAwesome
                        name="key"
                        color={theme.colors.greyDarkest}
                        size={theme.fontSizes.small}
                        style={{ marginHorizontal: theme.sizings.small }}
                      />
                      <Text isBold isTitleText size="small" color="black">
                        {` ${code}`}
                      </Text>
                    </View>
                  </View>
                </FlexRow.Right>
              </FlexRow>

              {currentSite?.showBasketTip && !!tip && (
              <FlexRow style={{ paddingHorizontal: theme.sizings.medium }}>
                <FlexRow.Left>
                  <Text color="dark" isTitleText>
                    {t('basket.tipAmount')}
                  </Text>
                </FlexRow.Left>

                <FlexRow.Right>
                  <Price
                    noLineHeight
                    price={tip || 0}
                    viewStyle={styles.price}
                    style={{ fontSize: theme.sizings.medium }}
                    color="dark"
                  />
                </FlexRow.Right>
              </FlexRow>
                )}

            </View>
          </View>

          {['at_arrival', 'created'].includes(state)
            && viewRole === 'barman'
            && currentUser.role.name !== 'waiter' && (
              <View
                style={{
                  paddingTop: 10,
                  paddingVertical: theme.sizings.tiny,
                  paddingHorizontal: theme.sizings.small,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {barmanList.map(
                  (barman) => barman.value && (
                  <View
                    key={`barman${barman.value}`}
                    style={{ margin: 3 }}
                  >
                    <Button
                      type="button"
                      text={barman.label}
                      onPress={() => {
                        assignBarman(barman.value);
                      }}
                      icon="user"
                      textSize="small"
                      iconSize="small"
                      style={{
                        paddingHorizontal: theme.sizings.small,
                        backgroundColor: barman?.color,
                      }}
                    />
                  </View>
                  ),
                )}
              </View>
          )}
          {(state === 'in_preparation'
            || (['at_arrival', 'created'].includes(state)
              && viewRole === 'waiter')) && (
              <FlexRow
                style={{
                  paddingTop: 10,
                  paddingVertical: theme.sizings.tiny,
                  paddingHorizontal: theme.sizings.small,
                }}
              >
                <FlexRow.Left>
                  {(viewRole === 'barman'
                  || (viewRole === 'waiter'
                    && currentUser.role.name !== 'barman'
                    && barman?.id)) && (
                    <Select
                      isTypeButton
                      options={barmanList}
                      onChange={assignBarman}
                      value={barman?.id || '---'}
                      icon={
                      viewRole === 'barman'
                      && currentUser.role.name !== 'waiter'
                        ? 'exchange'
                        : ''
                    }
                      size="small"
                      disabled={
                      viewRole !== 'barman'
                      || currentUser.role.name === 'waiter'
                    }
                      style={{
                        paddingHorizontal: theme.sizings.small,
                        borderColor: barman?.color,
                      }}
                    />
                  )}
                </FlexRow.Left>
                <FlexRow.Right>
                  {viewRole === 'barman'
                  && currentUser.role.name !== 'waiter' && (
                    <Button
                      type="button"
                      text={currentSite?.pleaseMyCarDriver ? t('orders.stateReadyDriver') : t('orders.stateReadyValet')}
                      onPress={() => {
                        updateState('ready');
                      }}
                      icon="shopping-bag"
                      textSize="small"
                      iconSize="small"
                      style={{ paddingHorizontal: theme.sizings.small }}
                      variant="dark"
                    />
                  )}
                  {viewRole === 'waiter'
                  && currentUser.role.name !== 'barman'
                  && (waiter?.id !== currentUser.id ? (
                    <Button
                      type="button"
                      text={t('orders.assignToMe')}
                      onPress={() => {
                        assignWaiter(currentUser.id, state);
                      }}
                      icon="hand-o-up"
                      textSize="small"
                      iconSize="small"
                      style={{
                        paddingHorizontal: theme.sizings.small,
                        backgroundColor:
                          currentUser?.color || theme.colors.dark,
                      }}
                    />
                  ) : (
                    <Button
                      type="button"
                      text={t('orders.unassignMe')}
                      onPress={() => {
                        assignWaiter(null, state);
                      }}
                      icon="hand-o-up"
                      textSize="small"
                      iconSize="small"
                      style={{
                        paddingHorizontal: theme.sizings.small,
                        paddingVertical: 5,
                        borderColor: waiter?.color,
                      }}
                      variant="light"
                    />
                  ))}
                </FlexRow.Right>
              </FlexRow>
          )}
          {state === 'ready'
            && (viewRole === 'barman' ? (
              currentUser.role.name !== 'waiter' ? (
                <FlexRow
                  style={{
                    paddingTop: 10,
                    paddingVertical: theme.sizings.tiny,
                    paddingHorizontal: theme.sizings.small,
                  }}
                >
                  <FlexRow.Left>
                    <Button
                      type="button"
                      // text={t('common.return')}
                      onPress={() => {
                        updateState('in_preparation');
                      }}
                      icon="arrow-left"
                      textSize="small"
                      iconSize="small"
                      variant="light"
                      style={{
                        paddingHorizontal: 5,
                        paddingVertical: 0,
                        borderColor: 'dark',
                      }}
                    />
                  </FlexRow.Left>
                  <FlexRow.Right>
                    {payment.status === 'PAID' ? (
                      <ConfirmButton
                        type="button"
                        text={currentSite?.pleaseMyCarDriver
                          ? t('orders.stateServedDriver') : t('orders.stateServedValet')}
                        onPress={() => {
                          updateState('done');
                        }}
                        icon="hand-o-right"
                        textSize="small"
                        iconSize="small"
                        style={{ paddingHorizontal: theme.sizings.small }}
                        variant="light"
                        confirmText={t('orders.confirmCloseOrder')}
                      />
                    ) : (
                      <ConfirmButton
                        type="button"
                        // text={t('orders.paid')}
                        text={currentSite?.pleaseMyCarDriver
                          ? t('orders.stateServedDriver') : t('orders.stateServedValet')}
                        onPress={() => {
                          updateState('done');
                        }}
                        icon="money"
                        textSize="small"
                        iconSize="small"
                        style={{ paddingHorizontal: theme.sizings.small }}
                        variant="light"
                        confirmText={
                          price > 0
                            ? `${t('orders.confirmPaid', {
                              total: formatPrice(
                                price,
                                  currentUser.site?.currency,
                              ),
                            })}`
                            : t('orders.confirmPaidFree')
                        }
                      />
                    )}
                  </FlexRow.Right>
                </FlexRow>
              ) : (
                <FlexRow
                  style={{
                    paddingTop: 10,
                    paddingVertical: theme.sizings.tiny,
                    paddingHorizontal: theme.sizings.small,
                  }}
                >
                  <FlexRow.Left>
                    <Select
                      isTypeButton
                      options={barmanList}
                      onChange={assignBarman}
                      value={barman?.id || '---'}
                      icon={
                        viewRole === 'barman'
                        && currentUser.role.name !== 'waiter'
                          ? 'exchange'
                          : ''
                      }
                      size="small"
                      disabled={
                        viewRole !== 'barman'
                        || currentUser.role.name === 'waiter'
                      }
                      style={{
                        paddingHorizontal: theme.sizings.small,
                        borderColor: barman?.color,
                      }}
                    />
                  </FlexRow.Left>
                  <FlexRow.Right />
                </FlexRow>
              )
            ) : (
              viewRole === 'waiter' && (
                <FlexRow
                  style={{
                    paddingTop: 10,
                    paddingVertical: theme.sizings.tiny,
                    paddingHorizontal: theme.sizings.small,
                  }}
                >
                  <FlexRow.Left>
                    {currentUser.role.name !== 'barman' && (
                      <Button
                        type="button"
                        // text={t('common.return')}
                        onPress={() => {
                          updateState('in_preparation');
                        }}
                        icon="arrow-left"
                        textSize="small"
                        iconSize="small"
                        variant="light"
                        style={{
                          paddingHorizontal: 5,
                          paddingVertical: 0,
                          borderColor: 'dark',
                        }}
                      />
                    )}
                    {waiter && currentUser.role.name === 'barman' && (
                      <Button
                        type="button"
                        text={waiter.username}
                        textSize="small"
                        iconSize="small"
                        variant="light"
                        disabled
                        style={{
                          paddingHorizontal: theme.sizings.small,
                          paddingVertical: 5,
                          borderColor: waiter?.color,
                          borderWidth: 3,
                        }}
                      />
                    )}
                  </FlexRow.Left>
                  {currentUser.role.name !== 'barman' ? (
                    <FlexRow.Right>
                      {currentUser.id === waiter?.id ? (
                        <Button
                          type="button"
                          text={t('orders.unassignMe')}
                          onPress={() => {
                            assignWaiter(null, state);
                          }}
                          icon="hand-o-up"
                          textSize="small"
                          iconSize="small"
                          style={{
                            paddingHorizontal: theme.sizings.small,
                            paddingVertical: 5,
                            marginLeft: theme.sizings.tiny,
                            borderColor: waiter?.color,
                          }}
                          variant="light"
                        />
                      ) : (
                        <Button
                          type="button"
                          text={t('orders.assignToMe')}
                          onPress={() => {
                            assignWaiter(currentUser.id, state);
                          }}
                          icon="hand-o-up"
                          textSize="small"
                          iconSize="small"
                          style={{
                            paddingHorizontal: theme.sizings.small,
                            marginLeft: theme.sizings.tiny,
                            backgroundColor:
                              currentUser?.color || theme.colors.dark,
                          }}
                        />
                      )}
                      {waiter && currentUser.id !== waiter?.id ? (
                        <ConfirmButton
                          type="button"
                          text={t('orders.IAmDelivering')}
                          onPress={() => {
                            assignWaiter(currentUser.id, 'in_delivery');
                          }}
                          icon="hand-o-right"
                          textSize="small"
                          iconSize="small"
                          style={{
                            paddingHorizontal: theme.sizings.small,
                            marginLeft: theme.sizings.tiny,
                            backgroundColor:
                              currentUser?.color || theme.colors.dark,
                          }}
                          confirmText={t('orders.waiterTakeFromOther')}
                        />
                      ) : (
                        <Button
                          type="button"
                          text={t('orders.IAmDelivering')}
                          onPress={() => {
                            assignWaiter(currentUser.id, 'in_delivery');
                          }}
                          icon="hand-o-right"
                          textSize="small"
                          iconSize="small"
                          style={{
                            paddingHorizontal: theme.sizings.small,
                            marginLeft: theme.sizings.tiny,
                            backgroundColor:
                              currentUser?.color || theme.colors.dark,
                          }}
                        />
                      )}
                    </FlexRow.Right>
                  ) : (
                    <FlexRow.Right />
                  )}
                </FlexRow>
              )
            ))}
          {state === 'in_delivery' && (
            <FlexRow
              style={{
                paddingTop: 10,
                paddingVertical: theme.sizings.tiny,
                paddingHorizontal: theme.sizings.small,
              }}
            >
              <FlexRow.Left>
                {currentUser.role.name !== 'barman' && (
                  <Button
                    type="button"
                    // text={t('common.return')}
                    onPress={() => {
                      updateState('ready');
                    }}
                    icon="arrow-left"
                    textSize="small"
                    iconSize="small"
                    variant="light"
                    style={{
                      paddingHorizontal: 5,
                      paddingVertical: 0,
                      borderColor: 'dark',
                    }}
                  />
                )}
                {waiter && currentUser.role.name === 'barman' && (
                  <FlexRow.Left>
                    <Button
                      type="button"
                      text={waiter.username}
                      textSize="small"
                      iconSize="small"
                      variant="light"
                      disabled
                      style={{
                        paddingHorizontal: theme.sizings.small,
                        paddingVertical: 5,
                        borderColor: waiter?.color,
                        borderWidth: 3,
                      }}
                    />
                  </FlexRow.Left>
                )}
              </FlexRow.Left>
              <FlexRow.Right>
                {currentUser.role.name !== 'barman'
                  && (payment.status === 'PAID' ? (
                    <ConfirmButton
                      type="button"
                      text={currentSite?.pleaseMyCarDriver
                        ? t('orders.stateServedDriver') : t('orders.stateServedValet')}
                      onPress={() => {
                        updateState('done');
                      }}
                      icon="hand-lizard-o"
                      textSize="small"
                      iconSize="small"
                      style={{ paddingHorizontal: theme.sizings.small }}
                      variant="dark"
                      confirmText={t('orders.confirmCloseOrder')}
                    />
                  ) : (
                    <ConfirmButton
                      type="button"
                      // text={t('orders.paid')}
                      text={currentSite?.pleaseMyCarDriver
                        ? t('orders.stateServedDriver') : t('orders.stateServedValet')}
                      onPress={() => {
                        updateState('done');
                      }}
                      icon="money"
                      textSize="small"
                      iconSize="small"
                      style={{ paddingHorizontal: theme.sizings.small }}
                      variant="dark"
                      confirmText={
                        price > 0
                          ? `${t('orders.confirmPaid', { total: price })}`
                          : t('orders.confirmPaidFree')
                      }
                    />
                  ))}
              </FlexRow.Right>
            </FlexRow>
          )}
          {state === 'done' && (
            <FlexRow
              style={{
                paddingTop: 10,
                paddingVertical: theme.sizings.tiny,
                paddingHorizontal: theme.sizings.small,
              }}
            >
              <FlexRow.Left>
                {barman && (
                  <FlexRow.Left>
                    <Button
                      type="button"
                      text={barman.username}
                      textSize="small"
                      iconSize="small"
                      variant="light"
                      disabled
                      style={{
                        paddingHorizontal: theme.sizings.small,
                        borderColor: barman?.color,
                        borderWidth: 3,
                      }}
                    />
                  </FlexRow.Left>
                )}
                {waiter && (
                  <FlexRow.Left>
                    <Button
                      type="button"
                      text={waiter.username}
                      textSize="small"
                      iconSize="small"
                      variant="light"
                      disabled
                      style={{
                        paddingHorizontal: theme.sizings.small,
                        paddingVertical: 5,
                        borderColor: waiter?.color,
                        borderWidth: 3,
                      }}
                    />
                  </FlexRow.Left>
                )}
              </FlexRow.Left>
              <FlexRow.Right />
            </FlexRow>
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
};

OrderListItem.propTypes = {
  order_items: PropTypes.array,
  id: PropTypes.number.isRequired,
  displayedId: PropTypes.number,
  code: PropTypes.string.isRequired,
  selectedHour: PropTypes.string.isRequired,
  tSelectedHour: PropTypes.object,
  localization: PropTypes.object,
  created_at: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  tip: PropTypes.number,
  state: PropTypes.string.isRequired,
  barman: PropTypes.object,
  payment: PropTypes.object,
  user: PropTypes.object,
  barmanList: PropTypes.array.isRequired,
  waiter: PropTypes.object,
  viewRole: PropTypes.string.isRequired,
  userInfo: PropTypes.object,
};

OrderListItem.defaultProps = {
  order_items: [],
  payment: {},
  barman: {},
  waiter: {},
  displayedId: null,
  localization: {},
  user: null,
  userInfo: null,
  tSelectedHour: null,
  tip: null,
};

const styles = StyleSheet.create({
  order: {
    borderRadius: theme.radius.medium,
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'auto',
  },

  wrapper: {
    flexDirection: 'row',
    marginLeft: 7,
    alignItems: 'left',
    justifyContent: 'space-between',
    paddingVertical: theme.sizings.tiny,
    paddingHorizontal: theme.sizings.smallMedium,
    marginTop: theme.sizings.tiny,
  },

  emphasis: {
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.tiny,
    borderWidth: 2,
    borderColor: theme.colors.dark,
    marginVertical: theme.sizings.tiny,
  },
});

export default OrderListItem;
