import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const LocalizationFieldContext = createContext();

export const LocalizationFieldProvider = ({ children }) => (
  <ContextProvider url="localization-models" context={LocalizationFieldContext}>{children}</ContextProvider>
);

LocalizationFieldProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useLocalizationField = () => useContext(LocalizationFieldContext);

export default useLocalizationField;
