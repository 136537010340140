import CardBG from 'assets/card.png';
import useAuth from 'auth/contexts/auth';
import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import { ImageBackground } from 'common/components/Images';
import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import fetchJSON from 'common/utils/fetchJSON';
import mangoPay from 'mangopay-cardregistration-js-kit/kit/mangopay-kit';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import {
  Platform, StyleSheet, View, useWindowDimensions,
} from 'react-native';
// import { MANGOPAY_CLIENT_ID, MANGOPAY_URL } from 'react-native-dotenv';
import theme from 'styles';
import PageBackground from 'common/components/PageBackground/PageBackground';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import useSite from 'sites/contexts/sites';

const MANGOPAY_CLIENT_ID = '';
const MANGOPAY_URL = '';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    paddingHorizontal: theme.sizings.medium,
    paddingTop: theme.sizings.large,
  },
  cardContainer: {
    position: 'relative',
    justifyContent: 'center',
    marginBottom: theme.sizings.medium,
  },
  cardBG: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#ddd',
    backgroundImage: 'linear-gradient(-45deg, #eaeaea , #ccc, #eaeaea 50%)',
    borderRadius: 12,
  },
  image: {
    borderRadius: theme.radius.medium,
  },
  inputWrapper: {
    marginVertical: 0,
  },
  inputContainer: {
    borderBottomColor: theme.colors.light,
  },
  input: {
    color: theme.colors.light,
    marginTop: theme.sizings.tiny,
    fontSize: theme.sizings.medium,
  },
  formHorizontal: {
    width: Platform.OS === 'ios' ? 200 : Platform.OS === 'web' ? 180 : 170,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    color: theme.colors.light,
    display: 'none',
  },
  submit: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: -140,
  },
});

const CreditCardForm = ({ route, navigation }) => {
  const { callback } = route.params;
  const [cardRegistration, setCardRegistration] = useState({});
  const { updateUser, user } = useAuth();
  const { setAlert } = useAlert();
  const { item: site } = useSite();

  const { t } = useTranslation();

  useEffect(() => {
    mangoPay.cardRegistration.baseURL = MANGOPAY_URL;
    mangoPay.cardRegistration.clientId = MANGOPAY_CLIENT_ID;

    fetchJSON({
      url: 'users/card-registration',
      method: 'POST',
      payload: {},
    }).then((result) => {
      setCardRegistration(result);
    });
  }, []);

  const onSubmit = useCallback(
    (values) => {
      const cardData = {
        cardNumber: values.cardNumber,
        cardExpirationDate: values.cardExpirationDate,
        cardCvx: values.cardCvx,
        cardType: 'CB_VISA_MASTERCARD',
      };

      mangoPay.cardRegistration.init({
        cardRegistrationURL: cardRegistration.CardRegistrationURL,
        preregistrationData: cardRegistration.PreregistrationData,
        accessKey: cardRegistration.AccessKey,
        Id: cardRegistration.Id,
      });

      mangoPay.cardRegistration.registerCard(
        cardData,
        async (res) => {
          await fetchJSON({
            url: 'users/save-card',
            method: 'POST',
            payload: res,
          });

          await updateUser({});

          navigation.pop(1);

          if (callback) {
            callback();
          }
        },
        (error) => {
          console.log({ error });
          setAlert({
            color: 'error',
            title: t('common.error'),
            message: t('auth.badCredentials'),
          });
        },
      );
    },
    [updateUser, setAlert, callback, cardRegistration, navigation, t],
  );

  const { width } = useWindowDimensions();

  return (
    <>
      <PageBackground />
      <View style={styles.wrapper}>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            cardHolder: user.username || '',
            cardNumber: user.cardAlias || '',
            cardCvx: '',
            cardExpirationDate: user.cardExpirationDate || '',
          }}
          render={({ handleSubmit, submitting }) => (
            <View style={{ paddingHorizontal: theme.sizings.mediumLarge }}>
              <View style={[styles.cardContainer, {
                height: (width * 0.67) - 32,
              }]}
              >
                {/* <ImageBackground image={CardBG} style={styles.cardBG} imageStyle={styles.image} /> */}
                <View style={styles.cardBG} />
                <FormInput
                  name="cardHolder"
                  placeholder={t('creditCard.cardHolder')}
                  containerStyle={styles.inputWrapper}
                  inputContainerStyle={styles.inputContainer}
                  inputStyle={styles.input}
                />
                <FormInput
                  type="number"
                  name="cardNumber"
                  placeholder={t('creditCard.cardNumber')}
                  containerStyle={styles.inputWrapper}
                  inputContainerStyle={styles.inputContainer}
                  inputStyle={styles.input}
                />
                <View style={styles.formHorizontal}>
                  <FormInput
                    type="number"
                    name="cardExpirationDate"
                    placeholder={t('creditCard.expirationDate')}
                    containerStyle={styles.inputWrapper}
                    inputContainerStyle={styles.inputContainer}
                    inputStyle={styles.input}
                  />
                  <FormInput
                    type="number"
                    name="cardCvx"
                    placeholder={t('creditCard.ccv')}
                    containerStyle={styles.inputWrapper}
                    inputContainerStyle={styles.inputContainer}
                    inputStyle={styles.input}
                  />
                </View>
              </View>
              <FooterButtonWrapper>
                <Button
                  data-cy="cypress_pay_card"
                  variant="light"
                  icon="credit-card"
                  text={t('common.submit')}
                  onPress={handleSubmit}
                  disabled={submitting}
                  type="submit"
                />
              </FooterButtonWrapper>
            </View>
          )}
        />

        <Text
          color="dark"
          customColor={site?.template?.backdropTextColor}
        >
          {t('creditCard.disclaimer')}

        </Text>

      </View>
    </>
  );
};

CreditCardForm.propTypes = {
  route: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

export default CreditCardForm;
