export default {
  de: 'Deutsche',
  en: 'English',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  pt: 'Português',
  ru: 'Pусский',
  ar: 'عرب',
  zh: '中文',
};
