import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import useTranslation from 'common/contexts/translations';

import Select from 'common/components/Select/Select';
import languages from 'common/utils/languages';

const LanguagePicker = ({ onChange, styles }) => {
  const { setLang, lang } = useTranslation();

  const pickerOptions = [
    {
      value: 'ar',
      label: languages.ar,
    },
    {
      value: 'zh',
      label: languages.zh,
    },
    {
      value: 'de-DE',
      label: languages.de,
    },
    {
      value: 'en-GB',
      label: languages.en,
    },
    {
      value: 'es-ES',
      label: languages.es,
    },
    {
      value: 'fr-FR',
      label: languages.fr,
    },
    {
      value: 'it-IT',
      label: languages.it,
    },
    {
      value: 'pt-PT',
      label: languages.pt,
    },
    {
      value: 'ru-RU',
      label: languages.ru,
    },
  ];

  const handleChange = useCallback((value) => {
    onChange(value);
    setLang(value);
  }, [setLang, onChange]);

  return (
    <Select
      placeholder="Select language"
      options={pickerOptions}
      value={lang}
      onChange={handleChange}
      styles={styles}
    />
  );
};

LanguagePicker.propTypes = {
  onChange: PropTypes.func,
  styles: PropTypes.object,
};

LanguagePicker.defaultProps = {
  onChange() {},
  styles: {},
};

export default LanguagePicker;
