import React from 'react';
import {
  TouchableOpacity, ImageBackground as RNImageBackground, View, Image as RNImage,
} from 'react-native';
import PropTypes from 'prop-types';

import createUrl from 'common/utils/createUrl';
import MissingImage from 'assets/image-not-found.jpg';

const setSource = (image) => {
  if (image) {
    if (Object.keys(image).length > 0 && image.url) {
      return { uri: createUrl(image.url) };
    }
    return image;
  }
  return MissingImage;
};

const ImageBackground = ({
  source, image, children, onPress, ...rest
}) => {
  if (!source && !image) {
    return null;
  }

  const imageBackground = (
    <RNImageBackground
      source={source || setSource(image)}
      {...rest}
    >
      {children}
    </RNImageBackground>
  );

  if (onPress) {
    return (
      <TouchableOpacity onPress={onPress} {...rest}>
        {imageBackground}
      </TouchableOpacity>
    );
  }

  return imageBackground;
};

ImageBackground.propTypes = {
  source: PropTypes.any,
  image: PropTypes.any,
  children: PropTypes.node,
  onPress: PropTypes.func,
};

ImageBackground.defaultProps = {
  source: null,
  image: {},
  children: <View />,
  onPress: null,
};

const Image = ({ image, ...rest }) => (
  <RNImage
    source={setSource(image)}
    {...rest}
  />
);

Image.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Image.defaultProps = {
  image: {},
};

export { Image, ImageBackground };
