import { Platform, StyleSheet } from 'react-native';

import theme from 'styles';

export default (editable, quantity) => StyleSheet.create({
  itemView: {
    flex: 1,
    width: '100%',
    aspectRatio: 1,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: quantity ? theme.colors.primary : theme.colors.greyLighter,
    borderRadius: theme.radius.medium,
    margin: theme.sizings.tiny,
  },

  innerItem: {
    width: '100%',
    height: '100%',
  },

  productContent: {
    justifyContent: 'space-evenly',
    alignSelf: 'stretch',
    alignItems: 'center',
    textAlign: 'center',
    flex: 1,
    width: '100%',
    padding: theme.sizings.tiny,

  },

  productLabel: {
    textAlign: 'center',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.sizings.small,
    borderBottomColor: 'rgba(0,0,0,0.2)',
    borderBottomWidth: 1,
    flex: 8,
  },

  variant: {
    marginBottom: 'auto',
    marginTop: 0,
    backgroundColor: theme.colors.secondaryLight,
    paddingHorizontal: theme.sizings.small,
    borderRadius: theme.radius.rounded,
  },

  quantityIndicator: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    paddingHorizontal: theme.sizings.tiny,
    textAlign: 'center',
    borderRadius: theme.radius.rounded,
    backgroundColor: theme.colors.darkerBlue,
  },
  spacer: {
    flex: 1,
  },

  badge: {
    width: Platform.OS === 'web' ? 40 : 20,
    height: '100%',
  },

  quantity: {
    marginVertical: 0,
    marginHorizontal: theme.sizings.small,
  },

  bottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 30,
    width: '100%',
    padding: theme.sizings.small,
  },

  price: {
    flex: quantity ? 3 : 1,
  },

  productButton: {
    paddingHorizontal: theme.sizings.small,
  },
});
