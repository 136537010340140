import React, { useEffect } from 'react';
import useTranslation from 'common/contexts/translations';
import { View, Platform } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Ionicons } from '@expo/vector-icons';
import { useKeepAwake } from 'expo-keep-awake';
import useAuth from 'auth/contexts/auth';

import BadgeController from 'common/components/BadgeController/BadgeController';
import OrderList from 'orders/pages/OrderList/OrderList';
import Catalog from 'waiter/pages/WaiterHome/Catalog';
import SpecialOffers from 'waiter/pages/WaiterHome/SpecialOffers';
/* import CallList from 'waiter/pages/WaiterHome/CallList';
import BookingList from 'waiter/pages/WaiterHome/BookingsList';
import BeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/BeachVisualizer'; */
import useOrders from 'orders/contexts/orders';
import useHistoryOrders from 'orders/contexts/historyOrders';
import useProduct from 'products/contexts/products';
import useSite from 'sites/contexts/sites';
import theme from './styles';

const WaiterRouter = () => {
  const { t } = useTranslation();
  const WaiterStack = createBottomTabNavigator();
  const { user } = useAuth();
  const { item: currentSite } = useSite();

  const { fetchItems: fetchProducts } = useProduct();
  const {
    handleActiveOrdersFilters: setActiveOrdersFilters,
    activeOrdersFilters,
    refreshOrders,
    fetchBarmanList,
    setSort: setOrdersSort,
  } = useOrders();
  const {
    handleActiveOrdersFilters: setActiveHistoryOrdersFilters,
    activeOrdersFilters: activeHistoryOrdersFilters,
    refreshOrders: refreshHistoryOrders,
    setSort: setHistoryOrdersSort,
  } = useHistoryOrders();

  useKeepAwake();

  useEffect(() => {
    setActiveHistoryOrdersFilters('state_ne', 'done');
    setHistoryOrdersSort('created_at:DESC');
  }, [setActiveHistoryOrdersFilters, setHistoryOrdersSort]);

  useEffect(() => {
    setActiveOrdersFilters('state_eq', 'done');
    setOrdersSort('created_at:ASC');
  }, [setActiveOrdersFilters, setOrdersSort]);

  useEffect(() => {
    refreshOrders();
  }, [activeOrdersFilters, refreshOrders]);

  useEffect(() => {
    refreshHistoryOrders();
  }, [activeHistoryOrdersFilters, refreshHistoryOrders]);

  useEffect(() => {
    fetchProducts();
    fetchBarmanList();
  }, [fetchProducts, fetchBarmanList]);

  const routeLabels = {
    // maps: t('tabs.maps'),
    // bookings: t('waiter.bookings'),
    orders: t('tabs.orders'),
    // calls: t('tabs.calls'),
    stock: t('waiter.stock'),
    // offers: t('waiter.offers'),
  };

  const routeIcons = {
    orders: 'ios-basket',
    // calls: 'ios-call',
    stock: 'ios-list',
    // offers: 'ios-gift',
    // maps: 'ios-map',
    // bookings: 'ios-book',
  };

  return ['waiter', 'barman', 'manager'].includes(user?.role.type) ? (
    <WaiterStack.Navigator
      screenOptions={({ route }) => ({
        // eslint-disable-next-line react/prop-types
        tabBarIcon: ({ color, size }) => (
          <View>
            <BadgeController name={route.name} />
            <Ionicons
              name={routeIcons[route.name]}
              size={size}
              color={color}
              data-cy="cypress_plus_icon"
            />
          </View>
        ),
        tabBarLabel: routeLabels[route.name],
      })}
      lazy={false}
      tabBarOptions={{
        style: {
          backgroundColor: theme.colors.dark,
          paddingTop: theme.sizings.small,
          // model perfect height for IOS
          height: Platform.OS === 'ios' ? 92 : 60,
        },
        labelStyle: {
          fontSize: theme.fontSizes.default,
          fontFamily: 'AvenirBold',
          paddingTop: Platform.OS === 'ios' ? theme.sizings.tiny : 0,
        },
        activeTintColor: theme.colors.secondaryLight,
        inactiveTintColor: theme.colors.greyDarker,
      }}
      initialRouteName={user?.role.type === 'waiter' ? 'waiter' : 'barman'}
    >
      {/* <WaiterStack.Screen name="maps" component={BeachVisualizer} options={{ title: t('waiter.maps') }} />
      <WaiterStack.Screen name="bookings" component={BookingList} options={{ title: t('waiter.bookings') }} /> */}
      {!currentSite?.pleaseMyCar && (
        <WaiterStack.Screen
          name="barman"
          component={OrderList}
          options={{
            title: t('route.barmanHome'),
          }}
        />
      )}
      <WaiterStack.Screen
        name="waiter"
        component={OrderList}
        options={{
          title: currentSite?.pleaseMyCar
            ? currentSite?.pleaseMyCarDriver
              ? t('route.waiterHomeDriver')
              : t('route.waiterHomeValet') : t('route.waiterHome'),
        }}
      />

      {!currentSite?.pleaseMyCar && (
        <>
          <WaiterStack.Screen
            name="history"
            component={OrderList}
            options={{ title: t('waiter.ordersHistory') }}
          />
          <WaiterStack.Screen
            name="stock"
            component={Catalog}
            options={{ title: t('waiter.stock') }}
          />

          {/* <WaiterStack.Screen name="calls" component={CallList} options={{ title: t('waiter.callList') }} /> */}
          {['manager', 'waiter', 'barman'].includes(user?.role.type) && (
            <WaiterStack.Screen
              name="offers"
              component={SpecialOffers}
              options={{ title: t('waiter.offers') }}
            />
          )}
        </>
      )}
    </WaiterStack.Navigator>
  ) : null;
};

export default WaiterRouter;
