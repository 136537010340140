import { useNavigation } from '@react-navigation/native';
import useBasket from 'basket/contexts/basket';
import useBooking from 'bookings/contexts/bookings';
import Button from 'common/components/Button/Button';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import FormInput from 'common/components/FormInput/FormInput';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Page from 'common/components/Page/Page';
import Select from 'common/components/Select/Select';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import useOrder from 'orders/contexts/orders';
import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';

const OrderSeatSelect = ({ route }) => {
  const [booking, setBooking] = useState(null);
  const [seat, setSeat] = useState(null);
  const [comments, setComments] = useState(null);
  const { setItems: setBasket } = useBasket();
  const { orderId, booking: currentBooking } = route.params;
  const navigation = useNavigation();

  const {
    fetchItem: fetchOrder,
    item: order,
    setItem: setOrder,
    isFetching: isOrdersFetching,
  } = useOrder();

  const {
    items: bookings,
  } = useBooking();

  const { t } = useTranslation();

  useEffect(() => {
    if (orderId && order?.id === orderId) {
      setBasket(order.order_items);
      setBooking(order.booking.id);
      setSeat(order?.seat?.id);
      setComments(order.comments);
    }
  }, [order, orderId, setBasket]);

  useEffect(() => {
    if (orderId === null) {
      setOrder(null);
    }
  }, [orderId, setOrder]);

  useEffect(() => {
    if (orderId) {
      fetchOrder(orderId);
    } else {
      setBasket([]);
    }
  }, [fetchOrder, orderId, setBasket]);

  useEffect(() => {
    if (currentBooking) {
      setBooking(currentBooking.id);
      setSeat(currentBooking.seats[0].id);
    }
  }, [currentBooking]);

  const pickerOptions = useMemo(() => bookings.map(({
    id, seats, clientName, user,
  }) => ({
    label: `${seats[0] ? seats[0].name : ''}- ${clientName}` || user?.username,
    value: id,
    seat: seats?.[0]?.id,
  })), [bookings]);

  const handleValidate = useCallback(() => {
    navigation.navigate('OrderDetail', { seat, comments, booking });
  }, [booking, comments, navigation, seat]);

  /* const handleCreateBooking = useCallback(() => {
    navigation.navigate('BookingEdit', {
      orderCreation: true,
    });
  }, [navigation]); */

  return (
    <Page>
      <LoadingSpinner
        visible={isOrdersFetching}
      />
      <ScrollView style={styles.innerView}>

        {/*
        <Button
          icon="arrow-right"
          text={t('orders.createBooking')}
          onPress={handleCreateBooking}
        />

        <Text
          isBold
          centered
          isUppercase
          style={styles.orCreate}
        >
          {t('common.or')}
        </Text>
        */}

        <View style={{ padding: theme.sizings.small }}>

          <Text style={styles.text}>
            {t('bookings.seatName')}
            {currentBooking ? `${currentBooking.seats[0].name} - ${currentBooking.clientName}` : ''}
          </Text>
          {!currentBooking ? (
            <Select
              options={pickerOptions}
              value={booking}
              onChange={(value, option) => {
                setBooking(value);
                setSeat(option.seat);
              }}
            />
          ) : null}
        </View>
        <FormInput
          type="text"
          finalForm={false}
          value={comments}
          onChange={setComments}
          label={t('common.comments')}
        />

        <FooterButtonWrapper>
          <Button
            variant="gradient"
            icon="arrow-right"
            text={t('orders.goNextStep')}
            disabled={!booking}
            onPress={handleValidate}
          />
        </FooterButtonWrapper>
      </ScrollView>
    </Page>
  );
};

const styles = StyleSheet.create({
  type: {
    marginTop: theme.sizings.medium,
  },

  innerView: {
    paddingHorizontal: theme.sizings.small,
    paddingTop: theme.sizings.medium,
  },

  orCreate: {
    marginTop: theme.sizings.large,
    marginBottom: theme.sizings.medium,
  },

  text: {
    color: theme.colors.primary,
    fontFamily: theme.fonts.bold,
    marginBottom: theme.sizings.tiny,
    fontSize: theme.fontSizes.medium,
  },
});

OrderSeatSelect.propTypes = {
  route: PropTypes.object.isRequired,
};

export default OrderSeatSelect;
