/* eslint-disable max-len */
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import useBasket from 'basket/contexts/basket';
import useCall from 'calls/contexts/calls';
import Button from 'common/components/Button/Button';
import { ImageBackground } from 'common/components/Images';
import ReductionPrice from 'common/components/ReductionPrice/ReductionPrice';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useTranslation from 'common/contexts/translations';
import useTrans from 'common/hooks/use-trans';
import getWording from 'common/utils/getWording';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import {
  StyleSheet, TouchableHighlight, View, ScrollView,
} from 'react-native';
import useSite from 'sites/contexts/sites';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';

const styles = StyleSheet.create({
  description: {
    width: '80%',
  },

  optionsTitle: {
    paddingTop: theme.sizings.medium,
  },
  optionsTitleText: {
    justifyContent: 'center',
  },
  options: {
    marginTop: theme.sizings.small,
  },
  optionVariants: {
    paddingTop: theme.sizings.smallMedium,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  option: {
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.tiny,
    marginRight: theme.sizings.small,
    marginBottom: theme.sizings.small,
    minWidth: 80,
    alignItems: 'center',
    borderRadius: theme.radius.rounded,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.32)',
  },
});

const Service = ({ route }) => {
  const { service, reduction } = route.params;
  const { addItem, setIsAllInclusiveBasket } = useBasket();
  const { t, i18n } = useTranslation();
  const { translateText } = useTrans();
  const { options } = service;
  const { item: site } = useSite();
  const navigation = useNavigation();

  const lang = i18n.language;

  const [selectedOptions, setSelectedOptions] = useState(options.map((o) => ({ option: o.option.id, variants: [] })));
  const [offsetPrice, setOffsetPrice] = useState(service.price);
  const { currentCall, callWaiter } = useCall();

  const handlePressOption = useCallback((optionIndex, variantId, variantPrice, multiple) => {
    const selectedCopy = [...selectedOptions];

    let offset = offsetPrice;

    if (multiple) {
      const variantIndex = selectedCopy[optionIndex].variants.findIndex((v) => v === variantId);

      if (variantIndex >= 0) {
        selectedCopy[optionIndex].variants.splice(variantIndex, 1);
        offset -= variantPrice;
      } else {
        selectedCopy[optionIndex].variants.push(variantId);
        offset += variantPrice;
      }
    } else {
      selectedCopy[optionIndex].variants = (selectedCopy[optionIndex].variants.length > 0
        && selectedCopy[optionIndex].variants[0].id === variantId)
        ? []
        : [variantId];
      offset += (selectedCopy[optionIndex].variants.length > 0
          && selectedCopy[optionIndex].variants[0].id === variantId) ? (variantPrice * -1) : variantPrice;
    }
    setSelectedOptions(selectedCopy);
    setOffsetPrice(offset);
  }, [offsetPrice, selectedOptions]);

  return (
    <ImageBackground image={service.images[0]} style={{ width: '100%', height: '100%', marginBottom: 0 }}>
      <View style={commonStyles.fullViewContainer}>
        <View style={commonStyles.fullViewDetailsContainer}>
          <View style={commonStyles.fullViewButtonContainer}>
            { service.callWaiter ? (
              <Button
                text={t(`beach.${currentCall ? 'cancelCall' : 'callWaiter'}`)}
                onPress={callWaiter}
                variant={currentCall ? 'greyDarker' : 'gradient'}
              />
            ) : (
              <Button
                text={t('basket.add')}
                variant="gradient"
                onPress={() => {
                  addItem(service,
                    // eslint-disable-next-line camelcase
                    selectedOptions,
                    service.price + offsetPrice,
                    'service'); // variantSelected ? variantSelected.price : service.price);
                  if (site?.pleaseMyCar) {
                    if (site?.all_inclusive && service?.all_inclusive) {
                      setIsAllInclusiveBasket(true);
                    }
                    navigation.navigate('Basket');
                  }
                }}
              />
            )}
          </View>
          <View style={commonStyles.fullViewDetailsContainer2}>
            <View style={commonStyles.fullViewDetailsPriceWrapper}>
              <ReductionPrice
                // price={variantSelected ? variantSelected.price : service.price}
                price={service.price + offsetPrice}
                reduction={reduction}
                newPriceColor="secondaryLight"
                reductionPriceColor="greyLighter"
                newPriceSize="huge"
                reductionPriceSize="largest"
              />
            </View>
            <ScrollView style={reduction ? commonStyles.fullViewDetailsContentDiscount : commonStyles.fullViewDetailsContent}>
              <View style={styles.description}>
                <TranslatedText tagsStyles={{ p: { color: theme.colors.light } }} value={service.description} />
              </View>
              { options.length > 0
                && (
                <View>
                  <View style={[styles.optionsTitle, commonStyles.textWithIcon]}>
                    <View style={commonStyles.fullViewDetailsIcon}>
                      <FontAwesome name="plus" color={theme.colors.secondaryLight} />
                    </View>
                    <View style={styles.optionsTitleText}>
                      <Text color="light" size="large">
                        {getWording(site?.vocabulary?.productOptions, t('common.options'), lang,
                          translateText)}
                      </Text>
                    </View>
                  </View>
                  <ScrollView style={styles.options}>
                    { /* eslint-disable-next-line camelcase  */ }
                    {options.map(({ option, variant_prices }, optionIndex) => (
                      <>
                        <View style={styles.optionsTitleText}>
                          <TranslatedText color="white" value={option.name} />
                        </View>
                        <View style={styles.optionVariants}>
                          {variant_prices.map((variant) => (
                            <TouchableHighlight
                              key={variant.id}
                              style={[styles.option, {
                                backgroundColor: selectedOptions[optionIndex].variants.find((v) => v === variant.option_variant.id) ? (
                                  'rgba(255, 255 ,255, 0.32)') : 'transparent',
                              }]}
                              onPress={() => handlePressOption(optionIndex, variant.option_variant.id, variant.price, option.multiple)}
                            >
                              <View>
                                { /* eslint-disable-next-line camelcase  */ }
                                <TranslatedText color="light" value={variant.option_variant.name} />
                              </View>
                            </TouchableHighlight>
                          ))}
                        </View>
                      </>
                    ))}
                  </ScrollView>
                </View>
                )}
            </ScrollView>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

Service.propTypes = {
  route: PropTypes.object.isRequired,
};

export default Service;
