/* eslint-disable global-require */
import React from 'react';
import { useFonts } from 'expo-font';

import Routing from './Routing';
import AppProvider from './AppProvider';
import AppLoader from './AppLoader';

export default function App() {
  const [fontsLoaded] = useFonts({
    Lato: require('assets/fonts/Lato/Lato-Regular.ttf'),
    LatoBold: require('assets/fonts/Lato/Lato-Bold.ttf'),
    Avenir: require('assets/fonts/Avenir/Avenir-Medium.ttf'),
    AvenirBold: require('assets/fonts/Avenir/Avenir-Black.ttf'),
    Roboto: require('assets/fonts/Roboto/Roboto-Regular.ttf'),
    RobotoBold: require('assets/fonts/Roboto/Roboto-Bold.ttf'),
    RobotoCondensed: require('assets/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf'),
    Montserrat: require('assets/fonts/Montserrat/Montserrat-Regular.ttf'),
    MontserratBold: require('assets/fonts/Montserrat/Montserrat-Bold.ttf'),
    Bebas: require('assets/fonts/Bebas/BebasNeue.otf'),
    Rubik: require('assets/fonts/Rubik/Rubik-Bold.ttf'),
    PalanquinDark: require('assets/fonts/Palanquin_Dark/PalanquinDark-Bold.ttf'),
    Sniglet: require('assets/fonts/Sniglet/Sniglet-Regular.ttf'),
    AbrilFatface: require('assets/fonts/Abril_Fatface/AbrilFatface-Regular.ttf'),
    ZillaSlab: require('assets/fonts/Zilla_Slab/ZillaSlab-Regular.ttf'),
    ZillaSlabBold: require('assets/fonts/Zilla_Slab/ZillaSlab-Bold.ttf'),
  });

  return (
    <AppProvider>
      <AppLoader>
        {fontsLoaded && <Routing />}
      </AppLoader>
    </AppProvider>
  );
}
