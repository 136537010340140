import { NavigationContainer } from '@react-navigation/native';
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import useAuth from 'auth/contexts/auth';
import ForgotPassword from 'auth/pages/ForgotPassword/ForgotPassword';
import ResetPassword from 'auth/pages/ResetPassword/ResetPassword';
import SignInPage from 'auth/pages/SignIn/SignIn';
import SignUpPage from 'auth/pages/SignUp/SignUp';
import ProductsCounter from 'basket/components/ProductsCounter/ProductsCounter';
import Basket from 'basket/pages/Basket/Basket';
// import AddBookingButton from 'bookings/components/AddBookingButton/AddBookingButton';
// import BookingEdit from 'bookings/pages/BookingEdit/BookingEdit';
// import BookingsList from 'bookings/pages/BookingsList/BookingsList';

import ComplementaryProducts from 'bookings/pages/ComplementaryProducts/ComplementaryProducts';
import DateSelect from 'bookings/pages/DateSelect/DateSelect';
import HourSelect from 'bookings/pages/HourSelect/HourSelect';
import MapsList from 'bookings/pages/MapsList/MapsList';
import SeatSelector from 'bookings/pages/SeatSelector/SeatSelector';
import UserTypePage from 'bookings/pages/UserTypePage/UserTypePage';
import CGU from 'cgu/pages/CGU';
import navigationRef from 'common/components/AppStateHandler/RootNavigation';
import LogoutButton from 'common/components/LogoutButton/LogoutButton';
import UserButton from 'common/components/UserButton/UserButton';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import useTrans from 'common/hooks/use-trans';
import { LinearGradient } from 'expo-linear-gradient';
import * as Linking from 'expo-linking';
import HomePage from 'home/pages/HomePage';
import ProfileOrdersList from 'orders/pages/OrderList/ProfileOrdersList';
import OrderDetail from 'orders/pages/OrderDetail/OrderDetail';
import OrderLocalization from 'orders/pages/OrderLocalization/OrderLocalization';
import OrderSeatSelect from 'orders/pages/OrderSeatSelect/OrderSeatSelect';
import OrderValidate from 'orders/pages/OrderValidate/OrderValidate';
import OrderSummary from 'orders/pages/OrderSummary/OrderSummary';
import Card from 'payments/pages/Card/Card';
import CreditCardForm from 'payments/pages/CreditCardForm/CreditCardForm';
import Payment from 'payments/pages/Payment/Payment';
import PaymentRecap from 'payments/pages/PaymentRecap/PaymentRecap';
import React, { useEffect, useRef } from 'react';
import {
  Dimensions, StyleSheet, View, Platform,
} from 'react-native';
import DropdownAlert from 'react-native-dropdownalert';
import TemplateHeaderTitle from 'sites/components/TemplateHeaderTitle/TemplateHeaderTitle';
import AroundDetail from 'sites/pages/SiteDetail/AroundDetail';
import CategoryDetail from 'sites/pages/SiteDetail/CategoryDetail';
import CategoryServiceDetail from 'sites/pages/SiteDetail/CategoryServiceDetail';
import ProductDetail from 'sites/pages/SiteDetail/Product';
import ServiceDetail from 'sites/pages/SiteDetail/Service';
import SitePresentation from 'sites/pages/SitePresentation/SitePresentation';
import SitesList from 'sites/pages/SitesList/SitesList';
import QuickSitesList from 'sites/pages/QuickSitesList/QuickSitesList';
import AccountDeleted from 'user/pages/AccountDeleted/AccountDeleted';
import DeleteAccount from 'user/pages/DeleteAccount/DeleteAccount';
import Profile from 'user/pages/Profile/Profile';
import BookingActivations from 'waiter/pages/WaiterHome/BookingActivations';
import WaiterBookingDetail from 'waiter/pages/WaiterHome/WaiterBookingDetail';
import MapHeaderTitle from 'maps/components/MapHeaderTitle/MapHeaderTitle';
import SiteOrders from 'sites/pages/SiteDetail/SiteOrders';
import SiteProducts from 'sites/pages/SiteDetail/SiteProducts';
import SiteHome from 'sites/pages/SiteDetail/SiteHome';
import TemplateHeaderGradient from 'sites/components/TemplateHeaderGradient/TemplateHeaderGradient';
import HeaderBackToSite from 'sites/components/HeaderBack/HeaderBackToSite/HeaderBackToSite';
import HeaderBackDefault from 'sites/components/HeaderBack/HeaderBackDefault/HeaderBackDefault';
import OrderInclusiveFormule from 'orders/pages/OrderInclusiveFormule/OrderInclusiveFormule';
import SiteOrdersLocal from 'sites/pages/SiteDetail/SiteOrdersLocal';
import useSite from 'sites/contexts/sites';
import useBasket from 'basket/contexts/basket';
import getWording from 'common/utils/getWording';
import PmcRouter from './PmcRouting';
import HomeRedirect from './HomeRedirect';
import theme from './styles';
import WaiterRouter from './WaiterRouting';
import OrderSelectDeliveryTime from './orders/pages/OrderSelectDeliveryTime/OrderSelectDeliveryTime';
import OrderUserDetails from './orders/pages/OrderUserDetails/OrderUserDetails';

// import logAnalytics from '../analytics';

const headerStyles = {
  headerBackTitleVisible: false,
  headerTitleAlign: 'center',
  headerTitleStyle: {
    color: theme.colors.light,
    fontSize: theme.fontSizes.larger,
    fontFamily: 'MontserratBold',
    width: Dimensions.get('window').width - (56 + 18) * 2,
    textAlign: 'center',
  },
};

const twoRightIcons = {
  headerTitleStyle: {
    color: theme.colors.light,
    fontSize: theme.fontSizes.larger,
    fontFamily: 'MontserratBold',
    // 80 taille de deux pictos à droite + marge * 2 côté
    width: Dimensions.get('window').width - (80 + 18) * 2,
    textAlign: 'center',
  },
};

const darkGradientHeader = {
  headerBackground: () => (
    <LinearGradient
      colors={['rgba(0,0,0,0.6)', 'rgba(0,0,0,0.2)']}
      start={[0, 0]}
      end={[0, 1]}
      style={{
        flex: 1,
      }}
    />
  ),
};

const styles = StyleSheet.create({
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerLeft: {
    marginLeft: theme.sizings.small,
  },
});

const cardStyle = Platform.OS === 'web' ? { height: '100%' } : null;
const prefix = Linking.makeUrl('/');
const MainStack = createStackNavigator();

export default () => {
  const { t, i18n } = useTranslation();
  const { item: currentSite, fetchItem: setCurrentSite } = useSite();
  const { setItems: setItemsBasket } = useBasket();
  const { translateText } = useTrans();
  const { setDropDownAlertRef } = useAlert();
  const routeNameRef = useRef();
  const { user } = useAuth();
  const linking = {
    enabled: false,
    prefixes: [prefix],
    config: {
      /* ResetPasswordPage: 'reset-password/:code', */
    },
  };

  const lang = i18n.language;

  useEffect(() => {
    if (user && !currentSite) {
      setCurrentSite(user.site?.id);
    }
  }, [currentSite, setCurrentSite, user]);

  return (
    <>
      <NavigationContainer
        onReady={() => {
          routeNameRef.current = navigationRef.current.getCurrentRoute().name;
        }}
        onStateChange={async () => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.current.getCurrentRoute().name;
          /* const trackScreenView = (routeName) => {
             logAnalytics('screen_view', {
              firebase_screen: routeName,
              firebase_screen_class: currentSite?.name || 'general',
            });
          }; */

          if (previousRouteName !== currentRouteName) {
            // Save the current route name for later comparison
            routeNameRef.current = currentRouteName;

            // Replace the line below to add the tracker from a mobile analytics SDK
            // await trackScreenView(currentRouteName);
          }
        }}
        ref={navigationRef}
        linking={linking}
      >
        <MainStack.Navigator
          screenOptions={{
            ...headerStyles,
            headerBackImage: () => <HeaderBackDefault />,
            cardStyle,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        >
          <MainStack.Screen
            name="home"
            options={{
              headerShown: false,
            }}
            component={HomeRedirect}
          />
          <MainStack.Screen
            name="SitesList"
            component={SitesList}
            options={{
              title: <TemplateHeaderTitle title={t('route.sitesList')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="QuickSitesList"
            component={QuickSitesList}
            options={{
              title: <TemplateHeaderTitle title={t('route.sitesList')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          {/* <MainStack.Screen
            name="BookingsList"
            component={BookingsList}
            options={{
              title: <TemplateHeaderTitle title={t('route.bookingsList')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          /> */}
          <MainStack.Screen
            name="HomePage"
            component={HomePage}
            options={{
              title: <TemplateHeaderTitle title={t('route.homePage')} />,
              headerBackground: () => <TemplateHeaderGradient />,
              headerRight: () => <UserButton />,
              headerShown: false,
              statusBarStyle: 'light',
              ...TransitionPresets.FadeFromBottomAndroid,
            }}
          />
          <MainStack.Screen
            name="orders"
            component={ProfileOrdersList}
            options={{
              title: <TemplateHeaderTitle title={t('waiter.ordersList')} />,
              headerBackground: () => <TemplateHeaderGradient />,
              headerRight: () => <UserButton />,
            }}
          />
          <MainStack.Screen
            name="ProfilePage"
            component={Profile}
            options={{
              title: <TemplateHeaderTitle title={t('route.profilePage')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="DeleteAccount"
            component={DeleteAccount}
            options={{
              title: <TemplateHeaderTitle title={t('route.deleteAccount')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="AccountDeleted"
            component={AccountDeleted}
            options={{
              title: <TemplateHeaderTitle title={t('route.accountDeleted')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="WaiterHome"
            options={{
              title: (
                <TemplateHeaderTitle
                  title={user?.username}
                />
              ),
              headerBackground: () => <TemplateHeaderGradient waiterTemplate />,
              headerLeft: () => (
                <View style={styles.headerLeft}>
                  <LogoutButton />
                </View>
              ),
              headerRight: () => (
                <View style={styles.headerRight}>
                  {/* <AddBookingButton /> */}
                  {/* {['waiter', 'barman', 'manager'].includes(user?.role.type) && <AddOrderButton />} */}
                  {/* currentSite?.pleaseMyCar && (
                    <View style={{ paddingHorizontal: theme.sizings.medium }}>
                      <HistoryButton />
                    </View>
                  ) */}
                  <UserButton />
                </View>
              ),
            }}
          >
            {(props) => currentSite?.pleaseMyCar ? <PmcRouter {...props} /> : <WaiterRouter {...props} />}
          </MainStack.Screen>
          <MainStack.Screen
            name="SitePresentation"
            initialParams={{ siteId: null, edit: false, mapId: null }}
            component={SitePresentation}
            options={{
              title: <TemplateHeaderTitle />,
              headerTransparent: true,
              ...darkGradientHeader,
            }}
          />
          <MainStack.Screen
            name="MapsList"
            initialParams={{ edit: false }}
            component={MapsList}
            options={{
              title: <TemplateHeaderTitle />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="Payment"
            initialParams={{ siteId: null }}
            component={Payment}
            options={{
              title: <TemplateHeaderTitle title={t('basket.payment')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="PaymentRecap"
            initialParams={{ siteId: null }}
            component={PaymentRecap}
            options={{
              title: <TemplateHeaderTitle title={t('basket.orderRecap')} />,
              headerBackground: () => (
                <TemplateHeaderGradient
                  waiterTemplate={user?.role.type === 'waiter'}
                />
              ),
            }}
          />
          <MainStack.Screen
            name="WaiterBookingDetail"
            initialParams={{ siteId: null }}
            component={WaiterBookingDetail}
            options={({ route }) => ({
              title: <TemplateHeaderTitle title={t('route.bookingDetail')} />,
              headerBackground: () => <TemplateHeaderGradient />,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <BookingActivations bookingId={route.params.id} />
                </View>
              ),
            })}
          />
          <MainStack.Screen
            name="Card"
            initialParams={{ siteId: null }}
            component={Card}
            options={{
              title: <TemplateHeaderTitle title={t('creditCard.creditCard')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="CreditCardForm"
            initialParams={{ siteId: null }}
            component={CreditCardForm}
            options={{
              title: <TemplateHeaderTitle title={t('creditCard.creditCard')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="OrderDetail"
            initialParams={{ orderId: null }}
            component={OrderDetail}
            options={{
              title: <TemplateHeaderTitle title={t('route.orderDetail')} />,
              headerBackground: () => (
                <TemplateHeaderGradient
                  waiterTemplate={user?.role.type === 'waiter'}
                />
              ),
            }}
          />
          <MainStack.Screen
            name="OrderInclusiveFormule"
            initialParams={{ orderId: null }}
            component={OrderInclusiveFormule}
            options={{
              title: <TemplateHeaderTitle />,
              headerBackground: () => (
                <TemplateHeaderGradient
                  waiterTemplate={user?.role.type === 'waiter'}
                />
              ),
            }}
          />
          <MainStack.Screen
            name="OrderLocalization"
            initialParams={{ orderId: null }}
            component={OrderLocalization}
            options={{
              title: (
                <TemplateHeaderTitle
                  title={getWording(
                    currentSite?.vocabulary?.processCustomForm,
                    t('route.localization'),
                    lang,
                    translateText,
                  )}
                />
              ),
              headerBackground: () => (
                <TemplateHeaderGradient
                  waiterTemplate={user?.role.type === 'waiter'}
                />
              ),
            }}
          />
          <MainStack.Screen
            name="CurrentOrderSummary"
            initialParams={{ orderId: null }}
            component={OrderSummary}
            options={{
              title: <TemplateHeaderTitle title={t('route.orderSummary')} />,
              headerBackground: () => (
                <TemplateHeaderGradient
                  waiterTemplate={user?.role.type === 'waiter'}
                />
              ),
            }}
          />
          <MainStack.Screen
            name="ValidateOrderSummary"
            initialParams={{ orderId: null }}
            component={OrderSummary}
            options={{
              title: <TemplateHeaderTitle title={t('route.orderSummary')} />,
              headerBackground: () => (
                <TemplateHeaderGradient
                  waiterTemplate={user?.role.type === 'waiter'}
                />
              ),
              headerLeft: () => <HeaderBackToSite />,
            }}
          />
          <MainStack.Screen
            name="FinalOrderSummary"
            initialParams={{ orderId: null, finalOrder: null }}
            component={OrderSummary}
            options={{
              title: <TemplateHeaderTitle title={t('route.orderSummary')} />,
              headerBackground: () => (
                <TemplateHeaderGradient
                  waiterTemplate={user?.role.type === 'waiter'}
                />
              ),
            }}
          />
          <MainStack.Screen
            name="EditOrderSummary"
            initialParams={{ orderId: null }}
            component={OrderSummary}
            options={{
              title: <TemplateHeaderTitle title={t('route.orderSummary')} />,
              headerBackground: () => (
                <TemplateHeaderGradient
                  waiterTemplate={user?.role.type === 'waiter'}
                />
              ),
            }}
          />
          <MainStack.Screen
            name="OrderSeatSelect"
            initialParams={{ orderId: null }}
            component={OrderSeatSelect}
            options={{
              title: <TemplateHeaderTitle title={t('route.orderDetail')} />,
              headerBackground: () => (
                <TemplateHeaderGradient
                  waiterTemplate={user?.role.type === 'waiter'}
                />
              ),
            }}
          />
          <MainStack.Screen
            name="OrderUserDetails"
            initialParams={{ orderId: null }}
            component={OrderUserDetails}
            options={{
              title: <TemplateHeaderTitle title={t('route.orderUserDetail')} />,
              headerBackground: () => (
                <TemplateHeaderGradient
                  waiterTemplate={user?.role.type === 'waiter'}
                />
              ),
            }}
          />
          {/* <MainStack.Screen
            name="BookingEdit"
            component={BookingEdit}
            initialParams={{
              orderCreation: false,
            }}
            options={{
              title: <TemplateHeaderTitle title={t('route.bookingDetail')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          /> */}
          <MainStack.Screen
            name="OrderValidate"
            component={OrderValidate}
            initialParams={{ order: null, bookingId: null }}
            options={{
              title: (
                <TemplateHeaderTitle
                  title={translateText(t('route.orderValidate'))}
                />
              ),
              headerBackground: () => (
                <TemplateHeaderGradient
                  waiterTemplate={user?.role.type === 'waiter'}
                />
              ),
            }}
          />
          <MainStack.Screen
            name="OrderSelectDeliveryTime"
            component={OrderSelectDeliveryTime}
            initialParams={{ siteId: null }}
            options={{
              title: (
                <TemplateHeaderTitle
                  title={getWording(
                    currentSite?.vocabulary?.processChoiceForm,
                    t('bookings.rendezVous'),
                    lang,
                    translateText,
                  )}
                />
              ),
              headerBackground: () => (
                <TemplateHeaderGradient
                  waiterTemplate={user?.role.type === 'waiter'}
                />
              ),
            }}
          />
          <MainStack.Screen
            name="SeatSelector"
            component={SeatSelector}
            initialParams={{ siteId: null }}
            options={({ route }) => ({
              title: <MapHeaderTitle mapId={route.params.mapId} />,
              headerTransparent: true,
              headerBackground: () => <TemplateHeaderGradient />,
            })}
          />
          <MainStack.Screen
            name="DateSelect"
            component={DateSelect}
            initialParams={{ siteId: null }}
            options={{
              title: <TemplateHeaderTitle />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="HourSelect"
            component={HourSelect}
            initialParams={{ siteId: null }}
            options={{
              title: <TemplateHeaderTitle />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="UserTypePage"
            component={UserTypePage}
            initialParams={{ siteId: null }}
            options={{
              title: <TemplateHeaderTitle />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="AroundDetail"
            component={AroundDetail}
            options={({ route }) => ({
              title: (
                <TemplateHeaderTitle
                  title={translateText(route.params.location.name)}
                />
              ),
              headerTransparent: true,
              ...darkGradientHeader,
              ...twoRightIcons,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <UserButton />
                  <ProductsCounter />
                </View>
              ),
            })}
          />
          {/*
          <MainStack.Screen
            name="SiteDetail"
            initialParams={{ siteId: null, bookingId: null }}
            options={{
              headerShown: false,
              ...twoRightIcons,
              ...darkGradientHeader,
              title: <TemplateHeaderTitle />,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <UserButton />
                  <ProductsCounter />
                </View>
              ),
            }}
          >
            {(props) => <SiteDetail {...props} />}
          </MainStack.Screen>
          */}

          <MainStack.Screen
            name="SiteHome"
            initialParams={{ siteId: null, bookingId: null }}
            options={{
              headerTransparent: true,
              ...twoRightIcons,
              title: null,
              headerLeft: null,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <UserButton onDarkBackground />
                  <ProductsCounter onDarkBackground />
                </View>
              ),
            }}
          >
            {(props) => <SiteHome {...props} />}
          </MainStack.Screen>

          <MainStack.Screen
            name="SiteProducts"
            initialParams={{ siteId: null, bookingId: null }}
            options={() => ({
              cardStyle,
              // ...HeaderDefaultStyle(),
              ...twoRightIcons,
              title: <TemplateHeaderTitle title={t('route.shop')} />,
              headerBackground: () => <TemplateHeaderGradient />,
              headerLeft: () => <HeaderBackToSite />,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <UserButton />
                  <ProductsCounter />
                </View>
              ),
            })}
          >
            {(props) => <SiteProducts {...props} />}
          </MainStack.Screen>

          <MainStack.Screen
            name="SiteOrders"
            initialParams={{ siteId: null, bookingId: null }}
            options={() => ({
              cardStyle,
              // ...HeaderDefaultStyle(),
              ...twoRightIcons,
              title: <TemplateHeaderTitle title={t('tabs.orders')} />,
              headerBackground: () => <TemplateHeaderGradient />,
              headerLeft: () => <HeaderBackToSite />,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <UserButton />
                  <ProductsCounter />
                </View>
              ),
            })}
          >
            {(props) => <SiteOrders {...props} />}
          </MainStack.Screen>

          <MainStack.Screen
            name="SiteOrdersLocal"
            initialParams={{ siteId: null, bookingId: null }}
            options={() => ({
              cardStyle,
              // ...HeaderDefaultStyle(),
              ...twoRightIcons,
              title: <TemplateHeaderTitle title={t('tabs.orders')} />,
              headerBackground: () => <TemplateHeaderGradient />,
              headerLeft: () => <HeaderBackToSite />,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <UserButton />
                  <ProductsCounter />
                </View>
              ),
            })}
          >
            {(props) => <SiteOrdersLocal {...props} />}
          </MainStack.Screen>

          <MainStack.Screen
            name="ProductDetail"
            initialParams={{ product: null }}
            component={ProductDetail}
            options={({ route }) => ({
              title: (
                <TemplateHeaderTitle
                  title={translateText(route.params.product.name)}
                  onDarkBackground
                />
              ),
              headerTransparent: true,
              headerBackImage: () => <HeaderBackDefault onDarkBackground />,
              ...darkGradientHeader,
              ...twoRightIcons,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <UserButton onDarkBackground />
                  <ProductsCounter onDarkBackground />
                </View>
              ),
            })}
          />
          <MainStack.Screen
            name="CategoryDetail"
            initialParams={{ products: null }}
            component={CategoryDetail}
            options={({ route }) => ({
              title: (
                <TemplateHeaderTitle
                  title={translateText(route.params.category?.name)}
                />
              ),
              headerBackground: () => <TemplateHeaderGradient />,
              ...twoRightIcons,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <UserButton />
                  <ProductsCounter />
                </View>
              ),
            })}
          />
          <MainStack.Screen
            name="ServiceDetail"
            initialParams={{ product: null }}
            component={ServiceDetail}
            options={({ route }) => ({
              title: (
                <TemplateHeaderTitle
                  title={translateText(route.params.service?.name)}
                />
              ),
              headerTransparent: true,
              ...twoRightIcons,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <UserButton />
                  <ProductsCounter />
                </View>
              ),
            })}
          />
          <MainStack.Screen
            name="CategoryServiceDetail"
            initialParams={{ services: null }}
            component={CategoryServiceDetail}
            options={({ route }) => ({
              title: (
                <TemplateHeaderTitle
                  title={translateText(route.params.category?.name)}
                />
              ),
              headerBackground: () => <TemplateHeaderGradient />,
              ...twoRightIcons,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <UserButton />
                  <ProductsCounter />
                </View>
              ),
            })}
          />
          <MainStack.Screen
            name="ComplementaryProducts"
            component={ComplementaryProducts}
            options={{
              title: (
                <TemplateHeaderTitle title={t('route.complementaryProducts')} />
              ),
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="SignInPage"
            component={SignInPage}
            initialParams={{ callback: null }}
            options={{
              title: <TemplateHeaderTitle title={t('route.signInPage')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="SignUpPage"
            component={SignUpPage}
            initialParams={{ callback: null }}
            options={{
              title: <TemplateHeaderTitle title={t('route.signUpPage')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="ForgotPasswordPage"
            component={ForgotPassword}
            initialParams={{ callback: null }}
            options={{
              title: <TemplateHeaderTitle title={t('auth.forgotPassword')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="ResetPasswordPage"
            component={ResetPassword}
            initialParams={{ callback: null }}
            options={{
              title: <TemplateHeaderTitle title={t('auth.resetPassword')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
          <MainStack.Screen
            name="Basket"
            component={Basket}
            options={{
              ...twoRightIcons,
              title: <TemplateHeaderTitle title={t('route.basket')} />,
              headerBackground: () => <TemplateHeaderGradient />,
              headerLeft: () => (
                <HeaderBackToSite
                  alsoOnClick={() => {
                    currentSite?.pleaseMyCar && setItemsBasket([]);
                  }}
                />
              ),
              headerRight: () => (
                <View style={styles.headerRight}>
                  <UserButton />
                  <ProductsCounter />
                </View>
              ),
            }}
          />
          {/* <MainStack.Screen
            name="Chat"
            component={Chat}
            options={({ route }) => ({
              headerTransparent: true,
              ...darkGradientHeader,
              title: route.params.user.username,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <BlockUserButton />
                </View>
              ),
            })}
          /> */}
          <MainStack.Screen
            name="Cgu"
            component={CGU}
            options={{
              title: <TemplateHeaderTitle title={t('route.cgu')} />,
              headerBackground: () => <TemplateHeaderGradient />,
            }}
          />
        </MainStack.Navigator>
      </NavigationContainer>
      {/* translucent={true} bug on android ca fait un decalage vers le bas */}
      <DropdownAlert ref={(ref) => setDropDownAlertRef(ref)} />
    </>
  );
};
