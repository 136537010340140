import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import useAuth from 'auth/contexts/auth';
import useBasket from 'basket/contexts/basket';
import Button from 'common/components/Button/Button';
import ConfirmButton from 'common/components/ConfirmButton/ConfirmButton';
import FormInput from 'common/components/FormInput/FormInput';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import React, {
  useCallback, useState,
} from 'react';
import { Form } from 'react-final-form';
import { StyleSheet, View } from 'react-native';
import theme from 'styles';

const Profile = () => {
  const { t, lang } = useTranslation();
  const navigation = useNavigation();
  const { user, updateUser, signout } = useAuth();
  const { setItems: setBasket } = useBasket();
  const { setAlert } = useAlert();
  const [shouldUpdatePassword, toggleUpdatePassword] = useState(false);

  const logout = () => {
    signout();
    setBasket([]);
    navigation.reset({
      index: 0,
      routes: [{ name: 'home' }],
    });
  };

  const onSubmit = useCallback(async ({
    name,
    email,
    phone,
    password,
  }) => {
    try {
      const userProperties = {
        name, email, language: lang, phone,
      };

      if (password) {
        await updateUser({ ...userProperties, password });
      } else {
        await updateUser(userProperties);
      }

      setAlert({ color: 'success', title: t('common.success'), message: t('auth.userUpdated') });
      toggleUpdatePassword(false);
    } catch {
      setAlert({ color: 'error', title: t('common.error'), message: t('auth.badCredentials') });
    }
  }, [t, setAlert, lang, updateUser]);

  const goCreditCard = () => navigation.navigate('CreditCardForm');
  const handlePassword = () => toggleUpdatePassword((val) => !val);

  const validate = useCallback((values) => {
    const errors = {};

    if (!shouldUpdatePassword) {
      return errors;
    }

    if (!values.password) {
      errors.password = t('errors.required');
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = t('errors.required');
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = t('errors.mustMatch');
    }

    return errors;
  }, [t, shouldUpdatePassword]);

  const isPro = ['waiter', 'barman', 'manager'].includes(user?.role.type);

  return (
    <Page scroll padding style={{ marginBottom: 0 }}>

      {!isPro && (
      <View style={{ ...styles.buttons }}>
        <Button
          style={[styles.button]}
          text={t('orders.myOrders')}
          onPress={() => navigation.navigate('orders')}
        />
      </View>
      )}
      <View>

        <Text
          size="large"
          isBold
          style={{ marginVertical: theme.sizings.medium, paddingHorizontal: theme.sizings.small }}
        >
          {t('profilePage.informations')}
        </Text>

        <Form
          onSubmit={onSubmit}
          initialValues={user?.id ? { ...user } : {}}
          validate={validate}
          render={({
            handleSubmit, submitting, pristine,
          }) => (
            <View style={styles.formView}>
              <View style={styles.inputs}>
                <FormInput
                  name="name"
                  label={t('auth.name')}
                  icon="user"
                />
                <FormInput
                  type="email"
                  name="email"
                  label={t('auth.email')}
                  icon="envelope"
                />
                <FormInput
                  type="phone-pad"
                  name="phone"
                  label={t('auth.phone')}
                  icon="phone"
                />
              </View>
              <View>
                <Button
                  onPress={handlePassword}
                  icon="pencil"
                  variant="primary"
                  iconSize="default"
                  text={shouldUpdatePassword ? t('auth.cancelPasswordEdit') : t('auth.updatePassword')}
                  style={[styles.button, { marginBottom: 0 }]}
                />
              </View>
              <View style={styles.inputs}>
                {shouldUpdatePassword && (
                  <>
                    <FormInput
                      placeholder={t('auth.newPassword')}
                      type="password"
                      name="password"
                      label={t('auth.newPassword')}
                      icon="lock"
                    />
                    <FormInput
                      placeholder={t('auth.confirmNewPassword')}
                      type="password"
                      name="confirmPassword"
                      label={t('auth.confirmNewPassword')}
                      icon="lock"
                    />
                  </>
                )}
              </View>
              <View style={styles.saveButton}>
                <Button
                  icon="user"
                  text={t('common.save')}
                  variant="primary"
                  onPress={handleSubmit}
                  disabled={submitting || pristine}
                />
              </View>

              {!isPro && (
              <View style={styles.creditCardContainer}>
                <View style={styles.creditCardView}>
                  <FontAwesome name="credit-card" color={theme.colors.primary} size={60} />
                  <View style={styles.creditCardInfo}>
                    <Text>{t('profilePage.cardNumber')}</Text>
                    <Text size="larger" color="primary">{user?.cardAlias || t('profilePage.noAlias')}</Text>
                  </View>
                </View>
                <View style={styles.activeBookingButtonContainer}>
                  <Button
                    onPress={goCreditCard}
                    noPadding
                    icon="pencil"
                    variant="gradient"
                    iconSize="default"
                    style={[styles.activeBookingButton]}
                  />
                </View>
              </View>
              )}
            </View>
          )}
        />
        <View style={styles.bottomActions}>
          <View style={{ ...styles.buttons, height: 160 }}>
            <ConfirmButton
              confirmText={t('auth.confirmLogout')}
              isFullwidth
              style={[styles.button]}
              text={t('auth.logout')}
              onPress={() => logout()}
              variant="primary"

            />

            <Button
              isFullwidth
              style={[styles.button]}
              text={t('cgu.cgu')}
              onPress={() => navigation.navigate({
                name: 'Cgu',
              })}
            />

            <Button
              isFullwidth
              style={[styles.button]}
              variant="danger"
              text={t('auth.deleteMyAccount')}
              onPress={() => navigation.navigate({
                name: 'DeleteAccount',
              })}
            />
          </View>
        </View>
      </View>
    </Page>
  );
};

const styles = StyleSheet.create({
  activeBooking: {
    marginTop: theme.sizings.small,
    marginBottom: theme.sizings.medium,
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.medium,
    backgroundColor: theme.colors.greyLightest,
    borderRadius: theme.radius.small,
    flexDirection: 'row',
    alignItems: 'center',
  },

  activeBookingMeta: {
    flexDirection: 'row',
  },
  activeBookingCode: {
    paddingRight: theme.sizings.medium,
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  activeBookingContent: {
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.greyLighter,
    paddingLeft: theme.sizings.medium,
  },
  activeBookingDate: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  activeBookingButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  activeBookingButton: {
    paddingHorizontal: 10,
    paddingVertical: theme.sizings.tiny,
  },

  saveButton: {
    marginTop: theme.sizings.small,
  },

  buttons: {
    marginTop: theme.sizings.large,
    marginHorizontal: 'auto',
    marginBottom: theme.sizings.large,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  button: {
    marginBottom: theme.sizings.small,
  },

  bottomActions: {
    marginBottom: theme.sizings.large,
  },

  formView: {
    alignItems: 'center',
    width: '100%',
  },

  inputs: {
    width: '100%',
  },

  creditCardContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.sizings.large,
    marginBottom: theme.sizings.large,
    paddingBottom: theme.sizings.medium,
    paddingHorizontal: theme.sizings.small,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.greyDarker,
  },

  creditCardView: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  creditCardInfo: {
    marginLeft: theme.sizings.small,
    justifyContent: 'center',
  },
});

export default Profile;
