import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity } from 'react-native';
import useTranslation from 'common/contexts/translations';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import Button from 'common/components/Button/Button';
import Text from 'common/components/Text/Text';
import Page from 'common/components/Page/Page';
import { Image } from 'common/components/Images';
import formatPrice from 'common/utils/formatPrice';
import Price from 'common/components/Price/Price';
import usePayment from 'payments/contexts/payments';
import PaymentIcon from 'assets/payment.png';

import theme from 'styles';

import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import useSite from 'sites/contexts/sites';
import styles from './Payment.styles';

const Payment = ({ route }) => {
  const { t } = useTranslation();
  const { payment, callback = () => {}, onlineSelected = false } = route.params;
  const { isPayFetching, setItem: setPayment } = usePayment();
  const navigation = useNavigation();
  const { item: site } = useSite();

  const handlePay = useCallback(async () => {
    // pay(payment.id).then(
    //   (response) => {
    //     // success
    //     let message = '';

    //     if (response.response !== 'OK') {
    //       message = `[${response.ResultCode}] : ${response.ResultMessage}`;

    //       console.log(`Payment error [${message}`);
    //     }
    //     callback(response.response === 'OK', message);
    //   },
    //   (response) => {
    //     // fail
    //     console.log('Payment error :', response);
    //     callback(false, response.ResultMessage);
    //   },
    // );
    // }, [pay, payment.id, callback]);
    navigation.navigate('Card', {
      callback,
      payment,
    });
  }, [callback, navigation, payment]);

  // const goCreditCardForm = () => payment.site.isDemo ? () => {} : navigation.navigate('CreditCardForm');

  const iWantToPayLater = useCallback(async () => {
    await setPayment((currentPayment) => ({
      ...currentPayment,
      online: false,
    }));
    callback(true);
  }, [callback, setPayment]);

  return (
    <Page backgroundImage={site?.template.imageBackdrop}>
      <View style={{ padding: theme.sizings.medium }}>
        <Image style={styles.image} image={PaymentIcon} />
        <View style={styles.price}>
          <Text
            centered
            size="large"
            isBold
            color="dark"
            customColor={site?.template?.backdropTextColor}
          >
            {t('bookings.amountToPay')}
          </Text>
          <Price
            price={
              payment.amount > 0
                ? payment.amount + payment.transactionFees
                : payment.amount
            }
            centered
            color="dark"
            customColor={site?.template?.backdropTextColor}
            size="huge"
          />
        </View>
        <View>
          <Text
            size="large"
            isBold
            centered
            color="dark"
            customColor={site?.template?.backdropTextColor}
          >
            {t('bookings.paymentMean')}
          </Text>
        </View>
        {/* <TouchableOpacity onPress={goCreditCardForm}>
          <View style={[styles.creditCardContainer, commonStyles.shadowBottom]}>
            <View style={styles.creditCardView}>
              <FontAwesome name="credit-card" color={theme.colors.primary} size={60} />
              <View style={styles.creditCardInfo}>
                <Text>{t('profilePage.cardNumber')}</Text>
                {!payment.site.isDemo && <Text size="larger" color="primary">{user?.cardAlias}</Text>}
                {payment.site.isDemo && <Text size="larger" color="primary">{t('beach.demo')}</Text>}
              </View>
            </View>
            <View style={styles.activeBookingButtonContainer}>
              <Button
                onPress={goCreditCardForm}
                disabled={payment.site.isDemo}
                noPadding
                icon="pencil"
                variant="gradient"
                iconSize="default"
                style={[styles.activeBookingButton]}
              />
            </View>
          </View>
        </TouchableOpacity> */}
        <View style={{ marginTop: 12 }}>
          {site?.canPayOffline && !onlineSelected && (
            <Button
              size="small"
              text={t(
                site?.pleaseMyCar
                  ? site?.pleaseMyCarDriver ? 'orders.toPayAtReceptionDriver' : 'orders.toPayAtReceptionValet'
                  : 'orders.toPayAtReception',
              )}
              icon="money"
              variant="light"
              styles={{ width: '80%' }}
              onPress={() => iWantToPayLater()}
            />
          )}
        </View>
      </View>
      <FooterButtonWrapper>
        <Button
          data-cy="cypress_pay"
          variant="light"
          icon="credit-card"
          text={t('basket.pay', {
            total: formatPrice(
              payment.amount > 0
                ? payment.amount + payment.transactionFees
                : payment.amount,
              site.currency,
            ),
          })}
          onPress={handlePay}
          disabled={isPayFetching}
        />
      </FooterButtonWrapper>
    </Page>
  );
};

Payment.propTypes = {
  route: PropTypes.object.isRequired,
};

export default Payment;
