import { StyleSheet } from 'react-native';
import theme from 'styles';

export default StyleSheet.create({

  seatRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.sizings.large,
    width: '75%',
  },
  seatDescription: {
    flex: 1,
    marginLeft: theme.sizings.medium,
    flexDirection: 'column',
    alignItems: 'flex-start',
    minWidth: 100,
  },
  price: {
    fontSize: theme.fontSizes.large,
    paddingBottom: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
    borderBottomColor: theme.colors.greyLighter,
    borderBottomWidth: 1,
  },
});
