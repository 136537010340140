import BasketItem from 'basket/components/BasketItem/BasketItem';
import useSite from 'sites/contexts/sites';
import useBasket from 'basket/contexts/basket';
import Button from 'common/components/Button/Button';
import Page from 'common/components/Page/Page';
import Price from 'common/components/Price/Price';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import useOffer from 'specialOffers/hooks/use-offer';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import { useNavigation, useRoute } from '@react-navigation/native';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import useOrder from 'orders/contexts/orders';
import getNextpage from 'common/utils/navigation/CleverNavigation';
import formatPrice from 'common/utils/formatPrice';
import { NativeSwitch as Switch } from 'common/components/Switch/Switch';
import FormInput from 'common/components/FormInput/FormInput';

const styles = StyleSheet.create({
  totalWrapper: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.greyLighter,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.sizings.medium,
    padding: theme.sizings.medium,
  },

  totalLabel: {
    flex: 1,
  },

  items: {
    marginTop: theme.sizings.small,
  },

  basketContainer: {
    flex: 1,
    paddingTop: theme.sizings.large,
    backgroundColor: theme.colors.white,
  },

  basketInner: {
    paddingHorizontal: theme.sizings.small,
  },
  optionsTitle: {
    paddingTop: theme.sizings.large,
  },
  optionsTitleText: {
    justifyContent: 'center',
  },
  options: {
    marginTop: theme.sizings.small,
  },
  bottomButton: {
    marginTop: theme.sizings.small,
  },
  footerErrorMessage: {
    marginTop: theme.sizings.small,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
});

const Basket = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { item: site } = useSite();
  const { items: basket, isAllInclusiveBasket } = useBasket();
  const { setItem: setOrder } = useOrder();
  const [tip, setTip] = useState(site?.showBasketTip ? 5 : 0);

  const { t } = useTranslation();
  const { findProductOffer, findServiceOffer } = useOffer();

  const activeAllInclusive = isAllInclusiveBasket && site?.all_inclusive;

  const [footerMessage, setFooterMessage] = useState(null);

  const getItemPrice = (reduction, item) => {
    if (activeAllInclusive && item.all_inclusive) {
      return 0;
    }
    if (reduction) {
      return Math.round(
        (((100 - reduction.discount_rate) / 100) * item.price * 100) / 100,
      );
    }
    return item.price;
  };

  useEffect(() => {
    if (site && site.canPayOnline) {
      if (site.onlineMaxPrice && total > site?.onlineMaxPrice) {
        setFooterMessage(
          <>
            <Text
              customColor={
                site?.template?.secondaryTextColor || theme.colors.light
              }
              size="large"
            >
              {t('basket.errorMaxPrice')}
              {' '}
            </Text>
            <Text
              isBold
              customColor={
                site?.template?.secondaryTextColor || theme.colors.light
              }
              size="large"
            >
              {formatPrice(site?.onlineMaxPrice, site.currency)}
            </Text>
          </>,
        );
      }
      if (site.onlineMinPrice && total < site?.onlineMinPrice) {
        setFooterMessage(
          <>
            <Text
              customColor={
                site?.template?.secondaryTextColor || theme.colors.light
              }
              size="large"
            >
              {t('basket.errorMinPrice')}
              {' '}
            </Text>
            <Text
              isBold
              customColor={
                site?.template?.secondaryTextColor || theme.colors.light
              }
              size="large"
            >
              {formatPrice(site?.onlineMinPrice, site.currency)}
            </Text>
          </>,
        );
      }
    }
  }, [site, total, handleDisableValidate, t]);

  const consolidateAndContinue = useCallback(
    async (payAfter) => {
      const amount = Math.round(total * 100) / 100;

      const order = {
        order_items: basket.map((orderItem) => {
          const offer = orderItem.product
            ? findProductOffer(orderItem.product)
            : findServiceOffer(orderItem.service);

          return {
            ...orderItem,
            price: offer
              ? ((100 - offer.discount_rate) / 100) * orderItem.price
              : orderItem.price,
            reduction: offer ? offer.discount_rate : null,
            all_inclusive: activeAllInclusive && orderItem.all_inclusive,
          };
        }),
        price: amount,
        tip: tip || 0,
        site: site.id,
        active: true,
        all_inclusive: activeAllInclusive,
        payAfter,
        orderStartTime: basket[0].orderStartTime,
      };

      const { nextPage, newOrder } = await getNextpage({
        currentScreenName: route.name,
        currentOrder: order,
        currentSite: site,
      });

      await setOrder(newOrder);

      navigation.navigate(nextPage);
    },
    [
      basket,
      findProductOffer,
      findServiceOffer,
      navigation,
      route.name,
      setOrder,
      site,
      total,
      activeAllInclusive,
      tip,
    ],
  );

  let total = 0;

  const products = basket.filter((item) => item.product);
  const services = basket.filter((item) => item.service);

  const handleDisableValidate = useCallback(
    (total) => {
      if (site && site.showBasketAmount) {
        if (site?.onlineMaxPrice && total > site?.onlineMaxPrice) {
          return true;
        }
        if (site?.onlineMinPrice) {
          return total < site?.onlineMinPrice;
        }
      } else {
        return false;
      }
    },
    [site],
  );

  const notBothPaymentMethod = !site?.canPayOffline || !site?.canPayOnline;

  return (
    <Page backgroundImage={site?.template.imageBackdrop}>
      <ScrollView>
        <View style={{ padding: theme.sizings.medium }}>
          {basket && basket.length > 0 ? (
            <View>
              {products && products.length > 0 && (
                <>
                  <Text
                    size="large"
                    customColor={site?.template?.backdropTextColor || theme.colors.dark}
                    isBold
                    isTitleText
                  >
                    {t(
                      site?.pleaseMyCar
                        ? site?.pleaseMyCarDriver ? 'common.productsDriver' : 'common.productsValet'
                        : 'common.products',
                    )}
                  </Text>
                  <View style={styles.items}>
                    {products.map((productItem, index) => {
                      const reduction = findProductOffer(productItem.product);

                      const itemPrice = getItemPrice(reduction, productItem);

                      total += itemPrice * productItem.quantity;

                      return (
                        <BasketItem
                          key={index}
                          price={productItem.price}
                          entity={productItem.product}
                          entityType="product"
                          number={productItem.quantity}
                          reduction={reduction}
                          selectedOptions={productItem.selected_options}
                          editable={
                            !site?.pleaseMyCar
                            || (site?.pleaseMyCar && productItem.quantity > 1)
                          }
                          isFree={
                            activeAllInclusive && productItem.all_inclusive
                          }
                        />
                      );
                    })}
                  </View>
                </>
              )}
              {services && services.length > 0 && (
                <>
                  <Text size="large" color="dark" isBold isTitleText>
                    {t('basket.services')}
                  </Text>
                  <View style={styles.items}>
                    {services.map((serviceItem, index) => {
                      const reduction = findServiceOffer(serviceItem.service);

                      const itemPrice = getItemPrice(reduction, serviceItem);

                      total += itemPrice * serviceItem.quantity;
                      return (
                        <BasketItem
                          key={index}
                          price={serviceItem.price}
                          entity={serviceItem.service}
                          entityType="service"
                          number={serviceItem.quantity}
                          reduction={reduction}
                          selectedOptions={serviceItem.selected_options}
                          editable={
                            !site?.pleaseMyCar
                            || (site?.pleaseMyCar && serviceItem.quantity > 1)
                          }
                        />
                      );
                    })}
                  </View>
                </>
              )}

              {site?.showBasketTip && (
                <View style={{ paddingVertical: theme.sizings.mediumLarge }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: theme.sizings.medium,
                    }}
                  >
                    <Switch
                      activeColor={theme.colors.secondary}
                      onChange={() => {
                        if (tip === undefined) {
                          setTip(5);
                        } else {
                          setTip(undefined);
                        }
                      }}
                      value={tip !== undefined}
                    />
                    <Text
                      style={{ marginLeft: theme.sizings.smallMedium }}
                      isBold
                      size="medium"
                      customColor={site?.template?.backdropTextColor || theme.colors.dark}

                    >
                      {t('basket.tip')}
                    </Text>
                  </View>

                  {tip !== undefined && (
                    <FormInput
                      type="number"
                      label={t('basket.tipAmountInCurrency', {
                        currency: site?.currency,
                      })}
                      onChange={setTip}
                      value={tip}
                      required
                      finalForm={false}
                      inline
                      containerStyle={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      inputContainerStyle={{ maxWidth: 80 }}
                      labelStyle={{ flex: 1 }}
                    />
                  )}
                </View>
              )}

              {(site?.showBasketAmount || tip > 0) && (
                <View style={styles.totalWrapper}>
                  <View style={styles.totalLabel}>
                    <Text size="large" customColor={site?.template?.backdropTextColor || theme.colors.dark}>
                      {t('basket.total')}

                    </Text>
                  </View>
                  <Price
                    price={total + (Number(tip) || 0)}
                    style={{ color: site?.template?.backdropTextColor || theme.colors.dark }}
                    size="huge"
                  />
                </View>
              )}
            </View>
          ) : (
            <View>
              {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
              <Text
                centered
                size="huge"
                style={{ marginTop: theme.sizings.medium }}
              >
                😅
              </Text>
              <Text
                size="large"
                style={{
                  textAlign: 'center',
                  marginTop: theme.sizings.medium,
                  paddingHorizontal: theme.sizings.large,
                }}
                customColor={site?.template?.backdropTextColor}
              >
                {t('basket.empty')}
              </Text>
            </View>
          )}
          <View style={commonStyles.scrollViewWithButton} />
        </View>

        <FooterButtonWrapper customHeight={site?.canPayOffline ? 125 : null}>

          {site?.showBasketAmount && total + (Number(tip) || 0) > 0 ? (
            <>
              {site?.canPayOnline && (
              <Button
                text={t(notBothPaymentMethod ? 'orders.goNextStep'
                  : site?.pleaseMyCarDriver ? 'basket.validateDriver'
                    : 'basket.validateValet')}
                icon="credit-card"
                variant="light"
                disabled={handleDisableValidate(total + (Number(tip) || 0))}
                onPress={() => consolidateAndContinue(false)}
              />
              )}

              {site?.canPayOffline && (
              <Button
                text={t(notBothPaymentMethod
                  ? 'orders.goNextStep'
                  : site?.pleaseMyCarDriver ? 'basket.payOfflineDriver'
                    : 'basket.payOfflineValet')}
                icon="money"
                variant="light"
                style={[styles.bottomButton]}
                onPress={() => consolidateAndContinue(true)}
              />
              )}
            </>
          ) : (
            <Button
              text={t('basket.freeValidBasket')}
              icon="check"
              variant="light"
              style={[styles.bottomButton]}
              onPress={() => consolidateAndContinue(true)}
            />
          )}

          {footerMessage !== null && total + (Number(tip) || 0) > 0
          && handleDisableValidate(total + (Number(tip) || 0)) && (
            <View style={styles.footerErrorMessage}>{footerMessage}</View>
          )}
        </FooterButtonWrapper>
      </ScrollView>
    </Page>
  );
};

Basket.propTypes = {};

Basket.defaultProps = {};

export default Basket;
