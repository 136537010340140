import { useCallback } from 'react';

import useSpecialOffers from 'specialOffers/contexts/specialOffers';

const applyReduction = (price, reduction) => {
  if (reduction) {
    return (((100 - reduction.discount_rate) / 100) * price);
  }

  return price;
};

const useOffer = () => {
  const {
    items: specialOffers,
  } = useSpecialOffers();

  const findProductOffer = useCallback((product) => {
    if (specialOffers.findIndex((offer) => offer.products.findIndex((p) => p.id === product.id) >= 0) >= 0) {
      return specialOffers.find((offer) => offer.products.findIndex((p) => p.id === product.id) >= 0);
    }
    return specialOffers.find((offer) => offer.categories.findIndex((c) => c.id === product.category) >= 0);
  },
  [specialOffers]);

  const findServiceOffer = useCallback((service) => {
    if (specialOffers.findIndex((offer) => offer.services.findIndex((p) => p.id === service.id) >= 0) >= 0) {
      return specialOffers.find((offer) => offer.services.findIndex((p) => p.id === service.id) >= 0);
    }
    return specialOffers.find((offer) => (
      offer.category_services.findIndex((c) => c.id === service.category_service) >= 0));
  },
  [specialOffers]);

  return {
    applyReduction,
    findProductOffer,
    findServiceOffer,
  };
};

export default useOffer;
