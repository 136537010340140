import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

import Text from 'common/components/Text/Text';
import Gradient from '../Gradient/Gradient';

const SeatName = ({
  size,
  style,
  viewStyle,
  withGradient,
  name,
  colors,
  ...props
}) => {
  const defaultStyle = {
    flexDirection: 'row',
    alignItems: 'baseline',
  };

  const defaultTextStyle = {
    fontFamily: 'MontserratBold',
  };

  return (
    <View style={[defaultStyle, viewStyle]}>
      {withGradient && <Gradient colors={colors} height={30} />}
      <Text
        noLineHeight
        style={[defaultTextStyle, style]}
        size={size}
        {...props}
      >
        {name}
      </Text>
    </View>
  );
};

SeatName.propTypes = {
  size: PropTypes.string,
  withGradient: PropTypes.bool,
  colors: PropTypes.array,
  style: PropTypes.any,
  viewStyle: PropTypes.any,
  name: PropTypes.string,
};

SeatName.defaultProps = {
  name: '',
  size: 'default',
  withGradient: false,
  colors: [],
  style: null,
  viewStyle: null,
};

export default SeatName;
