import { FontAwesome } from '@expo/vector-icons';
import MissingImage from 'assets/background_missing_image.jpg';
import Button from 'common/components/Button/Button';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useTranslation from 'common/contexts/translations';
import createUrl from 'common/utils/createUrl';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import {
  Dimensions,
  Linking, Platform, StyleSheet,
  TouchableHighlight,
  TouchableOpacity, View,
} from 'react-native';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';

const styles = StyleSheet.create({
  detailsContainer2: {
    marginHorizontal: theme.radius.medium,
    marginBottom: theme.radius.medium,
    flex: 1,
    borderRadius: theme.radius.medium,
    padding: theme.sizings.smallMedium,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    overflow: 'visible',
  },
  detailsContainer: {
    paddingTop: theme.radius.large,
  },

  main: {
    paddingTop: theme.sizings.medium,
  },

  description: {
    marginBottom: theme.sizings.small,
  },

  address: {
    marginBottom: theme.sizings.mediumLarge,
  },

  topSpacer: {
    /* 160 = La taille en PX de la partie que l'on veut afficher du site détails */
    height: Dimensions.get('window').height - 160,
    width: Dimensions.get('window').width,
  },

  textIcon: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: theme.sizings.small,
  },

  toggleIcon: {
    marginLeft: theme.sizings.small,
  },
});

const AroundDetail = ({ route }) => {
  const { t } = useTranslation();
  const { location } = route.params;

  const openMap = useCallback(() => {
    const { lat, lng } = location.lat_lon;
    const latLng = `${lat},${lng}`;
    const label = <TranslatedText value={location.name} />;
    const url = Platform.select({
      ios: `maps:0,0?q=${label}@${latLng}`,
      android: `geo:0,0?q=${latLng}(${label})`,
      default: `https://maps.google.com/maps?daddr=${latLng}`,
    });

    Linking.openURL(url);
  }, [location]);

  const toggleOpen = () => {
    ref.current.scrollToEnd({ animated: true });
  };

  const ref = React.useRef(null);

  return (
    <Page
      scroll
      ref={ref}
      style={{ marginBottom: 0 }}
      backgroundSource={{ uri: location.images[0] ? createUrl(location.images[0].url) : MissingImage }}
    >
      <TouchableOpacity
        onPress={toggleOpen}
      >
        <View style={styles.topSpacer} />
      </TouchableOpacity>
      <View style={styles.detailsContainer}>
        {location.phone
          && (
          <View style={commonStyles.fullViewButtonContainer}>
            <Button
              text={location.phone}
              variant="gradient"
              icon="phone"
              onPress={() => Linking.openURL(`tel:${location.phone}`)}
            />
          </View>
          )}
        <View style={styles.detailsContainer2}>
          <View style={styles.main}>
            <TranslatedText color="light" style={styles.description} value={location.description} />
            <View style={styles.textIcon}>
              <FontAwesome
                name="angle-down"
                color={theme.colors.light}
                size={theme.fontSizes.large}
              />
              <Text
                style={styles.toggleIcon}
                color="light"
                onPress={toggleOpen}
              >
                {t('common.details')}
              </Text>
            </View>
            <View>
              <View style={styles.textIcon}>
                <View style={commonStyles.fullViewDetailsIcon}>
                  <FontAwesome
                    name="info"
                    size={theme.fontSizes.small}
                    color={theme.colors.secondaryLight}
                  />
                </View>
                <Text color="light" size="large" style={styles.toggleIcon}>
                  {t('beach.coord')}
                </Text>
              </View>
              <TouchableHighlight onPress={openMap}>
                <View>
                  <Text color="light" style={styles.address}>{location.address}</Text>
                </View>
              </TouchableHighlight>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

AroundDetail.propTypes = {
  route: PropTypes.object.isRequired,
};

export default AroundDetail;
