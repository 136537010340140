import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  View, TouchableOpacity, Image,
} from 'react-native';
import Scanner from 'assets/scanner.png';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';

import Text from 'common/components/Text/Text';
import useBasket from 'basket/contexts/basket';
import LanguagePicker from 'common/components/LanguagePicker/LanguagePicker';
import theme from 'styles';
import useAuth from 'auth/contexts/auth';
import useTranslation from 'common/contexts/translations';
import commonStyles from 'styles/commonStyles';

import styles from './HomePage.styles';

const HomePage = () => {
  const { t, setLang } = useTranslation();
  const navigation = useNavigation();
  const { setItems: setBasket } = useBasket();
  const { user, updateUser } = useAuth();

  const handleLanguageChange = useCallback((language) => {
    user && updateUser({ language });
  }, [user, updateUser]);

  // TODO C'est un quick fix relatif à un bug de usePersisted state
  // qui force à recharger la page 2 fois pour avoir la bonne valeur initiale
  // du basket à savoir []
  useEffect(() => {
    setBasket([]);
  }, [setBasket]);

  useEffect(() => {
    user?.language && setLang(user.language);
  }, [user, setLang]);

  const links = useMemo(() => ([
    /* {
      title: lock ? t('beach.beach') : t('homePage.beaches'),
      action: async () => {
        if (lock) {
          const site = await fetchSiteData(lock);

          setItem(site);
          navigation.navigate('SitePresentation', { siteId: lock });
        } else {
          navigation.navigate({ name: 'SitesList' });
        }
      },
    }, */
    {
      title: t('homePage.account'),
      action: () => {
        if (user) {
          navigation.navigate('ProfilePage');
        } else {
          navigation.navigate('SignInPage', {
            callback: (u) => {
              ScreenOrientation.lockAsync(
                ScreenOrientation.OrientationLock[(u && ['waiter', 'barman'].includes(u.role?.type))
                  ? 'DEFAULT'
                  : 'PORTRAIT_UP'],
              );
              const nextPage = ['waiter', 'barman'].includes(u.role.type) ? 'WaiterHome' : 'ProfilePage';

              navigation.navigate(nextPage);
            },
          });
        }
      },
    },
    // { title: t('beach.iAmHere'), action: () => navigation.navigate({ name: 'QuickSitesList' }) },
  ]), [t, navigation, user]);

  return (
    <View style={[styles.mainView]}>

      {/*
      <ImageBackground source={Leaves} style={styles.bottomImage} />
      <ImageBackground source={Beach} style={styles.topImage} /><Image source={Logo} style={styles.logo} />
      */}
      <View style={styles.buttons}>
        <View style={styles.qrcode}>
          <Image style={styles.image} source={Scanner} />
          <View style={styles.scanQR}><Text centered>{t('beach.scanQRCode')}</Text></View>
        </View>

        <View>
          {links.map((l, index) => (
            <TouchableOpacity
              key={`link-${index}`}
              style={[styles.button, commonStyles.shadowBottom]}
              onPress={l.action}
            >
              <Text isBold color="dark" size="large">{l.title}</Text>
              <View style={styles.icon}>
                <LinearGradient
                  colors={[theme.colors.secondary, theme.colors.secondaryDark]}
                  start={[0, 0.5]}
                  end={[1, 0.5]}
                  style={{
                    zIndex: -10,
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    height: 30,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesome name="arrow-right" color={theme.colors.light} size={theme.fontSizes.medium} />
                </LinearGradient>
              </View>
            </TouchableOpacity>
          ))}
        </View>
        <LanguagePicker onChange={handleLanguageChange} />
      </View>
      <View>
        <Text>version 1.0.5-1</Text>
      </View>
    </View>
  );
};

export default HomePage;
