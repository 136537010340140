import React from 'react';
import useSite from 'sites/contexts/sites';
import { LinearGradient } from 'expo-linear-gradient';
import theme from 'styles';
import PropTypes from 'prop-types';

const TemplateHeaderGradient = ({ waiterTemplate }) => {
  const { item: site } = useSite();

  const startColor = true/* waiterTemplate */ ? theme.colors.darkGrey : (site?.template?.primaryColor || theme.colors.secondary);
  const endColor = true/* waiterTemplate */ ? theme.colors.dark : (site?.template?.primaryShadeColor || theme.colors.secondary);

  // const forcePmcOffline = false;

  return (
    <LinearGradient
      colors={[
        startColor, endColor,
      ]}
      start={[0, 0.5]}
      end={[1, 0.5]}
      style={{
        flex: 1,
      }}
    />
  );
};

export default TemplateHeaderGradient;

TemplateHeaderGradient.propTypes = {
  waiterTemplate: PropTypes.bool,
};

TemplateHeaderGradient.defaultProps = {
  waiterTemplate: false,
};
