import { FontAwesome } from '@expo/vector-icons';
import useBooking from 'bookings/contexts/bookings';
import Button from 'common/components/Button/Button';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Page from 'common/components/Page/Page';
import Price from 'common/components/Price/Price';
import Schedule from 'common/components/Schedule/Schedule';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useTranslation from 'common/contexts/translations';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Linking,
  Platform,
  TouchableOpacity, View,
} from 'react-native';
import useSite from 'sites/contexts/sites';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import styles from './SitePresentation.styles';

const SitePresentation = ({ navigation, route }) => {
  const { t } = useTranslation();
  const {
    item: site, setItem, isDataFetching, fetchSiteData, setCurrentSite,
  } = useSite();
  const { setItem: setBooking } = useBooking();

  useEffect(() => {
    if (site) {
      setCurrentSite(site);
    }
  }, [site, setCurrentSite]);

  const { siteId } = route.params;

  useEffect(() => {
    // async function fetchAndSetData() {
    //   const site = await fetchSiteData(siteId);

    //   setItem(site);
    // }

    // fetchAndSetData();
  }, [fetchSiteData, setItem, siteId]);

  const displaySeasonalOpenings = useMemo(() => {
    if (site?.dateBegin && site?.dateEnd) {
      return (
        <Text color="light">
          {t('beach.openFromTo', {
            fromDate: dayjs(site.dateBegin).format('LL'),
            toDate: dayjs(site.dateEnd).format('LL'),
          })}
        </Text>
      );
    }

    if (site?.dateBegin && !site?.dateEnd) {
      return (
        <Text color="light">
          {t('beach.openFrom', { fromDate: dayjs(site.dateBegin).format('LL') })}
        </Text>
      );
    }
  }, [site, t]);

  const openMap = useCallback(() => {
    const { lat, lng } = site;
    const latLng = `${lat},${lng}`;
    const label = site.name;
    const url = Platform.select({
      ios: `maps:0,0?q=${label}@${latLng}`,
      android: `geo:0,0?q=${latLng}(${label})`,
      default: `https://maps.google.com/maps?daddr=${latLng}`,
    });

    Linking.openURL(url);
  }, [site]);

  const toggleOpen = () => {
    ref.current.scrollToEnd({ animated: true });
  };

  const handleBookPressed = useCallback(async () => {
    setBooking({
      site: siteId,
    });
    if (site.hotel) {
      navigation.navigate('UserTypePage', { siteId });
    } else {
      navigation.navigate('DateSelect', { siteId });
    }
  }, [navigation, setBooking, site, siteId]);

  const ref = React.useRef(null);

  return (
    site && !isDataFetching
      ? (
        <Page safeArea scroll ref={ref} backgroundImage={site.images[0]} style={{ marginBottom: 0, paddingBottom: 0 }}>
          <TouchableOpacity
            onPress={toggleOpen}
          >
            <View style={styles.topSpacer} />
          </TouchableOpacity>
          <View style={styles.detailsContainer}>
            <View style={commonStyles.fullViewButtonContainer}>
              {(site?.canBookOnline && site?.is_open && site.maps.length > 0) && (
              <Button
                disabled={dayjs(site?.dateEnd).isBefore(dayjs())}
                variant="gradient"
                text={t(dayjs(site?.dateEnd).isBefore(dayjs()) ? 'beach.closedSeason' : 'beach.book')}
                onPress={handleBookPressed}
              />
              )}
              {(!site?.canBookOnline || !site?.is_open || site?.maps.length === 0) && (
                <Button
                  disabled
                  variant="gradient"
                  text={t((!site?.canBookOnline || site?.maps.length === 0) ? 'beach.noOnlineBooking' : 'beach.closed')}
                />
              )}
            </View>
            <View style={styles.detailsContainer2}>
              { site.min_price > 0 && (
              <View style={[commonStyles.fullViewDetailsPriceWrapper, styles.priceWrapper]}>
                <Text
                  style={styles.priceLabel}
                  centered
                  isUppercase
                  color="light"
                  size="tiny"
                >
                  {t('beach.priceFrom')}

                </Text>
                <Price
                  price={site.min_price}
                  color="secondaryLight"
                  size="huge"
                />
              </View>
              )}
              <View style={commonStyles.fullViewDetailsContent}>
                <View style={styles.description}>
                  <TranslatedText tagsStyles={{ p: { color: theme.colors.light } }} value={site.description} />
                </View>
                <View style={styles.textIcon}>
                  <FontAwesome
                    name="angle-down"
                    color={theme.colors.light}
                    size={theme.fontSizes.large}
                  />
                  <Text
                    style={styles.toggleIcon}
                    color="light"
                    onPress={toggleOpen}
                  >
                    {t('common.details')}
                  </Text>
                </View>
                <View>
                  {displaySeasonalOpenings}
                  <View style={styles.details}>
                    <View style={styles.timeColumn}>
                      <View style={styles.textIcon}>
                        <View style={commonStyles.fullViewDetailsIcon}>
                          <FontAwesome
                            name="calendar"
                            size={theme.fontSizes.large}
                            color={theme.colors.secondaryLight}
                          />
                        </View>
                        <Text size="large" color="light" style={styles.toggleIcon}>
                          {t('beach.schedule')}
                        </Text>
                      </View>
                      <Schedule schedule={site.schedule} />
                    </View>
                    <View style={styles.addressColumn}>
                      <View style={styles.textIcon}>
                        <View style={commonStyles.fullViewDetailsIcon}>
                          <FontAwesome
                            name="info"
                            size={theme.fontSizes.large}
                            color={theme.colors.secondaryLight}
                          />
                        </View>
                        <Text color="light" size="large" style={styles.toggleIcon}>
                          {t('beach.coord')}
                        </Text>
                      </View>
                      <Text color="light" size="medium" style={styles.address}>{site.address}</Text>
                      <View
                        style={styles.addressButton}
                      >
                        <Button
                          text={t('common.openMap')}
                          variant="gradient"
                          onPress={openMap}
                          disabled={!site.lat || !site.lng}
                        />
                      </View>

                    </View>
                  </View>
                </View>
                {site.phone && site.phone.length && (
                <Button
                  text={site.phone}
                  variant="gradient"
                  onPress={() => Linking.openURL(`tel:${site.phone}`)}
                  style={{ marginTop: theme.sizings.default }}
                />
                )}
              </View>
            </View>
          </View>
        </Page>
      )
      : (
        <LoadingSpinner
          visible={isDataFetching}
        />
      )
  );
};

SitePresentation.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default SitePresentation;
