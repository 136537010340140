import React from 'react';
import { View } from 'react-native';
import useSite from 'sites/contexts/sites';
import theme from 'styles';
import { FontAwesome } from '@expo/vector-icons';
import PropTypes from 'prop-types';

const HeaderBackDefault = ({ onDarkBackground }) => {
  const { item: site } = useSite();
  const template = site?.template;

  return (
    <View style={{ marginLeft: 8 }}>
      <FontAwesome
        color={!onDarkBackground && template?.primaryTextColor ? template.primaryTextColor : theme.colors.light}
        size={theme.fontSizes.larger}
        name="arrow-left"
      />
    </View>
  );
};

export default HeaderBackDefault;

HeaderBackDefault.propTypes = {
  onDarkBackground: PropTypes.bool,
};

HeaderBackDefault.defaultProps = {
  onDarkBackground: false,
};
