import { StyleSheet } from 'react-native';
import theme from 'styles';

export default StyleSheet.create({
  card: {
    backgroundColor: theme.colors.light,
    flex: 1,
    marginBottom: theme.sizings.mediumLarge,
    borderTopLeftRadius: theme.radius.medium,
    borderTopRightRadius: theme.radius.medium,
    borderBottomLeftRadius: theme.radius.medium,
    borderBottomRightRadius: theme.radius.medium,
    overflow: 'hidden',
    shadowColor: '#000000',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    position: 'relative',
    display: 'flex',
  },
  imageWrapper: {
    backgroundColor: theme.colors.white,
    overflow: 'hidden',
    width: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
  },
});
