import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';

import useAlert from 'common/contexts/alert';
import useBookings from 'bookings/contexts/bookings';
import useTranslation from 'common/contexts/translations';

import commonStyles from 'styles/commonStyles';
import theme from 'styles';

const AnableOrdersOnBooking = ({ bookingId }) => {
  const { validBooking, fetchItems: fetchBookings } = useBookings();
  const { setAlert } = useAlert();
  const { t } = useTranslation();

  const handleActivate = useCallback(async () => {
    await validBooking(bookingId);
    await fetchBookings();
    setAlert({ color: 'success', title: t('common.success'), message: t('bookings.validatedBooking') });
  }, [validBooking, fetchBookings, bookingId, setAlert, t]);

  return (
    <TouchableOpacity style={commonStyles.headerIcon} onPress={handleActivate}>
      <FontAwesome
        name="check"
        color={theme.colors.light}
        size={theme.fontSizes.larger}
      />
    </TouchableOpacity>
  );
};

AnableOrdersOnBooking.propTypes = {
  bookingId: PropTypes.number.isRequired,
};

export default AnableOrdersOnBooking;
