import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import Button from 'common/components/Button/Button';
import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';
import Price from 'common/components/Price/Price';
import RelativeDate from 'common/components/RelativeDate/RelativeDate';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useTranslation from 'common/contexts/translations';
import zeroPad from 'common/utils/zeroPad';
import useOrder from 'orders/contexts/orders';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import {
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import styles from 'sites/pages/SiteDetail/styles/OrderSummary.styles';
import theme from 'styles';

const OrderSummary = ({
  order,
}) => {
  const { t } = useTranslation();
  const { /* save: saveOrder, */ setItem: setOrder } = useOrder();
  const { item: site } = useSite();
  const navigation = useNavigation();
  /* const { setAlert } = useAlert(); */
  const {
    state,
    // eslint-disable-next-line camelcase
    order_items,
  } = order;

  const handleState = useCallback((state) => {
    switch (state) {
      case 'at_arrival':
        return t('orders.atArrival');
      case 'created':
        return site?.pleaseMyCarDriver ? t('orders.stateCreatedDriverForClient') : t('orders.stateCreatedValetForClient');
      case 'in_preparation':
        return site?.pleaseMyCarDriver ? t('orders.inPreparationDriver') : t('orders.inPreparationValet');
      case 'ready':
        return site?.pleaseMyCarDriver ? t('orders.readyDriver') : t('orders.readyValet');
      case 'done':
        return site?.pleaseMyCarDriver ? t('orders.stateServedDriver') : t('orders.stateServedValet');
      default:
        return site?.pleaseMyCarDriver ? t('orders.createdDriver') : t('orders.createdValet');
    }
  }, [t, site]);

  /* const reOrderHandle = useCallback(async () => {
    const payload = order;

    delete payload.id;
    delete payload.payment.id;

    payload.payment.status = 'PENDING';
    payload.site = payload.site.id;
    payload.state = 'created';

    try {
      await saveOrder(payload);

      setAlert({ color: 'success', title: t('common.success'), message: t('common.success') });

      const routes = [
        { name: 'home' },
        // { name: 'SiteHome', params: { siteId: site.id } },
      ];

      navigation.reset({
        index: 0,
        routes,
      });
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: t('basket.errorPlacing') });
    }
  }, [navigation, order, saveOrder, setAlert, t]); */

  return (
    <View
      style={styles.viewMaster}
    >
      <View
        style={[
          styles.viewOrderHeader,
          {
            backgroundColor: (state === 'done' || state === 'ready'
              ? theme.orderStateColor.cornflowerbluebackground
              : theme.orderStateColor.goldenrodbackground),
          }]}
      >
        <View style={[styles.viewOrderHeaderSection, { paddingLeft: 7 }]}>
          <View style={{ flex: 0.5 }}>
            <Text
              style={{ fontWeight: 'bold' }}
              size="large"
              color="black"
            >
              {`${t('orders.orderNbr', { orderId: zeroPad(order.displayedId, 3) })}`}
            </Text>
          </View>
          <Text
            isBold
            color="black"
          >
            {`${t('orders.validationCode')} : ${order.code}`}
          </Text>
          <View style={styles.viewTimeCmdContainer}>
            <FontAwesome
              size={theme.fontSizes.small}
              name="clock-o"
              color={theme.colors.dark}
            />
            <Text size="tiny" style={{ marginLeft: 7 }}>
              <RelativeDate value={order.updated_at || order.created_at} />
            </Text>
          </View>
        </View>
        <View style={styles.viewSeparatorContainer}>
          <View style={styles.viewSeparator} />
        </View>
        <View style={[styles.viewOrderHeaderSection, { flexDirection: 'row', justifyContent: 'space-between' }]}>
          <View style={{ flex: 0.9 }}>
            <Text
              isBold
              size="large"
              color="black"
            >
              {`${formatPrice(order.price, site.currency)}`}
            </Text>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <FontAwesome
                name="money"
                size={theme.fontSizes.large}
                color={order.payment?.status === 'PAID' ? 'cornflowerblue' : 'goldenrod'}
                style={{ marginRight: 5 }}
              />
              <Text
                size="large"
                style={{ color: (order.payment?.status === 'PAID' ? 'cornflowerblue' : 'goldenrod') }}
              >
                {order.payment?.status === 'PAID' ? t('orders.paid') : t('orders.notPaid')}
              </Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <FontAwesome
                name="flag-o"
                size={theme.fontSizes.large}
                color={state === 'done' || state === 'ready' ? 'cornflowerblue' : 'goldenrod'}
                style={{ marginRight: 5 }}
              />
              <Text
                size="large"
                style={{ color: (state === 'done' || state === 'ready' ? 'cornflowerblue' : 'goldenrod') }}
              >
                {handleState(state)}
              </Text>
            </View>
          </View>
        </View>
      </View>
      {/* eslint-disable-next-line camelcase */}
      {order_items && (
        <ScrollView style={{ padding: theme.sizings.small }}>
          {order_items?.map((orderItem) => (
            <View key={orderItem.id}>
              <View style={styles.viewDetailContainer}>
                <View
                  style={styles.viewLeftDetailContainer}
                >
                  <TranslatedText
                    color="#e8ad4c"
                    value={orderItem.name}
                  />
                </View>
                <View style={styles.viewRightDetailContainer}>
                  <Price
                    viewStyle={styles.price}
                    style={{ fontSize: theme.sizings.medium }}
                    price={orderItem.all_inclusive ? 0 : orderItem.price * orderItem.quantity}
                    color="dark"
                    customColor={theme.colors.dark}
                  />

                  {!site?.pleaseMyCar && (
                  <View style={[
                    styles.textQuantity,
                    {
                      backgroundColor: (state === 'done' || state === 'ready'
                        ? theme.orderStateColor.cornflowerbluebackground
                        : theme.orderStateColor.goldenrodbackground),
                    }]}
                  >
                    <Text
                      color="dark"
                      isTitleText
                    >
                      x
                      {`${orderItem.quantity}`}
                    </Text>
                  </View>
                  )}

                </View>
              </View>
              <View style={{ marginLeft: theme.sizings.large }}>
                {orderItem.selected_options.map((option) => (
                  <>
                    {option.variants.map((variant) => (
                      <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <TranslatedText value={variant.name} />
                      </View>
                    ))}
                  </>
                ))}
              </View>
            </View>
          ))}

          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              style={[styles.btnReOrder]}
              onPress={() => {
                setOrder(order);
                navigation.navigate('OrderSelectDeliveryTime', { order });
              }}
              text={t('common.edit')}
              variant="gradient"
            />

            <Button
              style={[styles.btnReOrder]}
              onPress={() => {
                setOrder(order);
                navigation.navigate('FinalOrderSummary', { order });
              }}
              text={t('common.seeDetails')}
              variant="gradient"
            />
          </View>
        </ScrollView>
      )}
    </View>
  );
};

OrderSummary.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderSummary;
