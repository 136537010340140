import * as Sentry from 'sentry-expo';

Sentry.init({
  dsn: 'https://d2685cfe147ed48c0d958ae4ccfcc02a@o432100.ingest.sentry.io/4506156671959040',
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 0.5,
  debug: true,
});

export default Sentry;
