import React from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import theme from 'styles';
import Text from 'common/components/Text/Text';
import BasketItem from 'basket/components/BasketItem/BasketItem';
import { useSSR } from 'react-i18next';
import useSite from 'sites/contexts/sites';

const styles = StyleSheet.create({
  basketContainer: {
    paddingHorizontal: theme.sizings.small,
    paddingBottom: theme.sizings.large,
  },

  title: {
    paddingTop: theme.sizings.medium,
    paddingBottom: theme.sizings.small,
  },
});

const OrderRecap = ({ order }) => {
  const { t } = useTranslation();
  const { item: site } = useSite();

  return (
    <View style={styles.basketContainer}>
      <ScrollView>
        {order.order_items?.filter((item) => item.product).length > 0 && (
          <Text isBold size="large" style={styles.title}>
            {t(site?.pleaseMyCar ? site?.pleaseMyCarDriver ? 'common.productsDriver'
              : 'common.productsValet' : 'common.products')}
          </Text>
        )}
        {order.order_items
          .filter((item) => item.product)
          .map((productItem, index) => (
            <BasketItem
              key={index}
              selectedOptions={productItem.selected_options}
              price={productItem.price}
              entity={productItem.product}
              entityType="product"
              number={productItem.quantity}
              editable={false}
            />
          ))}
        {order.order_items?.filter((item) => item.service).length > 0 && (
          <Text isBold size="large" style={styles.title}>
            {t('basket.services')}
          </Text>
        )}
        {order.order_items
          .filter((item) => item.service)
          .map((serviceItem, index) => (
            <BasketItem
              key={index}
              price={serviceItem.price}
              entity={serviceItem.service}
              entityType="service"
              number={serviceItem.quantity}
              selectedOptions={serviceItem.selected_options}
              editable={false}
            />
          ))}
      </ScrollView>
    </View>
  );
};

OrderRecap.propTypes = {
  order: PropTypes.object.isRequired,
};
OrderRecap.defaultProps = {};
export default OrderRecap;
