import {
  Image, ScrollView, StyleSheet, View, SafeAreaView,
} from 'react-native';
import Tongs from 'assets/tongs.png';
import { useAuth } from 'auth/contexts/auth';
import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import PageBackground from 'common/components/PageBackground/PageBackground';
import useSite from 'sites/contexts/sites';

const styles = StyleSheet.create({

  fullFormView: {
    flex: 1,
    // backgroundColor: theme.colors.white,
  },

  header: {
    paddingHorizontal: theme.sizings.medium,
    paddingBottom: theme.sizings.medium,
    alignItems: 'center',
  },

  image: {
    height: 102,
    width: 85,
    marginLeft: -10,
    marginBottom: theme.sizings.medium,
  },

  legende: {
    textAlign: 'center',
    marginBottom: theme.sizings.medium,
    width: '100%',
  },

  formView: {
    // alignItems: 'center',
  },

  scrollView: {
    paddingHorizontal: theme.sizings.large,
    paddingTop: theme.sizings.large,

  },

  innerButton: {
    marginTop: theme.sizings.large,
  },

  noAccount: {
    marginBottom: theme.sizings.medium,
  },

  bottomButton: {
    marginTop: theme.sizings.small,
    backgroundColor: theme.colors.dark,
    color: theme.colors.white,
  },
});

const SignIn = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { callback } = route.params;
  const { setAlert } = useAlert();
  const { item: site } = useSite();

  const auth = useAuth();
  const { isFetching } = auth;

  const forcePmcOffline = true;

  const onSubmit = useCallback(async ({ email, password }) => {
    try {
      const user = await auth.signin(email, password);

      if (['admin', 'superadmin'].includes(user?.role.type)) {
        throw new Error();
      }
      // callback can be passed to the signin function
      // if a callback function exist it will be called instead of redirecting
      // to the home page
      if (callback) {
        if (navigation.canGoBack()) {
          navigation.pop();
        }
        callback(user);
      } else {
        navigation.reset({
          index: 0,
          routes: [{
            name: 'home',
          }],
        });
      }
    } catch {
      setAlert({ color: 'error', title: t('common.error'), message: t('auth.badCredentials') });
    }
  }, [auth, callback, navigation, setAlert, t]);

  const goForgotPassword = async () => {
    navigation.navigate('ForgotPasswordPage', { callback });
  };
  const goSignUp = async () => {
    navigation.pop();
    navigation.navigate('SignUpPage', { callback });
  };

  return (
    <>
      <PageBackground />
      <SafeAreaView style={{ flex: 1 }}>
        <View style={styles.fullFormView}>
          <LoadingSpinner
            visible={isFetching}
          />
          <Form
            onSubmit={onSubmit}
            initialValues={{ email: '', password: '' }}
            render={({ handleSubmit, submitting }) => (
              <>
                <ScrollView style={styles.scrollView}>
                  <View style={styles.header}>
                    {!forcePmcOffline && (
                    <Image style={styles.image} source={Tongs} />
                    )}
                    <Text
                      size="large"
                      style={styles.legende}
                      customColor={site?.template?.backdropTextColor || theme.colors.light}
                    >
                      {t('auth.logInMessage')}
                    </Text>
                  </View>
                  <FormInput
                    data-cy="email"
                    type="email"
                    name="email"
                    label={t('auth.email')}
                    icon="envelope"
                  />
                  <FormInput
                    data-cy="password"
                    type="password"
                    name="password"
                    label={t('auth.password')}
                    icon="lock"
                  />
                  <View style={styles.innerButton}>
                    <Button
                      isFullwidth
                      variant="greyDarkest"
                      text={t('auth.forgotPassword')}
                      onPress={goForgotPassword}
                      type="submit"
                    />
                  </View>
                  <View style={commonStyles.scrollViewWithButton} />
                  <FooterButtonWrapper>
                    <Button
                      variant="light"
                      icon="user"
                      text={t('auth.login')}
                      onPress={handleSubmit}
                      disabled={submitting}
                      type="submit"
                    />

                    {!forcePmcOffline && (
                    <Button
                      data-cy="confirm_signin"
                      isFullwidth
                      variant="primary"
                      text={t('auth.goSignup')}
                      onPress={goSignUp}
                      type="submit"
                      style={[styles.noAccount, styles.bottomButton]}
                    />
                    )}
                  </FooterButtonWrapper>
                  <Text
                    size="medium"
                    centered
                    customColor={theme.colors.black}
                  >
                    version 1.0.5-1
                  </Text>
                </ScrollView>

              </>
            )}
          />
        </View>
      </SafeAreaView>
    </>
  );
};

SignIn.navigationOptions = {
  title: 'Signin',
};

SignIn.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

SignIn.defaultProps = {
};

export default SignIn;
