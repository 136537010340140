import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import Text from 'common/components/Text/Text';
import useSite from 'sites/contexts/sites';
import useTrans from 'common/hooks/use-trans';

const MapHeaderTitle = ({ mapId }) => {
  const { item: site } = useSite();
  const { translateText } = useTrans();
  const map = site?.maps.find((m) => m.id === mapId);

  return (
    <Text adjustsFontSizeToFit style={styles.title} isBold size="larger" color="light">
      {translateText(map?.name)}
    </Text>
  );
};

const styles = StyleSheet.create({
  title: {
    fontFamily: 'MontserratBold',
  },
});

MapHeaderTitle.propTypes = {
  mapId: PropTypes.string.isRequired,
};

export default MapHeaderTitle;
