import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const CategoryContext = createContext();

export const CategoriesProvider = ({ children }) => (
  <ContextProvider url="categories" context={CategoryContext}>{children}</ContextProvider>
);

CategoriesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useCategories = () => useContext(CategoryContext);

export default useCategories;
