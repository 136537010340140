import React from 'react';
import PropTypes from 'prop-types';
import {
  View, ScrollView, Text,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import useTranslation from 'common/contexts/translations';

import Button from 'common/components/Button/Button';
import commonStyles from 'styles/commonStyles';
import Page from 'common/components/Page/Page';
import FormInput from 'common/components/FormInput/FormInput';
import { Form } from 'react-final-form';
import useBooking from 'bookings/contexts/bookings';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';

const UserTypePage = ({ route }) => {
  const navigation = useNavigation();

  const { siteId } = route.params;
  const { t } = useTranslation();
  const { setItem: setBooking } = useBooking();

  const handleSubmit = (values) => {
    setBooking((prevBooking) => ({
      ...prevBooking,
      hotel: values.hotel,
      roomID: values.roomID,
    }));
    navigation.navigate('DateSelect', { siteId });
  };

  return (
    <Page padding>
      <Form
        onSubmit={handleSubmit}
        initialValues={{ hotel: false, roomID: '' }}
        render={({ handleSubmit, values }) => (
          <>
            <ScrollView>
              <FormInput
                name="hotel"
                label={t('common.youAre')}
                type="buttongroup"
                options={[{
                  value: false,
                  text: t('common.externalCustomer'),
                  iconName: 'user',
                }, {
                  value: true,
                  text: t('common.hotelCustomer'),
                  iconName: 'bed',
                }]}
              />
              {values.hotel && (
              <>
                <FormInput type="text" name="roomID" label={t('common.roomID')} required />
                <Text>{t('common.warningHotel')}</Text>
              </>
              )}
              <View style={commonStyles.scrollViewWithButton} />

              <FooterButtonWrapper>
                <Button
                  variant="gradient"
                  icon="arrow-right"
                  text={t('orders.continue')}
                  disabled={values.hotel && values.roomID === ''}
                  onPress={handleSubmit}
                />
              </FooterButtonWrapper>
            </ScrollView>
          </>
        )}
      />
    </Page>
  );
};

UserTypePage.propTypes = {
  route: PropTypes.object.isRequired,
};

export default UserTypePage;
