import './i18n';
import { Text, TouchableOpacity } from 'react-native';
import React, { useEffect, Suspense } from 'react';
import Entry from './src/App';
import 'Sentry/Sentry';
// import './analytics';

/* StatusBar.setTranslucent(true); */

const App = () => {
  useEffect(() => {
    // Tweak to force feedback on TouchableOpacity without setting the props in every instance of TouchableOpacity
    // as suggested here -> https://github.com/facebook/react-native/issues/29360
    if (TouchableOpacity.defaultProps == null) TouchableOpacity.defaultProps = {};
    TouchableOpacity.defaultProps.delayPressIn = 0;
  }, []);

  return (
    <Suspense fallback={<Text />}>
      <Entry />
    </Suspense>
  );
};

export default App;
