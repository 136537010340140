import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useState, useCallback, useEffect,
} from 'react';
import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';
import useTranslation from 'common/contexts/translations';
import useSite from 'sites/contexts/sites';
import useLocalOrders from './localOrders';

const HistoryOrderContext = createContext();

export const HistoryOrdersProvider = ({ children }) => {
  const { localHistoryUser } = useLocalOrders();
  const { item: site } = useSite();
  const [isOrderStateUpdating, setIsOrderStateUpdating] = useState(false);
  const [categorySelected, setCategorySelected] = useState('');
  const { t } = useTranslation();
  const [states, setStates] = useState({});
  const [activeOrdersFilters, setActiveOrdersFilters] = useState({});

  const getStates = useCallback(() => {
    setStates({
      at_arrival: t('orders.atArrival'),
      created: site?.pleaseMyCarDriver ? t('orders.stateCreatedDriver') : t('orders.stateCreatedValet'),
      in_preparation: site?.pleaseMyCarDriver ? t('orders.inPreparationDriver') : t('orders.inPreparationValet'),
      ready: site?.pleaseMyCarDriver ? t('orders.readyDriver') : t('orders.readyValet'),
      in_delivery: site?.pleaseMyCarDriver ? t('orders.stateInDeliveryDriver') : t('orders.stateInDeliveryValet'),
      done: site?.pleaseMyCarDriver ? t('orders.doneDriver') : t('orders.doneValet'),
    });
  }, [t, site]);

  useEffect(() => {
    getStates();
  }, [getStates]);

  const updateOrderState = useCallback(async (id, payload) => {
    setIsOrderStateUpdating(true);

    try {
      const res = await fetchJSON({ url: `orders/${id}/update-state`, method: 'PUT', payload });

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    } finally {
      setIsOrderStateUpdating(false);
    }
  }, [setIsOrderStateUpdating]);

  const fetchActiveOrders = useCallback(async (filters = {}) => {
    try {
      const esc = encodeURIComponent;

      if (localHistoryUser?.userId) {
        // eslint-disable-next-line no-param-reassign
        filters = { userId: localHistoryUser?.userId };
      }
      const queryParams = Object.keys(filters)
        .map((key) => `${esc(key)}=${esc(filters[key])}`)
        .join('&');
      const res = await fetchJSON({
        url: `orders/active${queryParams ? '?' : ''}${queryParams}`,
        method: 'GET',
      });

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    }
  }, [localHistoryUser]);

  return (
    <ContextProvider
      url="orders"
      context={HistoryOrderContext}
      value={{
        updateOrderState,
        isOrderStateUpdating,
        states,
        fetchActiveOrders,
        categorySelected,
        setCategorySelected,
        activeOrdersFilters,
        setActiveOrdersFilters,
      }}
    >
      {children}
    </ContextProvider>
  );
};

HistoryOrdersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useHistoryOrders = () => {
  const {
    setIsFetching, fetchActiveOrders, setItems, activeOrdersFilters, setActiveOrdersFilters, ...rest
  } = useContext(HistoryOrderContext);

  const refreshOrders = useCallback(() => {
    const getActivesAndSetOrders = async () => {
      const activeOrders = await fetchActiveOrders(activeOrdersFilters);

      setItems(activeOrders);
      setIsFetching(false);
    };

    getActivesAndSetOrders();
  }, [setIsFetching, fetchActiveOrders, setItems, activeOrdersFilters]);

  const handleActiveOrdersFilters = useCallback((key, value) => {
    setItems([]);
    setIsFetching(true);
    setActiveOrdersFilters((prevFilters) => {
      if (value) {
        return ({ ...prevFilters, [key]: value });
      }
      const newFilters = { ...prevFilters };

      delete newFilters[key];
      return (newFilters);
    });
  }, [setItems, setActiveOrdersFilters, setIsFetching]);

  return {
    refreshOrders,
    setIsFetching,
    fetchActiveOrders,
    setItems,
    activeOrdersFilters,
    handleActiveOrdersFilters,
    ...rest,
  };
};

export default useHistoryOrders;
