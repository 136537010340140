import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  View, StyleSheet,
} from 'react-native';
import useTranslation from 'common/contexts/translations';
import theme from 'styles';

import Text from 'common/components/Text/Text';
import Button from 'common/components/Button/Button';
import ButtonGroup from 'common/components/ButtonGroup/ButtonGroup';

const ModalContent = ({ payment, onSubmit }) => {
  const [methodSelected, setMethodSelected] = useState(payment.offline_method);
  const [paymentStatus, setPaymentStatus] = useState(payment.status);
  const { t } = useTranslation();

  return (
    <View style={styles.modalBox}>
      <Text
        bold
        size="larger"
        centered
        style={{ marginVertical: theme.sizings.large }}
      >
        {t('waiter.offlineMethodUsed')}
      </Text>
      <ButtonGroup
        onChange={setMethodSelected}
        value={methodSelected}
        options={[{
          value: 'CASH',
          text: t('basket.cashPay'),
          iconName: 'money',
        }, {
          value: 'CB',
          text: t('basket.cbPay'),
          iconName: 'credit-card',
        }]}
      />
      <ButtonGroup
        onChange={setPaymentStatus}
        value={paymentStatus}
        options={[{
          value: 'PENDING',
          text: t('orders.awaitingPayment'),
          iconName: 'clock-o',
        }, {
          value: 'PAID',
          text: t('orders.paid'),
          iconName: 'check',
        }]}
      />
      <Button
        variant="primary"
        text={t('common.confirm')}
        onPress={() => onSubmit({
          site: payment.site,
          offline_method: methodSelected,
          status: paymentStatus,
        })}
        style={{ marginTop: theme.sizings.large }}
      />
    </View>
  );
};

ModalContent.propTypes = {
  payment: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  modalBox: {
    margin: 'auto',
    paddingHorizontal: theme.sizings.large,
    paddingVertical: theme.sizings.larger,
    backgroundColor: theme.colors.light,
    opacity: 1,
    borderRadius: theme.radius.medium,
  },
});

export default ModalContent;
