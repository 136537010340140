import Clock from 'assets/clock.png';
import { useAuth } from 'auth/contexts/auth';
import Button from 'common/components/Button/Button';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import FormInput from 'common/components/FormInput/FormInput';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import {
  Image, ScrollView, StyleSheet, View, SafeAreaView,
} from 'react-native';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import PageBackground from 'common/components/PageBackground/PageBackground';
import useSite from 'sites/contexts/sites';

const styles = StyleSheet.create({

  fullFormView: {
    flex: 1,
    // backgroundColor: theme.colors.white,
  },

  header: {
    paddingHorizontal: theme.sizings.medium,
    paddingBottom: theme.sizings.medium,
    alignItems: 'center',
  },

  image: {
    height: 102,
    width: 106,
    marginLeft: -10,
    marginBottom: theme.sizings.medium,
  },

  legende: {
    textAlign: 'center',
    marginBottom: theme.sizings.medium,
  },

  scrollView: {
    paddingHorizontal: theme.sizings.large,
    paddingTop: theme.sizings.large,
  },

  innerButton: {
    marginTop: theme.sizings.large,
  },

  bottomButton: {
    marginTop: theme.sizings.small,
    backgroundColor: theme.colors.dark,
    color: theme.colors.white,
  },
});

const SignUp = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { callback } = route.params;
  const { setAlert } = useAlert();
  const { item: site } = useSite();

  const auth = useAuth();
  const { isFetching } = useAuth();
  const onSubmit = async ({
    name, email, phone, password, passwordConfirm,
  }) => {
    if (password !== passwordConfirm) {
      setAlert({ color: 'error', title: t('common.error'), message: t('auth.noMatchPassword') });
    } else {
      try {
        const user = await auth.signup({
          name, email, phone, password,
        });

        // callback can be passed to the signin function
        // if a callback function exist it will be called instead of redirecting
        // to the home page
        if (callback) {
          if (navigation.canGoBack()) {
            navigation.pop();
          }
          callback(user);
        } else {
          navigation.reset({
            index: 0,
            routes: [{
              name: [
                'waiter',
                'barman',
                'reception',
                'manager',
              ].includes(user.role.type) ? 'WaiterHome' : 'home',
            }],
          });
        }
      } catch {
        setAlert({ color: 'error', title: t('common.error'), message: t('auth.badCredentials') });
      }
    }
  };

  const goSignIn = async () => {
    navigation.pop();
    navigation.navigate('SignInPage', { callback });
  };

  return (
    <>
      <PageBackground />
      <SafeAreaView style={{ flex: 1 }}>
        <View style={styles.fullFormView}>
          <LoadingSpinner
            visible={isFetching}
          />
          <Form
            onSubmit={onSubmit}
            initialValues={{
              name: '', email: '', password: '', passwordConfirm: '', phone: '',
            }}
            render={({ handleSubmit, submitting, values }) => (
              <>
                <ScrollView style={styles.scrollView}>
                  <View style={styles.header}>
                    <Image style={styles.image} source={Clock} />
                    <Text
                      size="large"
                      style={styles.legende}
                      customColor={site?.template?.backdropTextColor || theme.colors.light}
                    >
                      {t('auth.signUpMessage')}
                    </Text>
                  </View>
                  <FormInput
                    data-cy="name"
                    name="name"
                    label={t('auth.name')}
                    icon="people"
                  />
                  <FormInput
                    data-cy="mail"
                    type="email"
                    name="email"
                    label={t('auth.email')}
                    icon="envelope"
                  />
                  <FormInput
                    data-cy="phone"
                    type="phone-pad"
                    name="phone"
                    label={t('auth.phone')}
                    icon="phone"
                  />
                  <FormInput
                    data-cy="password"
                    type="password"
                    name="password"
                    label={t('auth.password')}
                    icon="lock"
                  />
                  <FormInput
                    data-cy="password_confirm"
                    type="password"
                    name="passwordConfirm"
                    label={t('auth.passwordConfirm')}
                    icon="lock"
                  />
                  <View style={commonStyles.scrollViewWithButton} />

                  <FooterButtonWrapper>
                    <Button
                      variant="light"
                      icon="user"
                      text={t('auth.signUpButton')}
                      onPress={handleSubmit}
                      disabled={submitting || !values.name || !values.email || !values.password
                      || !values.passwordConfirm || !values.phone}
                      type="submit"
                    />
                    <Button
                      style={[styles.bottomButton]}
                      data-cy="submit"
                      variant="primary"
                      text={t('auth.goSignin')}
                      onPress={goSignIn}
                      type="submit"
                    />
                  </FooterButtonWrapper>
                </ScrollView>
              </>
            )}
          />
        </View>
      </SafeAreaView>
    </>
  );
};

SignUp.path = 'signup';

SignUp.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

SignUp.defaultProps = {
};

export default SignUp;
