import { StyleSheet } from 'react-native';

import theme from 'styles';

export default (editable, isTablet) => StyleSheet.create({
  itemView: {
    minheight: 80,
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.greyLightest,
    marginBottom: theme.sizings.small,
    borderRadius: theme.radius.medium,
    paddingRight: theme.sizings.medium,
  },

  optionView: {
    alignItems: 'flex-start',
    backgroundColor: theme.colors.greyLightest,
    marginBottom: theme.sizings.medium,
    borderRadius: theme.radius.medium,
    paddingHorizontal: theme.sizings.medium,
  },

  productImage: {
    minWidth: 80,
    height: '100%',
    overflow: 'hidden',
    borderTopLeftRadius: theme.radius.medium,
    borderBottomLeftRadius: theme.radius.medium,
  },

  productContent: {
    justifyContent: 'space-evenly',
    alignSelf: 'stretch',
    flex: 1,
    marginLeft: theme.sizings.medium,
  },

  productLabel: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  variant: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },

  variants: {
    marginBottom: theme.sizings.medium,
  },

  buttonsGroup: {
    width: isTablet ? (editable ? 160 : 80) : (editable ? 100 : 60),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.radius.rounded,
    overflow: 'hidden',
    paddingHorizontal: theme.sizings.small,
  },

  quantity: {
    marginVertical: 0,
    marginHorizontal: theme.sizings.small,
  },

  productButton: {
    paddingHorizontal: theme.sizings.small,
  },
});
