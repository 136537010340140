import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useState, useCallback, useMemo,
} from 'react';
import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';

const PaymentContext = createContext();

export const PaymentsProvider = ({ children }) => {
  const [isPayFetching, setIsPayFetching] = useState(false);

  const pay = useCallback(async (id) => {
    setIsPayFetching(true);

    try {
      const res = await fetchJSON({ url: `payments/${id}/pay`, method: 'PUT' });

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    } finally {
      setIsPayFetching(false);
    }
  }, []);

  const value = useMemo(() => ({
    pay,
    isPayFetching,
  }), [pay, isPayFetching]);

  return (
    <ContextProvider
      url="payments"
      context={PaymentContext}
      value={value}
    >
      {children}
    </ContextProvider>
  );
};

PaymentsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const usePayments = () => useContext(PaymentContext);

export default usePayments;
