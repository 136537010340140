import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import React, { useEffect } from 'react';
import {
  ScrollView,
  View,
} from 'react-native';
import useSite from 'sites/contexts/sites';
import theme from 'styles';
import OrderSummary from 'orders/components/OrderListItem/OrderSummary';
import useLocalOrders from 'orders/contexts/localOrders';

const SiteOrdersLocal = () => {
  const { t } = useTranslation();

  const { item: site } = useSite();
  const {
    localOrders,
    fetchOrders,
  } = useLocalOrders();

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  return (
    <Page
      backgroundImage={site?.template?.imageBackdrop}
    >
      <ScrollView style={{ padding: theme.sizings.medium }}>
        {localOrders.length === 0 && (
          <View>
            <Text centered size="huge">
              😅
            </Text>
            <Text
              size="large"
              style={{ textAlign: 'center', paddingHorizontal: theme.sizings.small, marginTop: theme.sizings.small }}
              customColor={site?.template?.backdropTextColor}
            >
              {t('orders.anyOrderYet')}
            </Text>
          </View>
        )}
        {
          // booking.valid &&
          localOrders.length >= 0 && (
            localOrders.reverse().map((
              order, key,
            ) => (
              <OrderSummary
                key={`${key}_orderSum_${order.id}`}
                order={order}
              />
            ))
          )
        }
      </ScrollView>
    </Page>
  );
};

export default SiteOrdersLocal;
