import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';

import useTranslation from 'common/contexts/translations';
import useSite from 'sites/contexts/sites';
import styles from './Text.styles';

const StyledText = ({
  color, customColor, noLineHeight, isUppercase, isBold, size, centered, children, style,
  isButtonText, isTitleText, ...rest
}) => {
  const { lang } = useTranslation();
  const { item: site } = useSite();
  const template = site?.template;

  const font = () => {
    if (isButtonText && template?.subtitleFont) {
      return template.subtitleFont;
    } if (isTitleText && template?.titleFont) {
      return template.titleFont;
    } if (template) {
      return template.primaryFont;
    }
    return null;
  };

  const applyFont = font();

  return (
    <Text
      {...rest}
      style={[
        styles(lang, color, customColor, size, noLineHeight, isUppercase, isBold, centered).text,
        style,
        applyFont && { fontFamily: `${applyFont}, sans-serif` },
      ]}
    >
      {children}
    </Text>
  );
};

StyledText.propTypes = {
  customColor: PropTypes.string,
  color: PropTypes.string,
  isUppercase: PropTypes.bool,
  isBold: PropTypes.bool,
  centered: PropTypes.bool,
  noLineHeight: PropTypes.bool,
  size: PropTypes.string,
  children: PropTypes.node,
  isButtonText: PropTypes.bool,
  isTitleText: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
};

StyledText.defaultProps = {
  children: null,
  customColor: null,
  color: 'dark',
  isUppercase: false,
  isBold: false,
  noLineHeight: false,
  size: 'default',
  centered: false,
  isButtonText: false,
  isTitleText: false,
  style: null,
};

export default StyledText;
