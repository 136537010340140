import React from 'react';
import PropTypes from 'prop-types';

import useTranslation from 'common/contexts/translations';
import Button from 'common/components/Button/Button';

const BookButton = ({ onSubmit, disabled, ...rest }) => {
  // const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <Button
        data-cy="cypress_book"
        disabled={disabled}
        onPress={onSubmit}
        variant="gradient"
        text={t('beach.book')}
        {...rest}
      />
    </>
  );
};

BookButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

BookButton.defaultProps = {
  disabled: false,
};

export default BookButton;
