import {
  useState, useCallback, useEffect,
} from 'react';
// import usePersistedState from './usePersistedState';

const useAsync = (asyncFn, options = {}) => {
  const opts = {
    ready: true,
    manual: false,
    storeData: true,
    cachePrefix: '',
    cacheDuration: null, // second. Null = infinite
    ...options,
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const [isPreviousData, setIsPreviousData] = useState(false);
  const [data, setData] = useState(null); // usePersistedState(null, `${opts.cachePrefix}_data`, opts.cacheDuration);
  const [error, setError] = useState(null);

  const execute = useCallback((...args) => {
    setIsProcessing(true);
    setError(null);
    if (opts.storeData) {
      setIsPreviousData(true);
    }
    const promise = asyncFn(...args);
    const { cancel } = promise;

    const newPromise = new Promise((resolve) => {
      promise.then((response) => {
        if (opts.storeData) {
          setData(response);
          setIsPreviousData(false);
        }
        resolve(response);
      }).catch((e) => {
        setError(e.message);
      }).finally(() => {
        setIsProcessing(false);
      });
    });

    newPromise.cancel = cancel;

    return newPromise;
  }, [setData, asyncFn, opts.storeData]);

  useEffect(() => {
    let promise = null;

    if (!opts.manual && opts.ready) {
      promise = execute();
    }
    return () => {
      if (promise && promise.cancel) {
        promise.cancel();
      }
    };
  }, [execute, opts.manual, opts.ready]);

  return {
    execute, isProcessing, data, error, isPreviousData, setData,
  };
};

export default useAsync;
