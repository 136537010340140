/* eslint-disable no-return-assign */
import { FontAwesome } from '@expo/vector-icons';
import Gradient from 'common/components/Gradient/Gradient';
import { ImageBackground } from 'common/components/Images';
import NavigationButton from 'common/components/NavigationButton/NavigationButton.native';
import Page from 'common/components/Page/Page';
import Price from 'common/components/Price/Price';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useTranslation from 'common/contexts/translations';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ScrollView,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import useSite from 'sites/contexts/sites';
import useHistoryOrders from 'orders/contexts/historyOrders';
import commonStyles from 'styles/commonStyles';
import { useNavigation } from '@react-navigation/native';
import Button from 'common/components/Button/Button';
import { useAuth } from 'auth/contexts/auth';
import IconFood from 'assets/icon-food.svg';
import HeaderSiteHome from 'assets/header-site-home.svg';
import zeroPad from 'common/utils/zeroPad';
import useSpecialOffers from 'specialOffers/contexts/specialOffers';
import PromoMessage from 'sites/components/PromoMessage/PromoMessage';
import LanguagePicker from 'common/components/LanguagePicker/LanguagePicker';
import theme from 'styles';
import useBasket from 'basket/contexts/basket';
import getNextpage from 'common/utils/navigation/CleverNavigation';
import styles from './styles/Info.styles';
import useOrders from 'orders/contexts/orders';

const SiteHome = () => {
  const { item: site } = useSite();
  const { user, updateUser } = useAuth();
  const { t } = useTranslation();
  const navigation = useNavigation();

  const laMome = site?.id === 45;

  const {
    fetchSpecialOffersApplicable,
    items: specialOffers,
    setItems: setSpecialOffers,
  } = useSpecialOffers();

  const { addItem, setIsAllInclusiveBasket, items: basket } = useBasket();

  const [detailsOpen, setDetailsOpen] = useState(false);

  const { setItem: setNewOrder } = useOrders();


  const {
    fetchItems: fetchOrders,
    items: orders,
    setItems: setOrders,
    setItem: setOrder,
    setFilters: setOrderFilters,
    setSort: setOrderSorting,
    filters,
    states,
  } = useHistoryOrders();

  useEffect(() => {
    const fetchAndSetData = async () => {
      const applicables = await fetchSpecialOffersApplicable({
        site: site?.id,
      });

      setSpecialOffers(applicables);
    };

    fetchAndSetData();
  }, [setSpecialOffers, fetchSpecialOffersApplicable, site]);

  useEffect(() => {
    setOrders([]);
    setOrderSorting('id:DESC');
    setOrderFilters({ site: site?.id });
  }, [setOrderFilters, setOrderSorting, setOrders, site]);

  useEffect(() => {
    if (user?.id && filters.site) {
      fetchOrders();
    } else {
      setOrders([]);
    }
  }, [fetchOrders, filters, setOrders, user]);

  useEffect(() => {
    if (
      ['waiter', 'barman', 'manager', 'reception'].includes(user?.role.type)
    ) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: 'WaiterHome', 
          },
        ],
      });
    }
  }, [navigation, user]);

  const template = site?.template;

  const rootCategories = site?.categories
    ?.filter(({ parent }) => !parent)
    .reduce((acc, category) => {
      // check if children categories of current category has products
      const count = category.children.reduce(
        (prev, curr) => prev + curr.products.length,
        category.products.length,
      );

      // if current category and its children doesn't have any products, we don't display it
      if (count > 0) {
        return [...acc, { ...category, count }];
      }

      return acc;
    }, []) || [];

  const handleLanguageChange = useCallback(
    (language) => {
      user && updateUser({ language });
    },
    [user, updateUser],
  );

  const [carProduct, setCarProduct] = useState(null);

  useEffect(() => {
    if (site?.pleaseMyCar) {
      const res = site?.categories?.[0].products?.[0];
      setCarProduct(res);
    }
  }, [site, setCarProduct]);

  const { height } = useWindowDimensions();

  const addProductToBasket = useCallback(() => {
    addItem(
      carProduct,
      // eslint-disable-next-line camelcase
      [],
      carProduct.price,
    );
    if (site?.all_inclusive && carProduct.all_inclusive) {
      console.log('set it', {
        siteInc: site?.all_inclusive,
        prodInc: carProduct.all_inclusive,
      });
      setIsAllInclusiveBasket(true);
    }
  }, [addItem, carProduct, setIsAllInclusiveBasket, site]);

  // skip basket page
  const free = (carProduct?.price === 0 || (site?.all_inclusive && carProduct?.all_inclusive));
  const onlyMethod = site?.canPayOffline || !site?.canPayOffline;
  const skipBasket = !site?.showStepBasketPage;

  const canSkipBasket = free && onlyMethod && skipBasket; // false

  /* console.log({ free, onlyMethod, skipBasket, canSkipBasket }); */

  const noBasketAndContinue = useCallback(async (payAfter) => {

    const order = {
      order_items: [{
        name: carProduct.name,
        product: carProduct,
        selected_options: [],
        reservation: null,
        description: carProduct.description,
        quantity: 1,
        price: 0, 
        reduction: null, 
        all_inclusive: true,
      }], 
      price: 0,
      tip: 0,
      site: site?.id,
      active: true,
      all_inclusive: true,
      payAfter,
      orderStartTime: new Date().getTime(),
    };

    console.log({ order }, 'home page'); // PB : pas d'order item au premier cycle

    const { nextPage, newOrder } = await getNextpage({
      currentScreenName: 'Basket', // pretend to already be on basket page, works fine
      currentOrder: order,
      currentSite: site,
    });

    await setNewOrder(newOrder);
    navigation.navigate(nextPage); // PB2 : order se perd après la navigation (page "formulaire personné / OrderLocalizarion")
  },
  [
    site,
    navigation,
    setNewOrder,
    carProduct
  ]);

  return (
    <Page
      transparentHeader
      padding
      backgroundImage={template?.image}
      style={{ ...styles.infoContainer, marginBottom: 0, overflow: 'hidden' }}
    >
      <View
        style={{
          backgroundImage: `url(${HeaderSiteHome})`,
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: height * 0.24,
        }}
      />

      <View
        style={[
          {
            height: template?.logo ? height * 0.2 : 'auto',
            width: template?.logo ? height * 0.2 : 'auto',
          },
          !template?.logo ? {
            alignSelf: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            paddingHorizontal: theme.sizings.large,
          } : {
            overflow: 'hidden',
            borderRadius: '100%'
          },
        ]}
      >
        {template?.logo ? (
          <ImageBackground
            image={template?.logo}
            style={{ width: '100%', height: '100%', marginBottom: 0 }}
            resizeMode="contain"
          />
        ) : (
          <Text
            size="large"
            color="light"
            isTitleText
            style={{
              fontSize: 25,
              lineHeight: 28,
            }}
          >
            {site?.name}
          </Text>
        )}
      </View>

      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 90,
        }}
      >
        {specialOffers?.length > 0 && (
          <View style={{ marginTop: 80 }}>
            {specialOffers.map((offer, index) => (
              <PromoMessage key={index} offer={offer} />
            ))}
          </View>
        )}

        {rootCategories && !site?.pleaseMyCar && (
          <View
            style={{
              width: '80%',
              marginTop: 80,
              alignItems: 'center',
            }}
          >
            <NavigationButton
              title={t('orders.newOrder')}
              link={
                site?.all_inclusive
                  ? 'OrderInclusiveFormule'
                  : template?.showRootCategoriesPage
                    ? 'SiteProducts'
                    : 'CategoryDetail'
              }
              icon={IconFood}
              iconImage
              params={{
                siteId: site?.id,
                category: site?.all_inclusive ? undefined : rootCategories[0],
              }}
              variant="light"
            />
          </View>
        )}

        {site?.pleaseMyCar && carProduct && (
          <View
            style={{
              width: '80%',
              marginTop: 80,
              alignItems: 'center',
            }}
          >
            <Button
              text={laMome ? t('beach.callWaiter') : site?.pleaseMyCarDriver ? t('orders.newOrderDriver') : t('orders.newOrderValet')}
              onPress={() => {
                // squizzer le panier = gratuit + un seul mode de paiement + "squizer" dans le back-office
                if (canSkipBasket) {
                  noBasketAndContinue(site?.canPayOffline); // true if pay offline after
                } else {
                  addProductToBasket();
                  navigation.navigate('Basket');
                }
              }}
              icon={laMome ? "bell" : "car"}
              iconFontV5
              variant="light"
            />
          </View>
        )}

        <View
          style={{
            width: '80%',
            marginTop: theme.sizings.medium,
            alignItems: 'center',
          }}
        >
          <NavigationButton
            title={t('common.history')}
            link="SiteOrdersLocal"
            icon="history"
            params={{ siteId: site?.id }}
            variant="gradient"
          />
        </View>

        <View
          style={{
            width: '80%',
            marginTop: theme.sizings.medium,
            alignItems: 'center',
          }}
        >
          <LanguagePicker onChange={handleLanguageChange} />
        </View>

        {site?.instagramShow && (
          <View
            style={{
              marginTop: theme.sizings.medium,
              alignItems: 'center',
            }}
          >
            <NavigationButton
              title={laMome ? t('beach.products') : t('common.instagram')}
              link={
                site?.instagramLink?.length
                  ? site.instagramLink
                  : 'http://www.instagram.com/pleasemycar'
              }
              icon={laMome ? "eye" : "instagram"}
              params={{ siteId: site?.id }}
              variant="gradient"
              isExtraLink
              iconFontV5
            />
          </View>
        )}
      </View>

      <View style={styles.absoluteContent}>

        {
          orders[0] && (
            <View
              style={[
                styles.absoluteContainer,
                // template?.primaryColor && { backgroundColor: template.primaryColor },
              ]}
            >
              <>
                <TouchableOpacity
                  style={styles.currentOrderHeader}
                  onPress={() => setDetailsOpen(!detailsOpen)}
                >
                  <>
                    <View style={[commonStyles.textWithIcon]}>
                      <View
                        style={[
                          commonStyles.fullViewDetailsIcon,
                          { backgroundColor: 'transparent' },
                        ]}
                      >
                        <FontAwesome
                          size={theme.fontSizes.large}
                          name="clock-o"
                          color={theme.colors.greyLighter}
                        />
                      </View>
                      <View style={styles.optionsTitleText}>
                        <Text size="large" color="light" isTitleText>
                          {`${t('orders.orderNbr', {
                            orderId: zeroPad(orders[0].displayedId, 3),
                          })} ${states[orders[0].state]}`}
                        </Text>
                      </View>
                    </View>
                    <FontAwesome
                      name={detailsOpen ? 'angle-up' : 'angle-down'}
                      size={theme.fontSizes.large}
                      color={theme.colors.greyLighter}
                    />
                  </>
                </TouchableOpacity>
                {detailsOpen && (
                  <ScrollView style={styles.orderItems}>
                    {orders[0].order_items?.map((orderItem) => (
                      <View style={styles.currentOrderItem} key={orderItem.id}>
                        <View style={styles.itemImage}>
                          {orderItem.product ? (
                            <ImageBackground
                              style={styles.itemImageInner}
                              image={
                                orderItem.product.images?.length
                                && orderItem.product.images[0]
                              }
                            />
                          ) : (
                            <ImageBackground
                              style={styles.itemImageInner}
                              image={
                                orderItem.service.images?.length
                                && orderItem.service.images[0]
                              }
                            />
                          )}
                        </View>
                        {orderItem.quantity > 1 && (
                          <View style={styles.itemQuantity}>
                            <Gradient height={50} />
                            <View>
                              <Text noLineHeight size="large" color="light">
                                {`x ${orderItem.quantity}`}
                              </Text>
                            </View>
                          </View>
                        )}
                        <View style={{ flex: 1 }}>
                          <TranslatedText
                            isBold
                            size="large"
                            color="light"
                            value={orderItem.name}
                          />
                        </View>
                        <Price
                          price={orderItem.price * orderItem.quantity}
                          color="greyLighter"
                          size="medium"
                        />
                      </View>
                    ))}

                    <Button
                      text={t('common.seeDetails')}
                      icon="eye"
                      onPress={() => {
                        setOrder(orders[0]);
                        navigation.navigate('FinalOrderSummary', {
                          order: orders[0],
                        });
                        setDetailsOpen(!detailsOpen);
                      }}
                      variant="light"
                      styles={styles.btnOpenDetail}
                    />
                  </ScrollView>
                )}
              </>
            </View>
          )
        }
      </View>
    </Page>
  );
};

export default SiteHome;
