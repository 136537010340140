import React from 'react';
import { View } from 'react-native';
import Button from 'common/components/Button/Button';
import { useNavigation } from '@react-navigation/native';
import PropTypes from 'prop-types';

const NavigationButton = ({
  link, title, params, variant, styles, icon, iconImage, alsoOnPress, navigate,
}) => {
  const navigation = useNavigation();

  const handlePress = () => {
    alsoOnPress();
    if (navigate) {
      navigation.navigate(link, params);
    }
  };

  return (
    <Button
      icon={icon}
      text={title}
      variant={variant}
      onPress={handlePress}
      style={styles}
    />
  );
};

NavigationButton.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconImage: PropTypes.bool,
  variant: PropTypes.string,
  params: PropTypes.object,
  styles: PropTypes.object,
  alsoOnPress: PropTypes.func,
  navigate: PropTypes.bool,
};

NavigationButton.defaultProps = {
  params: {},
  styles: {},
  variant: 'light',
  icon: '',
  iconImage: false,
  alsoOnPress: () => { },
  navigate: true,
};

export default NavigationButton;
