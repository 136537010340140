import React, { useCallback } from 'react';
import { View, TouchableHighlight, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import PropTypes from 'prop-types';

import Gradient from 'common/components/Gradient/Gradient';
import Text from 'common/components/Text/Text';
import commonStyles from 'styles/commonStyles';
import theme from 'styles';

const styles = StyleSheet.create({

  buttons: {
    justifyContent: 'center',
    backgroundColor: theme.colors.greyLightest,
    marginVertical: theme.sizings.small,
    borderRadius: theme.radius.rounded,
  },

  buttonWrapper: {
    overflow: 'hidden',
  },
  button: {

  },

  buttonTop: {
    borderTopLeftRadius: theme.radius.rounded,
    borderTopRightRadius: theme.radius.rounded,
  },
  buttonBottom: {
    borderBottomLeftRadius: theme.radius.rounded,
    borderBottomRightRadius: theme.radius.rounded,
  },
  buttonInner: {
    paddingHorizontal: theme.sizings.medium,
    paddingVertical: theme.sizings.small,
    alignItems: 'center',
    // height: '100%',
  },
});

const ButtonGroup = ({
  options, value, onChange,
}) => {
  const computeStyles = useCallback((index) => {
    if (index === 0) {
      return [styles.buttonWrapper, styles.buttonTop];
    } if (index === options.length - 1) {
      return [styles.buttonWrapper, styles.buttonBottom];
    }
    return [styles.buttonWrapper];
  }, [options]);

  return (
    <View style={[styles.buttons, commonStyles.shadowBottom]}>
      {options.map((option, index) => (
        <TouchableHighlight
          key={index}
          style={computeStyles(index)}
          onPress={() => onChange(option.value)}
        >
          <View style={styles.button}>
            {value === option.value && <Gradient height="100%" />}
            <View style={styles.buttonInner}>
              {option.iconName && (
              <FontAwesome
                name={option.iconName}
                color={value === option.value ? theme.colors.light : theme.colors.primary}
                size={theme.sizings.large}
              />
              )}
              <Text centered color={value === option.value ? 'light' : 'dark'}>{option.text}</Text>
            </View>
          </View>
        </TouchableHighlight>
      ))}
    </View>
  );
};

ButtonGroup.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ButtonGroup;
