import Modal from 'modal-react-native-web';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  ActivityIndicator, StyleSheet, Text, View,
} from 'react-native';
import theme from 'styles';

const transparent = 'transparent';
const styles = StyleSheet.create({
  activityIndicator: {
    flex: 1,
  },
  background: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  container: {
    backgroundColor: transparent,
    bottom: 0,
    flex: 1,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  textContainer: {
    alignItems: 'center',
    bottom: 0,
    flex: 1,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  textContent: {
    fontSize: theme.fontSizes.large,
    fontWeight: 'bold',
    height: 50,
    top: 80,
    color: 'white',
  },
});

const ANIMATION = ['none', 'slide', 'fade'];
const SIZES = ['small', 'normal', 'large'];

class LoadingSpinner extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.visible,
      textContent: this.props.textContent ? this.props.textContent : this.props.t('common.loading'),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};

    if (state.visible !== props.visible) newState.visible = props.visible;
    if (state.textContent !== (props.textContent ? props.textContent : props.t('common.loading'))) {
      newState.textContent = props.textContent ? props.textContent : props.t('common.loading');
    }
    return newState;
  }

  handleOnRequestClose() {
    if (this.props.cancelable) {
      this.close();
    }
  }

  close() {
    this.setState({ visible: false });
  }

  renderDefaultContent() {
    return (
      <View style={styles.background}>
        {this.props.customIndicator ? (
          this.props.customIndicator
        ) : (
          <ActivityIndicator
            color={this.props.color}
            size={this.props.size}
            style={[styles.activityIndicator, { ...this.props.indicatorStyle }]}
          />
        )}
        <View style={[styles.textContainer, { ...this.props.indicatorStyle }]}>
          <Text style={[styles.textContent, this.props.textStyle]}>
            {this.state.textContent}
          </Text>
        </View>
      </View>
    );
  }

  renderSpinner() {
    const spinner = (
      <View
        style={[styles.container, { backgroundColor: this.props.overlayColor }]}
        key={
          this.props.spinnerKey
            ? this.props.spinnerKey
            : `spinner_${Date.now()}`
        }
      >
        {this.props.children
          ? this.props.children
          : this.renderDefaultContent()}
      </View>
    );

    return (
      <Modal
        animationType={this.props.animation}
        onRequestClose={() => this.handleOnRequestClose()}
        supportedOrientations={['landscape', 'portrait']}
        transparent
        visible={this.state.visible}
        ariaHideApp={false}
      >
        {spinner}
      </Modal>
    );
  }

  render() {
    return this.renderSpinner();
  }
}

LoadingSpinner.propTypes = {
  cancelable: PropTypes.bool,
  color: PropTypes.string,
  animation: PropTypes.oneOf(ANIMATION),
  overlayColor: PropTypes.string,
  size: PropTypes.oneOf(SIZES),
  textContent: PropTypes.string,
  textStyle: PropTypes.object,
  visible: PropTypes.bool,
  indicatorStyle: PropTypes.object,
  customIndicator: PropTypes.element,
  children: PropTypes.element,
  spinnerKey: PropTypes.string,
  t: PropTypes.func.isRequired,
};

LoadingSpinner.defaultProps = {
  visible: false,
  cancelable: false,
  textContent: null,
  animation: 'none',
  color: 'white',
  size: 'large', // 'normal',
  overlayColor: 'rgba(0, 0, 0, 0.6)',
  textStyle: null,
  indicatorStyle: null,
  children: null,
  spinnerKey: null,
  customIndicator: null,
};

export default withTranslation()(LoadingSpinner);
