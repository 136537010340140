import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const TemplatesContext = createContext();

export const TemplatesProvider = ({ children }) => (
  <ContextProvider url="templates" context={TemplatesContext}>{children}</ContextProvider>
);

TemplatesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useTemplates = () => useContext(TemplatesContext);

export default useTemplates;
