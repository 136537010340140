/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Dimensions } from 'react-native';
import CalendarPicker from 'react-native-calendar-picker';
import dayjs from 'dayjs';
import { useNavigation } from '@react-navigation/native';
import useTranslation from 'common/contexts/translations';

import Button from 'common/components/Button/Button';
import Text from 'common/components/Text/Text';
import useSite from 'sites/contexts/sites';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
// import useBooking from 'bookings/contexts/bookings';
// import useSeatSelector from 'bookings/pages/SeatSelector/contexts/seatSelector';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';

const styles = StyleSheet.create({
  dateSelectScreen: {
    flex: 1,
    position: 'relative',
    backgroundColor: theme.colors.white,
  },
  dateSelectInner: {
    paddingTop: theme.sizings.large,
    flex: 1,
  },

  button: {
    position: 'absolute',
    zIndex: 40,
    top: -30,
  },

  daysLabelWrapper: {
    paddingTop: theme.sizings.medium,
    paddingBottom: theme.sizings.medium,
    width: '100%',
    backgroundColor: theme.colors.secondaryLight,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },

  headerMask: {
    borderTopLeftRadius: theme.radius.medium,
    borderTopRightRadius: theme.radius.medium,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 100,
    backgroundColor: theme.colors.secondaryDark,
  },

  calendar: {
    borderRadius: theme.radius.medium,
    position: 'relative',
    marginHorizontal: theme.sizings.small,
    marginTop: theme.sizings.small,
    paddingBottom: theme.sizings.mediumLarge,
    height: 'auto',
    backgroundColor: theme.colors.white,
  },

  selectedDay: {
    width: 40,
    height: 40,
    backgroundColor: theme.colors.secondary,
  },

  hideSelectedDay: {
    width: 40,
    height: 40,
    backgroundColor: theme.colors.white,
  },

  calendarText: {
    fontFamily: 'Avenir',
    fontSize: theme.fontSizes.large,
    color: theme.colors.dark,
  },

  nav: {
    fontSize: theme.fontSizes.default,
  },

  today: { fontFamily: 'AvenirBold' },
});

const weekdaysStyles = {
  0: {
    color: theme.colors.dark,
  },
  1: {
    color: theme.colors.dark,
  },
  2: {
    color: theme.colors.dark,
  },
  3: {
    color: theme.colors.dark,
  },
  4: {
    color: theme.colors.dark,
  },
  5: {
    color: theme.colors.dark,
  },
  6: {
    color: theme.colors.dark,
  },
};

const DateSelect = ({ route }) => {
  const { item: site } = useSite();
  const navigation = useNavigation();
  const defaultDate = dayjs().isBefore(dayjs(site.dateBegin))
    ? dayjs(site.dateBegin).utc().startOf('day')
    : dayjs().utc().startOf('day');
  const [hide, setHide] = useState(false);
  const [selectedDates, setSelectedDates] = useState([{ date: defaultDate, style: styles.selectedDay }]);
  const { t } = useTranslation();
  // const { setItem: setBooking } = useBooking();
  // const { setSelection } = useSeatSelector();

  /**
   * Change the actual date selected then check add it to the
   * selectedDateArray if there is no duplicate already stored.
   * Otherwise remove the duplicate and set the tmp array as the
   * new value.
   * @param newDate
   */
  const onDateChange = (newDate) => {
    let tmpSelectedDates = [...selectedDates];
    const chosenDate = newDate.utc().startOf('day');

    if (tmpSelectedDates.length > 0) {
      const result = tmpSelectedDates.find((elem) => elem.date.isSame(chosenDate));

      if (result) {
        tmpSelectedDates = tmpSelectedDates.filter((elem) => elem !== result);
        setHide(true);
      } else {
        tmpSelectedDates.push({
          date: chosenDate,
          style: styles.selectedDay,
        });
        setHide(false);
      }
    } else {
      tmpSelectedDates.push({
        date: chosenDate,
        style: styles.selectedDay,
      });
      setHide(false);
    }
    setSelectedDates(tmpSelectedDates.sort((a, b) => a.date - b.date));
  };

  const redirectSeatSelector = () => {
    // setBooking((prevBooking) => ({
    //   ...prevBooking,
    //   dates: selectedDates.map((date) => date.date.format()),
    // }));
    // setSelection([]);
    if (site.maps.length === 1) {
      navigation.navigate('SeatSelector', { siteId: route.params.siteId, mapId: site.maps[0].id });
    } else {
      navigation.navigate('MapsList');
    }
  };

  return (
    <View style={styles.dateSelectScreen}>
      <View style={styles.dateSelectInner}>
        <Text size="large" isBold centered>{t('bookings.pickdates')}</Text>
        <View style={[styles.calendar, commonStyles.shadowBottom]}>
          <View style={styles.headerMask} />
          <CalendarPicker
            width={Dimensions.get('window').width - theme.sizings.small}
            weekdays={t('common.weekdays').split(',')}
            months={t('common.months').split(',')}
            startFromMonday
            onDateChange={onDateChange}
            previousTitleStyle={styles.nav}
            nextTitleStyle={styles.nav}
            previousTitle={t('common.previous')}
            nextTitle={t('common.next')}
            textStyle={styles.calendarText}
            selectedDayColor={theme.colors.secondary}
            selectedDayStyle={hide ? styles.hideSelectedDay : styles.selectedDay}
            todayBackgroundColor="transparent"
            todayTextStyle={styles.today}
            maxDate={dayjs(site.dateEnd).format()}
            minDate={dayjs(site.dateBegin).format()}
            dayLabelsWrapper={styles.daysLabelWrapper}
            dayOfWeekStyles={weekdaysStyles}
            customDatesStyles={selectedDates}
          />
        </View>
      </View>
      <FooterButtonWrapper>
        <Button
          disabled={selectedDates.length === 0}
          text={t('common.selectSeat')}
          variant="gradient"
          onPress={redirectSeatSelector}
        />
      </FooterButtonWrapper>
    </View>
  );
};

DateSelect.propTypes = {
  route: PropTypes.object.isRequired,
};

export default DateSelect;
