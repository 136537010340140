import {
  useState, useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { Vibration } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Audio } from 'expo-av';
// import dayjs from 'dayjs';

import useTranslation from 'common/contexts/translations';
import useAlert from 'common/contexts/alert';
import { getApiUrl } from 'common/utils/createUrl';
import useCategories from 'categories/contexts/categories';
import useAuth from 'auth/contexts/auth';
import useCall from 'calls/contexts/calls';
import useOrders from 'orders/contexts/orders';
import useHistoryOrders from 'orders/contexts/historyOrders';
import useSpecialOffers from 'specialOffers/contexts/specialOffers';
import useSite from 'sites/contexts/sites';
// import useBooking from 'bookings/contexts/bookings';

import notificationSound from 'assets/sounds/cheerfull.mp3';
import useLocalOrders from 'orders/contexts/localOrders';
// import { resetNavigation } from 'common/components/AppStateHandler/RootNavigation';

const soundObject = new Audio.Sound();

soundObject.loadAsync(notificationSound);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState();
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { user, booking } = useAuth(); // setBooking

  const {
    fetchItems: fetchCalls,
  } = useCall();
  // const {
  //   fetchItems: fetchBookings, setFilter: filterBookings, checkCode,
  // } = useBooking();
  const {
    fetchItems: fetchOrders,
    filters: orderFilters,
    refreshOrders,
    fetchBarmanList,
  } = useOrders();
  const {
    fetchItems: fetchUserOrders,
    refreshOrders: refreshUserOrders,
  } = useHistoryOrders();

  const { localUser, localOrders, fetchOrders: fetchLocalOrders } = useLocalOrders();

  /*   const {
    fetchUsers,
    fetchMessages,
    chatActive,
  } = useChat(); */
  const {
    item: site,
    fetchSiteData,
    setItem: setSite,
  } = useSite();
  const {
    fetchItems: fetchCategories,
  } = useCategories();

  const {
    setItems: setSpecialOffers,
    fetchSpecialOffersApplicable,
    fetchItems: fetchSpecialOffers,
  } = useSpecialOffers();

  const authenticate = useCallback(async () => {
    const token = await AsyncStorage.getItem('jwt');

    socket.emit('authenticate', { token });
  }, [socket]);

  const authenticateUser = useCallback(async (orders) => {
    if (socket) {
      socket.emit('authenticateUser', { orders });
    }
  }, [socket]);

  // init socket
  useEffect(() => {
    setSocket(io(getApiUrl()));
  }, []);

  // setAlert not available the first time, so we have to reset events callback
  useEffect(() => {
    if (socket) {
      // socket.off('newCall');
      // socket.off('newBooking');
      // socket.off('updateBooking');
      // socket.off('seatChanged');
      // socket.off('deactivateBooking');
      // socket.off('callDeleted');
      socket.off('orderConfirmed');
      socket.off('newOrder');
      socket.off('orderStateChanged');
      socket.off('specialOffer');
      // socket.off('chatUpdate');
      socket.off('productUpdate');
      socket.off('reconnect');
      socket.off('paymentError');
      // socket.off('clientCall');
      socket.off('newStaffMember');

      socket.on('newStaffMember', () => {
        if (['waiter', 'barman'].includes(user?.role.type)) {
          fetchBarmanList();
        }
      });
      // socket.on('clientCall', () => {
      //   setAlert({ message: t('info.clientNotification') });
      //   Vibration.vibrate(400);
      //   soundObject.replayAsync();
      // });
      socket.on('paymentError', () => {
        setAlert({ color: 'error', title: t('common.error'), message: t('socket.paymentError') });
        Vibration.vibrate(400);
        soundObject.replayAsync();
        // if (['waiter', 'barman'].includes(user?.role.type)) {
        //   filterBookings({
        //     active: true,
        //     date: dayjs().utc().startOf('day').format(),
        //   });
        //   fetchBookings();
        // }
      });
      // socket.on('newCall', () => {
      //   if (['waiter', 'barman'].includes(user?.role.type)) {
      //     setAlert({ message: t('socket.call') });
      //     Vibration.vibrate(400);
      //     soundObject.replayAsync();
      //     fetchCalls();
      //   }
      // });
      // socket.on('newBooking', () => {
      //   if (['waiter', 'barman'].includes(user?.role.type)) {
      //     soundObject.replayAsync();
      //     filterBookings({
      //       active: true,
      //       date: dayjs().utc().startOf('day').format(),
      //     });
      //     fetchBookings();
      //   }
      // });
      // socket.on('updateBooking', () => {
      //   if (['waiter', 'barman'].includes(user?.role.type)) {
      //     filterBookings({
      //       active: true,
      //       date: dayjs().utc().startOf('day').format(),
      //     });
      //     fetchBookings();
      //   } else if (booking) {
      //     const refreshBooking = async () => {
      //       const result = await checkCode(booking.code);

      //       setBooking(result);
      //     };

      //     refreshBooking();
      //   }
      // });
      // socket.on('seatChanged', () => {
      //   if (['waiter', 'barman'].includes(user?.role.type)) {
      //     filterBookings({
      //       active: true,
      //       date: dayjs().utc().startOf('day').format(),
      //     });
      //     fetchBookings();
      //   } else if (booking) {
      //     const refreshBooking = async () => {
      //       const result = await checkCode(booking.code);

      //       setBooking(result);
      //     };

      //     refreshBooking();
      //   }
      //   setAlert({ message: t('info.seatChanged') });
      //   Vibration.vibrate(400);
      //   soundObject.replayAsync();
      // });
      // socket.on('deactivateBooking', () => {
      //   if (['waiter', 'barman'].includes(user?.role.type)) {
      //     filterBookings({
      //       active: true,
      //       date: dayjs().utc().startOf('day').format(),
      //     });
      //     setAlert(t('socket.deactivateBooking', 'success'));
      //     fetchBookings();
      //   } else {
      //     setAlert(t('socket.closedYourBooking', 'error'));
      //     resetNavigation({
      //       index: 0,
      //       routes: [{ name: 'HomePage' }],
      //     });
      //   }
      // });
      // socket.on('callDeleted', () => {
      //   if (['waiter', 'barman'].includes(user?.role.type)) {
      //     fetchCalls();
      //   } else if (orderFilters?.booking) {
      //     setAlert({ message: t('socket.waiterComming') });
      //     fetchCalls();
      //   }
      // });
      socket.on('orderConfirmed', () => {
        Vibration.vibrate(400);
        soundObject.replayAsync();
        setAlert({ message: t('socket.orderConfirmed') });
      });
      socket.on('newOrder', () => {
        setAlert({ message: t('socket.newOrder') });
        Vibration.vibrate(400);
        soundObject.replayAsync();
        if (['waiter', 'barman'].includes(user?.role.type)) {
          refreshOrders();
          refreshUserOrders();
        } else {
          if (user?.role.type === 'authenticated' || localUser?.userId) {
          // si user !== waiter && dans une booking
            fetchUserOrders();
          }
          fetchOrders();
          fetchLocalOrders();
        }
      });
      socket.on('orderStateChanged', () => {
        console.log('orderStateChanged');
        if (['waiter', 'barman'].includes(user?.role.type)) {
          soundObject.replayAsync();

          // fetchBookings();
          refreshOrders();
        } else {
          if (user?.role.type === 'authenticated' || localUser?.userId) {
            // si user !== waiter && dans une booking
            fetchUserOrders();
          }
          Vibration.vibrate(400);
          soundObject.replayAsync();
          setAlert({ message: t('orders.successUpdate') });
          fetchOrders();
          fetchLocalOrders();
        }
      });
      socket.on('specialOffer', () => {
        if (!['waiter', 'barman'].includes(user?.role.type)) { // && booking
          Vibration.vibrate(400);
          soundObject.replayAsync();
          const fetchAndSetData = async () => {
            const applicables = await fetchSpecialOffersApplicable({ site: site.id });

            setSpecialOffers(applicables);
          };

          fetchAndSetData();
        } else if (['waiter', 'barman'].includes(user?.role.type)) {
          fetchSpecialOffers();
        }
      });
      // socket.on('chatUpdate', () => {
      //   if (!['waiter', 'barman'].includes(user?.role.type) && chatActive) { // && booking
      //     fetchUsers();
      //     fetchMessages();
      //   }
      // });
      socket.on('productUpdate', () => {
        async function fetchAndSetSiteData() {
          const siteData = await fetchSiteData(site.id);

          setSite(siteData);
        }
        if (['waiter', 'barman'].includes(user?.role.type)) {
          fetchCategories();
        } else if (user?.role.type === 'authenticated' || localUser?.userId) {
          fetchAndSetSiteData();
        }
      });
      socket.on('reconnect', async () => {
        if (['waiter', 'barman'].includes(user?.role.type)) {
          authenticate();
        }
        if (user?.role.type === 'authenticated' && site) {
          authenticateUser(user.id, booking?.id, site.id);
        }
        if (localUser?.userId && site) {
          authenticateUser(localUser?.userId, booking?.id, site.id);
        }
      });
    }
  }, [setAlert, fetchCalls, user, authenticateUser, authenticate, socket, t, localUser,
    fetchOrders, fetchLocalOrders, orderFilters, fetchSpecialOffersApplicable, setSpecialOffers,
    fetchSiteData, setSite, fetchCategories, fetchSpecialOffers, refreshOrders,
    fetchBarmanList, fetchUserOrders, booking, site, refreshUserOrders]);

  // if user is logged in, authenticate socket
  useEffect(() => {
    if (['waiter', 'barman'].includes(user?.role?.type)) {
      authenticate();
    }
  }, [user, authenticate]);

  // if user is in his booking, bind bookingId with socket
  useEffect(() => {
    const orderIds = localOrders.map(({ id }) => id);

    authenticateUser(orderIds);
  }, [authenticateUser, localOrders]);

  return children;
};

SocketProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SocketProvider;
