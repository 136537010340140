import React, { useCallback, useEffect, useState } from 'react';
import {
  ScrollView, StyleSheet,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import CheckboxCardOption from 'orders/components/CheckboxCardOption/CheckboxCardOption';
import { PropTypes } from 'prop-types';
import Page from 'common/components/Page/Page';
import theme from 'styles';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import Button from 'common/components/Button/Button';
import useSite from 'sites/contexts/sites';
// eslint-disable-next-line import/extensions
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import getNextpage from 'common/utils/navigation/CleverNavigation';
import useOrder from 'orders/contexts/orders';
import useHourSlot from 'orders/pages/OrderSelectDeliveryTime/context/hour-slot';
import getWording from 'common/utils/getWording';
import useTrans from 'common/hooks/use-trans';

const OrderSelectDeliveryTime = ({ route }) => {
  const { t, lang } = useTranslation();
  const { translateText } = useTrans();

  const [selectedHour, setSelectedHour] = useState();
  const [tSelectedHour, setTSelectedHour] = useState();
  const [selected, setSelected] = useState(false);
  const navigation = useNavigation();
  const {
    item: site, isDataFetching, fetchSiteData,
  } = useSite();
  const { item: order, setItem: setOrder } = useOrder();

  // création d'un context pour pouvoir récup les données de l'API -> a voir si on garde
  const {
    items: hourSlots,
    fetchItems,
    setFilters,
    filters,
    isFetching: isHoursFetching,
  } = useHourSlot();

  useEffect(() => {
    setFilters({ site: site.id, active: true });
  }, [fetchSiteData, site, setFilters]);

  useEffect(() => {
    if (filters.site && filters.active) {
      fetchItems();
    }
  }, [fetchItems, filters]);

  useEffect(() => {
    setSelectedHour(order?.selectedHour);
    setTSelectedHour(order?.tSelectedHour);
  }, [order]);

  // TODO : Ce const ne peut pas remplacer le hourSlots tant que le site.hour_slots_models n'est pas bien populate
  // const hourModel = useMemo(() => (isDataFetching ? {} : site.hour_slot_models), [isDataFetching, site]);

  const consolidateAndContinue = useCallback(async (currentOrder) => {
    const { nextPage, newOrder } = await getNextpage({
      currentScreenName: route.name,
      currentOrder: { ...currentOrder, selectedHour, tSelectedHour },
      currentSite: site,
    });

    setOrder(newOrder);

    navigation.navigate(nextPage, { currentOrder: newOrder });
  }, [route.name, selectedHour, tSelectedHour, site, setOrder, navigation]);

  return (
    <Page backgroundImage={site?.template.imageBackdrop}>
      <LoadingSpinner
        visible={isDataFetching || isHoursFetching}
      />
      <Text
        style={styles.text}
        isBold
        centered
        customColor={site?.template?.backdropTextColor}
      >
        {getWording(
          hourSlots[0]?.tname,
          hourSlots[0]?.name,
          lang,
          translateText,
        )}
      </Text>
      <ScrollView style={[styles.innerView, { flex: 1, paddingBottom: 50 }]}>
        {!isHoursFetching && hourSlots[0]?.orderHours && hourSlots[0].orderHours.map((hour, index) => (
          <CheckboxCardOption
            key={`cb-${index}`}
            index={index}
            selected={selected}
            setSelected={setSelected}
            param={hour.Subtitle}
            paramTitle={hour.Subtitle}
            paramLegende={hour.Title}
            tParamTitle={hour.tSubtitle}
            tParamLegende={hour.tTitle}
            selectedParam={selectedHour}
            setSelectedParam={setSelectedHour}
            setTSelectedParam={setTSelectedHour}
            icon="clock-o"
          />
        ))}

        <FooterButtonWrapper relative>
          <Button
            variant="light"
            icon="arrow-right"
            text={t('orders.goNextStep')}
            disabled={!selectedHour}
            onPress={
            () => consolidateAndContinue(order)
          }
          />
        </FooterButtonWrapper>
      </ScrollView>
    </Page>
  );
};

const styles = StyleSheet.create({
  innerView: {
    paddingTop: theme.sizings.medium,
  },
  linearGradient: {
    width: '100%',
    height: '100%',
    paddingVertical: theme.sizings.medium,
    paddingHorizontal: theme.radius.large,
    borderRadius: theme.radius.medium,
  },
  text: {
    fontFamily: theme.fonts.bold,
    margin: theme.sizings.larger,
    fontSize: theme.fontSizes.medium,
  },
  button: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.sizings.medium,
    marginHorizontal: theme.sizings.large,
    borderRadius: theme.radius.medium,
  },
  checkboxWrapper: {
    marginRight: -20,
    marginTop: -20,
    marginBottom: -20,
  },
});

OrderSelectDeliveryTime.propTypes = {
  route: PropTypes.object.isRequired,
};

export default OrderSelectDeliveryTime;
