import Text from 'common/components/Text/Text';
import Button from 'common/components/Button/Button';
import DebouncedInput from 'common/components/DebouncedInput/DebouncedInput';
import Modal from 'common/components/Modal/Modal';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import {
  ScrollView, TouchableOpacity, View,
} from 'react-native';
import commonStyles from 'styles/commonStyles';

const Option = ({
  multiple, current, value, label, onPress,
}) => {
  const selected = useMemo(
    () => current && (multiple ? current.includes(value) : current === value),
    [current, multiple, value],
  );

  return (
    <TouchableOpacity onPress={() => onPress(value)}>
      <View
        style={
          {
            backgroundColor: selected ? 'rgba(0,0,0,0.2)' : 'transparent',
            padding: 10,
          }
        }
      >
        <Text size="medium">{label}</Text>
      </View>
    </TouchableOpacity>
  );
};

Option.propTypes = {
  multiple: PropTypes.bool.isRequired,
  current: PropTypes.any,
  value: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  onPress: PropTypes.func.isRequired,
};

Option.defaultProps = {
  current: null,
};

const Select = ({
  options, onChange, value, multiple, enableSearch, size, icon, isField, styles, ...rest
}) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [filter, setFilter] = useState('');

  const handleChange = useCallback((selected) => {
    if (multiple) {
      if (value.includes(selected)) {
        onChange(value.filter((val) => val !== selected));
      } else {
        onChange([...value, selected]);
      }
    } else {
      onChange(selected, options.find(({ value }) => value === selected));
      setModalVisible(false);
    }
  }, [onChange, options, value, multiple]);

  const current = multiple ? value || [] : value;

  let selectedText = '';

  if (multiple) {
    const labels = current.map((cval) => options.find(({ value: val }) => cval === val)?.label);

    selectedText = labels.join(', ');
  } else {
    selectedText = options.find(({ value: val }) => val === current)?.label;
  }

  return (
    <>
      {/* <TouchableOpacity onPress={() => { setModalVisible(true); }}>
        <View
          style={{
            backgroundColor: 'rgba(0,0,0,0.1)',
            padding: 10,
            borderRadius: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          {...rest}
        >
          <Text size={size}>{selectedText || t('common.select')}</Text>

          <FontAwesome
            size={theme.fontSizes.large}
            name="angle-down"
            color={theme.colors.greyDarkest}
          />
        </View>
      </TouchableOpacity> */}
      <Button
        variant="light"
        type="button"
        text={selectedText || t('common.select')}
        onPress={() => { setModalVisible(true); }}
        icon={icon}
        textSize={size}
        iconSize={size}
        style={[isField && {
          alignSelf: 'flex-start',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          paddingHorizontal: 12,
        }, { ...styles }]}
        {...rest}
      />
      <Modal
        style={commonStyles.modal}
        transparent
        visible={modalVisible}
        ariaHideApp={false}
        supportedOrientations={['portrait', 'landscape']}
      >
        <TouchableOpacity
          style={commonStyles.modalMask}
          onPress={() => { setModalVisible(false); }}
        >
          <View style={[commonStyles.modalBox, { maxHeight: 400 }]}>
            {enableSearch && (
              <DebouncedInput
                value={filter}
                onChange={setFilter}
              />
            )}
            <ScrollView>
              {options.filter(({ label }) => label.includes(filter)).map(({ value: val, label }, index) => (
                <Option
                  key={index}
                  value={val || ''}
                  label={label}
                  current={current}
                  multiple={multiple}
                  onPress={handleChange}
                />
              ))}
            </ScrollView>
            {multiple && (
              <View style={commonStyles.modalFooter}>
                <Button
                  variant="warning"
                  text={t('common.save')}
                  style={{ ...commonStyles.modalButton, flex: 1 }}
                  onPress={() => setModalVisible(false)}
                />
              </View>
            )}
          </View>

        </TouchableOpacity>
      </Modal>
    </>
  );
};

Select.propTypes = {
  value: PropTypes.any,
  multiple: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  enableSearch: PropTypes.bool,
  size: PropTypes.string,
  icon: PropTypes.string,
  isField: PropTypes.bool,
  styles: PropTypes.object,
};

Select.defaultProps = {
  value: '',
  multiple: false,
  onChange: () => { },
  enableSearch: false,
  size: 'medium',
  icon: '',
  isField: false,
  styles: {},
};

export default Select;
