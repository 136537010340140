import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Platform,
} from 'react-native';
import * as Notifications from 'expo-notifications';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import useLoading from 'common/contexts/loading';

const AppLoader = ({ children }) => {
  const { dataLoading } = useLoading();
  // Au premier cycle de ce composant le context Loading n'a pas encore été mis à jours,
  // initList sert à s'assurer qu'un deuxieme cycle à lieux avec le context mis à jour.
  // si un context utilise usePersistedState un clef est rentrée dans la liste et sera enlevée
  // à la fin de la récuperation de la donnée dans le AsyncStorage.
  const [initList, setInitList] = useState(false);

  useEffect(() => {
    setInitList(true);
    if (Platform.OS === 'android') {
      Notifications.dismissAllNotificationsAsync();
    }
  }, []);

  return (
    !initList || dataLoading.length ? (
      <LoadingSpinner
        visible
      />
    ) : <SafeAreaProvider>{ children }</SafeAreaProvider>
  );
};

AppLoader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]).isRequired,
};

export default AppLoader;
