import { useNavigation } from '@react-navigation/native';
import useAuth from 'auth/contexts/auth';
import useBooking from 'bookings/contexts/bookings';
import Button from 'common/components/Button/Button';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import FormInput from 'common/components/FormInput/FormInput';
import Page from 'common/components/Page/Page';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { ScrollView, Text, View } from 'react-native';
import useSite from 'sites/contexts/sites';
import commonStyles from 'styles/commonStyles';

const HourSelect = ({ route }) => {
  const { setAlert } = useAlert();
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigation = useNavigation();

  const { siteId } = route.params;

  const {
    save: saveBooking,
    update: updateBooking,
    item: booking,
    setItem: setBooking,
    checkCode,
  } = useBooking();

  const {
    item: site,
  } = useSite();

  const handleSubmit = (values) => {
    const { quick } = booking;

    const saveAndRedirect = async (postUser) => {
      if (quick) {
        // eslint-disable-next-line no-param-reassign
        postUser = {
          id: -1,
          name: 'Unknown user',
        };
      }
      if (!postUser) {
        return;
      }

      try {
        const payload = {
          ...booking,
          ...values,
          dates: booking.dates.map((date) => dayjs(date).utc().startOf('day').format()),
          active: true,
          valid: booking.hotel,
          user: postUser.id,
          clientName: postUser.username ? postUser.username : postUser.email,
        };

        let newBooking = null;

        if (booking?.id) {
          payload.payment = booking.payment.id;
          newBooking = await updateBooking(booking.id, payload);
        } else {
          payload.payment = {
            online: site?.canPayOnline,
            offline_method: 'CASH',
            transactionFees: site?.canPayOnline ? site.onlineTransactionFees : 0,
            status: 'PENDING',
            site: siteId,
          };
          newBooking = await saveBooking(payload);
        }

        setBooking(newBooking);

        if (quick && newBooking.code) {
          try {
            const codeReturn = await checkCode(newBooking.code);

            navigation.reset({
              index: 0,
              routes: [
                { name: 'home', params: {} },
                { name: 'SiteHome', params: { siteId: codeReturn.site.id, bookingId: codeReturn.id } },
              ],
            });
            return;
          } catch {
            setAlert({ color: 'error', title: t('common.error'), message: t('bookings.codeError') });
          }
        }

        // if there is no product flagged as complementary,
        // then there is no need to got to the complementary page selection
        /* if (site.complementaryProducts.length > 0 && booking.dates.length === 1) {
          navigation.navigate('ComplementaryProducts', {
            bookingId: newBooking.id,
            seatId: newBooking.seats[0].id,
            paymentId: newBooking.payment.id,
            siteId: site.id,
          });
        } else */
        if (site?.canPayOnline) {
          navigation.navigate('PaymentRecap', { paymentId: newBooking.payment.id });
        } else {
          navigation.reset({
            index: 0,
            routes: [{
              name: 'home',
            }, {
              name: 'ProfilePage',
            }],
          });
        }
      } catch (error) {
        setAlert({ color: 'error', title: t('common.error'), message: t('bookings.createError') });
      }
    };

    if (user || quick) {
      saveAndRedirect(user);
    } else {
      navigation.navigate('SignUpPage', { callback: saveAndRedirect });
    }
  };

  return (
    <Page padding>
      <Form
        onSubmit={handleSubmit}
        initialValues={{}}
        render={({ handleSubmit, values }) => (
          <>
            <ScrollView>
              <FormInput
                name="checkin"
                label={t('bookings.checkinTime')}
                type="time"
              />
              {site.canPayOnline === false && (
                <Text style={commonStyles.singleText}>{t('bookings.arrivalNotice')}</Text>
              )}
              <FormInput
                name="description"
                label={t('bookings.description')}
                type="text"
              />
              <View style={commonStyles.scrollViewWithButton} />

              <FooterButtonWrapper>
                <Button
                  variant="gradient"
                  icon="arrow-right"
                  text={t('orders.continue')}
                  disabled={values.checkin === ''}
                  onPress={handleSubmit}
                />
              </FooterButtonWrapper>
            </ScrollView>
          </>
        )}
      />
    </Page>
  );
};

HourSelect.propTypes = {
  route: PropTypes.object.isRequired,
};

export default HourSelect;
