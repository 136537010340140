import { useCallback } from 'react';

import useSite from 'sites/contexts/sites';
import useTranslation from 'common/contexts/translations';

const useTrans = () => {
  const { t, lang } = useTranslation();
  const { currentSite } = useSite();

  const translateText = useCallback((text) => {
    if (typeof text === 'string') {
      return text;
    }

    if (text) {
      let translation = '';

      if (text[lang]) {
        translation = text[lang];
      } else if (currentSite && text[currentSite.default_language]) {
        translation = text[currentSite.default_language];
      } else if (text['en-GB']) {
        translation = text['en-GB'];
      } else {
        return t('translation.missing');
      }
      return translation;
    }

    return t('translation.missing');
  }, [currentSite, lang, t]);

  return { translateText };
};

export default useTrans;
