import React from 'react';
import PropTypes from 'prop-types';
import {
  View, TouchableOpacity, StyleSheet,
} from 'react-native';
import { Form } from 'react-final-form';

import Modal from 'common/components/Modal/Modal';
import useTranslation from 'common/contexts/translations';
import FormInput from 'common/components/FormInput/FormInput';
import Button from 'common/components/Button/Button';
import Card from 'common/components/Card/Card';
import Text from 'common/components/Text/Text';
import theme from 'styles';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
  },

  cardContainer: {
    padding: theme.sizings.medium,
  },
});

const BookingCodeModal = ({
  setModalVisible, modalVisible, handleCodeSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      transparent
      visible={modalVisible}
      ariaHideApp={false}
    >
      <View style={styles.wrapper}>
        <TouchableOpacity
          data-cy="cypress_code"
          onPress={() => setModalVisible(false)}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(52, 52, 52, 0.8)',
          }}
        />

        <View style={styles.cardContainer}>
          <Card noShadow>
            <Text size="larger" centered isBold>
              {t('beach.enterTicket')}
            </Text>
            <Form
              onSubmit={handleCodeSubmit}
              initialValues={{ code: '' }}
              render={({ handleSubmit, values }) => (
                <>
                  <FormInput type="code" name="code" />
                  <Button
                    disabled={values?.code?.length === 0}
                    onPress={handleSubmit}
                    variant="dark"
                    text={t('common.submit')}
                  />
                </>
              )}
            />
          </Card>
        </View>
      </View>
    </Modal>
  );
};

BookingCodeModal.propTypes = {
  setModalVisible: PropTypes.func.isRequired,
  handleCodeSubmit: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool,
};

BookingCodeModal.defaultProps = {
  modalVisible: false,
};

export default BookingCodeModal;
