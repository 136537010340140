import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useState, useCallback,
} from 'react';
import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';

const SpecialOfferContext = createContext();

export const SpecialOffersProvider = ({ children }) => {
  const [isApplicableFetching, setIsApplicableFetching] = useState(false);

  const fetchSpecialOffersApplicable = useCallback(async (payload) => {
    setIsApplicableFetching(true);
    const esc = encodeURIComponent;
    const queryParams = Object.keys(payload).map((key) => `${esc(key)}=${esc(payload[key])}`).join('&');

    try {
      const res = await fetchJSON({ url: `special-offers/applicable?${queryParams}`, method: 'GET' });

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    } finally {
      setIsApplicableFetching(false);
    }
  }, []);

  return (
    <ContextProvider
      url="special-offers"
      context={SpecialOfferContext}
      value={{ fetchSpecialOffersApplicable, isApplicableFetching }}
    >
      {children}
    </ContextProvider>
  );
};

SpecialOffersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useSpecialOffers = () => useContext(SpecialOfferContext);

export default useSpecialOffers;
