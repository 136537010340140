import React, { useState, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import useProducts from 'products/contexts/products';
import PropTypes from 'prop-types';

import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import { NativeSwitch as Switch } from 'common/components/Switch/Switch';
import theme from 'styles';

const styles = StyleSheet.create({
  orderItem: {
    padding: theme.sizings.small,
    paddingTop: 12,
    marginTop: theme.sizings.small,
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.greyLightest,
    shadowColor: theme.colors.greyLighter,
    shadowOffset: { height: 1, width: 0 },
    shadowRadius: 2,
    shadowOpacity: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  alignLeft: {
    flexShrink: 1,
  },
});

const ProductItem = ({ product }) => {
  const [loading, setLoading] = useState(false);
  const { update } = useProducts();
  const handleToggleActive = useCallback(async () => {
    setLoading(true);
    try {
      await update(product.id, { ...product, active: !product.active });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [update, product]);

  return (
    <View style={styles.orderItem}>
      <TranslatedText style={styles.alignLeft} isBold size="medium" value={product.name} />
      <Switch
        loading={loading}
        activeColor={theme.colors.secondary}
        onChange={handleToggleActive}
        value={product.active}
      />
    </View>
  );
};

ProductItem.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductItem;
