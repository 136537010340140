import React, {
  useCallback,
  useEffect,
  useMemo, useState,
} from 'react';
import {
  StyleSheet, View, ScrollView, TouchableOpacity, useWindowDimensions,
} from 'react-native';
import useAuth from 'auth/contexts/auth';
import useScreenOrientation from 'auth/hooks/use-screen-orientation';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import OrderListItem from 'orders/components/OrderListItem/OrderListItem';
import useOrders from 'orders/contexts/orders';
import useHistoryOrders from 'orders/contexts/historyOrders';
import useProduct from 'products/contexts/products';
import theme from 'styles';
import Gradient from 'common/components/Gradient/Gradient';
import { useRoute } from '@react-navigation/native';
import useSite from 'sites/contexts/sites';

const ordersStateSortByRole = {
  barman: [
    {
      categoryName: 'stateCreated',
      states: ['created', 'at_arrival'],
    },
    {
      categoryName: 'stateInPreparation',
      states: ['in_preparation'],
    },
    {
      categoryName: 'categoryReady',
      states: ['ready'],
    },
  ],
  waiter: [
    {
      categoryName: 'toCome',
      states: ['created', 'at_arrival', 'in_preparation'],
    },
    {
      categoryName: 'toFetch',
      states: ['ready'],
    },
    {
      categoryName: 'stateInDelivery',
      states: ['in_delivery'],
    },
  ],
  history: [
    {
      categoryName: 'categoryServed',
      states: ['done'],
    },
  ],
};

const OrdersList = () => {
  const route = useRoute();
  const { isLandscape } = useScreenOrientation();

  const { t } = useTranslation();
  const { user } = useAuth();
  const { items: products } = useProduct();
  const {
    items: orders,
    isFetching,
    barmanList,
  } = useOrders();
  const {
    items: historyOrders,
    isFetching: isHistoryOrdersFetching,
  } = useHistoryOrders();
  const { item: currentSite } = useSite();

  const [showMyArea/* , setShowMyArea */] = useState(true);
  const [visibleOrderCategory, setVisibleOrderCategory] = useState();

  const { height } = useWindowDimensions();

  const viewRole = useMemo(() => route.name, [route.name]);/*
  const areaIds = useMemo(() => user && user.areas ? user.areas.map(({ id }) => id) : [], [user]); */
  const barmanListWithNone = useMemo(() => {
    const result = [
      ...barmanList
        .filter((barman) => {
          const result = barman.preparatorAreas?.find((bPA) => {
            const result = user.role.name !== 'barman'
            || user.preparatorAreas
              .filter((uPA) => uPA.site === bPA.site)
              .map((uPA) => uPA.id)
              .includes(bPA.id);

            return result;
          });

          return result;
        }),
      { value: null, label: t('common.none'), color: 'lightgrey' },
    ];

    return result;
  },
  [barmanList, t, user]);

  useEffect(() => {
    setVisibleOrderCategory(ordersStateSortByRole[route.name][0].categoryName);
  }, [route.name]);

  const filteredOrders = useMemo(() => {
    const activeOrders = orders;

    const ordersByState = activeOrders.reduce((acc, value) => {
      if (!acc[value.state]) {
        acc[value.state] = [];
      }

      // if (viewRole !== 'waiter' || value.deliveryMethod !== 'takeaway') {
      if (viewRole !== 'waiter') {
        if (!showMyArea
          || user.areas?.map((area) => area?.id).includes(value.waiterAreas?.id)
          || user.preparatorAreas?.map((area) => area?.id).includes(value.preparatorAreas?.id)) {
          acc[value.state].push(value);
        }
      }
      return acc;
    }, {});

    return ordersByState;
  }, [orders, showMyArea, user, viewRole]);

  const filteredHistoryOrders = useMemo(() => (
    showMyArea
      ? (historyOrders?.filter((order) => (
        user.areas?.map((area) => area?.id).includes(order.waiterAreas?.id)
        || user.preparatorAreas?.map((area) => area?.id).includes(order.preparatorAreas?.id))))
      : historyOrders
  ), [historyOrders, showMyArea, user]);

  const renderOrders = useCallback((orders, categoryName) => {
    const orderItems = orders.map((order) => (
      <OrderListItem
        style={styles.orderFull}
        key={order.id}
        products={products}
        barmanList={barmanListWithNone}
        {...order}
        viewRole={viewRole}
      />
    ));

    return (
      <View
        style={[isLandscape ? styles.orderColumnLandscape : styles.orderColumn, isLandscape && {
          height: height - 64,
        }]}
        key={categoryName}
      >

        {isLandscape && categoryName && (
          <View style={styles.orderColumnHead}>
            <Text centered>
              {t(`orders.${categoryName}${currentSite?.pleaseMyCar
                ? currentSite?.pleaseMyCarDriver ? 'Driver' : 'Valet' : null}`)}
              {` (${orders.length})`}
            </Text>
          </View>
        )}

        <View style={isLandscape ? styles.orderColumnMainLandscape : styles.orderColumnMain}>
          {orderItems}
        </View>

      </View>
    );
  }, [isLandscape, t, products, barmanListWithNone, viewRole, height, currentSite]);

  return (
    <Page>
      {!isLandscape && (
        <>
          <Gradient
            height={theme.sizings.larger + theme.sizings.small}
            colors={currentSite?.pleaseMyCar ? [theme.colors.pmcBlueDark, theme.colors.pmcBlueDark]
              : [theme.colors.darkGrey, theme.colors.dark]}
          />
          <View style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-end',
            overflowX: 'auto',
            height: theme.sizings.larger + theme.sizings.small,
            paddingHorizontal: theme.sizings.tiny,
          }}
          >
            {ordersStateSortByRole[viewRole].map((rcat, index) => {
              const current = rcat.categoryName === visibleOrderCategory;

              let orders = [];

              rcat.states.forEach((state) => {
                if (filteredOrders[state]) {
                  orders = orders.concat(filteredOrders[state]);
                }
              });

              return (
                <TouchableOpacity
                  key={index}
                  style={{
                    // borderBottomWidth: current ? 4 : 0,
                    // borderBottomColor: currentSite?.pleaseMyCar ? theme.colors.pmcBlueLight : theme.colors.light,
                    backgroundColor: currentSite?.pleaseMyCar && current ? theme.colors.pmcBlueLight : 'transparent',
                    paddingTop: theme.sizings.small,
                    paddingHorizontal: theme.sizings.medium,
                    height: theme.sizings.larger,

                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onPress={() => { setVisibleOrderCategory(rcat.categoryName); }}
                >
                  <Text
                    isBold={current}
                    customColor={theme.colors.light}
                    size="medium"
                  >
                    {`${t(`orders.${rcat.categoryName}${currentSite?.pleaseMyCar
                      ? currentSite?.pleaseMyCarDriver ? 'Driver' : 'Valet' : null}`)} (${viewRole === 'history'
                      ? filteredHistoryOrders.length : orders.length})`}

                  </Text>
                </TouchableOpacity>
              );
            })}
          </View>
        </>
      )}
      <LoadingSpinner
        visible={isFetching || (route.name === 'history' && isHistoryOrdersFetching)}
      />
      <ScrollView>
        {/* <FlexRow>
          <Button
            type="button"
            text="MyZoneOnly"
            onPress={() => { setShowMyArea(!showMyArea); }}
            textSize="small"
            variant={showMyArea ? 'light' : 'dark'}
          />
        </FlexRow> */}
        <View style={isLandscape ? styles.orderListLandscape : styles.orderList}>
          {
            Object.keys(filteredOrders).length > 0 ? ordersStateSortByRole[viewRole].map((orderState) => {
              let orders = [];

              if (viewRole === 'history') {
                orders = filteredHistoryOrders;
              } else {
                orderState.states.forEach((state) => {
                  if (filteredOrders[state]) {
                    orders = orders.concat(filteredOrders[state]);
                  }
                });
              }
              if (isLandscape || orderState.categoryName === visibleOrderCategory) {
                return renderOrders(orders, orderState?.categoryName);
              }
              return null;
            })
              : !isFetching && !isHistoryOrdersFetching && (<Text isBold size="larger">{t('orders.noOrders')}</Text>)
          }
        </View>
      </ScrollView>
    </Page>
  );
};

const styles = StyleSheet.create({
  statusView: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'space-around',
    width: '100%',
    backgroundColor: theme.colors.darkGrey,
  },

  titleArea: {
    marginBottom: theme.sizings.small,
  },

  orderFull: {
    width: '100%',
  },

  orderList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.sizings.small,
    backgroundColor: theme.colors.light,
  },
  orderListLandscape: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    backgroundColor: theme.colors.greyLighter,
  },
  orderColumnLandscape: {
    flex: 1,
    backgroundColor: theme.colors.light,
    borderRadius: theme.sizings.small,
    overflowY: 'auto',
    margin: theme.sizings.small,
  },
  orderColumn: {
  },
  orderColumnHead: {
    borderBottomColor: theme.colors.greyAlpha,
    borderBottomWidth: 1,
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colors.light,
    zIndex: 2,
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  orderColumnMainLandscape: {
    padding: theme.sizings.tiny,
    position: 'relative',
    zIndex: 1,
  },
  orderColumnMain: {
  },
});

export default OrdersList;
