import AsyncStorage from '@react-native-async-storage/async-storage';
import { getApiUrl } from './createUrl';

export const fetchJSON = async ({ url, method, payload }) => {
  const apiUrl = getApiUrl();
  const token = await AsyncStorage.getItem('jwt');
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  const res = await fetch(
    `${apiUrl}/${url}`,
    {
      method,
      headers,
      body: JSON.stringify(payload),
    },
  );

  if (!res.ok) {
    return null;
  }

  return res.json();
};

export default fetchJSON;
