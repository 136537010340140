import { StyleSheet } from 'react-native';
import theme from 'styles';

export default StyleSheet.create({

  viewMaster: {
    flexDirection: 'column',
    backgroundColor: theme.colors.light,
    marginBottom: theme.sizings.medium,
    borderRadius: theme.radius.medium
  },

  viewOrderHeader: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: theme.sizings.small,
    paddingRight: theme.sizings.small,
    borderRadius: theme.radius.medium,
    alignItems: 'center',
    justifyContent: 'space-around',
    alignSelf: 'center',
  },

  viewOrderHeaderSection: {
    flex: 0.4,
  },

  viewTimeCmdContainer: {
    flex: 0.4,
    flexDirection: 'row',
    alignItems: 'center',
  },

  viewSeparatorContainer: {
    alignItems: 'center',
    flex: 0.1,
  },

  viewSeparator: {
    height: 50,
    width: 1,
    backgroundColor: 'rgba(0,0,0,0.1)',
  },

  scrollDetail: {
    flex: 1,
  },

  viewDetailContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },

  viewLeftDetailContainer: {
    flex: 0.6,
  },

  viewRightDetailContainer: {
    flexDirection: 'row',
    flex: 0.4,
    justifyContent: 'flex-end',
  },

  textQuantity: {
    borderRadius: theme.radius.large,
    paddingHorizontal: theme.sizings.small,
    justifyContent: 'center',
  },

  price: {
    flex: 0.9,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },

  btnReOrder: {
    alignSelf: 'center',
    borderRadius: theme.radius.rounded,
    margin: theme.sizings.small,
    height: 35
  },

  gradientBtnBackground: {
    borderRadius: theme.radius.rounded,
    paddingHorizontal: 20,
    height: 40,
    lineHeight: 40,
    justifyContent: 'center',
    marginBottom: theme.sizings.small,
  },

  textReorderBtn: {
    alignSelf: 'center',
    color: 'white',
    fontSize: theme.sizings.medium,
    fontFamily: theme.fonts.bold,
  },

});
