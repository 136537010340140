import useBooking from 'bookings/contexts/bookings';
import ConfirmButton from 'common/components/ConfirmButton/ConfirmButton';
import useTranslation from 'common/contexts/translations';
import formatPrice from 'common/utils/formatPrice';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import useSite from 'sites/contexts/sites';
import commonStyles from 'styles/commonStyles';
import Order from 'waiter/components/BookingPayments/Order';

const BookingPayments = ({ bookingId: id }) => {
  const { t } = useTranslation();
  const { items: bookings, fetchItems: fetchBookings, payAll } = useBooking();
  const { item: site } = useSite();

  const booking = useMemo(() => bookings.find((book) => book.id === id), [bookings, id]);
  const orders = useMemo(() => booking?.orders.filter((order) => order.active), [booking]);
  const bookingPrice = useMemo(() => booking?.payment?.status !== 'PAID' && !booking?.payment?.online
    ? booking?.payment?.amount
    : 0, [booking]);

  const total = useMemo(() => {
    const t = Math.round(100 * booking?.orders.reduce(
      (acc, { payment: { status, online, amount } = {} }) => status !== 'PAID' && !online
        ? acc + amount
        : acc, bookingPrice,
    )) / 100;

    return t;
  }, [booking, bookingPrice]);

  const handlePayAll = useCallback(async () => {
    const response = await payAll(booking?.id);

    if (!response.error) {
      await fetchBookings();
    }
  }, [booking, fetchBookings, payAll]);

  return (
    <>
      <View style={styles.ordersPaymentView}>
        {orders.sort((order) => order.state === 'done' && order.payment.status === 'PAID' ? 1 : -1)
          .map((order) => <Order key={order.id} booking={booking} order={order} />)}
      </View>
      <View style={commonStyles.modalFooter}>
        {!!total && (
          <ConfirmButton
            confirmText="Hello"
            variant="warning"
            text={t('basket.pay', { total: formatPrice(total, site.currency) })}
            style={{ ...commonStyles.modalButton, flex: 1 }}
            onPress={handlePayAll}
          />
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  ordersPaymentView: {
    justifyContent: 'center',
  },
});

BookingPayments.propTypes = {
  bookingId: PropTypes.number.isRequired,
};

export default BookingPayments;
