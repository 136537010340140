import Card from 'common/components/Card/Card';
import { Image } from 'common/components/Images';
import PropTypes from 'prop-types';
import React from 'react';
import {
  View,
} from 'react-native';
import theme from 'styles';
import styles from './ItemCardProduct.styles';

const ItemCardProduct = ({
  children, image, layout, onPress,
}) => (
  <Card
    isClickable
    onPress={onPress}
    noPadding
    noShadow
    style={[styles.card, {
      flexDirection: layout === 'grid' ? 'column' : 'row',
      flexFlow: layout === 'grid' ? 'inherit' : 'wrap',
    },
    ]}
  >
    {image && (
      <View style={[styles.imageWrapper, {
        width: layout === 'grid' ? '100%' : 110,
        height: layout === 'grid' ? 160 : 110,
        borderRadius: layout === 'grid' ? 0 : theme.sizings.small,
        margin: layout === 'grid' ? 0 : theme.sizings.medium,
      }]}
      >
        <Image
          style={styles.image}
          image={image}
        />
      </View>
    )}

    <View style={[styles.content, {
      flex: 1,
      paddingHorizontal: layout === 'grid' ? 0 : theme.sizings.medium,
      paddingTop: layout === 'grid' ? 0 : theme.sizings.medium,
    }]}
    >
      {children}

    </View>
  </Card>
);

ItemCardProduct.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.any,
  layout: PropTypes.string,
  onPress: PropTypes.func.isRequired,
};

ItemCardProduct.defaultProps = {
  image: null,
  layout: 'grid',
};

export default ItemCardProduct;
