import React, { useEffect } from 'react';
import useTranslation from 'common/contexts/translations';
import {
  View, Platform,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useKeepAwake } from 'expo-keep-awake';
import useAuth from 'auth/contexts/auth';

import BadgeController from 'common/components/BadgeController/BadgeController';
import OrderList from 'orders/pages/OrderList/OrderList';
import useOrders from 'orders/contexts/orders';
import useHistoryOrders from 'orders/contexts/historyOrders';
import useProduct from 'products/contexts/products';
import useSite from 'sites/contexts/sites';
import { createStackNavigator } from '@react-navigation/stack';
import theme from './styles';

const PmcRouter = () => {
  const { t } = useTranslation();
  const PmcStack = createStackNavigator();
  const { user } = useAuth();

  const { fetchItems: fetchProducts } = useProduct();
  const {
    handleActiveOrdersFilters: setActiveOrdersFilters,
    activeOrdersFilters,
    refreshOrders,
    fetchBarmanList,
    setSort: setOrdersSort,
  } = useOrders();
  const {
    handleActiveOrdersFilters: setActiveHistoryOrdersFilters,
    activeOrdersFilters: activeHistoryOrdersFilters,
    refreshOrders: refreshHistoryOrders,
    setSort: setHistoryOrdersSort,
  } = useHistoryOrders();

  useKeepAwake();

  useEffect(() => {
    setActiveHistoryOrdersFilters('state_ne', 'done');
    setHistoryOrdersSort('created_at:DESC');
  }, [setActiveHistoryOrdersFilters, setHistoryOrdersSort]);

  useEffect(() => {
    setActiveOrdersFilters('state_eq', 'done');
    setOrdersSort('created_at:ASC');
  }, [setActiveOrdersFilters, setOrdersSort]);

  useEffect(() => {
    refreshOrders();
    // console.log('orders fetching');
  }, [activeOrdersFilters, refreshOrders]);

  useEffect(() => {
    refreshHistoryOrders();
    // console.log('history orders fetching');
  }, [activeHistoryOrdersFilters, refreshHistoryOrders]);

  useEffect(() => {
    fetchProducts();
    fetchBarmanList();
  }, [fetchProducts, fetchBarmanList]);

  const routeLabels = {
    // maps: t('tabs.maps'),
    // bookings: t('waiter.bookings'),
    orders: t('tabs.orders'),
    // calls: t('tabs.calls'),
    stock: t('waiter.stock'),
    // offers: t('waiter.offers'),
  };

  const routeIcons = {
    orders: 'ios-basket',
    // calls: 'ios-call',
    stock: 'ios-list',
    // offers: 'ios-gift',
    // maps: 'ios-map',
    // bookings: 'ios-book',
  };

  return (
    ['waiter', 'barman', 'manager'].includes(user?.role.type)
      ? (
        <PmcStack.Navigator
          screenOptions={({ route }) => ({
            // eslint-disable-next-line react/prop-types
            tabBarIcon: ({ color, size }) => (
              <View>
                <BadgeController name={route.name} />
                <Ionicons name={routeIcons[route.name]} size={size} color={color} data-cy="cypress_plus_icon" />
              </View>
            ),
            tabBarLabel: routeLabels[route.name],
          })}
          lazy={false}
          tabBarOptions={{
            style: {
              backgroundColor: theme.colors.dark,
              paddingTop: theme.sizings.small,
              // model perfect height for IOS
              height: Platform.OS === 'ios' ? 92 : 60,
            },
            labelStyle: {
              fontSize: theme.fontSizes.default,
              fontFamily: 'AvenirBold',
              paddingTop: Platform.OS === 'ios' ? theme.sizings.tiny : 0,
            },
            activeTintColor: theme.colors.secondaryLight,
            inactiveTintColor: theme.colors.greyDarker,
          }}
          initialRouteName={user?.role.type === 'waiter' ? 'waiter' : 'barman'}
        >
          <PmcStack.Screen
            name="barman"
            component={OrderList}
            options={{
              title: t('route.waiterHome'),
              headerShown: false,
            }}
          />
        </PmcStack.Navigator>
      )
      : null
  );
};

export default PmcRouter;
