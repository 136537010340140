import React, { useMemo, useCallback } from 'react';
import { View, Dimensions } from 'react-native';
import PropTypes from 'prop-types';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import useBasket from 'basket/contexts/basket';
import Button from 'common/components/Button/Button';
import Gradient from 'common/components/Gradient/Gradient';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import { ImageBackground } from 'common/components/Images';
import ReductionPrice from 'common/components/ReductionPrice/ReductionPrice';
import commonStyles from 'styles/commonStyles';
import theme from 'styles';
import useSite from 'sites/contexts/sites';
import styles from './BasketItem.styles';

const BasketItem = ({
  entity, entityType, number, editable, price, reduction = null, selectedOptions, isFree,
}) => {
  const { addItem, removeItem } = useBasket();
  const { item: site } = useSite();
  const isTablet = Dimensions.get('window').width > 767 && Dimensions.get('window').height > 767;

  const {
    optionView: optionViewStyle,
    itemView: itemViewStyle,
    productImage: productImageStyle,
    productContent: productContentStyle,
    productLabel: productLabelStyle,
    variant: variantStyle,
    buttonsGroup: buttonsGroupStyle,
    productButton: productButtonStyle,
    quantity: quantityStyle,
  } = useMemo(() => styles(editable, isTablet), [editable, isTablet]);

  const singleOptions = useMemo(() => selectedOptions.filter((selected) => {
    // quick fix because on update entity.options is not populate but this screen is tmp
    if (!entity.options) {
      return false;
    }
    const optId = (typeof selected.option === 'object') ? selected.option.id : selected.option;
    const o = entity.options.find(({ option }) => option.id === optId);

    return !o.option.multiple;
  }), [selectedOptions, entity]);

  const getVariantName = useCallback((selectedOption) => {
    // quick fix because on update entity.options is not populate but this screen is tmp
    if (!entity.options) {
      return null;
    }
    const optId = (typeof selectedOption.option === 'object') ? selectedOption.option.id : selectedOption.option;
    const o = entity.options.find(({ option }) => option.id === optId);
    const variant = o.variant_prices.find((vp) => vp.option_variant.id === selectedOption.variants[0]);

    if (variant) {
      return variant.option_variant.name;
    }
  }, [entity]);

  const getVariantPrice = useCallback((selectedOption) => {
    // quick fix because on update entity.options is not populate but this screen is tmp
    if (!entity.options) {
      return null;
    }
    const optId = (typeof selectedOption.option === 'object') ? selectedOption.option.id : selectedOption.option;
    const o = entity.options.find(({ option }) => option.id === optId);
    const variant = o.variant_prices.find((vp) => vp.option_variant.id === selectedOption.variants[0]);

    if (variant) {
      return variant.price;
    }
  }, [entity]);

  return (
    <View>
      <View style={[itemViewStyle, commonStyles.shadowBottom]}>
        <ImageBackground style={productImageStyle} image={entity?.images?.[0]} />
        <View style={productContentStyle}>
          <View style={productLabelStyle}>
            {isFree && (
            <FontAwesome5
              name="gift"
              color={site?.template?.cardTextColor || theme.colors.dark}
              size={theme.fontSizes.medium}
              style={{
                marginRight: theme.sizings.small,
              }}
            />
            )}
            <TranslatedText isBold value={entity.name} />
            {singleOptions && singleOptions.length > 0 && singleOptions.map((o) => (
              <View key={o.option} style={{ marginLeft: theme.sizings.small }}>
                <TranslatedText value={getVariantName(o)} />
              </View>
            ))}
          </View>

          {(!site?.pleaseMyCar || (site?.pleaseMyCar && number > 1)) && (
          <View style={buttonsGroupStyle}>
            <Gradient height={isTablet ? 60 : 40} />
            {editable && (
              <Button
                noPadding
                keepFont
                icon="minus"
                variant="transparent"
                iconSize="default"
                style={{ paddingHorizontal: theme.sizings.small }}
                onPress={() => removeItem(entity, selectedOptions, entityType)}
              />
            )}
            <Text size="large" color="light" style={quantityStyle} isTitleText>{number}</Text>
            {editable && (
              <Button
                noPadding
                keepFont
                icon="plus"
                variant="transparent"
                iconSize="default"
                style={{ paddingHorizontal: theme.sizings.small }}
                onPress={() => addItem(entity, selectedOptions, price, entityType)}
              />
            )}
          </View>
          )}
        </View>

        <ReductionPrice
          price={price}
          reduction={reduction}
          reductionPriceColor={site?.template?.cardTextColor || theme.colors.dark}
        />
      </View>

      <View style={[optionViewStyle, commonStyles.shadowBottom]}>
        {selectedOptions && selectedOptions.length > 0 && (
          selectedOptions.map((option) => (
            <View key={option.option} style={[variantStyle, { flexDirection: 'row', alignItems: 'center' }]}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <FontAwesome name="plus" color={theme.colors.dark} />
                <Text>&nbsp;</Text>
                <TranslatedText value={getVariantName(option)} />
              </View>
            </View>
          ))
        )}
        {/* reduction && (
          <View style={variantStyle}>
            <Text>Discount</Text>
            <Text isBold size="large">
              { formatPrice(Math.round(price * (reduction.discount_rate)) / 100, site.currency) }
            </Text>
          </View>
        ) */}
      </View>

    </View>
  );
};

BasketItem.path = 'products';

BasketItem.propTypes = {
  entity: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
  number: PropTypes.number,
  editable: PropTypes.bool,
  price: PropTypes.number,
  reduction: PropTypes.object,
  selectedOptions: PropTypes.array,
  isFree: PropTypes.bool,
};

BasketItem.defaultProps = {
  number: 1,
  editable: false,
  price: 0,
  reduction: null,
  selectedOptions: [],
  isFree: false,
};

export default BasketItem;
