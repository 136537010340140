import React from 'react';
import { StyleSheet } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import PropTypes from 'prop-types';

import theme from 'styles';

const setStyleColor = (color) => {
  switch (color) {
    case 'red':
      return [theme.colors.dangerBright, theme.colors.dangerLight];
    case 'yellow':
      return styles.overlayYellow;
    case 'green':
      return styles.overlayGreen;
    case 'blue':
      return [theme.colors.primary, theme.colors.primaryBright];
    default:
      return ([{}]);
  }
};

const Overlay = ({ color }) => (
  <LinearGradient
    colors={setStyleColor(color)}
    start={[0.5, 1]}
    end={[0.5, 0]}
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: 0,
      opacity: 0.5,
    }}
  />
);

Overlay.propTypes = {
  color: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  squareOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 1,
    opacity: 0.3,
  },
  overlayYellow: {
    backgroundColor: 'yellow',
  },
  overlayGreen: {
    backgroundColor: 'green',
  },
  overlayBlue: {
    backgroundColor: 'blue',
  },
});

export default Overlay;
