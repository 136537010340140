import React from 'react';
import Text from 'common/components/Text/Text';
import useSite from 'sites/contexts/sites';
import PropTypes from 'prop-types';

const TemplateHeaderTitle = ({ title, onDarkBackground }) => {
  const { item: site } = useSite();
  const template = site?.template;

  return (
    <Text
      adjustsFontSizeToFit
      isBold
      isTitleText
      size="larger"
      color="light"
      customColor={!onDarkBackground ? template?.primaryTextColor : ''}
    >
      {title || site?.name}
    </Text>
  );
};

export default TemplateHeaderTitle;

TemplateHeaderTitle.propTypes = {
  title: PropTypes.string,
  onDarkBackground: PropTypes.bool,
};

TemplateHeaderTitle.defaultProps = {
  title: null,
  onDarkBackground: false,
};
