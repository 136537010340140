import { useEffect, useState } from 'react';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useWindowDimensions } from 'react-native';
import { isTabletMode } from './deviceMode';

const useScreenOrientation = () => {
  const [isLandscape, setIsLandscape] = useState(false);
  const { width, height } = useWindowDimensions();
  const isTablet = isTabletMode({ width, height });

  useEffect(() => {
    const orientationChangeListener = ScreenOrientation.addOrientationChangeListener((event) => {
      const screenOrientation = event.orientationInfo.orientation;

      const isHorizontal = screenOrientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT
      || screenOrientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT;

      setIsLandscape(isTablet && isHorizontal);
    });

    return () => {
      ScreenOrientation.removeOrientationChangeListener(orientationChangeListener);
    };
  }, [isTablet]);

  useEffect(() => {
    (async () => {
      const screenOrientation = await ScreenOrientation.getOrientationAsync();

      const isHorizontal = screenOrientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT
      || screenOrientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT;

      setIsLandscape(isTablet && isHorizontal);
    })();
  }, [isTablet]);

  return { isLandscape };
};

export default useScreenOrientation;
