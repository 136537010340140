import React, {
  createContext, useContext, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import useAlert from 'common/contexts/alert';
import { useTranslation } from 'react-i18next';

export const SeatSelectorContext = createContext();

export const SeatSelectorProvider = ({ children }) => {
  const { setAlert } = useAlert();
  const { t } = useTranslation();
  const [itemDescription, setItemDescription] = useState({});
  const [items, setItems] = useState([]);
  const [selection, setSelection] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState(null);

  const addSelectedtoSelection = useCallback((oldMax = 0) => {
    const newItems = [...items];
    const item = { price: selectedSeat.price, ...newItems.find(({ id }) => selectedSeat.id === id) };

    if (selection.length < 3
      && (oldMax === 0
        || (oldMax !== 0 && selection.reduce((a, b) => a + (b.price || 0), 0) + selectedSeat.price <= oldMax))
    ) {
      item.selected = true;
      setSelection([...selection, item]);
      setItems(newItems);
    } else if ((oldMax !== 0 && selection.reduce((a, b) => a + (b.price || 0), 0) + selectedSeat.price > oldMax)) {
      setAlert({ color: 'error', title: t('common.error'), message: t('bookings.seatsToExpensive') });
    } else {
      setAlert({ color: 'error', title: t('common.error'), message: t('bookings.seatLimit') });
    }

    setSelectedSeat(null);
  }, [items, setItems, selection, t, setAlert, selectedSeat]);

  const handleSeatClick = useCallback((sitem) => {
    const newItems = [...items];
    const item = { price: sitem.price, ...newItems.find(({ id }) => sitem.id === id) };

    if (item.selected) {
      item.selected = !item.selected;
      setSelection(selection.filter(((seat) => seat.id !== sitem.id)));
      setItems(newItems);
    } else {
      setSelectedSeat(item);
    }
  }, [selection, items]);

  return (
    <SeatSelectorContext.Provider
      value={{
        setItemDescription,
        itemDescription,
        items,
        setItems,
        selection,
        setSelection,
        addSelectedtoSelection,
        setSelectedSeat,
        handleSeatClick,
        selectedSeat,
      }}
    >
      {children}
    </SeatSelectorContext.Provider>
  );
};

SeatSelectorProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default () => useContext(SeatSelectorContext);
