import { useEffect } from 'react';
import { AppState, Platform } from 'react-native';
import * as Updates from 'expo-updates';

import useAuth from 'auth/contexts/auth';
import useOrders from 'orders/contexts/orders';
import useSpecialOffers from 'specialOffers/contexts/specialOffers';
import useCategories from 'categories/contexts/categories';
// import useBooking from 'bookings/contexts/bookings';

import useHistoryOrders from 'orders/contexts/historyOrders';
import useSite from 'sites/contexts/sites';

const AppStateHandler = ({ children }) => {
  const {
    user, refreshUser, // booking, setBooking,
  } = useAuth();
  const {
    item: site,
  } = useSite();
  const {
    fetchItems: fetchOrders,
    refreshOrders,
    fetchBarmanList,
  } = useOrders();
  const {
    refreshOrders: refreshHistoryOrders,
  } = useHistoryOrders();
  const {
    setItems: setSpecialOffers,
    fetchItems: fetchSpecialOffers,
    fetchSpecialOffersApplicable,
  } = useSpecialOffers();
  const {
    fetchItems: fetchCategories,
  } = useCategories();

  useEffect(() => {
    const handleAppStateChange = async (nextAppState) => {
      if (!__DEV__ && Platform.OS !== 'web' && nextAppState === 'active') {
        try {
          const update = await Updates.checkForUpdateAsync();

          if (update.isAvailable) {
            await Updates.fetchUpdateAsync();
            await Updates.reloadAsync();
          }
        } catch (e) {
          console.log(e);
        }
      }

      if (nextAppState === 'active') {
        if (user?.role && ['waiter', 'barman'].includes(user.role.type)) {
          fetchBarmanList();
          // fetchCalls();
          refreshOrders();
          refreshUser();
          fetchCategories();
          fetchSpecialOffers();
          // Force refresh on bookings + refresh currentDate
          // setBookingFilters({
          //   active: true,
          //   date: dayjs().utc().startOf('day').format(),
          // });
        } else if (user) {
          // user
          // if (ordersFilters.booking) {
          fetchOrders();
          refreshHistoryOrders();
          // }
          // if (booking) {
          const fetchAndSetData = async () => {
            const applicables = await fetchSpecialOffersApplicable({ site: site.id });

            setSpecialOffers(applicables);
          };

          fetchAndSetData();

          //   if (chatActive) {
          //     fetchUsers();
          //     fetchMessages();
          //   }
          // }
        } else {
          // const refreshBooking = async () => {
          //   const result = await fetchBooking(booking.id);

          //   if (!result.active) {
          //     resetNavigation({
          //       index: 0,
          //       routes: [{ name: 'HomePage' }],
          //     });
          //   } else {
          //     setBooking(result);
          //   }
          // };

          // if (booking) {
          //   refreshBooking();
          // }
        }
      }
    };

    const listener = AppState.addEventListener('change', handleAppStateChange);

    return () => {
      listener.remove();
    };
  });

  return children;
};

export default AppStateHandler;
