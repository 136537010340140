import React from 'react';
import PropTypes from 'prop-types';
import { LinearGradient } from 'expo-linear-gradient';

import theme from 'styles';
import useSite from 'sites/contexts/sites';

const Gradient = ({
  height, width, colors, styles, alternative
}) => {
  const {item: site} = useSite();

  // custom OU template couleur 2 OU template couleur 1 OU couleurs par défaut
  const applyColors = colors ? colors : (
    site?.template && alternative ? [site?.template?.primaryColor, site?.template?.primaryShadeColor] : (
      site?.template ? [site?.template?.secondaryColor, site?.template?.secondaryShadeColor] : (
        [theme.colors.secondary, theme.colors.secondaryDark]
      )
    )
  );

  return(
  <LinearGradient
    colors={applyColors}
    start={[0, 0.5]}
    end={[1, 0.5]}
    style={[styles, {
      zIndex: -10,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      height,
      width,
    }]}
  />
)};

Gradient.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  styles: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  colors: PropTypes.array,
  alternative: PropTypes.bool,
};

Gradient.defaultProps = {
  styles: null,
  height: null,
  width: null,
  colors: null,
  alternative: false,
};

export default Gradient;
