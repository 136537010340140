import usePersistedState from 'common/utils/usePersistedState';
import React, {
  useState, createContext, useMemo, useContext, useEffect, useCallback,
} from 'react';
import { PropTypes } from 'prop-types';
import fetchJSON from 'common/utils/fetchJSON';
import useSite from 'sites/contexts/sites';

const LocalOrdersContext = createContext();

export const LocalOrdersProvider = ({ children }) => {
  const [localStorageOrders, setLocalStorageOrders] = usePersistedState([], 'local-history-orders');
  const [localHistoryUser, setLocalHistoryUser] = usePersistedState({}, 'local-user');
  // eslint-disable-next-line camelcase
  const [localOrders, setLocalOrders_protected] = useState([]);
  const { item: site } = useSite();

  const setLocalOrders = useCallback((orders) => {
    setLocalStorageOrders(orders.map(({ id }) => id));
  }, [setLocalStorageOrders]);

  const addLocalOrder = useCallback((order) => {
    const newOrderIdList = localOrders.map(({ id }) => id);

    if (!newOrderIdList.includes(order.id)) {
      newOrderIdList.push(order.id);
      setLocalStorageOrders(newOrderIdList);
    }
  }, [localOrders, setLocalStorageOrders]);

  const fetchOrders = useCallback(async () => {
    if (localStorageOrders.length && site) {
      const esc = encodeURIComponent;

      const queryParams = localStorageOrders
        .map((orderId) => `id=${esc(orderId)}`);

      queryParams.push(`site=${site.id}`);

      try {
        const res = await fetchJSON({
          url: `orders${queryParams ? '?' : ''}${queryParams.join('&')}`,
          method: 'GET',
        });

        setLocalOrders_protected(res);
      } catch (e) {
        console.log('Error reading orders in localstorage');
      }
    }
  }, [localStorageOrders, site]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const val = useMemo(() => ({
    localOrders,
    setLocalOrders,
    localHistoryUser,
    setLocalHistoryUser,
    addLocalOrder,
    fetchOrders,
  }), [localOrders, setLocalOrders, localHistoryUser, setLocalHistoryUser, addLocalOrder, fetchOrders]);

  return (
    <LocalOrdersContext.Provider value={val}>
      {children}
    </LocalOrdersContext.Provider>
  );
};

LocalOrdersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useLocalOrders = () => useContext(LocalOrdersContext);

export default useLocalOrders;
