/* eslint-disable no-constant-condition */
import { useNavigation, useRoute } from '@react-navigation/native';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import NavigationButton from 'common/components/NavigationButton/NavigationButton.web';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Page from 'common/components/Page/Page';
import Price from 'common/components/Price/Price';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import useOrder from 'orders/contexts/orders';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import useSite from 'sites/contexts/sites';
import useOffer from 'specialOffers/hooks/use-offer';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import useAlert from 'common/contexts/alert';
import useAuth from 'auth/contexts/auth';
import fetchJSON from 'common/utils/fetchJSON';
import useBasket from 'basket/contexts/basket';
import FlexRow from 'common/components/FlexRow/FlexRow';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import zeroPad from 'common/utils/zeroPad';
import { lowerFirstLetter } from 'common/utils/capitalize';
import useLocalOrders from 'orders/contexts/localOrders';
import useTrans from 'common/hooks/use-trans';
import getWording from 'common/utils/getWording';
import formatPrice from 'common/utils/formatPrice';

const styles = StyleSheet.create({
  innerView: {
    padding: theme.sizings.mediumLarge,
  },

  title: {
    marginTop: theme.sizings.medium,
    marginBottom: theme.sizings.small,
  },

  totalView: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.greyLighter,
    paddingTop: theme.sizings.medium,
    marginTop: theme.sizings.medium,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const OrderSummary = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { setAlert } = useAlert();
  const { t, i18n } = useTranslation();
  const { translateText } = useTrans();
  const {
    item: order,
    setItem: setOrder,
    fetchItems: fetchOrders,
    isFetching: isOrdersFetching,
    save: saveOrder,
    states,
  } = useOrder();

  const { addLocalOrder } = useLocalOrders();
  const { findProductOffer } = useOffer();
  const { item: site } = useSite();
  const { user } = useAuth();
  const { setItems } = useBasket();

  const lang = i18n.language;

  let total = 0;

  const [preparatorAreas, setPreparatorAreas] = useState();
  useEffect(() => {
    // if an order is passed in route params, overload the one in context
    // this hack is used to use an order from a different context
    // (historyOrders instead of order)
    if (route.params.order) {
      setOrder(route.params.order);
    }
  }, [route.params.order, setOrder]);

  useEffect(() => {
    let isFetchingPA = true;

    const getPA = async () => {
      let pa;

      if (order?.id) {
        pa = order.preparatorAreas;
      } else if (site.id && order?.localization) {
        pa = await fetchJSON({
          url: 'sites/preparatorArea',
          method: 'PUT',
          payload: {
            site_id: site.id,
            localizations: order.localization,
          },
        });
      }
      if (isFetchingPA && pa && Object.keys(pa).length) {
        setPreparatorAreas(pa);
      }
    };

    getPA().catch(console.error);

    return () => {
      isFetchingPA = false;
    };
  }, [order, site]);

  const ProceedToPayment = () => {
    if (user?.id) {
      saveAndPay(user);
    } else {
      saveAndPay(undefined);
      // navigation.navigate('SignUpPage', { callback: saveAndPay });
    }
  };

  const handleEditOrder = useCallback(async () => {
    try {
      const res = await fetchJSON({
        url: `orders/${order.id}`,
        method: 'PUT',
        payload: {
          ...order,
          created_at: new Date(),
        },
      });

      if (res?.statusCode === 401) {
        throw new Error('UNAUTHORIZED');
      } else if (res?.statusCode === 500) {
        throw new Error('SERVER_ERROR');
      } else {
        await setOrder(res);

        addLocalOrder(res);
      }

      navigation.navigate('SiteHome');
    } catch (e) {
      console.error(e);
      setAlert({
        color: 'error',
        title: t('common.error'),
        message: t('basket.errorPlacing'),
      });
    }
  }, [order, navigation, setOrder, addLocalOrder, setAlert, t]);

  const saveAndFinish = useCallback(
    async (isSuccess, errorMsg = '') => {
      if (isSuccess) {
        setAlert({
          color: 'success',
          title: t('common.success'),
          message: t('orders.successCreate'),
        });

        setItems([]);
        fetchOrders(); // TODO : Why when not auth (and not waiter) do we recieve a LOT of orders ?
        /* navigation.popToTop(); */
        if (!site?.pleaseMyCar) {
          navigation.navigate('ValidateOrderSummary');
        } else {
          // reset navigation and pass the site id as query param
          navigation.navigate('ValidateOrderSummary', { site: site.id });
        }
      } else {
        setAlert({
          color: 'error',
          title: t('common.echec'),
          message: `${t('orders.echecPaiement')}${
            errorMsg && '\n'
          }Error${errorMsg}`,
        });
      }
    },
    [setAlert, t, setItems, fetchOrders, navigation, site],
  );

  const saveAndPay = useCallback(
    async (user) => {
      const amount = Math.round(total * 100) / 100 + Number(order?.tip || 0);

      try {
        const onlineSelected = !order.payAfter;
        /* const methodSelected = 'CB'; */

        const res = await saveOrder({
          payment: {
            online: onlineSelected,
            /* offline_method: methodSelected, */
            amount,
            transactionFees:
              onlineSelected && total > 0 ? site.onlineTransactionFees : 0,
            status: 'PENDING',
            site: site.id,
          },
          preparatorAreas,
          ...order,
          user,
        });

        if (res?.statusCode === 401) {
          throw new Error('UNAUTHORIZED');
        } else {
          await setOrder(res);

          addLocalOrder(res);
        }

        if (onlineSelected) {
          //if (site.isDemo) : aucune différence

          navigation.navigate('Payment', {
            payment: res?.payment,
            callback: saveAndFinish,
            onlineSelected: true,
          });

        } else {
          saveAndFinish(true);
        }

        /* navigation.navigate('SiteHome'); */
      } catch (e) {
        console.error(e);
        setAlert({
          color: 'error',
          title: t('common.error'),
          message: t('basket.errorPlacing'),
        });
      }
    },
    [
      total,
      order,
      saveOrder,
      site,
      preparatorAreas,
      setOrder,
      addLocalOrder,
      navigation,
      saveAndFinish,
      setAlert,
      t,
    ],
  );

  return (
    <Page backgroundImage={site?.template.imageBackdrop}>
      <LoadingSpinner visible={isOrdersFetching || !order} />

      {order && (
        <>
          <ScrollView style={styles.innerView}>
            <View style={styles.title}>
              <View>
                <Text
                  isBold
                  isTitleText
                  size="large"
                  color="dark"
                  customColor={site?.template?.backdropTextColor}
                >
                  {route.name !== 'CurrentOrderSummary'
                    ? `${t('orders.orderNbr', {
                      orderId: zeroPad(order.displayedId, 3),
                    })}`
                    : t('orders.orderVerification')}
                </Text>
                {route.name !== 'CurrentOrderSummary' && (
                  <Text
                    isTitleText
                    size="large"
                    color="dark"
                    customColor={site?.template?.backdropTextColor}
                  >
                    {states[order.state]}
                  </Text>
                )}
              </View>
            </View>
            <View>
              <Text
                size="large"
                color="dark"
                customColor={site?.template?.backdropTextColor}
              >
                {/* order?.deliveryMethod === 'takeaway'
                  ? t('orders.toTakeAway')
                  : t(
                      site?.pleaseMyCar
                        ? site?.pleaseMyCarDriver ? 'orders.onTheSpotDriver' : 'orders.onTheSpotValet'
                        : 'orders.onTheSpot',
                  ) */} 

                {order?.selectedHour && lowerFirstLetter(
                  getWording(
                    order?.tSelectedHour,
                    order?.selectedHour,
                    lang,
                    translateText,
                  )
                )}
              </Text> 
              {order?.code && (
                <Text
                  color="dark"
                  customColor={site?.template?.backdropTextColor}
                >
                  {`${t('orders.validationCode')} : ${order?.code}`}
                </Text>
              )}

              {!!order?.localization
                && Object.keys(order.localization).map((key) => {
                  const currentField = order.localization[key];

                  return currentField.value ? (
                    <FlexRow
                      key={`${key}-row`}
                      align="left"
                      style={{
                        flexWrap: 'wrap',
                        marginTop: theme.sizings.medium,
                      }}
                    >
                      <Text
                        isBold
                        isTitleText
                        color="dark"
                        customColor={site?.template?.backdropTextColor}
                      >
                        {`${getWording(
                          currentField.tlabel,
                          currentField.name || key,
                          lang,
                          translateText,
                        )} : `}
                      </Text>

                      {currentField.type === 'select' ? (
                        <Text
                          color="dark"
                          customColor={site?.template?.backdropTextColor}
                        >
                          {getWording(
                            currentField.toption,
                            currentField.value,
                            lang,
                            translateText,
                          )}
                        </Text>
                      ) : (
                        <Text
                          color="dark"
                          customColor={site?.template?.backdropTextColor}
                        >
                          {currentField.value}
                        </Text>
                      )}
                    </FlexRow>
                  ) : null;
                })}

              {order.barman && (
                <FlexRow align="left">
                  <Text
                    isBold
                    isTitleText
                    color="dark"
                    customColor={site?.template?.backdropTextColor}
                  >
                    {`${t('orders.preparedBy')} : `}
                  </Text>
                  <Text
                    color="dark"
                    customColor={site?.template?.backdropTextColor}
                  >
                    {order.barman.username}
                  </Text>
                </FlexRow>
              )}
              {// order.deliveryMethod === 'deliver' &&
              order.waiter && (
                <FlexRow align="left">
                  <Text
                    isBold
                    isTitleText
                    color="dark"
                    customColor={site?.template?.backdropTextColor}
                  >
                    {t('orders.servedBy')}
                    {' : '}
                  </Text>
                  <Text
                    color="dark"
                    customColor={site?.template?.backdropTextColor}
                  >
                    {order.waiter.username}
                  </Text>
                </FlexRow>
              )
}

              {site?.askCoordinates && (
                <>
                  {order.userInfo?.name && (
                    <FlexRow
                      align="left"
                      stule={{ marginTop: theme.sizings.medium }}
                    >
                      <Text
                        isBold
                        isTitleText
                        color="dark"
                        customColor={site?.template?.backdropTextColor}
                      >
                        {t('auth.name')}
                        {' : '}
                      </Text>
                      <Text
                        color="dark"
                        customColor={site?.template?.backdropTextColor}
                      >
                        {order.userInfo?.name || '-'}
                      </Text>
                    </FlexRow>
                  )}

                  {order.userInfo?.email && (
                    <FlexRow align="left">
                      <Text
                        isBold
                        isTitleText
                        color="dark"
                        customColor={site?.template?.backdropTextColor}
                      >
                        {t('auth.email')}
                        {' : '}
                      </Text>
                      <Text
                        color="dark"
                        customColor={site?.template?.backdropTextColor}
                      >
                        {order.userInfo?.email || '-'}
                      </Text>
                    </FlexRow>
                  )}

                  {order.userInfo?.phone && (
                    <FlexRow align="left">
                      <Text
                        isBold
                        isTitleText
                        color="dark"
                        customColor={site?.template?.backdropTextColor}
                      >
                        {t('auth.phone')}
                        {' : '}
                      </Text>
                      <Text
                        color="dark"
                        customColor={site?.template?.backdropTextColor}
                      >
                        {order.userInfo?.phone
                          ? `+${order.userInfo?.phone.replace(':', ' ')}`
                          : '-'}
                      </Text>
                    </FlexRow>
                  )}
                </>
              )}
            </View>

            {order?.order_items?.forEach((productItem) => {
              const reduction = findProductOffer(productItem.product);

              total
                += Math.round(
                  (reduction
                    ? ((100 - reduction.discount_rate) / 100)
                      * productItem.price
                    : productItem.price)
                    * productItem.quantity
                    * 100,
                ) / 100;
            })}
            <View style={styles.totalView} />
            {order?.order_items && (
              <View style={{ padding: theme.sizings.small }}>
                {order.order_items?.map((orderItem, orderIndex) => (
                  <View key={orderIndex}>
                    <FlexRow>
                      <FlexRow.Left>
                        <TranslatedText
                          isTitleText
                          customColor={site?.template?.backdropTextColor}
                          value={orderItem.name}
                        />
                        {!site?.pleaseMyCar && (
                          <Text customColor={site?.template?.backdropTextColor} isTitleText>
                            x
                            {`${orderItem.quantity}`}
                          </Text>
                        )}
                      </FlexRow.Left>

                      <FlexRow.Right>
                        {site?.showBasketAmount && (
                          <Price
                            viewStyle={styles.price}
                            style={{ fontSize: theme.sizings.medium }}
                            price={orderItem.price * orderItem.quantity}
                            color="dark"
                            customColor={site?.template?.backdropTextColor} 
                          />
                        )}
                      </FlexRow.Right>
                    </FlexRow>

                    <View style={{ marginLeft: theme.sizings.mediumLarge }}>
                      {orderItem.selected_options.map((option, optionIndex) => (
                        <>
                          {option.variants.map((variant, variantIndex) => (
                            <View
                              key={`o${optionIndex}-v${variantIndex}`}
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <TranslatedText value={variant.name} customColor={site?.template?.backdropTextColor} />
                            </View>
                          ))}
                        </>
                      ))}
                    </View>
                  </View>
                ))}

                {site?.showBasketTip && (
                  <FlexRow>
                    <FlexRow.Left>
                      <Text color="dark" customColor={site?.template?.backdropTextColor} isTitleText>
                        {t('basket.tipAmount')}
                      </Text>
                    </FlexRow.Left>

                    <FlexRow.Right>
                      <Price
                        noLineHeight
                        price={order?.tip || 0}
                        viewStyle={styles.price}
                        style={{ fontSize: theme.sizings.medium }}
                        color="dark"
                        customColor={site?.template?.backdropTextColor} 
                      />
                    </FlexRow.Right>
                  </FlexRow>
                )}
              </View>
            )}

            {site?.showBasketAmount && (
              <>
                <View style={styles.totalView}>
                  <View>
                    <Text
                      size="large"
                      color="dark"
                      customColor={site?.template?.backdropTextColor}
                    >
                      {t('basket.total', {
                        price: total + Number(order?.tip || 0),
                      })}
                    </Text>
                  </View>
                  <Price
                    noLineHeight
                    price={total + Number(order?.tip || 0)}
                    size="huge"
                    style={{
                      color:
                        site?.template?.backdropTextColor || theme.colors.dark,
                    }}
                  />
                </View>
                <View>
                  {/*!order?.payment && (
                    <Text
                      size="medium"
                      color="dark"
                      customColor={site?.template?.backdropTextColor}
                    >
                      {
                        1
                          ? t(
                              site?.pleaseMyCar
                                ? site?.pleaseMyCarDriver ? 'orders.toPayAtReceptionDriver'
                                  : 'orders.toPayAtReceptionValet'
                                : 'orders.toPayAtReception',
                          )
                          : t('orders.toPayOnline')
                      }
                    </Text>
                    )*/}
                  {/*order?.payment && total + Number(order?.tip || 0) > 0 && (
                    <Text
                      size="medium"
                      isBold
                    >
                      {
                        1
                          ? t(
                              site?.pleaseMyCar
                                ? site?.pleaseMyCarDriver ? 'orders.toPayAtReceptionDriver'
                                  : 'orders.toPayAtReceptionValet'
                                : 'orders.toPayAtReception',
                          )
                          : t('orders.paymentOnlineValidated')
                      }
                    </Text>
                    )*/}
                </View>
              </>
            )}

            <View style={commonStyles.scrollViewWithButton} />

            <FooterButtonWrapper>
              <NavigationButton
                title={
                  route.name === 'CurrentOrderSummary'
                    ? order.payAfter
                      ? `${t('orders.validateOrder')}`
                      : `${t('basket.pay', { total: formatPrice(total + Number(order?.tip || 0), site.currency) })}`
                    : route.name === 'EditOrderSummary'
                      ? `${t('common.save')}`
                      : `${t('auth.goHome')}`
                }
                link="SiteHome"
                navigate={
                  site?.pleaseMyCar
                  || route.name === 'ValidateOrderSummary'
                  || route.name === 'FinalOrderSummary'
                }
                icon="check"
                params={{ siteId: site?.id }}
                alsoOnPress={
                  route.name === 'CurrentOrderSummary'
                    ? ProceedToPayment
                    : route.name === 'EditOrderSummary'
                      ? handleEditOrder
                      : fetchOrders
                }
              />
            </FooterButtonWrapper>
          </ScrollView>
        </>
      )}
    </Page>
  );
};

export default OrderSummary;
