import { Dimensions, StyleSheet } from 'react-native';
import theme from 'styles';

export default StyleSheet.create({
  mainView: {
    flex: 1,
    backgroundColor: theme.colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomImage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: Dimensions.get('window').width,
    height: 300,
  },
  image: {
    height: 250,
    width: 250,
  },
  qrcode: {
    // flex: 1,
    alignItems: 'center',
    marginBottom: 50,
  },
  scanQR: {
    marginTop: 16,
  },
  topImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: Dimensions.get('window').width,
    height: 300,
  },
  logo: {
    width: '75%',
    height: '50%',
    resizeMode: 'contain',
  },
  buttons: {
    width: Dimensions.get('window').width,
    paddingHorizontal: theme.sizings.small,
  },
  button: {
    alignSelf: 'stretch',
    backgroundColor: theme.colors.greyLightest,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: theme.sizings.medium,
    paddingHorizontal: theme.radius.large,
    marginBottom: theme.sizings.small,
    borderRadius: theme.radius.small,
  },

  icon: {
    width: 30,
    height: 30,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.radius.rounded,
  },
});
