import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import OrderProduct from 'orders/components/OrderProduct/OrderProduct';
import useOrders from 'orders/contexts/orders';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Dimensions, StyleSheet, TouchableHighlight, View,
} from 'react-native';
import { Icon } from 'react-native-elements';
import useOffer from 'specialOffers/hooks/use-offer';
import theme from 'styles';

const OrderCategory = ({
  products, children, name, id, orderAllInclusive,
}) => {
  const {
    setWaiterCatalog,
    setCategorySelected,
    categorySelected,
  } = useOrders();
  const {
    findProductOffer,
  } = useOffer();
  const { width } = Dimensions.get('window');

  const nbrColumn = useMemo(() => {
    if (width < 500) {
      return (2);
    }
    if (width < 700) {
      return (5);
    } if (width < 1000) {
      return (7);
    }
    return (7);
  }, [width]);

  const isSelected = useMemo(() => categorySelected === `p${id}`, [categorySelected, id]);

  useEffect(() => {
    if (isSelected) {
      onSelectCategory();
    }
  }, [onSelectCategory, width, isSelected]);

  const onSelectCategory = useCallback(() => {
    let rawCatalog = [];

    let catalog = [];

    setCategorySelected(`p${id}`);

    children.forEach((category) => {
      rawCatalog = rawCatalog.concat(...category?.products?.map((product) => {
        const reduction = findProductOffer(product);

        return (
          OrderProduct({ product, reduction, orderAllInclusive })
        );
      }));

      rawCatalog = rawCatalog.map((product, index) => (
        <>
          {product}
          {((index + 1) % nbrColumn === 0) && <View style={{ flexBasis: '100%' }} />}
        </>
      ));
      if (rawCatalog.length % nbrColumn !== 0) {
        rawCatalog.push(
          <View
            style={{
              flex: nbrColumn - (rawCatalog.length % nbrColumn),
              margin: theme.sizings.tinny,
            }}
          />,
        );
      }

      catalog = catalog.concat([
        <View key={category.id} style={styles.category}>
          <View style={styles.titleBox}>
            <TranslatedText isBold color="darkBlue" size="large" value={category.name} />
          </View>
          <View style={styles.productView}>
            {rawCatalog}
          </View>
        </View>]);
      rawCatalog = [];
    });
    rawCatalog = rawCatalog.concat(...products?.map((product) => {
      const reduction = findProductOffer(product);

      return (
        OrderProduct({ product, reduction, orderAllInclusive })
      );
    }));

    rawCatalog = rawCatalog.map((product, index) => (
      <>
        {product}
        {((index + 1) % nbrColumn === 0) && <View style={{ flexBasis: '100%' }} />}
      </>
    ));
    if (rawCatalog.length % nbrColumn !== 0) {
      rawCatalog.push(
        <View
          style={{
            flex: nbrColumn - (rawCatalog.length % nbrColumn),
            margin: theme.sizings.tinny,
          }}
        />,
      );
    }
    catalog = catalog.concat([
      <View key={0} style={styles.category}>
        <View style={styles.productView}>
          {rawCatalog}
        </View>
      </View>,
    ]);
    setWaiterCatalog(catalog);
  }, [children, findProductOffer, id, nbrColumn, products, setCategorySelected, setWaiterCatalog, orderAllInclusive]);

  return (
    <View style={styles.container}>
      <TouchableHighlight onPress={onSelectCategory}>
        <View stylze={styles.container}>
          <Icon
            name="circle"
            type="font-awesome"
            size={theme.fontSizes.larger}
            color={isSelected ? theme.colors.lightBrown : theme.colors.primary}
          />
          <TranslatedText style={styles.categoryName} size="small" isBold color="greyLightest" value={name} />
        </View>
      </TouchableHighlight>
    </View>
  );
};

const styles = StyleSheet.create({
  categoryName: {
    padding: theme.sizings.tiny,
    textAlign: 'center',
  },
  titleBox: {
    padding: theme.sizings.tiny,
  },
  category: {
    flexDirection: 'column',
    padding: theme.sizings.small,
  },
  productView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.sizings.tiny,
  },
});

OrderCategory.defaultProps = {
  children: [],
  orderAllInclusive: false,
};

OrderCategory.propTypes = {
  id: PropTypes.number.isRequired,
  products: PropTypes.array.isRequired,
  name: PropTypes.object.isRequired,
  children: PropTypes.array,
  orderAllInclusive: PropTypes.bool,
};

export default OrderCategory;
