import React from 'react';

import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import Page from 'common/components/Page/Page';
import theme from 'styles';
import { View } from 'react-native';

const CGU = () => {
  const { t } = useTranslation();

  return (
    <Page padding scroll>
      <View style={{ padding: theme.sizings.medium }}>
        <Text size="larger" color="primary">
          {t('cgu.cgu')}
        </Text>
        <Text size="larger" color="primary">
          General conditions of use – PleaseMyCar
        </Text>
        <Text>SAS PleaseMyCar PONTOISE (95300) SIREN : 948531025</Text>
        <Text size="large">ARTICLE 1 – object</Text>
        <Text>
          The purpose of this application, made available by the company
          PleaseMyCar and its partners, is to connect hotel or bathing
          establishments (for example, beaches, hotels, swimming pools, together
          referred to as “Beaches”) and their users, non-professional buyers.
          (Hereinafter referred to as the “Users” or the “Customer”) to order
          products and services, to pay for them, to communicate with the Beaches.
          These establishments can thus offer their deckchairs for reservation.
          Users of the application can make a reservation at these establishments.
          Beach bars can also offer their cards to users of the application, and
          they can place orders and make payments through this application. These
          General Conditions of Use (the “T & Cs”) define the conditions under
          which the PleaseMyCar company makes the technological tools available to
          Customers allowing them to book, order services and consumption, through
          the application. PleaseMyCar. By this contract, the PleaseMyCar company
          grants the User a non-exclusive and non-transferable right of use of the
          PleaseMyCar Software. PleaseMyCar is not the seller of the Products, nor
          the provider of the services offered. The T & Cs supplement the General
          Conditions of Sale or of the provision of services which govern the
          orders made between Users and the Beaches through PleaseMyCar and are
          accepted by the Customer to use the services of PleaseMyCar.
        </Text>
        <Text size="large">ARTICLE 2 – Scope</Text>
        <Text>
          These T & Cs apply without restriction or reservation to the use of the
          services and technological tools made available to Customers by
          PleaseMyCar. These T & Cs are accessible at any time on the PleaseMyCar
          application and will prevail, where applicable, over any other version
          or any other contradictory document. These T & Cs may be subject to
          subsequent modifications, the version applicable to the Customer’s order
          or reservation is the one in force on the date of the contract.
        </Text>
        <Text size="large">ARTICLE 3 – Services offered by PleaseMyCar</Text>
        <Text>
          Access to PleaseMyCar is strictly reserved for non-professional Clients
          acting in a private capacity, which the Client declares and acknowledges
          when using the PleaseMyCar services, also committing to carry out in
          good faith, all orders made with the Beaches through the PleaseMyCar
          application. The use of PleaseMyCar is free for Customers (excluding any
          connection costs billed by the operator) and without obligation to
          purchase. Only the purchase of Products or the order for a service at
          the Beaches is chargeable, according to the conditions provided for in
          the Beach GTC, which the Customer also accepts when placing an order
          through PleaseMyCar. All the prices displayed on the PleaseMyCar
          application are indicated per deckchair and per order. They include VAT
          and all other taxes (subject to change), unless otherwise stated on our
          app or on the booking confirmation email. Payment is made either online
          in a secure manner from your credit / debit card through the means of
          payment offered by PleaseMyCar, or directly to the establishment or the
          Hotel in which you are using its own means of regulations and conditions
          (CB, CASH, CHECK). ONLINE PAYMENTS VIA THE PleaseMyCar APP AND ITS
          PARTNER MANGOPAY CANNOT BE LESS THAN 10 € (TEN EUROS). To use the
          services of PleaseMyCar, the Customer must accept these T & Cs. The
          Customer undertakes to maintain the confidentiality of these codes and
          refrains from disclosing them to any person whatsoever. The Customer is
          solely responsible for the use of these codes and for access to
          PleaseMyCar, except for proof of fraudulent use which cannot be
          attributed to him. Any fraudulent use of these codes of which the
          Customer becomes aware must be notified immediately in writing to
          PleaseMyCar or to the Beach. The User is expressly forbidden to
          reproduce, permanently or temporarily, the PleaseMyCar Software in whole
          or in part, by any means and in any form, including on the occasion of
          loading, display, execution, transmission or storage of the Software.
          The User is prohibited from translating, adapting, arranging or
          modifying the Software, exporting it or merging it with other software.
          In the event of non-compliance with these T & Cs by the Customer,
          PleaseMyCar reserves the right to temporarily or permanently interrupt
          its access, by suspending or terminating these without notice or
          compensation.
        </Text>
        <Text size="large">ARTICLE 4 – Protection of personal data</Text>
        <Text>
          Pursuant to Law 78-17 of January 6, 1978 amended by Law No. 2018-493 of
          June 20, 2018, it is recalled that the personal data requested from the
          Customer by the company PleaseMyCar are necessary, both to guarantee the
          access to PleaseMyCar services only for placing and processing orders.
          These data are communicated to the Beaches in order to enable them to
          carry out the execution of the contract and of the Customers’ orders.
          They cannot in any case be used for other purposes. Each Beach only has
          access to personal data provided by Customers for its products and
          services ordered through the PleaseMyCar application when placing the
          order. PleaseMyCar and Les Plages undertake to ensure the security of
          the personal data that they keep for the purposes of carrying out and
          monitoring orders. The processing of information communicated through
          the PleaseMyCar application meets legal requirements for the protection
          of personal data, the information system used ensuring optimal
          protection of this data. The Customer has, in accordance with national
          and European regulations in force, a right of permanent access,
          modification, rectification, opposition, portability and limitation of
          processing with regard to information concerning him. This right can be
          exercised via the “contact” section of the application, or by writing,
          by mail and justifying your identity, to:
        </Text>
        <Text size="large">ARTICLE 5 – Intellectual property</Text>
        <Text>
          The content of the PleaseMyCar application is the property of the
          PleaseMyCar company and its partners and is protected by French and
          international laws relating to intellectual property. PleaseMyCar
          guarantees the Customer that it is the holder either of the economic
          rights on the Firmware / Software and their Documentation, or of an
          authorization from the author of the Firmware / Software and that it can
          therefore freely grant the Customer the right to ‘intended use herein.
          The concession of the right to use the Firmware / software does not
          entail the transfer of property rights to the benefit of the Customer.
          The software packages / software remain the property of PleaseMyCar or
          of their author, whatever the form, language, program medium or language
          used. Any total or partial reproduction of this content is strictly
          prohibited and is liable to constitute an offense of counterfeiting. It
          is prohibited to resell, use, copy, monitor (for example, by web crawler
          or screenshots), display, download, reproduce or establish deep links to
          any content or any information, any software and / or any products or
          services available on our application as part of a business or a
          commercial or competitive objective The user is not authorized to copy
          or reproduce on any media whatsoever, digital or not, the PleaseMyCar
          brand without the express written permission of the PleaseMyCar company.
          The customer undertakes not to access, copy or duplicate the databases
          of the software packages, nor the PleaseMyCar software packages or
          distributed by it, except with prior written and express agreement. The
          User is expressly prohibited from modifying, improving, editing,
          translating, decompiling, disassembling or creating one or more
          derivative work (s) from the Services and in particular from their
          content, without the prior written consent. by PleaseMyCar In accordance
          with the provisions of Law No. 98-536 of July 1, 1998, transposing into
          the Intellectual Property Code Directive 96/9 / EC of March 11, 1996,
          concerning the legal protection of databases, PleaseMyCar is producer of
          its database, accessible on the PleaseMyCar application, and of which it
          is the sole owner. By accessing the PleaseMyCar application, the user
          acknowledges that the data composing it are legally protected and, in
          accordance with the provisions of the aforementioned law of 1 July 1998,
          in particular refrains from extracting, reusing, storing, reproducing,
          representing or keep, directly or indirectly, on any medium, by any
          means and in any form whatsoever, all or qualitatively or quantitatively
          substantial part of the application to which the user is accessing as
          well as extracting it or the repeated and systematic reuse of
          qualitatively and quantitatively insubstantial parts when these
          operations clearly exceed the conditions of normal use. Any illegal use
          or above-mentioned action will constitute a material violation of
          intellectual property rights (copyright and database right)
        </Text>
        <Text size="large">ARTICLE 6 – Responsibilities</Text>
        <Text>
          It is recalled that the PleaseMyCar company is not the Seller of the
          Products, nor the provider of the services offered by the Beaches. It
          can therefore under no circumstances be held responsible for sales made
          between Customers and the Beaches to which it remains foreign. The
          Beaches are fully responsible for updating their prices. The Products
          cannot be returned or exchanged by PleaseMyCar and any dispute relating
          to a sale transaction carried out through PleaseMyCar must be settled
          directly between the Customer and the Beaches or Hotel. PleaseMyCar
          strives to provide a quality service, but can neither verify nor
          guarantee the accuracy, precision or completeness of the information,
          and cannot be held responsible for any error (manifest error or fault
          typographical), any interruption of service (due to a technical failure,
          whether temporary and / or partial, breakdown, repair, update,
          improvement or maintenance of our website or other), any inaccurate,
          misleading or erroneous information , or any lack of information. Each
          establishment remains responsible at all times for the precise, complete
          and exact nature of the (descriptive) information which concerns it and
          which is displayed on our application, including its prices and
          availability. The PleaseMyCar app does not constitute and should not be
          construed as any form of recommendation or endorsement of the quality,
          level of service or rating of each establishment offered to visitors.
          Subject to claims based on liability whether of a contractual, tort or
          quasi-tort nature, the exclusion or limitation of which would be null
          and void by law, PleaseMyCar’s liability may not be incurred beyond the
          sum total paid for the provision of services as indicated in the
          confirmation email, whether it is an isolated event or a series of
          related events. PleaseMyCar can in no case be held responsible for the
          operating loss, the interruption of service activity or any other
          derivative or indirect damage. PleaseMyCar’s liability is excluded in
          the event of misuse of the PleaseMyCar application services by the
          Customer or of fault on his part. It cannot be committed either for acts
          attributable to a third party. In accordance with the regulations in
          force, the responsibility of the PleaseMyCar company cannot be engaged
          or retained because of the content made available on the PleaseMyCar
          application, in particular with regard to the description of the
          Products, unless it does not promptly return them. inaccessible after
          having been informed of their illegality under the conditions provided
          for by this law. The Customer is solely responsible for the use of the
          services of the PleaseMyCar application, in particular for the
          assessments he makes on the application, and undertakes to guarantee, at
          first request, to indemnify and compensate the PleaseMyCar company for
          any damage, loss, loss of profit, which it could suffer if it were held
          liable by a third party, due to an action related to this use by the
          Customer. By uploading photos / images into the PleaseMyCar application
          (for example by sending a comment), the user certifies and guarantees to
          own the copyright of these and accepts that the PleaseMyCar company uses
          them at its discretion on its websites (mobile), apps, tools and
          promotional publications (online and offline). The user is the exclusive
          owner of all intellectual property rights attached to his Photographs.
          The user grants to the company PleaseMyCar free of charge in a
          non-exclusive, universal, irrevocable, unconditional manner and for the
          whole world on his photographs and images uploaded in the PleaseMyCar
          application a right of use, reproduction, distribution on any digital or
          unknown or future medium, and in all known or future formats, for all
          purposes and destinations and in particular for commercial or
          advertising purposes. The user expressly and graciously authorizes
          PleaseMyCar to transfer his Photos to any third party of his choice.
          These rights are granted to PleaseMyCar for the entire legal term of
          protection of copyright and its beneficiaries, including any extension
          that may be granted to the user in his capacity as author of the Photos.
          By uploading these photographs, the person who uploaded them accepts
          full moral and legal responsibility for all legal complaints made by any
          third party (including, but not limited to the owners of the deckchairs
          or beach bars) following the broadcast and the use of these photos /
          images by PleaseMyCar. The PleaseMyCar company is neither the owner, nor
          the author, nor does it approve, the photos / images uploaded by the
          user on the PleaseMyCar application. The PleaseMyCar company declines
          all responsibility for the photos / images posted. The person who
          downloaded the photos / images ensures that they do not contain any
          virus, Trojan horse or infected file and are not pornographic, illegal,
          obscene, insulting, reprehensible or inappropriate and do not infringe
          rights from any third party (intellectual property rights, copyright and
          the right to respect for private life). Any photo / image that does not
          meet the above criteria will not be published and / or may be deleted /
          deleted at any time by PleaseMyCar without prior notification.
        </Text>
        <Text size="large">ARTICLE 7 – Partial invalidity</Text>
        <Text>
          If one or more stipulations hereof are held to be invalid or declared as
          such in application of a law, a regulation or a final decision of a
          competent court, the other stipulations will retain all their force and
          all their scope.
        </Text>
        <Text size="large">ARTICLE 8 – Cancellation and modification of the</Text>
        <Text>
          reservation By making a reservation in an establishment, you acknowledge
          having read and accepted the cancellation, no-show and modification
          conditions. The general cancellation and no-show conditions are
          mentioned below and in the booking confirmation email.
        </Text>
        <Text>
          A) Cancellation Reservations can be canceled, but any cancellation will
          incur charges. The costs incurred by a cancellation are classified into
          two categories:
        </Text>
        <Text>
          a) If the cancellation request is made at least 24 hours before the date
          for which you made your reservation, the charge will be 50% of the total
          amount of your reservation.
        </Text>
        <Text>
          b) Otherwise, the costs incurred by your cancellation will be 100% of
          the total amount of your reservation. If you wish to view, modify or
          cancel your reservation, please refer to the confirmation email and
          follow the instructions there.
        </Text>
        <Text>
          B) Modification of the reservation at the request of the establishment
          By booking your deckchair on PleaseMyCar, you have the option of
          choosing the location of your deckchair. However, for reasons of
          convenience and optimization of locations, the establishment has the
          option of changing this location. PleaseMyCar cannot be held responsible
          for the locations allocated and no financial compensation can be claimed
          from PleaseMyCar. However,
        </Text>
        <Text>
          a) if this change of location generates a lower rate than the initial
          cost, the remainder will be borne by the establishment and reimbursed to
          the User by the Beach.
        </Text>
        <Text>
          b) If this change of location generates a price higher than the initial
          cost, no additional sum may be required from the User.
        </Text>
        <Text size="large">ARTICLE 8 – Applicable law</Text>
        <Text>
          These General Conditions of Use and the operations resulting from them
          are governed by French law. They are written in French. In the event
          that they are translated into one or more languages, only the French
          text will prevail in the event of a dispute.
        </Text>
        <Text size="large">
          ARTICLE 9 – Pre-contractual information – Customer acceptance
        </Text>
        <Text>
          The fact for a natural (or legal) person, to order on the PleaseMyCar
          application implies full and complete acceptance and acceptance of these
          General Conditions of Sale, which is expressly recognized by the
          Customer, who waives, in particular, to invoke any contradictory
          document which would be unenforceable against the company PleaseMyCar.
          9-2 . Identification Our contact information is as follows: Website
          address (www.PleaseMyCar.fr) SAS PleaseMyCar Joint stock company with a
          capital of 500 € euros whose head office is located at 81 rue Pasteur
          958430 BUTRY SUR OISE, registered in the PONTOISE Trade and Companies
          Register under the number B 848 094 082 Intra-community VAT number (FR00
          848 094 082) Email address (jeanmarc@pleasemycar.com)
        </Text>
        <Text size="large">ARTICLE 10- Force majeure</Text>
        <Text>
          The Parties cannot be held responsible if the non-performance or delay
          in the performance of any of their obligations, as described herein,
          results from a case of force majeure, within the meaning of article 1218
          of the Civil Code.
        </Text>
        <Text size="large">ARTICLE 11 – Unpredictability</Text>
        <Text>
          These General Conditions expressly exclude the legal contingency regime
          provided for in article 1195 of the Civil Code. PleaseMyCar and the user
          therefore each waive the right to avail themselves of the provisions of
          article 1195 of the Civil Code and the unforeseen regime provided for
          therein, committing to assume their obligations even if the contractual
          balance is upset. by circumstances which were unforeseeable at the time
          of the conclusion of this agreement.
        </Text>
        <Text size="large">ARTICLE 12 – Disputes</Text>
        <Text>
          Any dispute relating to the interpretation and / or execution of these T
          & Cs comes under French jurisdiction. Complaints should be sent to the
          PleaseMyCar application. It is recalled that any dispute or any
          complaint concerning the sale, description or delivery of the Products
          must be settled directly between the Customer and the Seller. The
          Customer is informed that he can in any case resort to conventional
          mediation, in particular with the Commission for consumer mediation
          (article L 612-1 consumer code) or with existing sectoral mediation
          bodies, and whose references appear on the PleaseMyCar application or
          any alternative dispute resolution method (conciliation, for example) in
          the event of a dispute.
        </Text>
      </View>
    </Page>
  );
};

export default CGU;
