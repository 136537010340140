import 'intl';
import i18n from 'i18next';

const formatPrice = (price, siteCurrency) => {
  const roundedPrice = Math.round(price * 100) / 100;
  const checkCurrency = siteCurrency || 'EUR';

  if (roundedPrice % 1 === 0) {
    return (new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: checkCurrency,
    }).format(roundedPrice.toFixed(0)));
  }
  const formatedPrice = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: checkCurrency,
  }).format(roundedPrice);

  return (formatedPrice);
};

export default formatPrice;
