import useBasket from 'basket/contexts/basket';
import Button from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import {
  TouchableOpacity, View,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import ProductWithOptionForm from './ProductWithOptionForm';

const ProductWithOptionFormModal = ({
  setModalVisible, modalVisible, entity, type,
}) => {
  const { t } = useTranslation();
  const { addForms } = useBasket();
  const { options } = entity;

  const [productForms, setProductForms] = useState([{
    selectedOptions: options.map((o) => ({ option: o.option.id, variants: [] })),
    offsetPrice: entity.price,
    quantity: 1,
  }]);

  const handleSaveDatas = useCallback(() => {
    let valid = true;

    productForms.forEach((form) => {
      form.selectedOptions.forEach((o) => {
        const opt = options.find((op) => op.option.id === o.option);

        if (opt.option.multiple !== true && o.variants.length === 0) {
          valid = false;
        }
      });
    });
    if (valid === false) {
      return false;
    }
    addForms(entity, productForms, type);
    return true;
  }, [options, addForms, entity, productForms, type]);

  const handleSetSelectedOptions = useCallback((value, index) => {
    const newForms = [...productForms];

    newForms[index] = value;
    setProductForms(newForms);
  }, [productForms]);

  const handleRemoveItem = useCallback((index) => {
    const newForms = [...productForms];

    newForms.splice(index, 1);
    setProductForms(newForms);
  }, [productForms]);

  const handleSetQuantity = useCallback((offset, index) => {
    const newForms = [...productForms];

    if (newForms[index].quantity + offset !== 0) {
      newForms[index].quantity += offset;
    } else {
      newForms.splice(index, 1);
    }
    setProductForms(newForms);
  }, [productForms]);

  const handleAddForm = useCallback(() => {
    const newForms = [...productForms];

    newForms.push({
      selectedOptions: options.map((o) => ({ option: o.option.id, variants: [] })),
      offsetPrice: entity.price,
      quantity: 1,
    });
    setProductForms(newForms);
  }, [options, entity.price, productForms]);

  return (
    <Modal
      transparent
      visible={modalVisible}
      ariaHideApp={false}
      style={commonStyles.modal}
    >
      <TouchableOpacity
        onPress={() => {
          if (handleSaveDatas()) {
            setModalVisible(false);
          }
        }}
        style={commonStyles.modalMask}
      >
        <View style={[commonStyles.modalBox, { backgroundColor: 'rgba(0, 0, 0, 0.7)' }]}>
          <ScrollView>
            {
              productForms.length >= 0 && productForms.map((pf, index) => (
                <ProductWithOptionForm
                  entity={entity}
                  key={index}
                  formValues={productForms[index]}
                  setSelectedOptions={(value) => handleSetSelectedOptions(value, index)}
                  setQuantity={(offset) => handleSetQuantity(offset, index)}
                  removeItem={() => handleRemoveItem(index)}
                />
              ))
            }
            <Button
              onPress={handleAddForm}
              text={t('orders.addVariant')}
              style={{ marginTop: productForms.length ? theme.sizings.smallMedium : 0 }}
            />
            { productForms.length >= 0 && (
              <Button
                onPress={() => {
                  if (handleSaveDatas()) {
                    setModalVisible(false);
                  }
                }}
                text={t('orders.saveSelection')}
                style={{ marginTop: theme.sizings.smallMedium }}
              />
            )}
          </ScrollView>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

ProductWithOptionFormModal.propTypes = {
  entity: PropTypes.object.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool,
  type: PropTypes.string,
};

ProductWithOptionFormModal.defaultProps = {
  modalVisible: false,
  type: 'product',
};

export default ProductWithOptionFormModal;
