import { useNavigation } from '@react-navigation/native';
import useBooking from 'bookings/contexts/bookings';
import { Image } from 'common/components/Images';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Price from 'common/components/Price/Price';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import useSeats from 'seats/contexts/seats';
import useSite from 'sites/contexts/sites';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import Board from './components/Board/Board';
import BookButton from './components/BookButton/BookButton';
import useSeatSelector from './contexts/seatSelector';

const SeatSelector = ({ route }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { item: site, isFetching } = useSite();
  const { siteId, mapId, edit } = route.params;
  const { setItems: setSeats, selection } = useSeatSelector();
  const { fetchSeatsAvailable, isAvailableFetching } = useSeats();
  const {
    item: booking,
    isFetching: isCreatingBooking,
    setItem: setBooking,
    update: updateBooking,
    checkCode,
  } = useBooking();
  const navigation = useNavigation();
  // We could request the API but since the map is contained in the site object we can avoid an API call.
  const map = useMemo(() => site.maps.find((map) => map.id === mapId), [mapId, site.maps]);

  useEffect(() => {
    const fetchAndSetData = async () => {
      if (site && booking.dates) {
        let availables = await fetchSeatsAvailable({
          dates: booking.dates.join(','),
          site: siteId,
          map: mapId,
        });

        if (booking.seats) {
          availables = availables.concat(booking.seats);
        }

        let seats = site.seats.filter(({ active, map }) => active && map === mapId);

        if (site.seats[0].x < 10) {
          seats = site.seats.map((seat) => ({ ...seat, x: seat.x * 90 + 50, y: seat.y * 90 + 200 }));
        }
        const seatsList = seats.map((item) => ({
          component: item.furniture
            ? <Image image={item.furniture.image} style={styles.item} />
            : null,
          selected: !!selection?.find((seat) => seat.id === item.id),
          available: !!availables.find(({ id }) => item.id === id),
          ...item,
        }));

        setSeats(seatsList);
      }
    };

    fetchAndSetData();
  }, [site, booking, setSeats, siteId, fetchSeatsAvailable, mapId, selection]);

  const total = useMemo(() => {
    let t = 0;

    if (edit) {
      return 0;
    }
    selection.filter((sel) => sel).forEach((seatSelected) => {
      const priceAreaId = seatSelected.price_area?.id;
      const priceObject = seatSelected.furniture.prices.find((p) => p.price_area === priceAreaId);

      if (priceObject) {
        t += booking.hotel ? priceObject.hotelPrice : priceObject.price;
      }
    });
    return t;
  }, [edit, selection, booking.hotel]);

  const onSubmit = async () => {
    if (edit) {
      try {
        await updateBooking(booking.id, { seats: selection.map((seat) => (seat.id)) });
        const res = await checkCode(booking.code);

        navigation.reset({
          index: 0,
          routes: [
            { name: 'HomePage', params: {} },
            { name: 'SiteHome', params: { siteId: res.site.id, bookingId: res.id } },
          ],
        });
        setAlert({ color: 'success', title: t('common.success'), message: t('bookings.successChangingSeat') });
      } catch {
        setAlert({ color: 'error', title: t('common.error'), message: t('bookings.errorChangingSeat') });
      }
    } else {
      setBooking((prevBooking) => ({
        ...prevBooking,
        seats: selection.map((seat) => (seat.id)),
      }));
      navigation.navigate('HourSelect', { siteId });
    }
  };

  return (
    <View style={styles.safeArea}>
      <View style={styles.beachContainer}>
        <LoadingSpinner
          visible={isAvailableFetching || isCreatingBooking}
        />
        {(!isFetching && site) && (
          <Board amount={booking?.payment?.amount} map={map} style={styles.grid} />
        )}
        <View style={[commonStyles.fullViewDetailsContainer, styles.recap]}>
          <View style={commonStyles.fullViewButtonContainer}>
            <BookButton
              onSubmit={onSubmit}
              disabled={isAvailableFetching || isCreatingBooking || selection.length === 0}
            />
          </View>
          <View style={commonStyles.fullViewDetailsContainer2}>
            {!edit
            && (
            <View style={[commonStyles.fullViewDetailsPriceWrapper, styles.total]}>
              <Price
                price={total}
                color="secondaryLight"
                size="huge"
              />
            </View>
            )}
            <View style={[commonStyles.fullViewDetailsContent, styles.recapInfo]}>
              <View style={styles.date}>
                <Text size="larger" color="primary">
                  {dayjs(Array.isArray(booking.dates) ? booking.dates[0] : booking.dates).format('LL')}
                </Text>
              </View>
              {selection.length > 0 && selection.filter((sel) => sel).map((seatInfos) => (
                <View key={seatInfos.id} style={styles.recapSeat}>
                  <Text isBold color="light" style={[styles.seatName, styles.recapText]}>{seatInfos.name}</Text>
                  <TranslatedText value={seatInfos.furniture.name} style={styles.recapText} isBold color="primary" />
                </View>
              ))}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: theme.colors.beach,
  },

  beachContainer: {
    height: '100%',
    justifyContent: 'center',
    paddingTop: theme.sizings.small,
    paddingBottom: theme.sizings.large,
  },
  recap: {
    width: Dimensions.get('window').width - theme.sizings.medium,
    minHeight: 120,
    justifyContent: 'center',
    left: 8,
    bottom: theme.sizings.small,
  },

  recapInfo: {
    marginTop: -theme.sizings.small,
    width: '70%',
  },

  recapSeat: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  seatName: {
    paddingRight: theme.sizings.small,
  },

  recapText: {
    fontFamily: 'MontserratBold',
  },

  grid: {
    height: Dimensions.get('window').height,
    width: Dimensions.get('window').width,
    position: 'absolute',
    overflow: 'hidden',
  },
  item: {
    width: '100%',
    height: '100%',
  },
  total: {
    top: 35,
  },
});

SeatSelector.propTypes = {
  route: PropTypes.object.isRequired,
};

export default SeatSelector;
