import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

export const BeachVisualizerContext = createContext();

export const BeachVisualizerProvider = ({ children }) => {
  const [seats, setSeats] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [seatModal, setSeatModal] = useState(null);
  const [date, setDate] = useState(dayjs().utc().startOf('day').format());
  const [searchSeat, setSearchSeat] = useState('');
  const [mapId, setMapId] = useState(-1);

  const handleClickonSquare = useCallback((item) => {
    if (item.bookings.length > 0) {
      setSeatModal(item);
    } else if (item.furniture && item.price_area) {
      setSelectedSeats((previousSelectedSeats) => {
        let newSeats = [...previousSelectedSeats];

        if (previousSelectedSeats.find(({ id }) => id === item.id) === undefined) {
          newSeats.push(item);
        } else {
          newSeats = newSeats.filter(({ id }) => id !== item.id);
        }
        return newSeats;
      });
    }
  }, []);

  const removeSelectedSeat = useCallback((item) => {
    setSelectedSeats((previousSelectedSeats) => previousSelectedSeats.filter(({ id }) => id !== item.id));
  }, []);
  const value = useMemo(() => ({
    date,
    setDate,
    seats,
    setSeats,
    seatModal,
    searchSeat,
    setSearchSeat,
    setSeatModal,
    selectedSeats,
    setSelectedSeats,
    removeSelectedSeat,
    handleClickonSquare,
    setMapId,
    mapId,
  }), [
    date,
    seats,
    seatModal,
    searchSeat,
    selectedSeats,
    removeSelectedSeat,
    handleClickonSquare,
    setMapId,
    mapId,
  ]);

  return (
    <BeachVisualizerContext.Provider value={value}>
      {children}
    </BeachVisualizerContext.Provider>
  );
};

BeachVisualizerProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default () => useContext(BeachVisualizerContext);
