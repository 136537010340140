import React, { useEffect } from 'react';

import useSpecialOffers from 'specialOffers/contexts/specialOffers';
import OfferItem from 'waiter/components/OfferItem/OfferItem';
import Page from 'common/components/Page/Page';

const Catalog = () => {
  const {
    fetchItems, items: specialOffers = [],
  } = useSpecialOffers();

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <Page scroll padding>
      {specialOffers.map((specialOffer) => (
        <OfferItem key={specialOffer.id} specialOffer={specialOffer} />
      ))}
    </Page>
  );
};

export default Catalog;
