import React, { useCallback, useMemo } from 'react';
import {
  View, StyleSheet, ScrollView,
} from 'react-native';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import { useAuth } from 'auth/contexts/auth';
import FormInput from 'common/components/FormInput/FormInput';
import Button from 'common/components/Button/Button';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';

import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import PageBackground from 'common/components/PageBackground/PageBackground';

const styles = StyleSheet.create({

  fullFormView: {
    flex: 1,
    marginTop: theme.sizings.larger,
  },

  header: {
    paddingHorizontal: theme.sizings.large,
  },

  formView: {
    alignItems: 'center',
  },

  inputs: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: theme.sizings.large,
    paddingVertical: theme.sizings.large,
  },

  buttons: {
    width: '80%',
    height: 120,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const ResetPassword = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { code } = route.params;
  const { setAlert } = useAlert();
  const { resetPassword, isFetching } = useAuth();

  const onSubmit = async (payload) => {
    try {
      await resetPassword(payload);
      setAlert({ color: 'success', title: t('common.success'), message: t('auth.resetPasswordSuccess') });
      navigation.navigate('SignInPage');
    } catch {
      setAlert({ color: 'error', title: t('common.error'), message: t('auth.resetPasswordError') });
    }
  };

  const validate = useCallback((values) => {
    const errors = {};

    if (!values.password) {
      errors.password = t('errors.required');
    }
    if (!values.passwordConfirmation) {
      errors.passwordConfirmation = t('errors.required');
    } else if (values.passwordConfirmation !== values.password) {
      errors.passwordConfirmation = t('errors.mustMatch');
    }
    return errors;
  }, [t]);

  const initialValues = useMemo(() => ({ password: '', passwordConfirmation: '', code }), [code]);

  return (
    <>
    <PageBackground />
    <View style={styles.fullFormView}>
      <LoadingSpinner
        visible={isFetching}
      />
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => (
          <>
            <ScrollView>
              <View style={styles.formView}>
                <View style={styles.inputs}>
                  <FormInput
                    type="password"
                    name="password"
                    label={t('auth.password')}
                    icon="lock"
                  />
                  <FormInput
                    type="password"
                    name="passwordConfirmation"
                    label={t('auth.passwordConfirmation')}
                    icon="lock"
                  />
                </View>
              </View>
              <View style={commonStyles.scrollViewWithButton} />
            </ScrollView>
            <FooterButtonWrapper>
                <Button
                  variant="gradient"
                  icon="user"
                  text={t('common.send')}
                  onPress={handleSubmit}
                  disabled={submitting}
                  type="submit"
                />
             </FooterButtonWrapper>  
          </>
        )}
      />
    </View>
    </>
  );
};

ResetPassword.path = 'reset-passord';

ResetPassword.navigationOptions = {
  title: 'ResetPassword',
};

ResetPassword.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default ResetPassword;
