import React, {
  createContext, useContext, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';

import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';
import useAuth from 'auth/contexts/auth';
import useNotification from 'realtime/contexts/notification';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import useSite from 'sites/contexts/sites';

const BookingContext = createContext();

export const BookingsProvider = ({ children }) => {
  const [isBookingFetching, setIsBookingFetching] = useState(false);
  const { setSeat, setBooking } = useAuth();
  const { pushToken } = useNotification();
  const { setAlert } = useAlert();
  const { t, lang } = useTranslation();
  const { setLockOn } = useSite();

  const validBooking = useCallback(async (bookingId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/${bookingId}/valid`,
        method: 'PUT',
      });

      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const deactivateBooking = useCallback(async (bookingId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/${bookingId}/deactivate`,
        method: 'PUT',
      });

      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const checkCode = useCallback(async (code) => {
    setIsBookingFetching(true);

    try {
      if (code === 'STOP') {
        setLockOn(null);

        return;
      }
      if (code.length <= 5) {
        const booking = await fetchJSON({ url: `bookings/check-code/${code}`, method: 'GET' });

        if (booking.error) {
          throw new Error('Error while checking code');
        }
        setBooking(booking);
        if (booking.seats) {
          setSeat(booking.seats[0]);
        }

        // Store pushToken in booking
        if (pushToken) {
          fetchJSON({ url: `bookings/${booking.id}`, method: 'PUT', payload: { pushToken, language: lang } });
        }

        return booking;
      }
      const site = await fetchJSON({ url: `sites/check-code/${code}`, method: 'GET' });

      if (site.error) {
        console.log(site.error);
        throw new Error('Error while checking code');
      }

      setLockOn(site.id);
      return ('OK');
    } catch (e) {
      throw new Error('BAD_REQUEST');
    } finally {
      setIsBookingFetching(false);
    }
  }, [lang, pushToken, setBooking, setLockOn, setSeat]);

  const payAll = useCallback(async (bookingId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/${bookingId}/payAll`,
        method: 'GET',
      });

      setAlert(t('bookings.updatePaymentStatus'), 'success');
      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const fetchBookingAtPlace = useCallback(async (seatId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/findWithSeat/${seatId}`,
        method: 'GET',
      });

      if (res.error) {
        setAlert({ color: 'error', title: t('common.error'), message: res.message });
      }

      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const fetchBookingByRoom = useCallback(async (roomId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/findByRoom/${roomId}`,
        method: 'GET',
      });

      if (res.error) {
        setAlert({ color: 'error', title: t('common.error'), message: res.message });
      }

      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const notifyBooking = useCallback(async (bookingId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/callBooking/${bookingId}`,
        method: 'GET',
      });

      if (res.error) {
        setAlert({ color: 'error', title: t('common.error'), message: res.message });
      } else {
        setAlert({ color: 'success', title: t('common.success'), message: t('info.notifiedClient') });
      }

      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const value = useMemo(() => ({
    payAll,
    isBookingFetching,
    checkCode,
    deactivateBooking,
    fetchBookingAtPlace,
    fetchBookingByRoom,
    validBooking,
    notifyBooking,
  }), [
    payAll,
    isBookingFetching,
    fetchBookingAtPlace,
    fetchBookingByRoom,
    checkCode,
    deactivateBooking,
    validBooking,
    notifyBooking,
  ]);

  return (
    <ContextProvider
      url="bookings"
      context={BookingContext}
      value={value}
    >
      {children}
    </ContextProvider>
  );
};

BookingsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useBooking = () => useContext(BookingContext);

export default useBooking;
