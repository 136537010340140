import React from 'react';
import {
  View, StyleSheet, ScrollView, SafeAreaView,
} from 'react-native';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import { useAuth } from 'auth/contexts/auth';
import FormInput from 'common/components/FormInput/FormInput';
import Button from 'common/components/Button/Button';
import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';

import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';

import PageBackground from 'common/components/PageBackground/PageBackground';
import useSite from 'sites/contexts/sites';

const styles = StyleSheet.create({
  scrollview: {
    paddingHorizontal: theme.sizings.large,
    paddingTop: theme.sizings.large,
  },

  fullFormView: {
    flex: 1,
    paddingTop: theme.sizings.larger,
  },

  header: {
    paddingHorizontal: theme.sizings.large,
  },

  formView: {
    alignItems: 'center',
  },

  inputs: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',

  },

  buttons: {
    width: '80%',
    height: 120,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const ForgotPassword = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { callback } = route.params;
  const { setAlert } = useAlert();
  const { forgotPassword, isFetching } = useAuth();
  const { item: site } = useSite();

  const onSubmit = async (payload) => {
    try {
      await forgotPassword(payload);
      setAlert({ color: 'success', title: t('common.success'), message: t('auth.newPasswordSent') });
      navigation.navigate('SignInPage', { callback });
    } catch {
      setAlert({ color: 'error', title: t('common.error'), message: t('auth.forgotPasswordWrongEmail') });
    }
  };

  return (
    <>
      <PageBackground />
      <SafeAreaView style={{ flex: 1 }}>
        <View style={styles.fullFormView}>
          <LoadingSpinner
            visible={isFetching}
          />
          <View style={styles.header}>
            <Text
              size="large"
              centered
              customColor={site?.template?.backdropTextColor || theme.colors.light}
            >
              {t('auth.forgotPasswordMessage')}
            </Text>
          </View>
          <Form
            onSubmit={onSubmit}
            initialValues={{ email: '' }}
            render={({ handleSubmit, submitting }) => (
              <>
                <ScrollView style={styles.scrollview}>
                  <View style={styles.formView}>
                    <View style={styles.inputs}>
                      <FormInput
                        type="email"
                        name="email"
                        icon="envelope"
                        label={t('auth.email')}
                      />

                    </View>
                  </View>
                  <View style={commonStyles.scrollViewWithButton} />
                  <FooterButtonWrapper>
                    <Button
                      variant="light"
                      icon="user"
                      text={t('common.send')}
                      onPress={handleSubmit}
                      disabled={submitting}
                      type="submit"
                    />
                  </FooterButtonWrapper>
                </ScrollView>

              </>
            )}
          />
        </View>
      </SafeAreaView>
    </>
  );
};

ForgotPassword.path = 'signin';

ForgotPassword.navigationOptions = {
  title: 'ForgotPassword',
};

ForgotPassword.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

ForgotPassword.defaultProps = {
};

export default ForgotPassword;
