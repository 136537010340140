import { Dimensions, StyleSheet } from 'react-native';
import theme from 'styles';

export default StyleSheet.create({
  infoContainer: {
    alignItems: 'center',
  },

  buttonContainer: {
    width: Dimensions.get('window').width,
    height: '60%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  btnOpenDetail: {
    alignSelf: 'center',
    borderRadius: theme.radius.rounded,
    marginTop: 15,
    marginBottom: 10,
  },

  gradientBtnBackground: {
    borderRadius: theme.radius.rounded,
    paddingHorizontal: 20,
    height: 40,
    lineHeight: 40,
    justifyContent: 'center',
    marginBottom: theme.sizings.small,
  },

  textReorderBtn: {
    alignSelf: 'center',
    color: 'white',
    fontSize: theme.sizings.medium,
    fontFamily: theme.fonts.bold,
  },

  waiterIconView: {
    position: 'relative',
    width: 100,
    height: 100,
    backgroundColor: theme.colors.secondaryLight,
    borderRadius: theme.radius.rounded,
    marginBottom: theme.sizings.medium,
  },

  waiterIcon: {
    position: 'absolute',
    width: 80,
    height: 80,
  },

  currentOrderHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  orderItems: {
    marginTop: theme.sizings.medium,
  },

  currentOrderItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.sizings.small,
  },

  itemQuantity: {
    marginRight: theme.sizings.small,
    borderRadius: theme.radius.rounded,
    overflow: 'hidden',
    minWidth: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },

  itemImage: {
    width: 50,
    height: 50,
    borderRadius: theme.radius.rounded,
    overflow: 'hidden',
    marginRight: theme.sizings.small,
  },

  itemImageInner: {
    width: '100%',
    height: '100%',
  },

  absoluteContent: {
    position: 'absolute',
    paddingBottom: theme.sizings.small,
    bottom: 0,
    left: theme.sizings.small,
    paddingRight: theme.sizings.small * 2,
    width: '100%'
  },

  absoluteContainer: {
    borderRadius: theme.radius.medium,
    padding: theme.sizings.smallMedium,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    overflow: 'hidden',
  },

  buttons: {
    margin: theme.sizings.small,
  },

  bookingContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  yellowCode: {
    marginLeft: theme.sizings.small,
    position: 'relative',
    top: theme.sizings.tiny,
  },
});
