import React, {
  useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';

import fetchJSON from 'common/utils/fetchJSON';

const SORT_KEY = '_sort';
const START_KEY = '_start';
const LIMIT_KEY = '_limit';

export const ContextProvider = ({
  url, context: Context, children, value = {},
}) => {
  /* const ctx = useLocalOrders();

  let localHistoryUser;

  if (ctx) {
    localHistoryUser = ctx.localHistoryUser;
  } */

  const [item, setItem] = useState(null);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [sort, setSort] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [filters, setFilters] = useState({});

  const setFilter = useCallback((key, value) => {
    setFilters((prevFilters) => {
      if (value) {
        return ({ ...prevFilters, [key]: value });
      }
      const newFilters = { ...prevFilters };

      delete newFilters[key];
      return (newFilters);
    });
  }, [setFilters]);

  const fetchItems = useCallback(
    async ({ pageIndex = -1, pageSize = 1000 } = {}) => {
      const pIndex = pageIndex <= -1 ? lastPage : pageIndex;

      const params = {
        [START_KEY]: pageSize * pIndex,
        [LIMIT_KEY]: pageSize,
        ...filters,
      };

      /* if (url === 'orders' && localHistoryUser?.userId) {
        params.userId = localHistoryUser?.userId;
      } */

      setLastPage(pIndex);

      if (sort) {
        params[SORT_KEY] = sort;
      }

      const esc = encodeURIComponent;
      const queryParams = Object.keys(params)
        .map((key) => `${esc(key)}=${esc(params[key])}`)
        .join('&');

      setIsFetching(true);
      try {
        if (pIndex === 0 && pageSize < 1000) {
          const count = await fetchJSON({
            url: `${url}/count${queryParams ? '?' : ''}${queryParams}`,
            method: 'GET',
          });

          setPageCount(Math.ceil(count / pageSize));
        }

        const res = await fetchJSON({
          url: `${url}${queryParams ? '?' : ''}${queryParams}`,
          method: 'GET',
        });

        setItems(res);
        return res;
      } catch (e) {
        setError(e);
      } finally {
        setIsFetching(false);
      }
    },
    [lastPage, filters, url, sort],
  );

  const fetchItem = useCallback(async (id) => {
    if (!id) {
      setItem({});
      return;
    }

    setIsFetching(true);
    try {
      const res = await fetchJSON({ url: `${url}/${id}`, method: 'GET' });

      setItem(res);
      return res;
    } catch (e) {
      setError(e);
    } finally {
      setIsFetching(false);
    }
  }, [url]);

  const save = useCallback(async (payload) => {
    setIsFetching(true);
    try {
      const res = await fetchJSON({ url, method: 'POST', payload });

      setItem(res);
      return res;
    } catch (e) {
      setError(e);
    } finally {
      setIsFetching(false);
    }
  }, [url]);

  const update = useCallback(async (id, payload, shouldSetItem = true) => {
    setIsFetching(true);
    try {
      const res = await fetchJSON({ url: `${url}/${id}`, method: 'PUT', payload });

      /**
       * 1. Boolean to controll whether or not the item should be reset with the PUT response
       * 2. Set to false to prevent page crashing when page needs to use nested elements of the object
       * because res does contain populated nested items.
       */
      if (shouldSetItem) {
        setItem(res);
      }
      return res;
    } catch (e) {
      setError(e);
    } finally {
      setIsFetching(false);
    }
  }, [url]);

  const remove = useCallback(async (id) => {
    setIsFetching(true);
    try {
      const res = await fetchJSON({ url: `${url}/${id}`, method: 'DELETE' });

      return (res);
    } catch (e) {
      setError(e);
    } finally {
      setIsFetching(false);
    }
  }, [url]);

  const val = useMemo(() => ({
    fetchItems,
    fetchItem,
    pageCount,
    remove,
    update,
    save,
    items,
    item,
    error,
    isFetching,
    sort,
    setSort,
    filters,
    setFilter,
    setFilters,
    setIsFetching,
    setItem,
    setItems,
    ...value,
  }), [
    fetchItems,
    fetchItem,
    pageCount,
    remove,
    update,
    setIsFetching,
    save,
    setFilter,
    items,
    item,
    error,
    isFetching,
    filters,
    sort,
    value,
  ]);

  return (
    <Context.Provider value={val}>
      {children}
    </Context.Provider>
  );
};

ContextProvider.propTypes = {
  url: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  value: PropTypes.object,
};

ContextProvider.defaultProps = {
  value: {},
};

export default ContextProvider;
