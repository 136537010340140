import Price from 'common/components/Price/Price';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import formatPrice from 'common/utils/formatPrice';
import PropTypes from 'prop-types';
import React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import useSite from 'sites/contexts/sites';
import theme from 'styles';

const OrderItem = ({ item, variant, size }) => {
  const { item: site } = useSite();

  return (
    <View>
      <View style={styles.viewDetailContainer}>
        <View style={styles.viewLeftDetailContainer}>
          <TranslatedText size={size} color="#e8ad4c" value={item.name} />
          {variant?.option_variant && (
          <View style={styles.variant}>
            <TranslatedText value={variant.option_variant.name} />
          </View>
        )}
          <View style={styles.viewRightDetailContainer}>
            <Price
              viewStyle={{ justifyContent: 'flex-end', alignItems: 'center' }}
              style={{ fontSize: 15, color: '#b9b4be' }}
              price={item.price * item.quantity}
              color="dark"
            />
          </View>

        </View>
        <View
          style={[
            styles.textQuantity,
            {
              backgroundColor: (item.state === 'done'
              || item.state === 'ready' ? '#cce0ff' : theme.colors.secondaryLight),
            },
          ]}
        >
          <Text size={size} color="dark">{`x${item.quantity}`}</Text>
        </View>
      </View>
      <View style={{ marginLeft: theme.sizings.large }}>
        {item.selected_options.map((option) => (
          <>
            {option.variants.map((variant, index) => (
              <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <TranslatedText value={variant.name} />
                <Text isBold color="greyDarker">
                  {formatPrice(variant.price ? variant.price : 0, site.currency)}
                </Text>
              </View>
            ))}
          </>
        ))}
      </View>
    </View>
  );
};

OrderItem.propTypes = {
  item: PropTypes.object.isRequired,
  variant: PropTypes.object,
  size: PropTypes.string,
};

OrderItem.defaultProps = {
  variant: {},
  size: 'large',
};

const SCREEN_WIDTH = Dimensions.get('screen').width;

const styles = StyleSheet.create({
  variant: {
    marginLeft: theme.sizings.small,
    backgroundColor: theme.colors.secondaryLight,
    paddingHorizontal: theme.sizings.small,
    borderRadius: theme.radius.rounded,
  },

  viewLeftDetailContainer: {
    flex: 0.6,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  viewDetailContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
  },

  textQuantity: {
    borderRadius: theme.sizings.medium,
    marginLeft: SCREEN_WIDTH * 0.02,
    paddingLeft: SCREEN_WIDTH * 0.02,
    paddingRight: SCREEN_WIDTH * 0.02,
  },

  viewRightDetailContainer: {
    flex: 0.4,
    justifyContent: 'flex-end',
  },
});

export default OrderItem;
