import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as ScreenOrientation from 'expo-screen-orientation';
import useTranslation from 'common/contexts/translations';

import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import useAuth from 'auth/contexts/auth';
import { Platform } from 'react-native';
import useSite from 'sites/contexts/sites';
import dayjs from 'dayjs';
import AsyncStorage from '@react-native-async-storage/async-storage';

const HomeRedirect = ({ navigation }) => {
  const { t } = useTranslation();
  const { refreshUser } = useAuth();
  const [fetchUserDone, setfetchUserDone] = useState(false);
  const { setItem, fetchSiteData } = useSite();

  useEffect(() => {
    const fetchUser = async () => {
      const user = await refreshUser();

      /* Platform.OS !== 'web' && ScreenOrientation.lockAsync(
        ScreenOrientation.OrientationLock[(user && [
          'waiter',
          'barman',
          'reception',
          'manager'].includes(user.role?.type))
          ? 'DEFAULT'
          : 'PORTRAIT_UP'],
      ); */

      let siteId = null;

      if (Platform.OS === 'web') {
        const params = new URLSearchParams(window.location.search);

        siteId = params.get('site');

        if (siteId) {
          const newData = { date: dayjs().add('1', 'day').toISOString(), siteId };

          await AsyncStorage.setItem('sess', JSON.stringify(newData));
          window.history.replaceState({}, document.title, '/');
        } else {
          const data = await AsyncStorage.getItem('sess');

          if (data) {
            try {
              const parsedData = JSON.parse(data);

              if (dayjs().isBefore(parsedData.date)) {
                siteId = parsedData.siteId;
                const newData = { date: dayjs().add('1', 'day').toISOString(), siteId };

                await AsyncStorage.setItem('sess', JSON.stringify(newData));
              }
            } catch (e) {
              console.error(e);
            }
          }
        }
        if (siteId) {
          fetchAndSetData(siteId);
        }
      }

      const isPro = user && [
        'waiter',
        'barman',
        'reception',
        'manager',
      ].includes(user.role?.type);

      const isMobile = Platform.OS !== 'web';

      navigation.reset({
        index: 0,
        routes: [{
          name: isPro ? 'WaiterHome'
            : isMobile ? 'SignInPage'
              : siteId ? 'SiteHome' : 'SignInPage',
        }],
      });
    };

    if (fetchUserDone === false) {
      setfetchUserDone(true);
      fetchUser();
    }
  }, [refreshUser, fetchUserDone, navigation, fetchAndSetData]);

  const fetchAndSetData = useCallback(async (siteId) => {
    const site = await fetchSiteData(siteId);

    setItem(site);
    /* navigation.navigate('SiteHome', { siteId }); */
  }, [fetchSiteData, setItem]);

  /* if (Platform.OS === 'web') {
    const params = new URLSearchParams(window.location.search);

    const siteId = params.get('site');

    if (siteId) {
      fetchAndSetData(siteId);
    }
  } */

  return (
    <LoadingSpinner
      visible
      textContent={t('common.loading')}
    />
  );
};

HomeRedirect.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default HomeRedirect;
