import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import Gradient from 'common/components/Gradient/Gradient';
import { ImageBackground } from 'common/components/Images';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import useSite from 'sites/contexts/sites';
import theme from 'styles';
import styles from './Button.styles';

const Button = ({
  style, variant, text, isFullwidth, onPress, icon, iconImage, iconColor, iconSize, noPadding, disabled,
  colors, font, keepFont, textSize, iconFontV5, ...rest
}) => {
  const { lang, t } = useTranslation();
  const { item: site } = useSite();
  const template = site?.template;

  let textNode = <></>;
  const hasText = Boolean(text);
  const darkIconColor = template?.secondaryColor || theme.colors.dark;
  const darkTextColor = template?.secondaryShadeColor || theme.colors.dark;
  const templateLightButton = template?.secondaryTextColor || theme.colors.light;

  if (hasText || icon) {
    textNode = (
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: hasText ? theme.sizings.medium : undefined,
      }}
      >
        {icon ? (
          <View style={{
            marginRight: (lang !== 'ar' && text) ? theme.sizings.small : 0,
            width: theme.fontSizes[iconSize],
            alignItems: 'center',
          }}
          >
            {iconImage ? (
              <ImageBackground
                image={icon}
                style={{ width: theme.fontSizes[iconSize], height: theme.fontSizes[iconSize] }}
                resizeMode="contain"
              />
            ) : (
              iconFontV5 ? (
                <FontAwesome5
                  name={icon}
                  size={theme.fontSizes[iconSize]}
                    // color={disabled ? theme.colors.greyDarkest : theme.colors[iconColor]}
                  color={disabled ? theme.colors.disabled
                    : (variant === 'light' || variant === 'greyDarker' || variant === 'greyLighter') ? darkIconColor
                      : theme.colors.light}
                />
              ) : (
                <FontAwesome
                  name={icon}
                  size={theme.fontSizes[iconSize]}
                      // color={disabled ? theme.colors.greyDarkest : theme.colors[iconColor]}
                  color={disabled ? theme.colors.disabled
                    : (variant === 'light' || variant === 'greyDarker' || variant === 'greyLighter') ? darkIconColor
                      : theme.colors.light}
                />

              )
            )}
          </View>
        ) : null}
        {hasText ? (
          <Text
            color={disabled ? 'disabled' : (
              (variant === 'light' || variant === 'greyDarker' || variant === 'greyLighter') ? 'dark'
                : 'light')}
            customColor={!disabled && (variant === 'light' || variant === 'greyDarker' || variant === 'greyLighter')
              ? darkTextColor
              : null}
            size={textSize}
            isBold
            isButtonText={!keepFont}
            centered
          >
            {text}
          </Text>
        ) : null}

      </View>
    );
  }

  return (
    <TouchableOpacity
      type="submit"
      onPress={onPress}
      disabled={disabled}
      style={[
        icon && hasText && styles(variant, isFullwidth, noPadding, disabled, templateLightButton).iconButton,
        icon && !hasText && styles(variant, isFullwidth, noPadding, disabled, templateLightButton).iconOnly,
        !icon && styles(variant, isFullwidth, noPadding, disabled, templateLightButton).button,
        variant === 'light' && { borderWidth: 3, borderColor: template?.secondaryColor || theme.colors.dark },
        style,
      ]}
      hitSlop={icon ? {
        top: 10, bottom: 10, left: 10, right: 10,
      } : null}
      {...rest}
    >
      {!disabled && variant === 'gradient' && <Gradient height={80} colors={colors} />}

      {textNode}

    </TouchableOpacity>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.node,
  icon: PropTypes.string,
  iconImage: PropTypes.bool,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  variant: PropTypes.string,
  isFullwidth: PropTypes.bool,
  noPadding: PropTypes.bool,
  onPress: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  colors: PropTypes.array,
  font: PropTypes.string,
  keepFont: PropTypes.bool,
  textSize: PropTypes.string,
  iconFontV5: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  variant: 'primary',
  icon: '',
  iconImage: false,
  iconColor: 'light',
  iconSize: 'large',
  isFullwidth: false,
  noPadding: false,
  style: {},
  onPress: () => { },
  text: '',
  colors: null,
  font: null,
  keepFont: false,
  textSize: 'large',
  iconFontV5: false,
};

export default Button;
