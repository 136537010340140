import React, { useCallback, useState } from 'react';
import { View, StyleSheet, ScrollView, TouchableHighlight, Dimensions } from 'react-native';
import PropTypes from 'prop-types';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import Button from 'common/components/Button/Button';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import ReductionPrice from 'common/components/ReductionPrice/ReductionPrice';
import useOffer from 'specialOffers/hooks/use-offer';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import ItemCardProduct from 'common/components/ItemCardProduct/ItemCardProduct';
import useBasket from 'basket/contexts/basket';
import Gradient from 'common/components/Gradient/Gradient';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import useSite from 'sites/contexts/sites';
import ProductBackground from 'assets/defaultBackground.jpg';

const styles = StyleSheet.create({
  productsLabel: {
    marginBottom: theme.sizings.small,
  },
  productsLabelGrid: {
    padding: theme.sizings.small,
  },
  description: {
    paddingVertical: theme.sizings.small,
  },
  buttonsGroupBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingVertical: theme.sizings.small,
  },
  buttonsGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.radius.rounded,
    overflow: 'hidden',
    paddingHorizontal: theme.sizings.small,
    marginHorizontal: theme.sizings.small
  },
  quantity: {
    marginVertical: 0,
    marginHorizontal: theme.sizings.small,
  },
  quantitySingle: {
    marginVertical: 0,
    align: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  productButton: {
    paddingHorizontal: theme.sizings.small,
  },
  icon: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.radius.rounded,
    marginRight: theme.sizings.small
  },
  optionsTitle: {
    paddingTop: theme.sizings.medium,
  },
  optionsTitleText: {
    justifyContent: 'center',
  },
  options: {
    padding: theme.sizings.small,
  },
  optionVariants: {
    paddingTop: theme.sizings.smallMedium,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  option: {
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.tiny,
    marginRight: theme.sizings.small,
    marginBottom: theme.sizings.small,
    minWidth: 80,
    alignItems: 'center',
    borderRadius: theme.radius.rounded,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.32)',
  },
});

const ProductItem = ({ product, navigation, template }) => {
  const {
    id, name, images, description, options, variant_prices: variantPrices, price,
  } = product;
  const { findProductOffer } = useOffer();
  const {item: site} = useSite();
  const reduction = findProductOffer(product);
  const {setIsAllInclusiveBasket} = useBasket()

  const minPrice = (options && options.length > 0)
    ? price + Math.min(...options.map((o) => Math.min(...o.variant_prices.map((vp) => vp.price))))
    : price;
  
  const maxPrice = (options && options.length > 0)
    ? price + Math.max(...options.map((o) => Math.max(...o.variant_prices.map((vp) => vp.price))))
    : price;

  const handlePress = () => {
    navigation.navigate('ProductDetail', { product, reduction, template });
  };

  const isTablet = Dimensions.get('window').width > 767 && Dimensions.get('window').height > 767;

  const calculateAdditionalOptionsPrice = useCallback((currentSelectedOptions) => {
    const totalPrice = product.options.map(
      (option) => {
        return option.variant_prices.map(
          (variantPrice) => {
            const optionPrice = currentSelectedOptions.find(
                (selectedOption) => selectedOption.option === option.option.id
              ).variants.includes(variantPrice.option_variant.id);
            return optionPrice ? variantPrice.price : 0;
          }
        ).reduce((partial, item) => partial + item, 0);
      }
    ).reduce((partial, item) => partial + item, 0);
    // return only the additionnal price for options
    return totalPrice ? totalPrice : 0;
  }, [product]);

  const handlePressOption = useCallback((optionIndex, variantId, variantPrice, multiple) => {
    const selectedCopy = [...selectedOptions];

    if (multiple) {
      const variantIndex = selectedCopy[optionIndex].variants.findIndex((v) => v === variantId);

      if (variantIndex >= 0) {
        selectedCopy[optionIndex].variants.splice(variantIndex, 1);
      } else {
        selectedCopy[optionIndex].variants.push(variantId);
      }

    } else {
      selectedCopy[optionIndex].variants = (selectedCopy[optionIndex].variants.length > 0
        && selectedCopy[optionIndex].variants[0].id === variantId)
        ? []
        : [variantId];
    }

    const offset = calculateAdditionalOptionsPrice(selectedCopy);

    setSelectedOptions(selectedCopy);
    setOptionsAdditionnalPrice(offset);
  }, [optionsAdditionnalPrice, selectedOptions]);

  /* ajouter le produit au panier */
  const { addItem, removeItem, items: basket, isAllInclusiveBasket } = useBasket();
  const [selectedOptions, setSelectedOptions] = useState(options.map((o) => ({ option: o.option.id, variants: [] })));
  const [optionsAdditionnalPrice, setOptionsAdditionnalPrice] = useState(0);
  const quantity = basket.filter((item) => item.product.id === id)[0]?.quantity || 0;
  const isFree = isAllInclusiveBasket && site?.all_inclusive && product.all_inclusive;

  return (
    <View key={id}
      style={{
        width: template?.productDisplayType === 'grid' && template?.productsByColumn !== '1' ? '50%' : '100%',
        paddingHorizontal: theme.sizings.medium
      }}>

      <ItemCardProduct
        onPress={(template?.isProductDetailVisible && !options.length) ? handlePress : () => { }}
        image={template?.productDisplayType !== 'list' ? images[0] || ProductBackground : null}
        layout={template?.productDisplayType}
      >

        {/* LAYOUT 1 : titre spécial pour les très grandes images */}
        {template?.productDisplayType === 'grid' && (
          <>
            <View style={styles.productsLabelGrid}>
              <TranslatedText style={{ color: theme.colors.dark }} isBold isTitleText size="medium" value={name} />
            </View>

            {/* jolis prix sur très grandes images */}
            {template?.isPriceVisible && (
              <View style={[commonStyles.priceProductWrapper,
              {
                height: reduction ? 95 : 60,
                backgroundColor: theme.colors.greyLighter
              }
              ]}>
                <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  {isFree && (
                    <FontAwesome5
                      name="gift"
                      color={template?.cardTextColor || theme.colors.dark}
                      size={theme.fontSizes.medium}
                      style={{
                        marginRight: theme.sizings.small,
                      }}
                    />
                  )}
                  <ReductionPrice
                    price={isFree ? 0 : minPrice + optionsAdditionnalPrice}
                    reduction={reduction}
                    reductionPriceColor={template?.cardTextColor || theme.colors.dark}
                  />
                  {!isFree
                  && maxPrice !== minPrice + optionsAdditionnalPrice
                  && product.options.length > 0
                  && !selectedOptions.filter((option) => option.variants.length > 0).length > 0
                  && (
                    <>
                      <Text size="larger"> - </Text>
                      <ReductionPrice
                        price={maxPrice}
                        reduction={reduction}
                        reductionPriceColor={template?.cardTextColor || theme.colors.dark}
                      />
                    </>
                  )}
                </View>
              </View>
            )}
          </>
        )}

        {/* LAYOUT 2 : titre spécial pour les listes */}
        {template?.productDisplayType !== 'grid' && (
          <View style={styles.productsLabel}>
            <TranslatedText isBold isTitleText size="large" value={name} />

            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              {/* prix simples sur les listes */}
              {template?.isPriceVisible && (
                <>
                    {isFree && (
                      <FontAwesome5
                        name="gift"
                        color={template?.cardTextColor || theme.colors.dark}
                        size={theme.fontSizes.medium}
                        style={{
                          marginRight: theme.sizings.small,
                        }}
                      />
                    )}
                  <ReductionPrice
                    price={isFree ? 0 : minPrice + optionsAdditionnalPrice}
                    reduction={reduction}
                    reductionPriceColor={template?.cardTextColor || theme.colors.dark}
                  />
                </>
              )}
              {template?.isPriceVisible
              && !isFree 
              && maxPrice !== minPrice + optionsAdditionnalPrice
              && product.options.length > 0
              && !selectedOptions.filter((option) => option.variants.length > 0).length > 0
              && (
                <>
                  <Text size="larger"> - </Text>
                  <ReductionPrice
                    price={maxPrice}
                    reduction={reduction}
                    reductionPriceColor={template?.cardTextColor || theme.colors.dark}
                  />
                </>
              )}
            </View>
          </View>
        )}


        {/* description seulement si pleine largeur */}
        {(template?.productDisplayType !== 'grid' || template?.productsByColumn === '1') && (
          <View style={[
            styles.description,
            template?.productDisplayType === 'grid' && { paddingHorizontal: theme.sizings.small }
          ]}>
            <TranslatedText tagsStyles={{ p: { color: theme.colors.dark } }} value={description} 
            //truncateLength={50} 
            />
          </View>
        )}

        {options.length > 0
          && (
            <View style={styles.options}>
              { /* eslint-disable-next-line camelcase  */}
              {options.map(({ option, variant_prices }, optionIndex) => (
                <View key={`option_${optionIndex}`}>
                  <View style={styles.optionsTitleText}>
                    <TranslatedText color="dark" value={option.name} />
                  </View>
                  <View style={styles.optionVariants}>
                    {variant_prices.map((variant) => {
                      const isSelected = selectedOptions[optionIndex].variants.find((v) => v === variant.option_variant.id);

                      return (
                        <TouchableHighlight
                          key={variant.id}
                          style={[styles.option, {
                            backgroundColor: isSelected ? 'rgba(0, 0 , 0, 0.32)' : 'transparent',
                          }]}
                          onPress={() => handlePressOption(optionIndex, variant.option_variant.id, variant.price, option.multiple)}
                        >
                          <View>
                            <TranslatedText color="dark" value={variant.option_variant.name} />
                          </View>
                        </TouchableHighlight>
                      )
                    })}
                  </View>
                </View>
              ))} 
            </View>
          )}

        <View style={styles.buttonsGroupBox}>
          {/* on affiche les boutons d'ajouts seulement si pas d'options, OU si options dont toutes sont précisées */}
          {(!options.length || (options.length && !selectedOptions.find((o) => !o.variants.length))) && (
            /* s'il y'a des options, on se contente du bouton "+", sans les quantités */
            (quantity < 1 || options.length) ? (
              <View style={[commonStyles.cardButtonProductWrapper, { marginLeft: theme.sizings.medium }]}>
                <TouchableOpacity
                  onPress={() => {
                    addItem(product,
                      // eslint-disable-next-line camelcase
                      selectedOptions,
                      product.price + optionsAdditionnalPrice); // variantSelected ? variantSelected.price : product.price);
                      if (site?.pleaseMyCar) {
                        if(site?.all_inclusive && product?.all_inclusive){
                          setIsAllInclusiveBasket(true)
                        }
                        navigation.navigate('Basket');
                      }
                  }}>
                  <View style={[styles.icon, {
                        width: isTablet ? 60 : 32,
                        height: isTablet ? 60 : 32,
                  }]}>
                    <Gradient height={isTablet ? 60 : 32} />
                    <Text size="large" color="light" 
                    style={[styles.quantitySingle, {
                      height: isTablet ? 60 : 32,
                      width: isTablet ? 60 : 32,
                      lineHeight: isTablet ? 60 : 32,
                    }]}>
                      <FontAwesome name="plus" color={theme.colors.light} size={theme.fontSizes.medium} />
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            ) : (
              <View style={[commonStyles.cardButtonProductWrapper, styles.buttonsGroup, {width: isTablet ? 160 : 100}]}>
                <Gradient height={isTablet ? 60 : 32} />
                <Button
                  noPadding
                  keepFont
                  icon="minus"
                  variant="transparent"
                  iconSize="default"
                  style={[styles.productButton, {
                    height: isTablet ? 60 : 32,
                    width: isTablet ? 60 : 32,
                  }]}
                  onPress={() => removeItem(product, selectedOptions, 'product')}
                />
                <Text size="large" color="light" style={styles.quantity} isTitleText>{quantity}</Text>
                <Button
                  noPadding
                  keepFont
                  icon="plus"
                  variant="transparent"
                  iconSize="default"
                  style={[styles.productButton, {
                    height: isTablet ? 60 : 32,
                    width: isTablet ? 60 : 32,
                  }]}
                  onPress={() => {
                    addItem(product, selectedOptions, price + optionsAdditionnalPrice, 'product');
                    if (site?.pleaseMyCar) {
                      if(site?.all_inclusive && product?.all_inclusive){
                        setIsAllInclusiveBasket(true)
                      }
                      navigation.navigate('Basket');
                    }
                  }}
                />
              </View>
            )
          )}

          {template?.isProductDetailVisible && options.length > 0 && (
            <View style={commonStyles.cardButtonProductWrapper}>
              <TouchableOpacity onPress={handlePress}>
                <View style={styles.icon}>
                  <Gradient height={isTablet ? 60 : 32} />
                  <View style={{ 
                    height: isTablet ? 60 : 32,
                    width: isTablet ? 60 : 32,
                    justifyContent: 'center', alignItems: 'center' }}>
                    <FontAwesome name="arrow-right" color={theme.colors.light} size={theme.fontSizes.medium} />
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>


      </ItemCardProduct>
    </View>
  );
}

ProductItem.propTypes = {
  navigation: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
};

const renderProducts = (products, navigation, template) => (
  <View style={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap', width: '100%' }}>
    {products?.map((product) => (
      <ProductItem key={`product_${product.id}`} product={product} navigation={navigation} template={template} />
    ))}
  </View>
);

const CategoryDetail = ({ route, navigation }) => {
  const { item: site } = useSite();

  const rootCategories = site?.categories?.filter(({ parent }) => !parent).reduce((acc, category) => {
    // check if children categories of current category has products
    const count = category.children.reduce((prev, curr) => prev + curr.products.length, category.products.length);

    // if current category and its children doesn't have any products, we don't display it
    if (count > 0) {
      return [...acc, { ...category, count }];
    }

    return acc; 
  }, []).sort((a, b) => Number(a.ordering || 0) - Number(b.ordering || 0)) || [];

  const isTablet = Dimensions.get('window').width > 767 && Dimensions.get('window').height > 767;


  return (
    <Page backgroundImage={site?.template.imageBackdrop} >
      <Gradient height={50} alternative />
      <View style={{
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        height: isTablet ? 75 : 50,
        paddingHorizontal: theme.sizings.tiny,
      }}>
 
        {rootCategories.map((rcat, index) => {
          const current = rcat.id === route.params.category?.id;
          return (
            <TouchableOpacity
              key={rcat.id}
              onPress={() => {
                navigation.navigate('CategoryDetail', { 
                  siteId: site?.id, category: rootCategories[index]
                });
              }}

              style={{
                borderBottomWidth: current ? 4 : 0,
                borderBottomColor: site?.template?.primaryTextColor || theme.colors.light,
                paddingVertical: theme.sizings.tiny,
                paddingHorizontal: theme.sizings.medium,
                height: 50,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TranslatedText
                isBold={current}
                size="medium"
                value={rcat.name}
                customColor={site?.template?.primaryTextColor || theme.colors.light}
              />
            </TouchableOpacity>
          )
        })}
      </View>
      <ScrollView style={{ paddingVertical: theme.sizings.medium }}>
        {renderProducts(route.params.category?.products, navigation, site?.template)}
        {route.params.category?.children?.map(({ id, name, products }) => (
          <View key={id}>
            <View style={[
              styles.productsLabel,
              { paddingHorizontal: theme.sizings.medium, marginBottom: theme.sizings.medium }
            ]}>
              <TranslatedText
                isBold isTitleText color="dark" size="larger"
                customColor={site?.template?.backdropTextColor} value={name}
              />
            </View>
            {renderProducts(products, navigation, site?.template)}
          </View>
        ))}
      </ScrollView>
    </Page>
  );
};

CategoryDetail.propTypes = {
  route: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

export default CategoryDetail;
