import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigation } from '@react-navigation/native';
import { View } from 'react-native';

import Button from 'common/components/Button/Button';

const NavigationButton = ({
  title, link, params, variant, icon, iconImage, styles, alsoOnPress, navigate, font,
  iconFontV5, isExtraLink,
}) => {
  const navigation = useNavigation();

  const handlePress = () => {
    alsoOnPress();
    if (navigate) {
      navigation.navigate(link, params);
    }
  };

  return isExtraLink ? (
    <Link
      to={link}
      style={{
        alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center',
      }}
    >
      <Button
        icon={icon}
        iconImage={iconImage}
        text={title}
        variant={variant}
        onPress={() => {}}
        style={{ ...styles }}
        font={font}
        iconFontV5={iconFontV5}
      />
    </Link>
  ) : (
    <Button
      icon={icon}
      iconImage={iconImage}
      text={title}
      variant={variant}
      onPress={handlePress}
      style={{ ...styles }}
      font={font}
      iconFontV5={iconFontV5}
    />
  );
};

NavigationButton.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  variant: PropTypes.string,
  icon: PropTypes.string,
  iconImage: PropTypes.bool,
  styles: PropTypes.object,
  alsoOnPress: PropTypes.func,
  navigate: PropTypes.bool,
  font: PropTypes.string,
  iconFontV5: PropTypes.bool,
  isExtraLink: PropTypes.bool,
};

NavigationButton.defaultProps = {
  icon: '',
  iconImage: false,
  variant: 'light',
  styles: {},
  alsoOnPress: () => { },
  navigate: true,
  font: null,
  iconFontV5: false,
  isExtraLink: false,
};

export default NavigationButton;
