import { useState, useEffect, useCallback } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

const usePersistedState = (initialValue, key) => {
  const [storedValue, setStoredValue] = useState(initialValue);

  const valueIsArray = Array.isArray(initialValue);

  useEffect(() => {
    const getStoredValue = async () => {
      try {
        const value = await AsyncStorage.getItem(key);

        if (value !== null) {
          let v = JSON.parse(value);

          if (valueIsArray && !Array.isArray(JSON.parse(value))) {
            v = [];
          }
          setStoredValue(v);
        }
      } catch (error) {
        // Handle error (e.g., unable to retrieve data from storage)
        console.error('Error retrieving data from AsyncStorage:', error);
      }
    };

    getStoredValue();
  }, [key, valueIsArray]);

  const setValue = useCallback(
    async (value) => {
      try {
        let v;

        setStoredValue(value);
        if (typeof value === 'function') {
          v = value(storedValue);
        } else {
          v = value;
        }
        await AsyncStorage.setItem(key, JSON.stringify(v));
      } catch (error) {
        // Handle error (e.g., unable to store data in AsyncStorage)
        console.error('Error storing data in AsyncStorage:', error);
      }
    },
    [key, storedValue],
  );

  return [storedValue, setValue];
};

export default usePersistedState;
