/* eslint-disable no-restricted-syntax */
import React, {
  useContext, useCallback, createContext, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import usePersistedState from 'common/utils/usePersistedState';
import useSite from 'sites/contexts/sites';

const BasketContext = createContext();

export const BasketProvider = ({ children }) => {
  const [items, setItems] = useState([], 'basket');
  const { item: site } = useSite();

  // only for basic user frontend, before order was created at basket validation
  const [isAllInclusiveBasket, setIsAllInclusiveBasket] = usePersistedState(false, 'allInclusive');

  const addItem = useCallback((toAdd, selectedOptions, price, type = 'product', quantity = 1) => {
    const newList = [...items];

    const found = newList.find((item) => (
      item[type] && item[type].id === toAdd.id && _.isEqual(item.selected_options, selectedOptions)));

    if (found !== undefined) {
      found.quantity += 1;
    } else {
      const isFree = toAdd.all_inclusive && site?.all_inclusive && isAllInclusiveBasket;

      const newItem = {
        name: toAdd.name,
        [type]: toAdd,
        selected_options: _.cloneDeep(selectedOptions),
        reservation: null,
        price: isFree ? 0 : price,
        description: toAdd.description,
        quantity,
        all_inclusive: toAdd.all_inclusive,
        orderStartTime: new Date().getTime(),
      };

      if (type === 'product') {
        newItem.service = null;
      } else {
        newItem.product = null;
      }

      newList.push(newItem);
    }
    setItems(newList);
  }, [setItems, items, site, isAllInclusiveBasket]);

  const removeItem = useCallback((toRemove, selectedOptions, type = 'product') => {
    const newList = [...items];
    const found = newList.find((item) => (
      item[type] && item[type].id === toRemove.id && _.isEqual(item.selected_options, selectedOptions)));

    if (found !== undefined && found.quantity > 1) {
      found.quantity -= 1;
      setItems(newList);
    } else if (found !== undefined && found.quantity <= 1) {
      setItems(newList.filter((item) => (
        !(item[type].id === toRemove.id && _.isEqual(item.selected_options, selectedOptions)))));
    }
  }, [setItems, items]);

  const removeAllOf = useCallback((toRemove, type = 'product') => {
    const newList = [...items];
    const found = newList.find((item) => (
      item[type] && item[type].id === toRemove?.id));

    if (found !== undefined) {
      setItems(newList.filter((item) => (
        !(item[type] && item[type].id === toRemove.id))));
    }
  }, [setItems, items]);

  const addForms = useCallback((product, productForms, type = 'product') => {
    const newList = [...items.filter((item) => (
      !(item[type] && item[type].id === product.id)))];

    productForms.forEach((form) => {
      const isFree = product.all_inclusive && site?.all_inclusive && isAllInclusiveBasket;

      const newItem = {
        name: product.name,
        [type]: product,
        selected_options: _.cloneDeep(form.selectedOptions),
        reservation: null,
        price: isFree ? 0 : product.price + form.offsetPrice,
        description: product.description,
        quantity: form.quantity,
      };

      if (type === 'product') {
        newItem.service = null;
      } else {
        newItem.product = null;
      }

      newList.push(newItem);
    });
    setItems(newList);
  }, [setItems, items, site, isAllInclusiveBasket]);

  const value = useMemo(() => ({
    addItem,
    removeItem,
    items,
    setItems,
    removeAllOf,
    addForms,
    isAllInclusiveBasket,
    setIsAllInclusiveBasket,
  }), [
    addItem,
    removeItem,
    items,
    setItems,
    removeAllOf,
    addForms,
    isAllInclusiveBasket,
    setIsAllInclusiveBasket,
  ]);

  return (
    <BasketContext.Provider value={value}>
      {children}
    </BasketContext.Provider>
  );
};

BasketProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

BasketProvider.defaultProps = {
};

export const useBasket = () => useContext(BasketContext);
export default useBasket;
