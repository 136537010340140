import React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import useSite from 'sites/contexts/sites';
import { CheckBox } from 'react-native-elements/dist/checkbox/CheckBox';
import { LinearGradient } from 'expo-linear-gradient';
import getWording from 'common/utils/getWording';
import useTranslation from 'common/contexts/translations';
import useTrans from 'common/hooks/use-trans';
import Text from '../../../common/components/Text/Text';

const CheckboxCardOption = ({
  index,
  selected, setSelected,
  param, paramTitle, tParamTitle, paramLegende, tParamLegende,
  selectedParam, setSelectedParam, setTSelectedParam,
  icon,
}) => {
  const {
    item: site,
  } = useSite();
  const { lang } = useTranslation();
  const { translateText } = useTrans();

  return (
    <TouchableOpacity
      key={`hour-${index}`}
      onPress={() => {
        setSelected(!selected);
        setSelectedParam(selectedParam === param ? '' : param);
        setTSelectedParam(selectedParam === param ? null : tParamTitle);
      }}
      style={[styles.button, commonStyles.shadowBottom]}
    >
      <LinearGradient
        colors={selectedParam === param
          ? [
            site?.template?.primaryColor || theme.colors.secondary,
            site?.template?.primaryShadeColor || theme.colors.secondaryDark,
          ] : [
            theme.colors.greyLightest, theme.colors.greyLightest,
          ]}
        start={[0, 0.5]}
        end={[1, 0.5]}
        style={styles.linearGradient}
      >
        <View style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          {paramLegende && (
          <Text
            customColor={selectedParam === param
              ? site?.template?.primaryTextColor || theme.colors.light
              : theme.colors.dark}
            size="small"
          >
            {getWording(
              tParamLegende,
              paramLegende,
              lang,
              translateText,
            )}
          </Text>
          )}
          <View style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          >
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
            }}
            >
              <FontAwesome
                name={icon}
                color={selectedParam === param
                  ? (site?.template?.primaryTextColor || theme.colors.light)
                  : theme.colors.darkGrey}
                size={theme.fontSizes.medium}
                style={{
                  paddingVertical: theme.sizings.tiny,
                }}
              />
              <Text
                isBold
                customColor={selectedParam === param
                  ? (site?.template?.primaryTextColor || theme.colors.light)
                  : theme.colors.dark}
                size="large"
                style={{ paddingHorizontal: theme.sizings.medium }}
              >
                {getWording(
                  tParamTitle,
                  paramTitle,
                  lang,
                  translateText,
                )}
              </Text>
            </View>
            <View style={styles.checkboxWrapper}>
              <CheckBox
                checkedIcon="check-circle-o"
                uncheckedIcon="circle-o"
                checked={selectedParam === param}
                onPress={() => {
                  setSelected(!selected);
                  setSelectedParam(selectedParam === param ? '' : param);
                }}
                checkedColor={site?.template?.primaryTextColor || theme.colors.light}
              />
            </View>

          </View>
        </View>
      </LinearGradient>

    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  innerView: {
    paddingHorizontal: theme.sizings.small,
    paddingTop: theme.sizings.medium,
  },
  linearGradient: {
    width: '100%',
    height: '100%',
    paddingVertical: theme.sizings.medium,
    paddingHorizontal: theme.radius.large,
    borderRadius: theme.radius.medium,
  },
  text: {
    fontFamily: theme.fonts.bold,
    margin: theme.sizings.larger,
    fontSize: theme.fontSizes.medium,
  },
  button: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.sizings.medium,
    marginHorizontal: theme.sizings.large,
    borderRadius: theme.radius.medium,
  },
  checkboxWrapper: {
    marginRight: -20,
    marginTop: -20,
    marginBottom: -20,
  },
});

CheckboxCardOption.propTypes = {
  index: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  param: PropTypes.any.isRequired,
  paramTitle: PropTypes.string.isRequired,
  paramLegende: PropTypes.string.isRequired,
  selectedParam: PropTypes.any,
  setSelectedParam: PropTypes.func.isRequired,
  setTSelectedParam: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  tParamTitle: PropTypes.object,
  tParamLegende: PropTypes.object,
};

CheckboxCardOption.defaultProps = {
  selectedParam: '',
  tParamTitle: null,
  tParamLegende: null,
};

export default CheckboxCardOption;
