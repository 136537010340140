import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import useBookings from 'bookings/contexts/bookings';
import CloseBooking from 'waiter/pages/WaiterHome/CloseBooking';
import AnableOrdersOnBooking from 'waiter/pages/WaiterHome/AnableOrdersOnBooking';

const BookingActivations = ({ bookingId }) => {
  const { items: bookings } = useBookings();

  const booking = bookings.find((book) => book.id === bookingId);

  if (!booking) {
    return null;
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      <View>
        {!booking.valid && <AnableOrdersOnBooking bookingId={bookingId} />}
      </View>
      <View>
        {booking.active && <CloseBooking bookingId={bookingId} />}
      </View>
    </View>
  );
};

BookingActivations.propTypes = {
  bookingId: PropTypes.number.isRequired,
};

export default BookingActivations;
