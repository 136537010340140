/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';

import useBasket from 'basket/contexts/basket';
import ReductionPrice from 'common/components/ReductionPrice/ReductionPrice';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import commonStyles from 'styles/commonStyles';
import { FontAwesome5 } from '@expo/vector-icons';
import styles from './OrderProductItem.styles';
import ProductWithOptionFormModal from './ProductWithOptionFormModal';

const OrderProductItem = ({
  entity,
  type,
  editable,
  price,
  reduction,
}) => {
  const {
    addItem, removeAllOf, items: basket,
  } = useBasket();
  const [modalVisible, setModalVisible] = useState(false);

  const quantity = useMemo(
    () => basket.filter((p) => p[type] && p[type].id === entity.id)
      .map((p) => p.quantity)
      .reduce((a, b) => a + b, 0),
    [basket, entity, type],
  );
  const hasQuantity = useMemo(
    () => quantity > 0,
    [quantity],
  );

  return (
    <View style={[styles(editable, hasQuantity).itemView, commonStyles.shadowBottom]}>
      <TouchableOpacity
        onPress={(() => editable
          && (entity.options?.length ? setModalVisible(true) : addItem(entity, [], price, type)))}
        onLongPress={(() => editable && removeAllOf(entity))}
        style={styles(editable, hasQuantity).innerItem}
      >
        <View style={styles(editable, hasQuantity).productContent}>
          <View style={styles(editable, hasQuantity).productLabel}>
            {price === 0 && (
              <FontAwesome5
                name="gift"
                color="dark"
              />
            )}
            <TranslatedText
              style={{ margin: 'auto' }}
              size="medium"
              color="dark"
              isBold
              value={entity.name}
            />
          </View>
          <View style={styles(editable, hasQuantity).bottom}>
            {hasQuantity ? (
              <View style={styles(editable, hasQuantity).quantityIndicator}>
                <Text size="small" color="light">x</Text>
                <Text size="small" color="light" style={styles(editable, hasQuantity).quantity}>
                  {quantity}
                </Text>
              </View>
            ) : <View style={styles(editable, hasQuantity).spacer} />}

            <ReductionPrice
              showOriginalPrice={false}
              containerStyle={styles(editable, hasQuantity).price}
              price={price}
              newPriceSize="medium"
              newPriceColor={hasQuantity ? 'white' : 'primary'}
              reduction={reduction}
            />
          </View>
        </View>
      </TouchableOpacity>
      {entity.options.length > 0 && (
        <ProductWithOptionFormModal
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          entity={entity}
          type={type}
        />
      )}
    </View>
  );
};

OrderProductItem.path = 'products';

OrderProductItem.propTypes = {
  entity: PropTypes.object.isRequired,
  type: PropTypes.string,
  editable: PropTypes.bool,
  price: PropTypes.number,
  reduction: PropTypes.object,
};

OrderProductItem.defaultProps = {
  editable: false,
  type: 'product',
  price: 0,
  reduction: null,
};

export default OrderProductItem;
