import React, { useState } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

import ZoomableView from 'common/components/ZoomableView/ZoomableView';
import { ImageBackground } from 'common/components/Images';
import BeachBG from 'assets/beach.jpg';
import useSeatSelector from '../../contexts/seatSelector';
import BoardSquare from '../BoardSquare/BoardSquare';
import ModalSeat from '../ModalSeat/ModalSeat';

const style = {
  position: 'absolute',
  borderWidth: 1,
  borderColor: 'grey',
  borderStyle: 'dashed',
  padding: 0,
};

const RenderSquare = ({ item }) => (
  <>
    { item?.furniture && (
    <View style={{
      ...style,
      width: (item?.furniture?.width || 100) * item.scale,
      height: (item?.furniture?.height || 100) * item.scale,
      left: item.x,
      top: item.y,
      transform: [{ rotate: `${item.rotate ? item.rotate : 0}deg` }],
    }}
    >
      <BoardSquare item={item} />
    </View>
    ) }
  </>
);

RenderSquare.propTypes = {
  item: PropTypes.object.isRequired,
};

const Board = ({ map, style, amount }) => {
  const {
    items, selectedSeat, setSelectedSeat, addSelectedtoSelection,
  } = useSeatSelector();
  const [boardDimensions, setBoardDimensions] = useState({
    scale: 1,
    width: 400,
    height: 800,
    scaleW: 1,
    scaleH: 1,
  });

  const getBoxDimensions = (layoutEvent) => {
    let { height: viewHeight, width: viewWidth } = layoutEvent.nativeEvent.layout;

    const width = map?.backgroundWidth ? map.backgroundWidth : 400;
    const height = map?.backgroundHeight ? map.backgroundHeight : 800;

    const scaleH = viewHeight / height;
    const scaleW = viewWidth / width;

    let scale;

    if (scaleH > scaleW) {
      scale = scaleH;
      viewWidth = scale * width;
    } else {
      scale = scaleW;
      viewHeight = scale * height;
    }
    setBoardDimensions({
      scale,
      width: viewWidth,
      height: viewHeight,
      scaleH,
      scaleW,
    });
  };

  const width = map?.backgroundWidth ? map.backgroundWidth : 400;
  const height = map?.backgroundHeight ? map.backgroundHeight : 800;

  const styleBG = {
    position: 'absolute',
    top: 0,
    left: 0,
    width,
    height,
  };

  const imageSrc = map?.backgroundImage
    ? {
      image: map.backgroundImage,
    }
    : {
      source: BeachBG,
    };

  return (
    <>
      <View style={style} onLayout={getBoxDimensions}>
        <ZoomableView
          style={{
            width: boardDimensions.width,
            height: boardDimensions.height,
          }}
          scale={1}
          minScale={1}
          maxScale={10}
          bindToBorders={false}
        >
          <View
            style={{
              width: boardDimensions.width * boardDimensions.scaleW,
              height: boardDimensions.height * boardDimensions.scaleH,
            }}
          >
            <View style={{
              width,
              height,
              transform: [
                { scale: boardDimensions.scale },
                { translateX: -((width * (1 - boardDimensions.scale)) / 2) / boardDimensions.scale },
                { translateY: -((height * (1 - boardDimensions.scale)) / 2) / boardDimensions.scale },
              ],
              position: 'relative',
              overflow: 'hidden',
            }}
            >
              <ImageBackground style={styleBG} {...imageSrc} />
              {items.map((item) => (
                <RenderSquare key={item.id} x={item.x} y={item.y} item={item} />
              ))}
            </View>
          </View>
        </ZoomableView>
      </View>
      <ModalSeat
        visible={!!selectedSeat}
        seat={selectedSeat}
        onHide={() => setSelectedSeat(null)}
        onClick={() => addSelectedtoSelection(amount)}
        onCancel={() => setSelectedSeat(false)}
      />
    </>
  );
};

Board.propTypes = {
  map: PropTypes.object.isRequired,
  style: PropTypes.any,
  amount: PropTypes.number,
};

Board.defaultProps = {
  style: null,
  amount: 0,
};

export default Board;
