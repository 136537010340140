import React from 'react';
import useSite from 'sites/contexts/sites';
import theme from 'styles';
import { FontAwesome } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import commonStyles from 'styles/commonStyles';
import PropTypes from 'prop-types';

const HeaderBackToSite = ({ alsoOnClick }) => {
  const { item: site } = useSite();
  const template = site?.template;
  const navigation = useNavigation();
  const goToInfo = () => {
    navigation.navigate('SiteHome');
    alsoOnClick && alsoOnClick();
  };

  return (
    <TouchableOpacity
      style={[commonStyles.headerIcon, { marginLeft: theme.sizings.small }]}
      onPress={goToInfo}
    >
      <FontAwesome
        color={template?.primaryTextColor || theme.colors.light}
        size={theme.fontSizes.larger}
        name="home"
      />
    </TouchableOpacity>
  );
};

export default HeaderBackToSite;

HeaderBackToSite.propTypes = {
  alsoOnClick: PropTypes.func,
};

HeaderBackToSite.defaultProps = {
  alsoOnClick: () => {},
};
