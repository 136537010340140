import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useState, useCallback,
} from 'react';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [dropDownAlertRef, setDropDownAlertRef] = useState(null);

  const setAlert = useCallback(({ color = 'success', title = 'Notification', message = '' }) => {
    dropDownAlertRef.alertWithType(color, title, message);
  }, [dropDownAlertRef]);

  return (
    <AlertContext.Provider value={{ setAlert, setDropDownAlertRef }}>
      {children}
    </AlertContext.Provider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useAlert = () => useContext(AlertContext);

export default useAlert;
