import { FontAwesome } from '@expo/vector-icons';
import Modal from 'common/components/Modal/Modal';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import useOrders from 'orders/contexts/orders';
import usePayment from 'payments/contexts/payments';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import {
  StyleSheet, TouchableHighlight, TouchableOpacity, View,
} from 'react-native';
import theme from 'styles';
import Text from 'common/components/Text/Text';
import ModalContent from './ModalContent';

const PaymentState = ({ payment }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const {
    update,
  } = usePayment();
  const { setAlert } = useAlert();
  const { refreshOrders } = useOrders();

  const handleUpdatePayment = useCallback(async (payload) => {
    try {
      await update(payment.id, payload);

      refreshOrders();
    } catch {
      setAlert({ color: 'error', title: t('common.succes'), message: t('orders.errorUpdate') });
    } finally {
      setModalVisible(false);
    }
  }, [payment.id, setAlert, t, update, refreshOrders]);

  return (
    <View>
      <TouchableHighlight
        onPress={() => setModalVisible(!payment.online)}
      >
        <View style={styles.wrapper}>
          <FontAwesome
            name="credit-card-alt"
            size={theme.sizings.mediumLarge}
            style={styles.paymentIcon}
          />

          {payment.status === 'PAID' ? (
            <FontAwesome
              name="check"
              color={payment.status === 'PAID' ? theme.colors.success : theme.colors.danger}
              size={theme.sizings.mediumLarge}
              style={styles.stateIcon}
            />
          ) : (
            <FontAwesome
              name="times"
              color={payment.status === 'PAID' ? theme.colors.success : theme.colors.danger}
              size={theme.sizings.mediumLarge}
              style={styles.stateIcon}
            />
          )}

          {/* {
            payment.status === 'PENDING'
              ? <Text isBold customColor={theme.colors.danger}>{t('orders.notPaid')}</Text>
              : (payment.status === 'PAID'
                ? <Text isBold customColor={theme.colors.success}>{t('orders.paid')}</Text>
                : <Text isBold customColor={theme.colors.danger}>{t('common.error')}</Text>
              )
          } */}
        </View>
      </TouchableHighlight>
      <Modal
        style={{ margin: 10 }}
        transparent
        visible={modalVisible}
        ariaHideApp={false}
      >
        <TouchableOpacity
          onPress={() => setModalVisible(false)}
          style={{
            flex: 1,
            backgroundColor: 'rgba(52, 52, 52, 0.8)',
          }}
        >
          <ModalContent
            payment={payment}
            onSubmit={handleUpdatePayment}
          />
        </TouchableOpacity>
      </Modal>
    </View>
  );
};

PaymentState.propTypes = {
  payment: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  modalBox: {
    margin: 'auto',
    backgroundColor: theme.colors.light,
    opacity: 1,
  },
  buttonGroup: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: 10,
  },
  paymentIcon: {
    margin: 5,
  },
  stateIcon: {
    position: 'absolute',
    right: 5,
    top: 0,
    textShadowColor: '#ffffff',
    textShadowOffset: { width: -2, height: 2 },
    textShadowRadius: 0,
  },
  iconText: {
    padding: 6,
    margin: 10,
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: theme.sizings.tiny,
    paddingHorizontal: theme.sizings.smallMedium,
    marginTop: theme.sizings.tiny,
    borderColor: 'rgba(0,0,0,0.1)',
    borderWidth: 1,
    borderRadius: theme.radius.medium,
    marginBottom: theme.sizings.small,
    position: 'relative',
  },
});

export default PaymentState;
