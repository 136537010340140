/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
  View, StyleSheet,
} from 'react-native';
import PropTypes from 'prop-types';

import Text from 'common/components/Text/Text';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import useTranslation from 'common/contexts/translations';
import useSite from 'sites/contexts/sites';
import FormInput from '../FormInput/FormInput';

const PhoneInput = ({
  value, onChange, label, labelStyle, ...rest
}) => {
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState(value.split(':')[0]);
  const [phoneNumber, setPhoneNumber] = useState(value.split(':')[1]);
  const { item: site } = useSite();

  const onCountryCodeChange = (newCountryCode) => {
    setCountryCode(newCountryCode);
    onChange(`${newCountryCode}:${phoneNumber}`);
  };

  const onPhoneNumberChange = (newPhoneNumber) => {
    setPhoneNumber(newPhoneNumber);
    onChange(`${countryCode}:${newPhoneNumber}`);
  };

  return (
    <View style={styles.containerStyle}>
      <Text
        isBold
        style={styles.labelStyle}
        customColor={site?.template?.backdropTextColor || theme.colors.dark}
      >
        {label}

      </Text>
      <View style={styles.phoneInputContainer}>
        <Text
          isBold
          style={styles.prefixStyle}
          customColor={site?.template?.backdropTextColor || theme.colors.dark}
        >
          +

        </Text>
        <View style={styles.countryCodeContainer}>
          <FormInput
            type="text"
            finalForm={false}
            style={styles.countryCodeInput}
            value={countryCode}
            label={t('common.countryCode')}
            onChange={onCountryCodeChange}
            keyboardType="phone-pad"
            {...rest}
          />
        </View>
        <View style={styles.phoneNumberContainer}>
          <FormInput
            type="text"
            finalForm={false}
            style={styles.phoneNumberInput}
            value={phoneNumber}
            label={t('common.phoneNumber')}
            onChange={onPhoneNumberChange}
            keyboardType="phone-pad"
            {...rest}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  containerStyle: {
    width: '100%',
    marginBottom: 20,
  },
  labelStyle: {
    fontWeight: 'bold',
    fontSize: theme.fontSizes.medium,
    fontFamily: theme.fonts.bold,
  },
  prefixStyle: {
    ...commonStyles.smallText,
    marginRight: 5,
    alignSelf: 'flex-end',
    paddingBottom: theme.sizings.medium,
    marginVertical: theme.sizings.medium,
    fontWeight: 'bold',
  },
  phoneInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  countryCodeContainer: {
    flex: 1,
    marginRight: 10,
  },
  countryCodeInput: {
    width: '100%',
  },
  phoneNumberContainer: {
    flex: 2,
  },
  phoneNumberInput: {
    width: '100%',
  },
  phoneNumberError: {
    color: theme.colors.danger,
    ...commonStyles.smallText,
  },
});

PhoneInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
};

PhoneInput.defaultProps = {
  value: ':',
  label: '',
  labelStyle: 0,
};

export default PhoneInput;
