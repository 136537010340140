import { ImageBackground } from 'common/components/Images';
import Price from 'common/components/Price/Price';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import useTrans from 'common/hooks/use-trans';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';

const BookingRecap = ({ booking, paid }) => {
  const { t } = useTranslation();
  const { translateText } = useTrans();
  const formatedDate = dayjs(booking.date).format('LL');

  return (
    <View style={commonStyles.contentWrapper}>
      <Text centered size="larger" style={styles.title}>
        {t('bookings.bookedFor', { date: formatedDate })}
      </Text>

      {(booking.checkin)
        ? (
          <Text centered size="large" style={styles.title}>
            {t('bookings.bookedAt', {
              hour: dayjs(booking.checkin).format('HH:mm'),
            })}
          </Text>
        )
        : <></>}

      <Text isBold size="large" style={styles.title}>{t('bookings.seats')}</Text>
      {(booking.seats || []).map((seat, index) => {
        let price = 0;

        if (seat.price_area) {
          const priceAreaId = seat.price_area.id;
          const p = seat.furniture.prices.find((p) => p.price_area === priceAreaId);

          if (p) {
            price = p.price;
          }
        }

        return (
          <View key={index}>
            <View style={[styles.card, commonStyles.shadowBottom]}>
              <ImageBackground style={styles.furnitureImage} image={seat.furniture.image} />
              <View style={styles.text}>
                <Text>{`${t('bookings.seatName')}${seat.name}`}</Text>
                <Text>{`${t('bookings.furnitureName')}${translateText(seat.furniture.name)}`}</Text>
              </View>
              <Price
                price={price}
                color="greyDarker"
                size="largest"
              />
            </View>
          </View>
        );
      })}
      {paid && (
      <View style={styles.bookingCode}>
        <Text noLineHeight size="largest">n°</Text>
        <Text
          noLineHeight
          size="huge"
          style={{ marginLeft: theme.sizings.tiny, position: 'relative', top: 4 }}
        >
          {booking.code}

        </Text>
      </View>
      )}
    </View>
  );
};

BookingRecap.propTypes = {
  booking: PropTypes.object.isRequired,
  paid: PropTypes.bool,
};

BookingRecap.defaultProps = {
  paid: false,
};

const styles = StyleSheet.create({
  title: {
    paddingTop: theme.sizings.medium,
    paddingBottom: theme.sizings.small,
  },
  card: {
    height: 80,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.greyLightest,
    borderRadius: theme.radius.medium,
    paddingRight: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
  },
  text: {
    marginLeft: theme.sizings.medium,
    flex: 1,
  },

  furnitureImage: {
    width: 80,
    height: '100%',
    overflow: 'hidden',
    borderTopLeftRadius: theme.radius.medium,
    borderBottomLeftRadius: theme.radius.medium,
  },

  bookingCode: {
    padding: theme.sizings.large,
    flexDirection: 'row',
    alignItems: 'baseline',
    alignSelf: 'center',
  },
});

export default BookingRecap;
