import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useState, useCallback,
} from 'react';

const LoadingContext = createContext();

// 🚨 Put this BEFORE the provider you want to use PersistedState with
export const LoadingProvider = ({ children }) => {
  const [dataLoading, setDataLoading] = useState([]);

  const addDataLoading = useCallback((key) => {
    setDataLoading((prevDataLoading) => ([key, ...prevDataLoading]));
  }, [setDataLoading]);

  const removeDataLoading = useCallback((key) => {
    setDataLoading((prevDataLoading) => {
      const newData = [...prevDataLoading.filter((item) => item !== key)];

      return (newData);
    });
  }, [setDataLoading]);

  return (
    <LoadingContext.Provider value={{ dataLoading, addDataLoading, removeDataLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useLoading = () => useContext(LoadingContext);

export default useLoading;
