import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';
import {
  Image, TouchableOpacity, View, BackHandler,
} from 'react-native';

import useBooking from 'bookings/contexts/bookings';
import Button from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import Price from 'common/components/Price/Price';
import useTranslation from 'common/contexts/translations';
import useTrans from 'common/hooks/use-trans';
import createUrl from 'common/utils/createUrl';
import Text from 'common/components/Text/Text';

import commonStyles from 'styles/commonStyles';
import styles from './ModalSeat.styles';

const ModalSeat = ({
  seat, visible, onHide, onClick, onCancel,
}) => {
  const { t } = useTranslation();
  const { translateText } = useTrans();
  const { item: booking } = useBooking();

  const price = useMemo(() => {
    if (seat && seat.price_area && seat.furniture && seat.furniture.prices) {
      const priceAreaId = seat.price_area.id;
      const p = seat.furniture.prices.find((p) => Number.isInteger(p.price_area)
        ? p.price_area === priceAreaId : p.price_area.id === priceAreaId);

      return p;
    }
    return null;
  }, [seat]);

  useEffect(() => {
    const backAction = () => {
      onCancel();
      return (true);
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, [onCancel]);

  return (
    <Modal
      transparent
      visible={visible}
      ariaHideApp={false}
      style={commonStyles.modal}
    >
      <TouchableOpacity
        onPress={onHide}
        style={commonStyles.modalMask}
      >
        <View style={commonStyles.modalBox}>

          <View style={styles.seatRow}>
            {seat?.furniture?.image && (
              <View style={styles.seatImage}>
                <Image
                  style={{ width: 150, height: 150 }}
                  source={{
                    uri: createUrl(
                      seat.furniture.picture
                        ? seat.furniture.picture.url
                        : seat.furniture.image.url,
                    ),
                  }}
                />
              </View>
            )}

            <View style={styles.seatDescription}>
              <Text
                isBold
                size="large"
              >
                {t('bookings.seatNbr', { nbr: translateText(seat?.name) })}
              </Text>
              <Price price={booking.hotel ? price?.hotelPrice : price?.price} style={styles.price} />
              <Text isBold>{translateText(seat?.furniture?.name)}</Text>
              <Text>{translateText(seat?.furniture?.description)}</Text>
            </View>
          </View>
          <View style={commonStyles.modalFooter}>
            <Button
              variant="greyLighter"
              text={t('common.cancel')}
              onPress={onCancel}
              style={commonStyles.modalButton}
            />
            <Button
              variant="primary"
              text={t('common.confirm')}
              onPress={onClick}
            />
          </View>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

ModalSeat.propTypes = {
  seat: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

ModalSeat.defaultProps = {
  seat: null,
  onCancel: () => {},
};

export default ModalSeat;
