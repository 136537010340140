import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const WaiterAreaContext = createContext();

export const WaiterAreasProvider = ({ children }) => (
  <ContextProvider url="waiter-areas" context={WaiterAreaContext}>{children}</ContextProvider>
);

WaiterAreasProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useWaiterAreas = () => useContext(WaiterAreaContext);

export default useWaiterAreas;
