import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { View } from 'react-native';
import styles from './Schedule.style';

const Schedule = ({ schedule }) => {
  const { t, lang } = useTranslation();

  const days = useMemo(() => {
    if (lang !== 'en-GB') {
      return ({
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
        sunday: {},
        ...schedule,
      });
    }

    return ({
      sunday: {},
      monday: {},
      tuesday: {},
      wednesday: {},
      thursday: {},
      friday: {},
      saturday: {},
      ...schedule,
    });
  }, [schedule, lang]);

  return (
    <View style={styles.schedule}>
      <View style={styles.days}>
        {Array.from({ length: 7 }, (v, i) => (
          <Text key={i} color="light" size="medium">{dayjs().weekday(i).format('ddd')}</Text>
        ))}
      </View>
      <View style={styles.hours}>
        {Object.keys(days).map((day) => (
          <Text key={day} color="light" size="medium">
            {!days[day].isClosed && (days[day].startHour || t('common.undefined'))}
            {!days[day].isClosed && '-'}
            {!days[day].isClosed && (days[day].endHour || t('common.undefined'))}
            {days[day].isClosed && t('common.closed')}
          </Text>
        ))}
      </View>
    </View>
  );
};

Schedule.propTypes = {
  schedule: PropTypes.object,
};

Schedule.defaultProps = {
  schedule: {},
};

export default Schedule;
