import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import React, { useEffect } from 'react';
import {
  ScrollView,
  View,
} from 'react-native';
import useSite from 'sites/contexts/sites';
import theme from 'styles';
import useHistoryOrders from 'orders/contexts/historyOrders';
import OrderSummary from 'orders/components/OrderListItem/OrderSummary';

const SiteOrders = () => {
  const { t } = useTranslation();
  const {
    items: orders,
    fetchItems: fetchOrders,
  } = useHistoryOrders();
  const { item: site } = useSite();

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  /*
  const { booking } = useAuth();
  const {
    fetchItems: fetchCalls,
    setFilter: setCallFilters,
  } = useCall();

   const {
    fetchSpecialOffersApplicable,
    setItems: setSpecialOffers,
  } = useSpecialOffers();

  useEffect(() => {
    if (booking) {
      setFilter('booking', booking.id);
      setFilter('created_at_gte', dayjs().utc().startOf('day').format());
      setFilter('created_at_lte', dayjs().utc().endOf('day').format());
      setFilter('_sort', 'created_at:DESC');
      setCallFilters('booking', booking.id);
    }
  }, [booking, setCallFilters, setFilter]);

  useEffect(() => {
    if (filters.booking) {
      fetchOrders();
      fetchCalls();
    }
  }, [filters, fetchOrders, fetchCalls]);

  useEffect(() => {
    const fetchAndSetData = async () => {
      const applicables = await fetchSpecialOffersApplicable({ booking: booking.id, site: booking.site.id });

      setSpecialOffers(applicables);
    };

    fetchAndSetData();
  }, [booking, setSpecialOffers, fetchSpecialOffersApplicable]);

  */

  return (
    <Page
      backgroundImage={site?.template?.imageBackdrop}
    >
      <ScrollView style={{ padding: theme.sizings.medium }}>
        {orders.length === 0 && (
          <View>
            <Text centered size="huge">
              😅
            </Text>
            <Text
              size="large"
              style={{ textAlign: 'center', paddingHorizontal: theme.sizings.small, marginTop: theme.sizings.small }}
              customColor={site?.template?.backdropTextColor}
            >
              {t('orders.anyOrderYet')}
            </Text>
          </View>
        )}
        {
          // booking.valid &&
          orders.length >= 0 && (
            orders.reverse().map((
              order, key,
            ) => (
              <OrderSummary
                key={`${key}_orderSum_${order.id}`}
                order={order}
              />
            ))
          )
        }
      </ScrollView>
    </Page>
  );
};

export default SiteOrders;
