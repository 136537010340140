import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import HTML from 'react-native-render-html';

import useTrans from 'common/hooks/use-trans';
import Text from 'common/components/Text/Text';
import truncateText from 'common/utils/truncateText';

const TranslatedText = ({
  value, truncateLength, tagsStyles, suffix,
  isButtonText, isTitleText, customColor, ...rest
}) => {
  const { translateText } = useTrans();

  const formattedValue = useMemo(() => {
    let translatedValue = translateText(value);

    if (!tagsStyles) {
      /* Remove tags if we are going to only use the text inside it */
      const regex = /(<([^>]+)>)/gi;

      translatedValue = translatedValue.replace(regex, '');
    }

    return truncateLength ? truncateText(translatedValue, truncateLength) : translatedValue;
  }, [translateText, value, tagsStyles, truncateLength]);

  return (
    tagsStyles ? (
      <HTML source={{ html: formattedValue }} tagsStyles={tagsStyles} {...rest} />
    ) : (
      <Text isTitleText={isTitleText} isButtonText={isButtonText} customColor={customColor} {...rest}>
        {formattedValue}
        {' '}
        {suffix}
      </Text>
    )
  );
};

TranslatedText.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  suffix: PropTypes.string,
  truncateLength: PropTypes.number,
  tagsStyles: PropTypes.object,
  isButtonText: PropTypes.bool,
  isTitleText: PropTypes.bool,
  customColor: PropTypes.string,
};

TranslatedText.defaultProps = {
  value: '',
  suffix: null,
  truncateLength: null,
  tagsStyles: null,
  isButtonText: false,
  isTitleText: false,
  customColor: null,
};

export default TranslatedText;
