import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/Button/Button';
import Confirm from '../Confirm/Confirm';

const ConfirmButton = ({
  confirmText, style, variant, ...rest
}) => (
  <Confirm confirmText={confirmText}>
    <Button
      style={style}
      variant={variant}
      {...rest}
    />
  </Confirm>
);

ConfirmButton.propTypes = {
  confirmText: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  variant: PropTypes.string,
};

ConfirmButton.defaultProps = {
  style: null,
  variant: null,
};

export default ConfirmButton;
