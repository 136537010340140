import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import { useAuth } from 'auth/contexts/auth';
import useAlert from 'common/contexts/alert';
import useBookings from 'bookings/contexts/bookings';
import useTranslation from 'common/contexts/translations';

import commonStyles from 'styles/commonStyles';
import theme from 'styles';

const CloseBooking = ({ bookingId }) => {
  const {
    deactivateBooking,
    fetchItems: fetchBookings,
  } = useBookings();
  const { setAlert } = useAlert();
  const { t } = useTranslation();
  const { reset } = useNavigation();
  const { user } = useAuth();

  const handleDeactivate = useCallback(async () => {
    await deactivateBooking(bookingId);
    await fetchBookings();
    setAlert({ color: 'success', title: t('common.success'), message: t('bookings.deactivatedBooking') });
    ScreenOrientation.lockAsync(
      ScreenOrientation.OrientationLock[(user && ['waiter', 'barman'].includes(user.role?.type))
        ? 'DEFAULT'
        : 'PORTRAIT_UP'],
    );
    reset({
      index: 0,
      routes: [{ name: ['waiter', 'barman'].includes(user.role.type) ? 'WaiterHome' : 'HomePage' }],
    });
  }, [reset, user, deactivateBooking, fetchBookings, bookingId, setAlert, t]);

  return (
    <TouchableOpacity style={commonStyles.headerIcon} onPress={handleDeactivate}>
      <FontAwesome
        name="trash"
        color={theme.colors.light}
        size={theme.fontSizes.larger}
      />
    </TouchableOpacity>
  );
};

CloseBooking.propTypes = {
  bookingId: PropTypes.number.isRequired,
};

export default CloseBooking;
