import React, {
  createContext, useContext, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';

const SeatContext = createContext();

export const SeatsProvider = ({ children }) => {
  const [isAvailableFetching, setIsAvailableFetching] = useState(false);

  const fetchSeatsAvailable = useCallback(async (payload) => {
    setIsAvailableFetching(true);
    const esc = encodeURIComponent;
    const queryParams = Object.keys(payload).map((key) => `${esc(key)}=${esc(payload[key])}`).join('&');

    try {
      const res = await fetchJSON({ url: `seats/available?${queryParams}`, method: 'GET' });

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    } finally {
      setIsAvailableFetching(false);
    }
  }, []);

  return (
    <ContextProvider
      url="seats"
      context={SeatContext}
      value={{ fetchSeatsAvailable, isAvailableFetching }}
    >
      {children}

    </ContextProvider>
  );
};

SeatsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useSeat = () => useContext(SeatContext);

export default useSeat;
