import React, {
  useState, useMemo,
} from 'react';
import {
  ScrollView, StyleSheet,
} from 'react-native';
import CheckboxCardOption from 'orders/components/CheckboxCardOption/CheckboxCardOption';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';

import useSite from 'sites/contexts/sites';
import theme from 'styles';

import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import NavigationButton from 'common/components/NavigationButton/NavigationButton.web';
import useBasket from 'basket/contexts/basket';

const OrderInclusiveFormule = () => {
  const { t } = useTranslation();
  const { item: site } = useSite();
  const { setIsAllInclusiveBasket } = useBasket();
  const template = site?.template;

  const [isInclusive, setIsInclusive] = useState();
  const [selected, setSelected] = useState(false);

  const rootCategories = site?.categories?.filter(({ parent }) => !parent).reduce((acc, category) => {
    // check if children categories of current category has products
    const count = category.children.reduce((prev, curr) => prev + curr.products.length, category.products.length);

    // if current category and its children doesn't have any products, we don't display it
    if (count > 0) {
      return [...acc, { ...category, count }];
    }

    return acc;
  }, []) || [];

  const formules = useMemo(() => {
    // Page data array
    const res = [
      {
        Title: t('formule.isInclusive'),
        Subtitle: t('formule.isInclusiveSubtitle'),
        Value: true,
        Icon: 'check',
      },
      {
        Title: t('formule.isNotInclusive'),
        Subtitle: t('formule.isNotInclusiveSubtitle'),
        Value: false,
        Icon: 'check',
      },
    ];

    return res;
  }, [t]);

  return (
    <Page backgroundImage={site?.template.imageBackdrop}>

      <Text style={styles.text} isBold centered>
        {t('formule.title')}
      </Text>
      <ScrollView style={styles.innerView}>
        {formules.map((formule, index) => (
          <CheckboxCardOption
            key={`cb-${index}`}
            index={index}
            selected={selected}
            setSelected={setSelected}
            param={formule.Value}
            paramTitle={formule.Title}
            paramLegende={formule.Subtitle}
            tParamTitle={formule.tSubtitle}
            tParamLegende={formule.tTitle}
            selectedParam={isInclusive}
            setSelectedParam={setIsInclusive}
            icon={formule.Icon}
          />
        ))}

        <FooterButtonWrapper>
          <NavigationButton
            title={t('orders.goNextStep')}
            link={template?.showRootCategoriesPage ? 'SiteProducts' : 'CategoryDetail'}
            params={{ siteId: site?.id, category: rootCategories[0] }}
            variant="light"
            icon="arrow-right"
            alsoOnPress={() => {
              setIsAllInclusiveBasket(isInclusive);
            }}
          />
        </FooterButtonWrapper>
      </ScrollView>

    </Page>
  );
};

const styles = StyleSheet.create({
  innerView: {
    paddingHorizontal: theme.sizings.small,
    paddingTop: theme.sizings.medium,
  },
  linearGradient: {
    width: '100%',
    height: '100%',
    paddingVertical: theme.sizings.medium,
    paddingHorizontal: theme.radius.large,
    borderRadius: theme.radius.medium,
  },
  text: {
    fontFamily: theme.fonts.bold,
    margin: theme.sizings.larger,
    fontSize: theme.fontSizes.medium,
  },
  button: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.sizings.large,
    marginHorizontal: theme.sizings.large,
    borderRadius: theme.radius.medium,
  },
});

export default OrderInclusiveFormule;
