import React, {
  useMemo, useEffect, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Input } from 'react-native-elements';
import debounce from 'lodash/debounce';

export const DebouncedInput = ({
  value, onChange, debounceTimeout, ...rest
}) => {
  const [localValue, setValue] = useState(value);

  const setDebouncedChange = useMemo(() => debounce(onChange, 300), [onChange]);

  const handleInputChange = useCallback((e) => {
    setValue(e.nativeEvent.text);
    setDebouncedChange(e.nativeEvent.text);
  }, [setDebouncedChange]);

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <Input
      value={`${localValue}` || ''}
      onChange={handleInputChange}
      {...rest}
    />
  );
};

DebouncedInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  debounceTimeout: PropTypes.number,
};

DebouncedInput.defaultProps = {
  debounceTimeout: 300,
  value: '',
};

export default DebouncedInput;
