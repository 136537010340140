import React, {
  createContext, useContext, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import ContextProvider from 'common/contexts/crud';
import { useTranslation } from 'react-i18next';
import useAlert from 'common/contexts/alert';
import useAuth from 'auth/contexts/auth';

const CallsContext = createContext();

export const CallsProvider = ({ children }) => (
  <ContextProvider url="calls" context={CallsContext}>
    {children}
  </ContextProvider>
);

CallsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useCall = () => {
  const context = useContext(CallsContext);
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { seat, booking } = useAuth();

  const currentCall = useMemo(() => {
    if (booking) {
      return context.items.length
        ? context.items.find((call) => call.booking.id === booking.id)
        : null;
    }
    return null;
  }, [context.items, booking]);

  const callWaiter = useCallback(async () => {
    let call = null;

    if (!currentCall) {
      call = await context.save({ seat, booking, site: booking?.site?.id });
      if (!call.error) {
        setAlert({ message: t('info.waiterCalled') });
        context.setItems([call]);
      } else {
        setAlert({
          color: 'error',
          title: t('common.error'),
          message: t('info.waiterNotCalled'),
        });
      }
    } else {
      call = await context.remove(currentCall.id);
      if (!call.error) {
        setAlert({ message: t('info.callDeleted') });
        context.setItems([]);
      } else {
        setAlert({
          color: 'error',
          title: t('common.error'),
          message: t('info.errorDeletingCall'),
        });
      }
    }
  }, [seat, booking, t, setAlert, context, currentCall]);

  return { ...context, currentCall, callWaiter };
};

export default useCall;
