import React from 'react';
import PropTypes from 'prop-types';
import useSite from 'sites/contexts/sites';

import {
  Elements,
} from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';
import CardContent from '../../components/CardContent';

const Card = ({ route }) => {
  const { payment, callback } = route.params;

  const { item: site } = useSite();
  // eslint-disable-next-line max-len
  const stripePromise = loadStripe('pk_test_51JijuRHCJXVdgTaFQmA5oFFZLMstmmdlqVvH2Cev1tZH8HjSYi9m0BnkU9VTDkMtdmQyVsVwaqKfTIZqWlq0IcXW00YjIVomSv', {
    stripeAccount: site.stripeAccountId,
  });

  return (
    <Elements stripe={stripePromise}>
      <CardContent payment={payment} callback={callback} />
    </Elements>
  );
};

Card.propTypes = {
  route: PropTypes.object.isRequired,
};

export default Card;
