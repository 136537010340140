import { useNavigation } from '@react-navigation/native';
import { useHeaderHeight } from '@react-navigation/elements';
import useBasket from 'basket/contexts/basket';
import useCategory from 'categories/contexts/categories';
// import useCategoryService from 'categoryServices/contexts/categoryServices';
import Button from 'common/components/Button/Button';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Page from 'common/components/Page/Page';
import useTranslation from 'common/contexts/translations';
import OrderCategory from 'orders/components/OrderCategory/OrderCategory';
// import OrderCategoryService from 'orders/components/OrderCategoryService/OrderCategoryService';
import useOrder from 'orders/contexts/orders';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import {
  Dimensions, ScrollView, StyleSheet, View,
} from 'react-native';
import theme from 'styles';

const OrderDetail = ({ route }) => {
  const { setItems: setBasket, items: basket } = useBasket();
  const { orderId } = route.params;
  const headerHeight = useHeaderHeight();
  const {
    comments,
    // seat, booking,
  } = route.params;
  const navigation = useNavigation();
  const {
    items: categories,
    isFetching: isCategorysFetching,
    fetchItems,
  } = useCategory();
  /* const {
    items: categoryServices,
    isFetching: isCategoryServicesFetching,
    fetchItems: fetchItemsServices,
  } = useCategoryService(); */
  const {
    fetchItem: fetchOrder,
    item: order,
    isFetching: isOrdersFetching,
    waiterCatalog,
  } = useOrder();
  const { t } = useTranslation();
  const { height } = Dimensions.get('window');

  const selectorStyle = {
    flexDirection: 'column',
    width: '20%',
    paddingVertical: theme.sizings.small,
    height: Dimensions.get('window').height - (headerHeight + 80),
    backgroundColor: theme.colors.darkerBlue,
  };

  const scrollViewStyle = {
    width: '80%',
    maxHeight: height - (headerHeight + 80),
  };

  const productViewStyle = {

  };

  useEffect(() => {
    setBasket([]);
    fetchOrder(orderId);
  }, [fetchOrder, orderId, setBasket]);

  useEffect(() => {
    if (!isOrdersFetching && order) {
      setBasket(order.order_items);
    }
  }, [isOrdersFetching, order, setBasket]);

  useEffect(() => {
    fetchItems();
    /* fetchItemsServices(); */
  }, [basket, fetchItems]);

  const handlePress = useCallback(() => {
    navigation.navigate('OrderValidate', {
      order,
      // bookingId: booking,
      // seatId: seat,
      comments,
    });
  }, [navigation, order, comments]);

  return (
    <Page>
      <LoadingSpinner
        visible={isCategorysFetching || isOrdersFetching /* || isCategoryServicesFetching */}
      />
      <View style={styles.container}>
        <ScrollView style={selectorStyle}>
          {categories.filter(
            (c) => !c.parent || (!c.parent && c?.products.length),
          ).map((category) => (
            <OrderCategory
              key={category.id}
              orderAllInclusive={order?.all_inclusive}
              {...category}
            />
          ))}
          {/* {categoryServices.filter(
            (c) => !c.parent || (!c.parent && c?.services.length),
          ).map((categoryService) => (
            <OrderCategoryService key={categoryService.id} {...categoryService} />))} */}
        </ScrollView>
        <ScrollView style={scrollViewStyle} contentContainerStyle={productViewStyle}>
          {waiterCatalog}
        </ScrollView>
      </View>
      <FooterButtonWrapper inBox>
        <Button
          variant="gradient"
          icon="arrow-right"
          text={t('orders.goValidation')}
          disabled={
            // !booking
            basket.length === 0
          }
          onPress={handlePress}
        />
      </FooterButtonWrapper>
    </Page>
  );
};

const styles = StyleSheet.create({
  type: {
    marginTop: theme.sizings.medium,
  },

  container: {
    flexDirection: 'row',
    flex: 1,
  },
});

OrderDetail.propTypes = {
  route: PropTypes.object.isRequired,
};

export default OrderDetail;
