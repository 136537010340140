import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

const FlexRow = ({
  children, style, align, ...rest
}) => {
  const initialStyle = {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent:
      align === 'center'
        ? 'space-around'
        : align === 'left'
          ? 'flex-start'
          : align === 'right'
            ? 'flex-end'
            : 'space-between',
  };

  return (
    <View style={[initialStyle, style]} {...rest}>
      {children}
    </View>
  );
};

FlexRow.propTypes = {
  children: PropTypes.node,
  style: PropTypes.any,
  align: PropTypes.string,
};

FlexRow.defaultProps = {
  children: null,
  style: null,
  align: '',
};

/* FlexRow child element */
const FlexRowElement = ({ children, style, ...rest }) => (
  <View style={{ flexDirection: 'column' }}>
    <View style={[{ flexDirection: 'row', alignItems: 'center' }, style]} {...rest}>
      {children || <></>}
    </View>
  </View>
);

FlexRowElement.propTypes = {
  children: PropTypes.node,
  style: PropTypes.any,
};

FlexRowElement.defaultProps = {
  children: null,
  style: null,
};

FlexRow.Left = FlexRowElement;
FlexRow.Right = FlexRowElement;
FlexRow.Element = FlexRowElement;
export default FlexRow;
