import React from 'react';
import { View, StyleSheet } from 'react-native';
import useTranslation from 'common/contexts/translations';
import { Form } from 'react-final-form';
import { useNavigation } from '@react-navigation/native';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import { useAuth } from 'auth/contexts/auth';
import FormInput from 'common/components/FormInput/FormInput';
import useAlert from 'common/contexts/alert';
import Text from 'common/components/Text/Text';
import theme from 'styles';
import ConfirmButton from 'common/components/ConfirmButton/ConfirmButton';
import Page from 'common/components/Page/Page';

const DeleteAccount = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { setAlert } = useAlert();
  const auth = useAuth();
  const { isFetching, deleteAccount } = auth;

  const onSubmit = async ({ password }) => {
    try {
      await deleteAccount(password);
      navigation.reset({
        index: 0,
        routes: [{ name: 'AccountDeleted' }],
      });
    } catch {
      setAlert({ color: 'error', title: t('common.error'), message: t('auth.incorrectPassword') });
    }
  };

  return (
    <Page scroll padding style={{ marginBottom: 0 }}>
      <View style={styles.mainView}>
        <LoadingSpinner
          visible={isFetching}
        />
        <View>
          <Text size="large" isBold>{t('auth.deleteAccount')}</Text>
          <Text>{t('auth.deleteAccountDesc')}</Text>
          <Form
            onSubmit={onSubmit}
            initialValues={{ password: '' }}
            render={({
              handleSubmit,
            }) => (
              <View style={styles.formView}>
                <View style={styles.inputs}>
                  <FormInput
                    type="password"
                    name="password"
                    placeholder={t('auth.password')}
                    icon="lock"
                  />
                </View>
                <View style={styles.buttons}>
                  <ConfirmButton
                    confirmText={t('auth.confirmDeletion')}
                    data-cy="cypress_delete"
                    style={styles.button}
                    variant="danger"
                    text={t('auth.delete')}
                    onPress={handleSubmit}
                  />
                </View>
              </View>
            )}
          />
        </View>
      </View>
    </Page>
  );
};

DeleteAccount.propTypes = {
};

DeleteAccount.defaultProps = {
};

const styles = StyleSheet.create({
  mainView: {
    paddingHorizontal: theme.sizings.small,
    paddingTop: theme.sizings.large,
  },
  activeBooking: {
    paddingVertical: theme.sizings.medium,
    alignItems: 'center',
  },
  button: {
    margin: theme.sizings.medium,
    width: '100%',
  },

  buttons: {
    alignItems: 'center',
  },

  submitButton: {
    backgroundColor: theme.colors.secondaryDark,
    borderRadius: 50,
    paddingHorizontal: theme.sizings.large,
    width: 200,
    marginBottom: theme.sizings.medium,
  },

  submitButtonTitle: {
    color: theme.colors.light,
  },

  formView: {
    alignItems: 'center',
    width: '100%',
  },

  inputs: {
    width: '100%',
  },
});

export default DeleteAccount;
