import Button from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import commonStyles from 'styles/commonStyles';
import theme from 'styles';

const Confirm = ({ confirmText, children }) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);

  const child = React.Children.only(children);

  const handleConfirm = () => {
    setModalVisible(false);
    child.props.onPress();
  };

  return (
    <>
      <Modal
        style={commonStyles.modal}
        transparent
        visible={modalVisible}
        ariaHideApp={false}
      >
        <TouchableOpacity
          onPress={() => setModalVisible(false)}
          style={commonStyles.modalMask}
        >
          <View style={commonStyles.modalBox}>
            <Text style={commonStyles.modalText}>{confirmText}</Text>
            <View style={commonStyles.modalFooter}>
              <Button
                variant="greyLighter"
                text={t('common.cancel')}
                onPress={() => setModalVisible(false)}
                style={{ marginHorizontal: theme.sizings.tiny }}
              />
              <Button
                variant="success"
                text={t('common.confirm')}
                onPress={handleConfirm}
                style={{ marginHorizontal: theme.sizings.tiny }}
              />
            </View>
          </View>
        </TouchableOpacity>
      </Modal>
      {React.cloneElement(child, { onPress: () => setModalVisible(true) })}
    </>
  );
};

Confirm.propTypes = {
  confirmText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Confirm;
