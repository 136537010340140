import React from 'react';
import PropTypes from 'prop-types';

import OrderProductItem from './OrderProductItem';

// TODO enlever ce composant qui ne sert plus à rien
const OrderProduct = ({ product, reduction, orderAllInclusive }) => {
  const isFree = orderAllInclusive && product.all_inclusive;

  return (
    [<OrderProductItem
      editable
      price={isFree ? 0 : product.price}
      type="product"
      entity={product}
      reduction={reduction}
    />]
  );
};

OrderProduct.path = 'products';

OrderProduct.propTypes = {
  product: PropTypes.object.isRequired,
  reduction: PropTypes.object,
  orderAllInclusive: PropTypes.bool,
};

OrderProduct.defaultProps = {
  reduction: null,
  orderAllInclusive: false,
};

export default OrderProduct;
