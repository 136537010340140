import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import Text from 'common/components/Text/Text';
import useBasket from 'basket/contexts/basket';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import useSite from 'sites/contexts/sites';
import useTranslation from 'common/contexts/translations';

const styles = StyleSheet.create({
  countBadge: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: theme.colors.danger,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    borderRadius: theme.radius.rounded,
  },
  headerTextButton: {
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  headerText: {
    color: '#fff',
    marginRight: 10,
    fontWeight: 'bold',
  },
  active: {
    opacity: 1,
  },
  inactive: {
    opacity: 0.6,
  },
});

const ProductsCounter = ({ onDarkBackground }) => {
  const { t } = useTranslation();
  const { items: basket } = useBasket();
  const navigation = useNavigation();
  const { item: site } = useSite();
  const template = site?.template;

  const [count, setCount] = useState(0);

  useEffect(() => {
    let tmp = 0;

    if (basket) {
      basket.forEach((item) => {
        tmp += item.quantity;
      });
      setCount(tmp);
    }
  }, [basket]);

  return !site?.pleaseMyCar && (
    <TouchableOpacity
      // style={[commonStyles.headerIcon, count > 0 ? styles.headerTextButton : null]}
      style={[commonStyles.headerIcon, styles.button, count > 0 ? styles.active : styles.inactive]}
      // className={count > 0 && 'icon-basket-active'}
      onPress={count > 0 ? () => {
        navigation.navigate('Basket');
      } : null}
    >
      {/* count > 0 && (
        <View>
          <Text style={styles.headerText}>{t('common.finishOrder')}</Text>
        </View>
      ) */}
      <FontAwesome
        name="shopping-basket"
        color={!onDarkBackground && template?.primaryTextColor ? template?.primaryTextColor : theme.colors.light}
        size={theme.fontSizes.large}
      />
      {count > 0 && (
      <View style={[styles.countBadge, template?.secondaryColor && { backgroundColor: template.secondaryColor }]}>
        <View>
          <Text color="light" size="tiny" isButtonText customColor={template?.secondaryTextColor}>{count}</Text>
        </View>
      </View>
      )}
    </TouchableOpacity>
  );
};

export default ProductsCounter;

ProductsCounter.propTypes = {
  onDarkBackground: PropTypes.bool,
};

ProductsCounter.defaultProps = {
  onDarkBackground: false,
};
