export const isDesktopMode = (windowDimension) => windowDimension.width > 1024;

export const isMobileMode = (windowDimension) => windowDimension.width < 768;

export const isTabletMode = (windowDimension) => windowDimension.width > 767
&& windowDimension.height > 599;

export const isHorizontalMode = (windowDimension) => windowDimension.width > windowDimension.height;

export default isDesktopMode;
