/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import theme from 'styles';

// import useBookings from 'bookings/contexts/bookings';
import useTranslation from 'common/contexts/translations';
import ItemCard from 'common/components/ItemCard/ItemCard';
import Text from 'common/components/Text/Text';
import NavigationButton from 'common/components/NavigationButton/NavigationButton';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import Price from 'common/components/Price/Price';
import commonStyles from 'styles/commonStyles';
import useSite from 'sites/contexts/sites';
import styles from './SiteListItemStyle';

const SiteListItem = ({
  navigation,
  name,
  address,
  description,
  id,
  active,
  images,
  distance,
  is_open,
  onlineMinPrice: minPrice,
  targetLink,
  siteCurrency,
}) => {
  const { t } = useTranslation();
  // const { setItem: setBooking } = useBookings();
  const {
    setItem, fetchSiteData,
  } = useSite();

  const handlePress = async () => {
    async function fetchAndSetData() {
      const site = await fetchSiteData(id);

      // setBooking((prevBooking) => ({
      //   ...prevBooking,
      //   site: id,
      // }));
      setItem(site);
      navigation.navigate(targetLink, { siteId: id });
    }
    fetchAndSetData();
  };

  return (
    <View style={styles(active).siteItem}>
      <View style={styles(active).header}>
        <Text style={styles(active).title} isBold size="large">{name}</Text>
        <Text size="large">
          {address}
        </Text>
      </View>
      <ItemCard onPress={handlePress} image={images[0]}>
        <View style={styles(active).content}>
          <View style={styles(active).metas}>
            <View style={styles(active).description}>
              <TranslatedText
                tagsStyles={{ p: { color: theme.colors.greyDarker } }}
                value={description}
                truncateLength={50}
              />
            </View>
            { distance && <Text color="greyDarker">{t('around.howFar', { distance: Math.floor(distance) })}</Text>}
            <View style={styles(active).state}>
              <Text
                color="greyDarker"
                style={styles(active && is_open).stateText}
              >
                {(active && is_open) ? t('common.open') : t('common.closed')}

              </Text>
              <View style={styles(active && is_open).stateIcon} />
            </View>
          </View>

        </View>
        {minPrice > 0 && (
          <View style={commonStyles.priceWrapper}>
            <Text
              style={commonStyles.priceLabel}
              centered
              isUppercase
              color="darkBlue"
              size="tiny"
            >
              {t('beach.priceFrom')}

            </Text>
            <Price
              price={minPrice}
              size="huge"
              siteCurrency={siteCurrency}
            />
          </View>
        ) }
        <View style={commonStyles.cardButtonWrapper}>
          <NavigationButton
            title={t('common.seeDetails')}
            link={targetLink}
            icon="eye"
            params={{ siteId: id }}
            alsoOnPress={handlePress}
            navigate={false}
            variant="gradient"
          />
        </View>
      </ItemCard>
    </View>
  );
};

SiteListItem.propTypes = {
  name: PropTypes.string.isRequired,
  navigation: PropTypes.object.isRequired,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  address: PropTypes.string,
  active: PropTypes.bool.isRequired,
  is_open: PropTypes.bool.isRequired,
  images: PropTypes.array,
  onlineMinPrice: PropTypes.number,
  distance: PropTypes.number,
  id: PropTypes.number.isRequired,
  targetLink: PropTypes.string,
  siteCurrency: PropTypes.string,
};

SiteListItem.defaultProps = {
  description: '',
  address: '',
  onlineMinPrice: null,
  images: [],
  distance: null,
  targetLink: 'SitePresentation',
  siteCurrency: 'EUR',
};

export default SiteListItem;
