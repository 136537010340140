import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import useAuth from 'auth/contexts/auth';
import useBookings from 'bookings/contexts/bookings';
import useCall from 'calls/contexts/calls';
import Page from 'common/components/Page/Page';
import RelativeDate from 'common/components/RelativeDate/RelativeDate';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  StyleSheet,
  TouchableOpacity, View,
} from 'react-native';
import theme from 'styles';
import BookingPayments from 'waiter/components/BookingPayments/BookingPayments';

const WaiterBookingDetail = ({ route: { params: { id, seatId } } }) => {
  const {
    items: bookings,
    notifyBooking,
  } = useBookings();
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const {
    fetchItems: fetchCalls,
    remove,
    items: calls,
  } = useCall();
  const { user } = useAuth();

  const booking = useMemo(() => bookings.find((book) => book.id === id), [bookings, id]);

  const call = useMemo(() => calls.find((call) => booking.seats.find((seat) => seat.id === call.seat.id)),
    [booking, calls]);

  const deleteCall = useCallback(async () => {
    await remove(call.id);
    fetchCalls();
  }, [fetchCalls, remove, call]);

  const clientCall = useCallback(async () => {
    try {
      await notifyBooking(booking.id);
    } catch (e) {
      console.log(e);
    }
  }, [notifyBooking, booking]);

  const goToOrder = useCallback(
    () => navigate('OrderDetail', {
      seat: seatId || booking.seats[0].id, booking: booking.id,
    }), [navigate, booking, seatId],
  );
  const goEditBooking = useCallback(() => navigate('BookingEdit', { id: booking.id }), [booking, navigate]);

  useEffect(() => {
    fetchCalls();
  }, [fetchCalls]);

  if (!booking) {
    return (
      <Page>
        <View>
          <Text>{t('bookings.noBooking')}</Text>
        </View>
      </Page>
    );
  }

  return (
    <Page scroll safeArea>
      <View style={styles.bookingInfo}>
        <View style={styles.clientInfo}>
          <Text isBold size="large">{`${booking.clientName} - ${booking.code}`}</Text>
          {booking.seats.length && (
            <>
              <Text>{t('bookings.seats')}</Text>
              <View style={styles.seats}>
                {booking.seats
                  .sort((a, b) => a.name >= b.name ? 1 : -1)
                  .map((seat, index) => (
                    <Text key={seat.id} isBold size="large">
                      {`${seat.name}${index !== booking.seats.length - 1 ? ' - ' : ''}`}
                    </Text>
                  ))}
              </View>
            </>
          )}
          {booking.roomID != null && booking.roomID !== ''
           && (<Text size="medium">{t('bookings.roomNbr', { nbr: booking.roomID })}</Text>)}
        </View>
        <View>
          <TouchableOpacity onPress={goEditBooking}>
            <View style={styles.editBooking}>
              <View style={styles.editBookingLabel}>
                <Text color="primary">{t('common.edit')}</Text>
              </View>
              <View style={styles.button}>
                <FontAwesome name="pencil" color={theme.colors.primary} size={theme.sizings.mediumLarge} />
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.detailsContainer}>
        <View style={[styles.calls, styles.category]}>
          <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row' }}>
            <View style={{ justifyContent: 'center' }}>
              <Text isBold size="large">{t('tabs.calls')}</Text>
            </View>
            <View>
              <TouchableOpacity onPress={clientCall}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <View style={{ justifyContent: 'center' }}>
                    <Text color="primary">{t('info.notifyClient')}</Text>
                  </View>
                  <View style={styles.button}>
                    <FontAwesome
                      name="bell"
                      color={theme.colors.primary}
                      size={theme.sizings.mediumLarge}
                    />
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          </View>
          {call && (
            <View>
              <View style={styles.call}>
                <View style={styles.info}>
                  <View style={styles.info}>
                    <Text>{t('info.waiterCalled')}</Text>
                    <Text color="light" size="larger" style={styles.infoText}>
                      {`${call.seat.name} - ${booking.clientName}`}
                    </Text>
                  </View>
                  <View style={styles.date}>
                    <FontAwesome
                      style={styles.dateIcon}
                      name="clock-o"
                      size={theme.sizings.medium}
                      color={theme.colors.light}
                    />
                    <Text noLineHeight color="light">
                      <RelativeDate value={call.createad_at} />
                    </Text>
                  </View>
                </View>
                <TouchableOpacity style={styles.button} onPress={deleteCall}>
                  <FontAwesome name="check" color={theme.colors.light} size={theme.sizings.mediumLarge} />
                </TouchableOpacity>
              </View>
            </View>
          )}
        </View>
        <View style={styles.borderBottom} />

        <View style={styles.detailOrders}>
          <View style={styles.detailOrdersTitle}>
            <View style={styles.addOrderLabel}>
              <Text isBold size="large">{t('waiter.orders')}</Text>
            </View>
            { ['waiter', 'barman', 'manager'].includes(user?.role.type) && (
            <View style={styles.addOrder}>
              <View style={styles.addOrderLabel}>
                <Text color="secondaryDark">{t('common.new')}</Text>
              </View>
              <TouchableOpacity
                style={styles.button}
                onPress={goToOrder}
              >
                <View style={styles.addOrderButton}>
                  <FontAwesome name="plus" color={theme.colors.secondaryDark} size={theme.sizings.mediumLarge} />
                </View>
              </TouchableOpacity>
            </View>
            )}
          </View>

          <BookingPayments bookingId={booking.id} />
        </View>
      </View>
    </Page>
  );
};

WaiterBookingDetail.propTypes = {
  route: PropTypes.shape({ params: PropTypes.object }),
};

WaiterBookingDetail.defaultProps = {
  route: {
    params: {},
  },
};

const styles = StyleSheet.create({
  clientInfo: {},

  editBookingLabel: {
    justifyContent: 'center',
  },

  bookingInfo: {
    padding: 10,
    backgroundColor: '#f6f6f6',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  editBooking: {
    display: 'flex',
    flexDirection: 'row',
  },

  borderBottom: {
    borderBottomColor: '#e5e5e5',
    borderBottomWidth: 1,
    marginBottom: 10,
  },

  detailsContainer: {
    marginTop: 10,
    marginLeft: 18,
    marginRight: 18,
    marginHorizontal: 15,
  },

  calls: {},

  call: {
    backgroundColor: theme.colors.primary,
    paddingHorizontal: theme.sizings.medium,
    paddingVertical: theme.sizings.small,
    borderRadius: theme.radius.medium,
    marginBottom: theme.sizings.small,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  info: {
    flex: 1,
  },

  infoText: {
    fontFamily: 'Bebas',
  },

  date: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  dateIcon: {
    marginRight: theme.sizings.small,
  },

  seats: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  button: {
    width: 50,
    height: 50,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: theme.radius.rounded,
    justifyContent: 'center',
    alignItems: 'center',
  },

  detailOrders: {},

  detailOrdersTitle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  addOrder: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },

  addOrderLabel: {
    justifyContent: 'center',
  },

  addOrderButton: {
    borderRadius: theme.radius.rounded,
  },
});

export default WaiterBookingDetail;
