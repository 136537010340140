import { StyleSheet } from 'react-native';
import theme from 'styles';

export default (variant, isFullwidth, noPadding, disabled, templateLightButton) => StyleSheet.create({
  button: {
    backgroundColor: disabled ? theme.colors.light : (
      variant === 'transparent' ? 'transparent' : (
        variant === 'light' ? templateLightButton : theme.colors[variant])),
    borderRadius: theme.radius.rounded,
    paddingVertical: noPadding ? 0 : theme.sizings.small,
    paddingHorizontal: noPadding ? 0 : theme.sizings.large,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },

  iconButton: {
    backgroundColor: disabled ? theme.colors.light : (
      variant === 'transparent' ? 'transparent' : (
        variant === 'light' ? templateLightButton : theme.colors[variant])),
    borderRadius: theme.radius.rounded,
    paddingVertical: noPadding ? 0 : theme.sizings.small,
    paddingHorizontal: noPadding ? 0 : theme.sizings.large,
    overflow: 'hidden',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },

  iconOnly: {
    width: 30,
    height: 30,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.radius.rounded,
    paddingVertical: noPadding ? 0 : theme.sizings.small,
    paddingHorizontal: noPadding ? 0 : theme.sizings.large,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },

  iconWrapper: {
    marginRight: 0,
    height: theme.sizings.large,
    width: theme.sizings.large,
  },

});
