import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import ContextProvider from 'common/contexts/crud';

const HourSlotContext = createContext();

export const HourSlotProvider = ({ children }) => (
  <ContextProvider url="hour-slot-models" context={HourSlotContext}>{children}</ContextProvider>
);

HourSlotProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useHourSlot = () => useContext(HourSlotContext);

export default useHourSlot;
