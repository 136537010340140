import { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const RelativeDate = ({ short, value }) => {
  // this is done to force react to rerender every minutes, more info :
  // https://fr.reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  // eslint-disable-next-line no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const zeroPad = (num, places) => String(num).padStart(places, '0');

  if (short) {
    const diff = dayjs().diff(value, 'hours', true);
    const hours = zeroPad(parseInt(diff, 10), 2);
    const minutes = zeroPad(parseInt((diff - hours) * 60, 10), 2);

    return hours ? `${hours}h${minutes}` : `${minutes}m`;
  }

  const formatedDate = dayjs(value).fromNow();

  return formatedDate;
};

RelativeDate.propTypes = {
  value: PropTypes.any.isRequired,
  short: PropTypes.bool,
};

RelativeDate.defaultProps = {
  short: false,
};

export default RelativeDate;
