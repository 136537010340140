import MapListItem from 'bookings/components/MapListItem/MapListItem';
import Page from 'common/components/Page/Page';
import PropTypes from 'prop-types';
import React from 'react';
import useSite from 'sites/contexts/sites';

const MapsList = ({ route, navigation }) => {
  const {
    item: site,
  } = useSite();
  const { edit } = route.params;

  /** Site is loading by previous page but can have little delay */
  if (!site) {
    return null;
  }

  return (
    <Page safeArea scroll padding style={{ marginBottom: 0, paddingBottom: 0 }}>
      {site.maps.map((map) => (
        <>
          {map.active && (
          <MapListItem
            navigation={navigation}
            key={map.id}
            siteId={site.id}
            edit={edit}
            {...map}
          />
          )}
        </>
      ))}
    </Page>
  );
};

MapsList.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default MapsList;
