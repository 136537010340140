import PropTypes from 'prop-types';
import React from 'react';
import { View, Dimensions } from 'react-native';
import useSite from 'sites/contexts/sites';
import theme from 'styles';
import Gradient from '../Gradient/Gradient';

const FooterButtonWrapper = ({
  inBox, customHeight, children, relative,
}) => {
  const { item: site } = useSite();
  const template = site?.template;

  const isTablet = Dimensions.get('window').width > 767 && Dimensions.get('window').height > 767;

  return (
    <View style={[
      {
        paddingVertical: theme.sizings.small,
      },
      inBox && {
        height: isTablet ? ('auto' || 60) : (customHeight || 60),
        maxHeight: isTablet ? ('auto' || 60) : (customHeight || 60),
      },
      /* {
        flex: 1,
        position: relative ? 'relative' : 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        marginTop: 0,
        backgroundColor: template?.secondaryColor || theme.colors.dark,
      },
      !inBox && {
        height: isTablet ? ('auto' || 150) : (customHeight || 96),
        maxHeight: isTablet ? ('auto' || 150) : (customHeight || 96),
        paddingTop: theme.sizings.smallMedium,
      }, */
    ]}
    >
      {/* template && (
        <Gradient
          start={[0, 0.5]}
          end={[1, 0.5]}
          width="100%"
          height="100%"
        />
      ) */}
      <View style={[{
        position: 'relative',
        width: '100%',
        alignItems: 'center',
        // top: inBox ? 0 : -40,
      },
      inBox && {
        height: '100%',
        justifyContent: 'center',
      }]}
      >
        {children}
      </View>
    </View>
  );
};

FooterButtonWrapper.propTypes = {
  inBox: PropTypes.bool,
  children: PropTypes.node,
  customHeight: PropTypes.number,
  relative: PropTypes.bool,
};

FooterButtonWrapper.defaultProps = {
  inBox: false,
  children: null,
  customHeight: null,
  relative: false,
};

export default FooterButtonWrapper;
