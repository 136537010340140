import React, {
  useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Dimensions, View } from 'react-native';
import theme from 'styles';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import Button from 'common/components/Button/Button';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import useFetch from 'common/hooks/useFetch';
import cardImage from 'assets/card.png';
import useSite from 'sites/contexts/sites';
import FooterButtonWrapper from 'common/components/FooterButtonWrapper/FooterButtonWrapper';

const useOptions = () => {
  // const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: 18,
          color: '#424770',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [],
  );

  return options;
};

const CardContent = ({
  callback = () => {},
  payment,
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const { item: site } = useSite();
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { data: intent } = useFetch(`payments/${payment.id}/intent`);
  const { fetchData } = useFetch(`payments/${payment.id}/paid`, { manual: true });
  const handleSubmit = async (event) => {
    setError(null);
    setLoading(true);

    try {
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const result = await stripe.confirmCardPayment(intent.client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      });

      if (result.paymentIntent) {
        const payload = {
          transactionId: result.paymentIntent.id,
          payment_method: result.paymentIntent.payment_method,
        };

        await fetchData({ method: 'POST', payload });
        console.log('Payment done');
        callback(true, '');
      } else {
        setError(result.error.message);
        console.log('ERROR', result.error.message);
        callback(false, result.error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Page backgroundImage={site?.template.imageBackdrop}>

        <View style={{
          padding: theme.sizings.medium,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <View style={{
            display: 'flex',
            flexDirection: 'column',
            justifContent: 'center',
            paddingRight: 32,
            flex: 1,
          }}
          >
            <Text
              centered
              size="large"
              isBold
              color="dark"
              customColor={site?.template?.backdropTextColor}
            >
              {t('creditCard.cardDescription')}

            </Text>
          </View>
          <View style={{
            width: 294,
            maxWidth: 294,
            height: 190,
            padding: 16,
            display: 'flex',
            flexFlow: 'wrap',
            alignItems: 'center',
            shadowColor: '#000000',
            shadowOffset: { width: 2, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
            borderRadius: 16,
            backgroundImage: `url(${cardImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            marginTop: 32,
          }}
          >
            <form style={{ width: '100%' }}>
              <Text size="medium" isBold>{t('creditCard.cardNumber')}</Text>
              <CardNumberElement
                options={options}
                onReady={() => {
                // console.log('CardNumberElement [ready]');
                }}
                onChange={() => {
                // console.log('CardNumberElement [change]', event);
                }}
                onBlur={() => {
                // console.log('CardNumberElement [blur]');
                }}
                onFocus={() => {
                // console.log('CardNumberElement [focus]');
                }}
              />
              <Text size="medium" isBold>{t('creditCard.expirationDate')}</Text>
              <CardExpiryElement
                options={options}
                onReady={() => {
                // console.log('CardNumberElement [ready]');
                }}
                onChange={() => {
                // console.log('CardNumberElement [change]', event);
                }}
                onBlur={() => {
                // console.log('CardNumberElement [blur]');
                }}
                onFocus={() => {
                // console.log('CardNumberElement [focus]');
                }}
              />
              <Text size="medium" isBold>{t('creditCard.ccv')}</Text>
              <CardCvcElement
                options={options}
                onReady={() => {
                // console.log('CardNumberElement [ready]');
                }}
                onChange={() => {
                // console.log('CardNumberElement [change]', event);
                }}
                onBlur={() => {
                // console.log('CardNumberElement [blur]');
                }}
                onFocus={() => {
                // console.log('CardNumberElement [focus]');
                }}
              />

            </form>
          </View>
        </View>
        { Boolean(error) && (
        <View style={{
          textAlign: 'center',
          paddingHorizontal: theme.sizings.mediumLarge,
        }}
        >
          <Text
            size="small"
            color="dark"
            customColor={site?.template?.backdropTextColor}
            isBold
          >
            {error}

          </Text>
        </View>
        )}
        <FooterButtonWrapper>
          <Button
            data-cy="cypress_pay"
            variant="light"
            icon="credit-card"
            text={isLoading ? t('common.loading') : t('creditCard.confirm')}
            onPress={handleSubmit}
            disabled={(isLoading)}
          />
        </FooterButtonWrapper>
      </Page>
    </>
  );
};

CardContent.propTypes = {
  callback: PropTypes.func.isRequired,
  payment: PropTypes.any.isRequired,
};

export default CardContent;
