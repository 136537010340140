import React, { useMemo } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

import useBooking from 'bookings/contexts/bookings';
import theme from 'styles';
import useSeatSelector from '../../contexts/seatSelector';
import Overlay from './Overlay';
import Item from '../Item/Item';

const BoardSquare = ({
  item,
}) => {
  const { item: booking } = useBooking();
  const { handleSeatClick } = useSeatSelector();

  const { available, selected } = item;

  const price = useMemo(() => {
    if (item && item.price_area && item.furniture && item.furniture.prices) {
      const priceAreaId = item.price_area.id;
      const price = item.furniture.prices.find((p) => Number.isInteger(p.price_area)
        ? p.price_area === priceAreaId : p.price_area.id === priceAreaId);

      return price;
    }
    return null;
  }, [item]);

  return (
    <TouchableOpacity
      data-cy="cypress_seat"
      style={styles.boardSquare}
      onPress={available
        ? () => { handleSeatClick({ price: booking.hotel ? price?.hotelPrice : price?.price, ...item }); }
        : () => {}}
    >
      <View style={styles.square}>
        <Item {...item} hotel={false} />
      </View>
      { selected && <Overlay color="blue" /> }
      { !available && <Overlay color="red" /> }
    </TouchableOpacity>
  );
};

BoardSquare.propTypes = {
  item: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  boardSquare: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  square: {
    backgroundColor: theme.colors.beach,
    width: '100%',
    height: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.colors.secondary,
  },
});

export default BoardSquare;
