import useCategories from 'categories/contexts/categories';
import Page from 'common/components/Page/Page';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import theme from 'styles';
import ProductItem from 'waiter/components/ProductItem/ProductItem';

const styles = StyleSheet.create({
  parent: {
    marginTop: theme.sizings.medium,
  },

  child: {
    marginTop: theme.sizings.small,
  },
});

const Catalog = () => {
  const {
    fetchItems, items: categories = [],
  } = useCategories();

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const cats = categories.sort((a, b) => Number(a.ordering || 0) - Number(b.ordering || 0));

  return (
    <Page padding scroll>
      { cats.map((category) => (
        <View key={category.id}>
          <View style={styles[category.parent ? 'child' : 'parent']}>
            <TranslatedText
              isBold
              color={category.parent ? 'dark' : 'primary'}
              size={category.parent ? 'large' : 'larger'}
              value={category.name}
            />
          </View>
          <View>
            { category.products?.map((product) => (
              <ProductItem key={product.id} product={product} />
            ))}
          </View>
        </View>
      ))}
    </Page>
  );
};

export default Catalog;
