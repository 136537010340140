import { useHeaderHeight } from '@react-navigation/elements';
import { ImageBackground } from 'common/components/Images';
import React from 'react';
import { Dimensions, View } from 'react-native';
import useSite from 'sites/contexts/sites';
import theme from 'styles';

const PageBackground = () => {
  const { item: site } = useSite();
  const headerHeight = useHeaderHeight();
  const { width, height } = Dimensions.get('window');

  return (
    <>
      {site?.template?.imageBackdrop && (
      <View style={{
        flex: 1,
        backgroundColor: theme.colors.white,
        width,
        height: height - headerHeight,
        position: 'absolute',
        top: 0,
        left: 0,
      }}
      >
        <ImageBackground
          image={site.template.imageBackdrop}
          style={{ width: '100%', height: '100%', position: 'absolute' }}
        />
      </View>
  )}
    </>
  );
};

export default PageBackground;
