import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PanResponder, StyleSheet, View } from 'react-native';

export default class ZoomableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scale: this.props.scale,
      lastScale: 1,
      offsetX: 0,
      offsetY: 0,
      lastX: 0,
      lastY: 0,
      lastMovePinch: false,
    };
    this.distant = 150;

    this.prevTouchInfo = {
      prevTouchX: 0,
      prevTouchY: 0,
      prevTouchTimeStamp: 0,
    };
  }

  componentDidMount() {
    this.gestureHandlers = PanResponder.create({
      onStartShouldSetPanResponder: this.handleStartShouldSetPanResponder,
      onMoveShouldSetPanResponder: this.handleMoveShouldSetPanResponder,
      onPanResponderGrant: this.handlePanResponderGrant,
      onPanResponderMove: this.handlePanResponderMove,
      onPanResponderRelease: this.handlePanResponderEnd,
      onPanResponderTerminationRequest: () => true,
      onShouldBlockNativeResponder: () => false,
    });
  }

  /**
 * Takes a single offset value and calculates the correct offset value within our view to make
 *
 * @param offsetValue
 * @param containerSize
 * @param elementSize
 * @param zoomLevel
 *
 * @returns {number}
 */
  getBoundOffsetValue = (offsetValue, containerSize, elementSize, zoomLevel) => {
    const startBorder = ((elementSize - containerSize) / 2) / zoomLevel;
    const endBorder = -startBorder;

    // calculate distance to start and end borders
    const distanceToStart = (offsetValue - startBorder);
    const distanceToEnd = (offsetValue + startBorder) * -1;

    // if both sides (before and after the element) have a positive distance
    // => (our zoomed content is smaller than the frame)
    // => so center it
    if (containerSize > elementSize) {
      return ((containerSize / 2) - (elementSize / 2) / zoomLevel);
    }

    // if everything above failed
    // => (one side is outside of the borders)
    // => find out which one it is and make sure it is 0
    if (distanceToStart > 0) {
      return startBorder;
    }

    // if there is distance to the end border
    // => (it is outside of the box)
    // => adjust offset to make sure it stays within
    if (distanceToEnd > 0) {
      return endBorder;
    }

    // if everything above failed
    // => (everything is within borders)
    // => just return the original offset value
    return offsetValue;
  };

  /**
   * Get the original box dimensions and save them for later use.
   * (They will be used to calculate boxBorders)
   *
   * @param layoutEvent
   * @private
   */
  getBoxDimensions = (layoutEvent) => {
    const {
      height, width,
    } = layoutEvent.nativeEvent.layout;

    this.setState({
      originalWidth: width,
      originalHeight: height,
    });
  };

  handleStartShouldSetPanResponder = (evt, gestureState) => {
    const currentTouchTimeStamp = Date.now();

    if (gestureState.numberActiveTouches === 1) {
      if (this.isDoubleTap(currentTouchTimeStamp, gestureState)) {
        const scale = this.state.scale * 2;

        if (scale < this.props.maxScale && scale > this.props.minScale) {
          const zoomObj = this.bindOffsetValuesToBorders({
            scale,
            offsetX: this.state.offsetX,
            offsetY: this.state.offsetY,
          });

          this.setState({ ...zoomObj, lastMovePinch: true });
        }

        this.prevTouchInfo = {
          prevTouchX: 0,
          prevTouchY: 0,
          prevTouchTimeStamp: 0,
        };
      }

      this.prevTouchInfo = {
        prevTouchX: gestureState.x0,
        prevTouchY: gestureState.y0,
        prevTouchTimeStamp: currentTouchTimeStamp,
      };
    }

    return false;
  };

  handleMoveShouldSetPanResponder = (e, gestureState) => (
    this.props.scalable
      && (Math.abs(gestureState.dx) > 2
        || Math.abs(gestureState.dy) > 2
        || gestureState.numberActiveTouches === 2)
  );

  distance= (x0, y0, x1, y1) => Math.sqrt((x1 - x0) ** 2 + (y1 - y0) ** 2)

  isDoubleTap= (currentTouchTimeStamp, { x0, y0 }) => {
    const { prevTouchX, prevTouchY, prevTouchTimeStamp } = this.prevTouchInfo;
    const dt = currentTouchTimeStamp - prevTouchTimeStamp;
    const delay = 300;
    const radius = 20;

    return dt < delay && this.distance(prevTouchX, prevTouchY, x0, y0) < radius;
  }

  handlePanResponderGrant = (e, gestureState) => {
    if (gestureState.numberActiveTouches === 2) {
      const dx = Math.abs(
        e.nativeEvent.touches[0].pageX - e.nativeEvent.touches[1].pageX,
      );

      const dy = Math.abs(
        e.nativeEvent.touches[0].pageY - e.nativeEvent.touches[1].pageY,
      );

      const distant = Math.sqrt(dx * dx + dy * dy);

      this.distant = distant;
    }
  };

  handlePanResponderEnd = () => {
    this.setState((prevState) => ({
      lastX: prevState.offsetX,
      lastY: prevState.offsetY,
      lastScale: prevState.scale,
    }));
  };

  handlePanResponderMove = (e, gestureState) => {
    // zoom
    if (gestureState.numberActiveTouches === 2) {
      const dx = Math.abs(
        e.nativeEvent.touches[0].pageX - e.nativeEvent.touches[1].pageX,
      );

      const dy = Math.abs(
        e.nativeEvent.touches[0].pageY - e.nativeEvent.touches[1].pageY,
      );

      const distant = Math.sqrt(dx * dx + dy * dy);

      const scale = (distant / this.distant) * this.state.lastScale;
      // check scale min to max hello

      if (scale < this.props.maxScale && scale > this.props.minScale) {
        const zoomObj = this.bindOffsetValuesToBorders({
          scale,
          offsetX: this.state.offsetX,
          offsetY: this.state.offsetY,
        });

        this.setState({ ...zoomObj, lastMovePinch: true });
      }
    } else if (gestureState.numberActiveTouches === 1) {
      if (this.state.lastMovePinch) {
        // eslint-disable-next-line no-param-reassign
        gestureState.dx = 0;
        // eslint-disable-next-line no-param-reassign
        gestureState.dy = 0;
      }

      const moveObj = this.bindOffsetValuesToBorders({
        offsetX: this.state.lastX + gestureState.dx / this.state.scale,
        offsetY: this.state.lastY + gestureState.dy / this.state.scale,
        scale: this.state.scale,
      });

      this.setState({
        ...moveObj,
        lastMovePinch: false,
      });
    }
  };

  /**
   * Takes a change object (that is going to be used in setState) and makes sure offsetX and
   * offsetY are within our view borders. If that is not the case, they will be corrected.
   *
   * @param changeObj the object that is going to be modified.
   *    Needs to contain at least the following elements:
   *    {
   *      scale: numeric,
   *      offsetX: numeric,
   *      offsetY: numeric,
   *    }
   * @private
   */
  bindOffsetValuesToBorders(changeObj) {
    // if bindToBorders is disabled -> nothing do here
    if (!this.props.bindToBorders) {
      return changeObj;
    }

    const { originalWidth, originalHeight } = this.state;

    const currentElementWidth = originalWidth * changeObj.scale;
    const currentElementHeight = originalHeight * changeObj.scale;

    const newChangeObj = { ...changeObj };

    // make sure that view doesn't go out of borders
    const offsetXBound = this.getBoundOffsetValue(
      changeObj.offsetX,
      originalWidth,
      currentElementWidth,
      changeObj.scale,
    );

    newChangeObj.offsetX = offsetXBound;

    const offsetYBound = this.getBoundOffsetValue(
      changeObj.offsetY,
      originalHeight,
      currentElementHeight,
      changeObj.scale,
    );

    newChangeObj.offsetY = offsetYBound;

    return newChangeObj;
  }

  render() {
    if (!this.gestureHandlers) {
      return null;
    }
    return (
      <View
        {...this.gestureHandlers.panHandlers}
        onLayout={this.getBoxDimensions}
        style={[
          styles.container,
          this.props.style,
          {
            transform: [
              { scaleX: this.state.scale },
              { scaleY: this.state.scale },
              { translateX: this.state.offsetX },
              { translateY: this.state.offsetY },
            ],
          },
        ]}
      >
        {this.props.children}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

ZoomableView.propTypes = {
  children: PropTypes.any.isRequired,
  style: PropTypes.any,
  scale: PropTypes.number,
  scalable: PropTypes.bool,
  minScale: PropTypes.number,
  maxScale: PropTypes.number,
  bindToBorders: PropTypes.bool,
};

ZoomableView.defaultProps = {
  scalable: true,
  style: null,
  scale: 1,
  minScale: 0.5,
  maxScale: 2,
  bindToBorders: true,
};
