/* eslint-disable max-len */
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import useBasket from 'basket/contexts/basket';
import useCall from 'calls/contexts/calls';
import Button from 'common/components/Button/Button';
import Gradient from 'common/components/Gradient/Gradient';
import { ImageBackground } from 'common/components/Images';
import ReductionPrice from 'common/components/ReductionPrice/ReductionPrice';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import useTrans from 'common/hooks/use-trans';
import getWording from 'common/utils/getWording';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import {
  StyleSheet, TouchableHighlight, View, ScrollView, Dimensions,
} from 'react-native';
import useSite from 'sites/contexts/sites';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';

const styles = StyleSheet.create({
  description: {
    width: '80%',
  },

  optionsTitle: {
    paddingTop: theme.sizings.medium,
  },
  optionsTitleText: {
    justifyContent: 'center',
  },
  options: {
    marginTop: theme.sizings.small,
  },
  optionVariants: {
    paddingTop: theme.sizings.smallMedium,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  option: {
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.tiny,
    marginRight: theme.sizings.small,
    marginBottom: theme.sizings.small,
    minWidth: 80,
    alignItems: 'center',
    borderRadius: theme.radius.rounded,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.32)',
  },
  buttonsGroupBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.radius.rounded,
    overflow: 'hidden',
    paddingHorizontal: theme.sizings.small,
  },
  quantity: {
    marginVertical: 0,
    marginHorizontal: theme.sizings.small,
  },
  productButton: {
    paddingHorizontal: theme.sizings.small,
  },
});

const Product = ({ route }) => {
  const { t, i18n } = useTranslation();
  const { item: site } = useSite();
  const navigation = useNavigation();
  const { translateText } = useTrans();

  const lang = i18n.language;
  const {
    addItem, removeItem, items: basket, isAllInclusiveBasket, setIsAllInclusiveBasket,
  } = useBasket();
  const template = site?.template;
  const { product, reduction } = route.params;
  const { options, /* variant_prices: variantPrices, */ price } = product;
  const { setAlert } = useAlert();

  const minPrice = (options && options.length > 0)
    ? price + Math.min(...options.map((o) => Math.min(...o.variant_prices.map((vp) => vp.price))))
    : price;
  const maxPrice = (options && options.length > 0)
    ? price + Math.max(...options.map((o) => Math.max(...o.variant_prices.map((vp) => vp.price))))
    : price;

  const isFree = site?.all_inclusive && isAllInclusiveBasket && product.all_inclusive;

  const [selectedOptions, setSelectedOptions] = useState(options.map((o) => ({ option: o.option.id, variants: [] })));
  const [offsetPrice, setOffsetPrice] = useState(0);

  const { currentCall, callWaiter } = useCall();
  const quantity = basket.filter((item) => item.product.id === product.id)[0]?.quantity || 0;
  const isTablet = Dimensions.get('window').width > 767 && Dimensions.get('window').height > 767;

  const calculateAdditionalOptionsPrice = useCallback((currentSelectedOptions) => {
    const totalPrice = product.options.map(
      (option) => option.variant_prices.map(
        (variantPrice) => {
          const optionPrice = currentSelectedOptions.find(
            (selectedOption) => selectedOption.option === option.option.id,
          ).variants.includes(variantPrice.option_variant.id);

          return optionPrice ? variantPrice.price : 0;
        },
      ).reduce((partial, item) => partial + item, 0),
    ).reduce((partial, item) => partial + item, 0);
    // return only the additionnal price for options

    return totalPrice || 0;
  }, [product]);

  const handlePressOption = useCallback((optionIndex, variantId, variantPrice, multiple) => {
    const selectedCopy = [...selectedOptions];

    if (multiple) {
      const variantIndex = selectedCopy[optionIndex].variants.findIndex((v) => v === variantId);

      if (variantIndex >= 0) {
        selectedCopy[optionIndex].variants.splice(variantIndex, 1);
      } else {
        selectedCopy[optionIndex].variants.push(variantId);
      }
    } else {
      selectedCopy[optionIndex].variants = (selectedCopy[optionIndex].variants.length > 0
        && selectedCopy[optionIndex].variants[0].id === variantId)
        ? []
        : [variantId];
    }

    const offset = calculateAdditionalOptionsPrice(selectedCopy);

    setSelectedOptions(selectedCopy);
    setOffsetPrice(offset);
  }, [calculateAdditionalOptionsPrice, selectedOptions]);

  const handleAddBasket = (product, selectedOptions, price) => {
    if (!product.options.length || selectedOptions.find((o) => o.variants.length > 0)) {
      addItem(product, selectedOptions, price);

      if (site?.pleaseMyCar) {
        if (site?.all_inclusive && product?.all_inclusive) {
          setIsAllInclusiveBasket(true);
        }
        navigation.navigate('Basket');
      }
    } else {
      setAlert({ color: 'error', title: t('common.error'), message: t('basket.errorNoOptions') });
    }
  };

  return (
    <ImageBackground image={product.images[0]} style={{ width: '100%', height: '100%', marginBottom: 0 }}>
      <View style={commonStyles.fullViewContainer}>
        <View style={commonStyles.fullViewDetailsContainer}>
          <View style={commonStyles.fullViewButtonContainer}>
            {product.callWaiter ? (
              <Button
                text={t(`beach.${currentCall ? 'cancelCall' : 'callWaiter'}`)}
                onPress={callWaiter}
                variant={currentCall ? 'greyDarker' : 'gradient'}
              />
            ) : (
              <View style={styles.buttonsGroupBox}>
                {/* on affiche les boutons d'ajouts seulement si pas d'options, OU si options dont toutes sont précisées */}
                {
                  /* s'il y'a des options, on se contente du bouton "+", sans les quantités */
                  (quantity < 1 || options.length) ? (

                    <Button
                      text={t('basket.add')}
                      variant="gradient"
                      onPress={() => {
                        handleAddBasket(product,
                          // eslint-disable-next-line camelcase
                          selectedOptions,
                          product.price + offsetPrice); // variantSelected ? variantSelected.price : product.price);
                      }}
                      /* disabled={!(!options.length || (options.length && !selectedOptions.find((o) => !o.variants.length)))} */
                    />
                  ) : (
                    <View style={[commonStyles.cardButtonProductWrapper, styles.buttonsGroup, {
                      width: isTablet ? 160 : 100,
                    }]}
                    >
                      <Gradient height={isTablet ? 65 : 55} />
                      <Button
                        noPadding
                        keepFont
                        icon="minus"
                        variant="transparent"
                        iconSize="default"
                        style={[styles.productButton, {
                          height: isTablet ? 60 : 32,
                          width: isTablet ? 60 : 32,
                        }]}
                        onPress={() => removeItem(product, selectedOptions, 'product')}
                      />
                      <Text size="large" color="light" style={styles.quantity} isTitleText>{quantity}</Text>
                      <Button
                        noPadding
                        keepFont
                        icon="plus"
                        variant="transparent"
                        iconSize="default"
                        style={[styles.productButton, {
                          height: isTablet ? 60 : 32,
                          width: isTablet ? 60 : 32,
                        }]}
                        onPress={() => addItem(product, selectedOptions, product.price + offsetPrice, 'product')}
                      />
                    </View>
                  )
                }
              </View>
            )}
          </View>
          <View style={{
            padding: theme.sizings.smallMedium,
            backgroundColor: theme.colors.greyAlpha,
            minHeight: isTablet ? 290 : null,
          }}
          >
            {template?.isPriceVisible && (
              <View style={[commonStyles.fullViewDetailsPriceWrapper, {
                backgroundColor: theme.colors.light,
              }]}
              >
                <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >
                  {isFree && (
                    <FontAwesome5
                      name="gift"
                      color={template?.cardTextColor || theme.colors.dark}
                      size={theme.fontSizes.medium}
                      style={{
                        marginRight: theme.sizings.small,
                      }}
                    />
                  )}
                  <ReductionPrice
                    // price={variantSelected ? variantSelected.price : product.price}
                    price={isFree ? 0
                      : minPrice + offsetPrice}
                    reduction={reduction}
                    reductionPriceColor={template?.cardTextColor || theme.colors.dark}
                    priceCustomColor={template?.cardTextColor || theme.colors.dark}
                    newPriceSize="huge"
                    reductionPriceSize="largest"
                  />
                  {!isFree
                  && maxPrice !== minPrice + offsetPrice
                  && product.options.length > 0
                    && !selectedOptions.filter((option) => option.variants.length > 0).length > 0
                    && (
                      <>
                        <Text size="huge"> - </Text>
                        <ReductionPrice
                          price={maxPrice}
                          reduction={reduction}
                          reductionPriceColor={template?.cardTextColor || theme.colors.dark}
                          priceCustomColor={template?.cardTextColor || theme.colors.dark}
                          newPriceSize="huge"
                          reductionPriceSize="largest"
                        />
                      </>
                    )}
                </View>
              </View>
            )}
            <ScrollView style={reduction ? commonStyles.fullViewDetailsContentDiscount : commonStyles.fullViewDetailsContent}>
              <View style={styles.description}>
                <TranslatedText
                  customColor={theme.colors.light}
                  style={{ color: theme.colors.light }}
                  /* tagsStyles={{
                    p: { color: theme.colors.light },
                    span: { color: theme.colors.light },
                  }} */
                  value={product.description}
                />
              </View>
              {options.length > 0
                && (
                  <View>
                    <View style={[styles.optionsTitle, commonStyles.textWithIcon]}>
                      <View style={commonStyles.fullViewDetailsIcon}>
                        <FontAwesome name="plus" color={theme.colors.secondaryLight} />
                      </View>
                      <View style={styles.optionsTitleText}>
                        <Text color="light" size="large">
                          {getWording(site?.vocabulary?.productOptions, t('common.options'), lang,
                            translateText)}
                        </Text>
                      </View>
                    </View>
                    <ScrollView style={styles.options}>
                      { /* eslint-disable-next-line camelcase  */}
                      {options.map(({ option, variant_prices }, optionIndex) => (
                        <View key={`option_${optionIndex}`}>
                          <View style={styles.optionsTitleText}>
                            <TranslatedText color="white" value={option.name} />
                          </View>
                          <View style={styles.optionVariants}>
                            {variant_prices.map((variant) => {
                              const isSelected = selectedOptions[optionIndex].variants.find((v) => v === variant.option_variant.id);

                              return (
                                <TouchableHighlight
                                  key={variant.id}
                                  style={[styles.option, {
                                    backgroundColor: isSelected ? 'rgba(255, 255 , 255, 0.32)' : 'transparent',
                                  }]}
                                  onPress={() => handlePressOption(optionIndex, variant.option_variant.id, variant.price, option.multiple)}
                                >
                                  <View>
                                    <TranslatedText color="light" value={variant.option_variant.name} />
                                  </View>
                                </TouchableHighlight>
                              );
                            })}
                          </View>
                        </View>
                      ))}
                    </ScrollView>
                  </View>
                )}
            </ScrollView>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

Product.propTypes = {
  route: PropTypes.object.isRequired,
};

export default Product;
