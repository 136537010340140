import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import theme from 'styles';

const OrderStateBadge = ({ orders }) => {
  const cssClassByState = {
    at_arrival: 'light',
    created: 'warning',
    ready: 'sucess',
    in_preparation: 'info',
  };
  const ordersByState = _.groupBy(orders, 'state');

  let badge = null;

  Object.keys(cssClassByState).some((state) => {
    if (ordersByState[state] && ordersByState[state].length) {
      badge = <View style={styles(cssClassByState[state]).badge} />;
      return true;
    }
    return false;
  });

  return (
    badge
  );
};

OrderStateBadge.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const styles = (color) => StyleSheet.create({
  badge: {
    position: 'absolute',
    right: -6,
    top: -3,
    backgroundColor: theme.colors[color],
    borderRadius: theme.radius.rounded,
    width: theme.sizings.small,
    height: theme.sizings.small,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default OrderStateBadge;
