import React, { useCallback, useEffect, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import * as Location from 'expo-location';
import PropTypes from 'prop-types';
import { LinearGradient } from 'expo-linear-gradient';
import { FontAwesome } from '@expo/vector-icons';
import dayjs from 'dayjs';

import Page from 'common/components/Page/Page';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Text from 'common/components/Text/Text';
import SiteListItem from 'sites/components/SiteListItem/SiteListItem';
import useSite from 'sites/contexts/sites';
import BookingCodeModal from 'bookings/components/BookingCodeModal/BookingCodeModal';
import useAlert from 'common/contexts/alert';
import useBookings from 'bookings/contexts/bookings';
import useTranslation from 'common/contexts/translations';
import commonStyles from 'styles/commonStyles';
import theme from 'styles';
// import useSeatSelector from 'bookings/pages/SeatSelector/contexts/seatSelector';
import styles from 'home/pages/HomePage.styles';

const QuickSitesList = ({ navigation }) => {
  const { t } = useTranslation();
  const {
    fetchItems: fetchSites,
    items: sites,
    isFetching,
    setFilters,
  } = useSite();
  const { checkCode, setItem: setBooking } = useBookings();
  const { setAlert } = useAlert();
  // const { setSelection } = useSeatSelector();

  const [modalVisible, setModalVisible] = useState(false);

  const handleSubmit = useCallback(async ({ code }) => {
    try {
      const booking = await checkCode(code);

      if (booking === 'OK') {
        navigation.navigate('HomePage');
        setAlert({ color: 'success', title: t('common.success'), message: t('bookings.codeSuccess') });
      }
      navigation.navigate('SiteHome', { siteId: booking.site.id, bookingId: booking.id });
    } catch {
      setAlert({ color: 'error', title: t('common.error'), message: t('bookings.codeError') });
    } finally {
      setModalVisible(false);
    }
  }, [checkCode, navigation, setAlert, t]);

  useEffect(() => {
    const fetch = async () => {
      const { status } = await Location.requestForegroundPermissionsAsync();

      if (status === 'granted') {
        const position = await Location.getCurrentPositionAsync({});

        setFilters({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      }
    };

    setBooking(() => ({
      quick: true,
      hotel: false,
      roomID: '',
      dates: [dayjs().utc().startOf('day').format()],
    }));
    // setSelection([]);

    fetch();
  }, [navigation, setBooking, setFilters]); // , setSelection

  // fetchSites change à chaque fois que setFilters est appelé
  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  return (
    <Page safeArea scroll padding style={{ marginBottom: 0, paddingBottom: 0 }}>
      <LoadingSpinner
        visible={isFetching}
      />
      <BookingCodeModal
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
        handleCodeSubmit={handleSubmit}
      />
      <TouchableOpacity
        style={[styles.button, commonStyles.shadowBottom]}
        onPress={() => setModalVisible(true)}
      >
        <Text isBold color="dark" size="large">{t('beach.enterTicket')}</Text>
        <View style={styles.icon}>
          <LinearGradient
            colors={[theme.colors.secondary, theme.colors.secondaryDark]}
            start={[0, 0.5]}
            end={[1, 0.5]}
            style={{
              zIndex: -10,
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              height: 30,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FontAwesome name="arrow-right" color={theme.colors.light} size={theme.fontSizes.medium} />
          </LinearGradient>
        </View>
      </TouchableOpacity>
      {sites.map((site) => (
        <SiteListItem
          navigation={navigation}
          key={site.id}
          targetLink="MapsList"
          {...site}
        />
      ))}
    </Page>
  );
};

QuickSitesList.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default QuickSitesList;
