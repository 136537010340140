import { StyleSheet } from 'react-native';
import theme from 'styles';

export default StyleSheet.create({
  card: {
    backgroundColor: theme.colors.dark,
    flex: 1,
    marginBottom: theme.sizings.large,
    borderTopLeftRadius: theme.radius.medium,
    borderTopRightRadius: theme.radius.medium,
    borderBottomLeftRadius: theme.radius.medium,
    borderBottomRightRadius: theme.radius.medium,
    overflow: 'hidden',
  },
  imageWrapper: {
    backgroundColor: theme.colors.white,
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: 250,
  },
});
