/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
  View, StyleSheet, TouchableOpacity, TouchableHighlight,
} from 'react-native';
import CalendarPicker from 'react-native-calendar-picker';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Modal from 'common/components/Modal/Modal';
import Text from 'common/components/Text/Text';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import useTranslation from 'common/contexts/translations';

const DateInput = ({
  value, onChange, label, multiple, ...rest
}) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [date, setDate] = useState(dayjs().utc().startOf('day'));

  const onDateChange = (newDate) => {
    setDate(newDate.utc().startOf('day'));

    if (multiple) {
      let tmpSelectedDates = value;
      const chosenDate = newDate.utc().startOf('day');

      if (tmpSelectedDates.length > 0) {
        const result = tmpSelectedDates.find((elem) => chosenDate.isSame(elem.date));

        if (result) {
          tmpSelectedDates = tmpSelectedDates.filter((elem) => elem !== result);
        } else {
          tmpSelectedDates.push({
            date: chosenDate,
            style: styles.selectedDay,
          });
        }
      } else {
        tmpSelectedDates.push({
          date: chosenDate,
          style: styles.selectedDay,
        });
      }
      onChange(tmpSelectedDates.sort((a, b) => a.date - b.date));
    } else {
      onChange(newDate.utc().startOf('day'));
      setModalVisible(false);
    }
  };

  return (
    <View style={styles.containerStyle}>
      <TouchableHighlight onPress={() => setModalVisible(true)}>
        <View>
          <Text color="primary" isBold style={styles.labelStyle}>{label}</Text>
          {value.length ? (
            value.map((date) => (
              <Text style={styles.inputContainerStyle}>{date.date.format('LL')}</Text>
            ))
          ) : (
            <Text style={styles.inputContainerStyle}>{date.format('LL')}</Text>
          )}
        </View>
      </TouchableHighlight>
      <Modal
        style={commonStyles.modal}
        transparent
        visible={modalVisible}
        ariaHideApp={false}
      >
        {/* Inline style, styled component not working */}
        <TouchableOpacity
          onPress={() => setModalVisible(false)}
          style={commonStyles.modalMask}
        >
          <View style={commonStyles.modalBox}>
            <CalendarPicker
              onDateChange={onDateChange}
              selectedStartDate={date.format()}
              weekdays={t('common.weekdays').split(',')}
              months={t('common.months').split(',')}
              startFromMonday
              previousTitle={t('common.previous')}
              nextTitle={t('common.next')}
              selectedDayColor={theme.colors.secondary}
              selectedDayStyle={styles.selectedDay}
              todayBackgroundColor="transparent"
              customDatesStyles={value}
              {...rest}
            />
          </View>
        </TouchableOpacity>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  inputContainerStyle: {
    borderBottomColor: theme.colors.primary,
    borderBottomWidth: 1,
    alignSelf: 'stretch',
    paddingTop: theme.sizings.small,
    fontSize: theme.fontSizes.medium,
  },

  containerStyle: {
    marginVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
  },

  labelStyle: {
    textTransform: 'capitalize',
    color: theme.colors.primary,
    fontSize: theme.fontSizes.medium,
  },

  selectedDay: {
    width: 40,
    height: 40,
    backgroundColor: theme.colors.secondary,
  },
});

DateInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  multiple: PropTypes.bool,
};

DateInput.defaultProps = {
  value: '',
  label: '',
  multiple: false,
};

export default DateInput;
