import React from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity } from 'react-native';

import styles from './Card.styles';

const Card = ({
  children, noPadding, noShadow, style, isClickable, onPress,
}) => (
  <>
    {isClickable ? (
      <TouchableOpacity delayPressIn={150} onPress={onPress}>
        <View style={[styles(noPadding, noShadow).card, style]}>
          {children}
        </View>
      </TouchableOpacity>
    ) : (
      <View style={[styles(noPadding, noShadow).card, style]}>{children}</View>
    )}
  </>
);

Card.propTypes = {
  noPadding: PropTypes.bool,
  noShadow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  isClickable: PropTypes.bool,
  onPress: PropTypes.func,
};

Card.defaultProps = {
  noPadding: false,
  noShadow: false,
  style: {},
  isClickable: false,
  onPress: () => {},
};

export default Card;
