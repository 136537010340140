import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import { AuthProvider } from 'auth/contexts/auth';
import { SitesProvider } from 'sites/contexts/sites';
import { ChatProvider } from 'sites/contexts/chat';
import { BasketProvider } from 'basket/contexts/basket';
import { OrdersProvider } from 'orders/contexts/orders';
import { CategoriesProvider } from 'categories/contexts/categories';
import { CategoryServicesProvider } from 'categoryServices/contexts/categoryServices';
import { SeatsProvider } from 'seats/contexts/seats';
import { ProductsProvider } from 'products/contexts/products';
import { SeatSelectorProvider } from 'bookings/pages/SeatSelector/contexts/seatSelector';
import { BeachVisualizerProvider } from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import { WaiterAreasProvider } from 'waiterAreas/contexts/waiterAreas';
import { MapsProvider } from 'maps/contexts/maps';
// import { BookingsProvider } from 'bookings/contexts/bookings';
import { SocketProvider } from 'realtime/contexts/socket';
import { NotificationProvider } from 'realtime/contexts/notification';
import { AlertProvider } from 'common/contexts/alert';
import { LoadingProvider } from 'common/contexts/loading';
import { TranslationProvider } from 'common/contexts/translations';
import { PaymentsProvider } from 'payments/contexts/payments';
import { CallsProvider } from 'calls/contexts/calls';
import { SpecialOffersProvider } from 'specialOffers/contexts/specialOffers';
import AppStateHandler from 'common/components/AppStateHandler/AppStateHandler';
import { TemplatesProvider } from 'sites/contexts/templates';
import { LocalOrdersProvider } from 'orders/contexts/localOrders';
import { HourSlotProvider } from './orders/pages/OrderSelectDeliveryTime/context/hour-slot';
import { LocalizationFieldProvider } from './orders/pages/OrderLocalization/context/localizationField';
import { HistoryOrdersProvider } from './orders/contexts/historyOrders';

const AppProvider = ({ children }) => (
  <Suspense>
    <LoadingProvider>
      <TranslationProvider>
        <SpecialOffersProvider>
          <AlertProvider>
            <NotificationProvider>
              <AuthProvider>
                <SitesProvider>
                  <LocalOrdersProvider>
                    <ChatProvider>
                      <BasketProvider>
                        <BeachVisualizerProvider>
                          <WaiterAreasProvider>
                            <MapsProvider>
                              <OrdersProvider>
                                <HistoryOrdersProvider>
                                  <CategoriesProvider>
                                    <CategoryServicesProvider>
                                      <ProductsProvider>
                                        <SeatSelectorProvider>
                                          <SeatsProvider>
                                            <CallsProvider>
                                              {/* <BookingsProvider> */}
                                              <PaymentsProvider>
                                                <SocketProvider>
                                                  <AppStateHandler>
                                                    <HourSlotProvider>
                                                      <LocalizationFieldProvider>
                                                        <TemplatesProvider>
                                                          {children}
                                                        </TemplatesProvider>
                                                      </LocalizationFieldProvider>
                                                    </HourSlotProvider>
                                                  </AppStateHandler>
                                                </SocketProvider>
                                              </PaymentsProvider>
                                              {/* </BookingsProvider> */}
                                            </CallsProvider>
                                          </SeatsProvider>
                                        </SeatSelectorProvider>
                                      </ProductsProvider>
                                    </CategoryServicesProvider>
                                  </CategoriesProvider>
                                </HistoryOrdersProvider>
                              </OrdersProvider>
                            </MapsProvider>
                          </WaiterAreasProvider>
                        </BeachVisualizerProvider>
                      </BasketProvider>
                    </ChatProvider>
                  </LocalOrdersProvider>
                </SitesProvider>
              </AuthProvider>
            </NotificationProvider>
          </AlertProvider>
        </SpecialOffersProvider>
      </TranslationProvider>
    </LoadingProvider>
  </Suspense>
);

AppProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppProvider;
