import React from 'react';
import { TouchableOpacity } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as ScreenOrientation from 'expo-screen-orientation';
import useTranslation from 'common/contexts/translations';

import useAuth from 'auth/contexts/auth';
import useBasket from 'basket/contexts/basket';
import theme from 'styles';
import commonStyles from 'styles/commonStyles';
import Confirm from '../Confirm/Confirm';

const LogoutButton = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { setItems: setBasket } = useBasket();
  const { signout } = useAuth();

  const logout = () => {
    signout();
    setBasket([]);
    ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
    navigation.reset({
      index: 0,
      routes: [{ name: 'home' }],
    });
  };

  return (
    <Confirm confirmText={t('auth.confirmLogout')}>
      <TouchableOpacity style={commonStyles.headerIcon} onPress={() => logout()}>
        <FontAwesome
          color={theme.colors.light}
          size={theme.fontSizes.larger}
          name="sign-out"
        />
      </TouchableOpacity>
    </Confirm>
  );
};

export default LogoutButton;
