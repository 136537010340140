import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

import OrderStateBadge from 'orders/components/OrderStateBadge/OrderStateBadge';
import useCall from 'calls/contexts/calls';
import useOrder from 'orders/contexts/orders';
import theme from 'styles';

const BadgeController = ({ name }) => {
  const { items: calls } = useCall();
  const { items: orders } = useOrder();

  switch (name) {
    case 'calls':
      if (calls.length) {
        return (<View style={styles.badge} />);
      }
      return null;
    case 'orders':
      return (<OrderStateBadge orders={orders} />);
    default:
      return null;
  }
};

BadgeController.propTypes = {
  name: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    right: -6,
    top: -3,
    backgroundColor: theme.colors.info,
    borderRadius: theme.radius.rounded,
    width: theme.sizings.small,
    height: theme.sizings.small,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default BadgeController;
