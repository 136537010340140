import { StyleSheet } from 'react-native';
import theme from 'styles';

export default StyleSheet.create({
  image: {
    width: 130,
    height: 120,
    alignSelf: 'center',
    marginVertical: theme.sizings.large,
  },

  price: {
    alignItems: 'center',
  },

  creditCardContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.sizings.medium,
    padding: theme.sizings.small,
    backgroundColor: theme.colors.greyLightest,
    borderRadius: theme.radius.medium,
  },

  creditCardView: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  creditCardInfo: {
    marginLeft: theme.sizings.small,
    justifyContent: 'center',
  },

  activeBookingButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  activeBookingButton: {
    paddingHorizontal: 10,
    paddingVertical: theme.sizings.tiny,
  },

});
