import React, {
  createContext, useContext, useEffect, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  Platform, AppState,
} from 'react-native';
import useTranslation from 'common/contexts/translations';

import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';

import useAlert from 'common/contexts/alert';

const NotificationContext = createContext();

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

export const NotificationProvider = ({ children }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const [pushToken, setPushToken] = useState(null);
  const [appState, setAppState] = useState(AppState.currentState);

  useEffect(() => {
    const handleAppStateChange = async (nextAppState) => {
      if (nextAppState === 'active' && Platform.OS === 'android') {
        await Notifications.dismissAllNotificationsAsync();
      }
      setAppState(nextAppState);
    };

    const listener = AppState.addEventListener('change', handleAppStateChange);

    return () => {
      listener.remove();
    };
  }, [appState]);

  const registerForPushNotifications = useCallback(async () => {
    if (Platform.OS === 'web') return;

    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();

      let finalStatus = existingStatus;

      if (existingStatus !== 'granted') {
        try {
          const { status } = await Notifications.requestPermissionsAsync();

          finalStatus = status;
        } catch (e) {
          console.error(e);
          finalStatus = 'error';
        }
      }
      if (finalStatus !== 'granted') {
        // eslint-disable-next-line no-alert
        alert('Failed to get push token for push notification!');
        return;
      }
      const token = (await Notifications.getExpoPushTokenAsync()).data;

      setPushToken(token);
    } else {
      // eslint-disable-next-line no-alert
      alert('Must use physical device for Push Notifications');
    }
  }, []);

  useEffect(() => {
    registerForPushNotifications();
  }, [registerForPushNotifications]);

  useEffect(() => {
    const handleNotification = (notification) => {
      // for now dismiss all notification if the app is foregrounded
      if (appState === 'active' && notification.origin === 'received') {
        Notifications.dismissNotificationAsync(notification.notificationId);
      }
    };

    const notificationSubscription = Notifications.addNotificationReceivedListener(handleNotification);

    return () => {
      notificationSubscription.remove();
    };
  }, [appState, setAlert, t]);

  return (
    <NotificationContext.Provider value={{ pushToken, registerForPushNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useNotification = () => useContext(NotificationContext);

export default useNotification;
