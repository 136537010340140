import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import Text from 'common/components/Text/Text';
import i18n from 'i18next';
import useSite from 'sites/contexts/sites';
import Gradient from '../Gradient/Gradient';

const getCurrencySize = (size) => {
  switch (size) {
    case 'huge': return 'larger';
    case 'largest': return 'large';
    case 'larger': return 'medium';
    case 'large': return 'default';
    case 'medium': return 'small';
    case 'default': return 'tiny';
    case 'small': return 'tiny';
    default:
      return 'tiny';
  }
};

const getPaddingBottom = (size) => {
  switch (size) {
    case 'huge': return 6;
    case 'largest': return 3;
    case 'larger': return 1;
    case 'default': return 1;
    default:
      return 0;
  }
};

const Price = ({
  price,
  size,
  siteCurrency,
  style,
  viewStyle,
  withGradient,
  ...props
}) => {
  const styles = StyleSheet.create({
    wrapper: {
      flexDirection: 'row',
      alignItems: 'baseline',
    },
    amount: {
      fontFamily: 'MontserratBold',
    },
    currency: {
      fontFamily: 'MontserratBold',
      paddingBottom: getPaddingBottom(size),
      alignSelf: 'flex-end',
    },
  });

  const { item: site } = useSite();
  const checkCurrency = siteCurrency || site?.currency || 'EUR';

  const getCurrencySymbol = (locale, currency) => (0).toLocaleString(locale, {
    style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0,
  }).replace(/\d/g, '').trim();

  const getCurrencySymbolPosition = (locale, currency) => {
    const currencyString = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay: 'code',
    }).format(99); // number doesn't matter here

    if (currencyString.slice(0, 3) === currency) {
      return 'before';
    }
    if (currencyString.slice(-3) === currency) {
      return 'after';
    }
  };

  return (
    <View style={[styles.wrapper, viewStyle]}>
      {withGradient && <Gradient height={30} />}

      <View style={{
        display: 'flex',
        flexDirection: getCurrencySymbolPosition(i18n.language, checkCurrency) === 'before'
          ? 'row' : 'row-reverse',
      }}
      >
        <Text
          noLineHeight
          style={[styles.currency, style]}
          size={getCurrencySize(size)}
          {...props}
        >
          {getCurrencySymbol(i18n.language, checkCurrency)}
        </Text>
        <Text
          noLineHeight
          style={[styles.amount, style]}
          size={size}
          {...props}
        >
          {price}
        </Text>
      </View>

    </View>
  );
};

Price.propTypes = {
  price: PropTypes.number,
  withGradient: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.any,
  viewStyle: PropTypes.any,
  siteCurrency: PropTypes.string,
};

Price.defaultProps = {
  price: 0,
  withGradient: false,
  size: 'default',
  color: null,
  style: null,
  viewStyle: null,
  siteCurrency: null,
};

export default Price;
