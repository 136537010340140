import { useNavigation } from '@react-navigation/native';
import ItemCardProduct from 'common/components/ItemCardProduct/ItemCardProduct';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useTranslation from 'common/contexts/translations';
import React, { useCallback } from 'react';
import { View } from 'react-native';
import useSite from 'sites/contexts/sites';
import useSpecialOffers from 'specialOffers/contexts/specialOffers';
import theme from 'styles';
import CategoryBackground from 'assets/defaultBackground.jpg';
import styles from './styles/CategoriesList.styles';

const SiteProducts = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { items: specialOffers } = useSpecialOffers();
  const { item: site } = useSite();
  const template = site?.template;

  const findReduction = useCallback(
    (categoryId, type) => {
      const reduction = specialOffers.find(
        (offer) => offer[type].findIndex((c) => c.id === categoryId) >= 0,
      );

      if (reduction) {
        return (
          <View
            style={[
              styles.reductionBadge,
              {
                backgroundColor:
                  site?.template?.secondaryColor || theme.colors.danger,
              },
            ]}
          >
            <Text
              customColor={
                site?.template?.secondaryTextColor || theme.colors.light
              }
              size="medium"
              isBold
              isTitleText
            >
              {t('beach.currentlyDiscounted', {
                discountRate: reduction.discount_rate,
              })}
            </Text>
          </View>
        );
      }
      return null;
    },
    [specialOffers, t, site.template],
  );

  const categoriesWithProducts = site?.categories
    ?.filter(({ parent }) => !parent)
    .reduce((acc, category) => {
      // check if children categories of current category has products
      const count = category.children.reduce(
        (prev, curr) => prev + curr.products.length,
        category.products.length,
      );

      // if current category and its children doesn't have any products, we don't display it
      if (count > 0) {
        return [...acc, { ...category, count }];
      }

      return acc;
    }, [])
    .sort((a, b) => Number(a.ordering || 0) - Number(b.ordering || 0)) || [];

  const categoriesWithServices = site?.category_services
    .filter(({ parent }) => !parent)
    .reduce((acc, categoryService) => {
      // check if children categories of current category has services
      const count = categoryService.children.reduce(
        (prev, curr) => prev + curr.services.length,
        categoryService.services.length,
      );

      // if current category and its children doesn't have any services, we don't display it
      if (count > 0) {
        return [...acc, { ...categoryService, count }];
      }

      return acc;
    }, []);

  const renderCategories = (type, rootCategory) => {
    const mainTitle = type === 'products'
      ? site?.pleaseMyCar
        ? site?.pleaseMyCarDriver ? t('beach.productsDriver') : t('beach.productsValet')
        : t('beach.products')
      : t('beach.services');
    const redirection = type === 'products' ? 'CategoryDetail' : 'CategoryServiceDetail';

    if (rootCategory?.length > 0) {
      return (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexFlow: 'wrap',
            width: '100%',
          }}
        >
          <Text
            isBold
            isTitleText
            size="large"
            style={styles.title}
            customColor={template?.backdropTextColor}
          >
            {mainTitle}
          </Text>
          {rootCategory
            .filter(({ parent }) => !parent)
            .map((category) => (
              <View
                key={category.id}
                style={{
                  width:
                    template?.categoriesDisplayType === 'grid'
                    && template?.categoriesByColumn !== '1'
                      ? '50%'
                      : '100%',
                  paddingHorizontal: theme.sizings.medium,
                }}
              >
                <ItemCardProduct
                  onPress={() => navigation.navigate(redirection, { category })}
                  image={
                    template?.categoriesDisplayType !== 'list'
                      ? category.image || CategoryBackground
                      : null
                  }
                  layout={template?.categoriesDisplayType}
                >
                  <View
                    style={[
                      template?.categoriesDisplayType === 'list' && {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flexFlow: 'wrap',
                        paddingBottom: theme.sizings.small,
                      },
                      template?.categoriesDisplayType === 'list-icon' && {
                        justifyContent: 'space-between',
                        paddingBottom: theme.sizings.medium,
                        height: '100%',
                      },
                    ]}
                  >
                    <View
                      style={[
                        template?.categoriesDisplayType !== 'grid'
                          ? { marginBottom: theme.sizings.small }
                          : { padding: theme.sizings.small },
                        template?.categoriesDisplayType === 'list-icon' && {
                          width: '100%',
                        },
                      ]}
                    >
                      <TranslatedText
                        isBold
                        isTitleText
                        size="large"
                        style={{ color: theme.colors.dark }}
                        value={category.name}
                      />
                    </View>

                    {findReduction(category.id, 'categories')}

                    <View
                      style={[
                        styles.productsCountView,
                        template?.categoriesDisplayType === 'grid' && {
                          position: 'absolute',
                          top: -150,
                          right: 8,
                        },
                      ]}
                    >
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          paddingHorizontal: theme.sizings.small,
                          backgroundColor: theme.colors.light,
                        }}
                      >
                        <Text
                          style={styles.productCount}
                          color="light"
                          size="medium"
                          isButtonText
                          customColor={template?.cardTextColor}
                        >
                          {type === 'products'
                            ? category.children.reduce(
                              (prev, curr) => prev + curr.products.length,
                              category.products.length,
                            )
                            : category.children.reduce(
                              (prev, curr) => prev + curr.services.length,
                              category.services.length,
                            )}
                        </Text>
                      </View>
                    </View>
                  </View>
                </ItemCardProduct>
              </View>
            ))}
        </View>
      );
    }
  };

  return (
    <Page
      scroll
      padding
      backgroundImage={template?.imageBackdrop}
      style={{ marginBottom: 0 }}
    >
      {renderCategories('products', categoriesWithProducts)}
      {renderCategories('services', categoriesWithServices)}
    </Page>
  );
};

export default SiteProducts;
