import React from 'react';
import PropTypes from 'prop-types';
import TP from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import dayjs from 'dayjs';

const TimePicker = ({ value, onChange }) => {
  let val = null;

  if (!value) val = null;
  else if (value && typeof value === 'string') val = dayjs(value);
  else val = value;

  return (
    <TP
      value={val}
      onChange={onChange}
      showSecond={false}
      minuteStep={15}
    />
  );
};

TimePicker.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TimePicker;
